import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, mergeMap, filter, map } from 'rxjs';
import { retry, take, tap } from 'rxjs/operators';
import { FeatureActivityOptions, FeaturesState, Feature } from './feature-toggling.types';

@Injectable({
	providedIn: 'root',
})
export class FeatureTogglingService {

	featuresInProgress = new BehaviorSubject<Feature[]>([]);
	featuresInProgress$ = this.featuresInProgress.asObservable();

	private cachedFeaturesState = new BehaviorSubject<FeaturesState>({});
	public cachedFeaturesState$ = this.cachedFeaturesState.asObservable();

	private defaultFeatureActivityOptions: FeatureActivityOptions = {
		cache: true,
		checkOnlyCache: false,
	};

	constructor(
		private http: HttpClient,
	) {}

	/**
	 * Метод для определения активности определённой фичи
	 * Отправляет запрос, либо берёт признак активности фичи из кэша
	 */
	public checkFeatureActivity(
		serviceUrl: string,
		featureEnum: Feature,
		options: FeatureActivityOptions = this.defaultFeatureActivityOptions,
		queryParams: {[key: string]: string} = {}
	): Observable<boolean> {
		const cachedFeaturesState = this.cachedFeaturesState.getValue();

		const alreadyInProgress = this.featuresInProgress.getValue().includes(featureEnum);

		if (alreadyInProgress) {
			return this.featuresInProgress$
				.pipe(
					map(features => features.includes(featureEnum)),
					filter(isIncludedInList => !isIncludedInList),
					mergeMap(() => this.checkFeatureActivity(serviceUrl, featureEnum, options, queryParams)),
					take(1),
				);
		}

		if (options.checkOnlyCache) {
			return of(cachedFeaturesState[featureEnum]).pipe(take(1));
		}

		if (!options.cache) {
			return this.requestFeatureActivity(serviceUrl, featureEnum, queryParams);
		}

		const availableInCache = cachedFeaturesState.hasOwnProperty(featureEnum);

		if (!availableInCache) {
			return this.requestFeatureActivity(serviceUrl, featureEnum, queryParams);
		}

		return of(cachedFeaturesState[featureEnum]).pipe(take(1));
	}

	/**
	 * Метод для изменения состояния активности определённой фичи
	 */
	public setFeatureActivity(
		featureEnum: Feature,
		activity: boolean,
	): void {
		this.cachedFeaturesState.next({
			...this.cachedFeaturesState.getValue(),
			[featureEnum]: activity,
		});
	}

	/**
	 * Метод для получения закешированного состояния активности фич
	 */
	public get cachedFeatures(): FeaturesState {
		return this.cachedFeaturesState.getValue();
	}

	/**
	 * Метод для отправки запроса для определения активности определённой фичи
	 */
	private requestFeatureActivity(serviceUrl: string, featureEnum: Feature, queryParams: {[key: string]: string}): Observable<boolean> {
		const params = new HttpParams({fromObject: {...queryParams}});
		this.addFeatureToProgressList(featureEnum);

		return this.http.get<boolean>(`/api/${serviceUrl}/features/${featureEnum}`, {params})
			.pipe(
				retry(2),
				tap({
					next: (isActive: boolean) => {
						this.setFeatureActivity(featureEnum, isActive);
						this.removeFeatureFromProgressList(featureEnum);
					},
					error: () => {
						this.setFeatureActivity(featureEnum, false);
						this.removeFeatureFromProgressList(featureEnum);
					},
				})
			);
	}

	/** Метод для добавления фичи в список фич со статусом "ожидание ответа сервера" */
	addFeatureToProgressList(featureEnum: Feature) {
		this.featuresInProgress.next([...this.featuresInProgress.getValue(), featureEnum]);
	}

	/** Метод для удаления фичи из списка фич со статусом "ожидание ответа сервера" */
	removeFeatureFromProgressList(featureEnum: Feature) {
		const featuresInProgress = this.featuresInProgress.getValue();
		const targetIndex = featuresInProgress.findIndex(feature => feature === featureEnum);

		if (targetIndex !== -1) {
			featuresInProgress.splice(targetIndex, 1);
			this.featuresInProgress.next(featuresInProgress);
		}
	}

}
