import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DeviceService } from '../../../services/device.service';

@Component({
	selector: 'b-shared-emoji',
	template: `
		<b-shared-emoji-view
			[isEmojiSupported]="isEmojiSupported"
			[class]="classEmoji"
			[classInner]="classInner"
			[emoji]="emoji"
		></b-shared-emoji-view>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiComponent implements OnInit {
	isEmojiSupported: boolean | null = null;

	/**
	 * Эмодзи
	 *
	 */
	@Input() emoji: string;

	/**
	 * Классы компонента
	 *
	 */
	@Input() classEmoji: string;

	/**
	 * Классы для внутреннего блока
	 *
	 */
	@Input() classInner: string;

	constructor(public deviceService: DeviceService) {}

	ngOnInit() {
		this.isEmojiSupported = this.deviceService.isEmojiSupported() && this.deviceService.сolorEmojiIsSupported();
	}

}
