import {Inject, Injectable} from '@angular/core';
import {
	HttpErrorResponse,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { of, throwError, timer } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';


@Injectable()
export class MockService implements HttpInterceptor {
	constructor(
		@Inject('mockedResponses') private mockedResponses,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		for (const mock of this.mockedResponses.filter((m) => m.active !== false)) {
			if (request.url === mock.url) {
				if (mock.method && request.method !== mock.method) {
					continue;
				}

				const status = mock?.status || 200;
				const isSuccess = status === 200;
				let response;

				if (isSuccess) {
					response = of(
						new HttpResponse({
							status,
							body: mock.respBody.value,
							headers: new HttpHeaders(mock?.headers || {}),
						}),
					);
				} else {
					response = throwError(
						() => new HttpErrorResponse({
							status,
							error: mock.respBody.error,
						}),
					);
				}

				if (mock.delay) {
					return isSuccess
						? response.pipe(delay(mock.delay))
						: timer(mock.delay).pipe(mergeMap(() => response));
				} else {
					return response;
				}
			}
		}

		return next.handle(request);
	}
}
