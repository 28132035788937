import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InnPipe } from './inn.pipe';

@NgModule({
	declarations: [InnPipe],
	imports: [CommonModule],
	exports: [InnPipe],
})
export class InnPipeModule {}
