import {
	Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, Input, ViewChildren, QueryList, ElementRef, ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs';
import { ChipsValue } from './models/ChipsValue';


@Component({
	selector: '[b-shared-chips]',
	templateUrl: './chips.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: ChipsComponent,
		}
	],
})
export class ChipsComponent implements OnInit, OnDestroy, ControlValueAccessor {

	/** атрибут type для input-ов */
	@Input() type: 'radio' | 'checkbox' = 'radio';

	/** список тегов */
	@Input() chips: string[] | null = null;

	@Input() wrapperClass: string | null;
	@Input() labelClass: string | null = 'chip';
	@Input() inputClass: string | null = 'chip__input';
	@Input() contentClass: string | null = 'chip__btn';

	fieldValue$ = new BehaviorSubject<ChipsValue>(null);
	fieldValue = this.fieldValue$
		.asObservable()
		.pipe(distinctUntilChanged());

	onChange = (value: ChipsValue) => {};
	onTouched = () => {};
	touched = false;
	disabled: boolean = null;
	subscriptions = new Subscription();
	inputsName = Guid.create().toString();

	@ViewChildren('inputsElements') inputsElements: QueryList<ElementRef<HTMLInputElement>>;

	constructor(
		private changeDetector: ChangeDetectorRef,
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.fieldValue.subscribe({
				next: value => {
					if (!this.inputsElements?.toArray()) {
						this.changeDetector.detectChanges();
					}

					const inputsElements = this.inputsElements?.toArray();

					this.onChange(value);

					if (this.type === 'checkbox' && Array.isArray(value)) {
						inputsElements.forEach(checkbox => {
							checkbox.nativeElement.checked = value.includes(checkbox.nativeElement.value);
						});
					} else if (this.type === 'radio' && typeof value === 'string') {
						inputsElements.forEach(checkbox => {
							checkbox.nativeElement.checked = value === checkbox.nativeElement.value;
						});
					} else {
						inputsElements.forEach(checkbox => {
							checkbox.nativeElement.checked = false;
						});
					}
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	writeValue(value: ChipsValue) {
		this.fieldValue$.next(value);
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	changeHandler(event: Event) {
		const targetInput = event.target as HTMLInputElement;
		const tagName: string = targetInput.value;
		let inputValue: boolean;

		if (event instanceof KeyboardEvent) {
			inputValue = this.type === 'radio' || !targetInput.checked;
		} else {
			inputValue = targetInput.checked;
		}

		targetInput.checked = inputValue;
		const currentValue = this.fieldValue$.getValue();
		let model: ChipsValue = null;

		this.markAsTouched();

		if (this.type === 'checkbox' && Array.isArray(currentValue)) {
			model = this.toggle(
				(currentValue || []),
				tagName,
				inputValue,
			);
		} else if (this.type === 'radio' && typeof currentValue === 'string') {
			model = inputValue ? tagName : null;
		} else {
			model = null;
		}

		this.fieldValue$.next(model);
	}

	toggle(array: string[] = [], item: string, value: boolean): string[] {
		const newArray = [...array];

		if (value) {
			newArray.push(item);
		} else {
			const targetIndex = newArray.findIndex(i => i === item);
			if (targetIndex !== -1) newArray.splice(targetIndex, 1);
		}

		return newArray;
	}

	trackByChips(index: number, chip: string) {
		return chip;
	}

}
