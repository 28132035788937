import {
	Component,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	Input,
	OnInit,
	ChangeDetectorRef,
	Inject,
	ElementRef,
	Output,
	EventEmitter,
	ViewChild,
	AfterViewInit,
	OnDestroy,
} from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MultiselectGroup, MultiselectGroupType, MultiselectItemsList, MultiselectMode, MultiselectOverlayPosition, MultiselectValueViewMode } from './multiselect.types';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { DOCUMENT } from '@angular/common';
import { ScrollService } from '../../../services/scroll.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { OverlayService2 } from '../overlay/overlay.service';

@Component({
	selector: 'b-shared-multiselect',
	templateUrl: './multiselect.component.html',
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translate3d(0, {{translateY}}, 0)' }),
				animate('200ms ease-in', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
			], {params : { translateY: '2%' }}),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0, transform: 'translate3d(0, {{translateY}}, 0)' })),
			], {params : { translateY: '2%' }}),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: MultiSelectComponent,
		}
	],
})
export class MultiSelectComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {

	overlayIsOpened$ = new BehaviorSubject<boolean>(false);
	overlayIsOpened = this.overlayIsOpened$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _overlayIsOpened(): boolean {
		return this.overlayIsOpened$.getValue();
	}

	// режим работы мультиселекта
	@Input() mode: MultiselectMode = MultiselectMode.checkbox;

	// тип отображаемого значения в инпуте
	@Input() inputViewMode: MultiselectValueViewMode = MultiselectValueViewMode.placeholder;

	// режим позиционирования выпадающего слоя:
	// вверх/вниз или автоматический режим, при котором слой будет всплывать туда, где больше места в видимой области окна браузера
	@Input() suggestPosition: MultiselectOverlayPosition = MultiselectOverlayPosition.auto;

	// иконка стрелочки
	@Input() dropIconName: string = 'icons_24_1-5_arrow-down';

	/* Флаг для вкл/выкл режима закрытия слоя при нажатии на Escape **/
	@Input() closeOnEsc: boolean = true;

	/** флаг для скрытия кнопок закрытия оверлеев при расскрытом мультиселекте */
	@Input() hideCloseButtons: boolean = false;

	@Input() placeholder: string;
	@Input() disabled: string;
	@Input() countPlural: [string, string, string];
	@Input() uniqueElementPropFn = item => item?.id || Guid.create().toString();
	@Input() uniqueGroupPropFn = group => group?.id || Guid.create().toString();

	@Output() showHandler = new EventEmitter();
	@Output() hideHandler = new EventEmitter();

	@ViewChild('viewValueElement') viewValueElement: ElementRef<HTMLElement>;
	@ViewChild('listContainer') listContainer: ElementRef<HTMLElement>;
	@ViewChild('triggerButton') triggerButton: ElementRef<HTMLElement>;
	@ViewChild('overlay') overlay: ElementRef<HTMLElement>;

	subscriptions = new Subscription();
	onChange = (value) => {};
	onTouched = () => {};
	touched = false;
	fieldIsDisabled = false;
	position: MultiselectOverlayPosition;
	groupTypes = MultiselectGroupType;
	model: MultiselectItemsList;
	groupedModel: MultiselectGroup[];
	modeTypes = MultiselectMode;
	guid = Guid;
	firstChange = true;

	plural;
	pluralLoaded$ = new BehaviorSubject<boolean>(false);
	pluralLoaded = this.pluralLoaded$.asObservable();

	constructor(
		public changeDetector: ChangeDetectorRef,
		@Inject(DOCUMENT) private document: Document,
		public elementRef: ElementRef,
		private scrollService: ScrollService,
		private overlayService: OverlayService2,
	) {}

	async ngOnInit() {
		this.subscriptions.add(
			fromEvent(this.document, 'pointerdown').subscribe(this.documentListener)
		);

		this.subscriptions.add(
			this.overlayIsOpened.subscribe({
				next: overlayIsOpened => {
					if (overlayIsOpened) {
						this.overlayService.registerDropdownOverlay(this);
					} else {
						this.overlayService.removeDropdownOverlay(this);
					}
				}
			})
		);

		if (this.inputViewMode === MultiselectValueViewMode.count) {
			this.plural = (await import('plural-ru')).default;
			this.pluralLoaded$.next(true);
			this.changeDetector.detectChanges();
		}
	}

	ngAfterViewInit() {
		this.updatePosition();
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	updatePosition() {
		this.position = this.suggestPosition === MultiselectOverlayPosition.auto
			? this.getSuggestAutoPosition()
			: this.suggestPosition;
	}

	writeValue(value) {
		if (this.mode === MultiselectMode.grouped) {
			this.groupedModel = value?.length ? this.extendGroupedModel(value) : [];
		} else {
			this.model = value?.length ? this.extendModel(value, this.mode) : [];
		}

		// при первом изменении обновляем значение fieldControl'a, расширенное extendGroupedModel/extendModel
		if (this.firstChange && value) {
			this.firstChange = false;
			this.onChange(this.mode === MultiselectMode.grouped ? this.groupedModel : this.model);
		}
	}

	get overlayNgClass() {
		return {
			'pos-top-100p': this.position === MultiselectOverlayPosition.under,
			'pos-bottom-100p': this.position === MultiselectOverlayPosition.above,
		};
	}

	get animationParams() {
		return {
			value: this._overlayIsOpened ? ':enter' : ':leave',
			params: {
				translateY: this.position === MultiselectOverlayPosition.under ? '-2%' : '2%',
			},
		};
	}

	getSuggestAutoPosition() {
		const viewportSize = this.scrollService?.getViewportSize();
		const inputRect = this.viewValueElement?.nativeElement?.getBoundingClientRect();
		const under = viewportSize?.viewportHeight - inputRect?.top - inputRect?.height;
		const above = inputRect?.top;

		return under > above
			? MultiselectOverlayPosition.under
			: MultiselectOverlayPosition.above;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.fieldIsDisabled = disabled;

		this.changeDetector.detectChanges();
	}

	get isDisabled() {
		return this.fieldIsDisabled || this.disabled;
	}

	get valueView() {
		switch (this.inputViewMode) {
		case MultiselectValueViewMode.chips:
			return null; // TODO: доработать при необходимости
		case MultiselectValueViewMode.list:
			return null; // TODO: доработать при необходимости
		case MultiselectValueViewMode.count:
			return this.getCountView();
		case MultiselectValueViewMode.placeholder:
		default:
			return this.placeholder;
		}
	}

	getCountView() {
		switch (this.mode) {
		case MultiselectMode.checkbox:
			if (!this.pluralLoaded$.getValue()) {
				return '...'; // TODO: заменить на лоадер
			}

			if (this.model.selectedItems?.length > 1 && this.countPlural?.length === 3) {
				return `${this.model.selectedItems.length} ${this.plural(this.model.selectedItems.length, ...this.countPlural)}`;
			} else {
				return this.model.selectedItems?.[0]?.label || this.placeholder;
			}
		// TODO: при необходимости доработать отображение для других режимов мультиселекта
		default:
			return this.placeholder;
		}
	}

	changeModel() {
		this.markAsTouched();

		if (this.mode === MultiselectMode.grouped) {
			this.groupedModel = this.groupedModel?.length ? this.extendGroupedModel(this.groupedModel) : [];
			this.onChange(this.groupedModel);
		} else {
			this.model = this.model?.length ? this.extendModel(this.model, this.mode) : [];
			this.onChange(this.model);
		}

	}

	extendModel(model: MultiselectItemsList, mode: 'checkbox' | 'radio') {
		let result: MultiselectItemsList = [...model];

		if (mode === 'checkbox') {
			result = result.map(i => {
				if (!i.hasOwnProperty('checked')) {
					i.checked = false;
				}

				return i;
			});

			result.selectedItems = result.filter(i => i.checked);
			result.selectedIds = result.selectedItems.map(i => i.id);

			return result;
		}

		if (mode === 'radio') {
			const selectedRadioId = model.selectedRadioId || result.find(i => i.checked).id;
			result = result.map(i => {
				i.checked = Boolean(selectedRadioId && i.id === selectedRadioId);
				return i;
			});

			result.selectedRadioId = selectedRadioId;
			result.selectedRadio = model.selectedRadio || result.find(i => i.checked);

			return result;
		}

		return result;
	}

	extendGroupedModel(model: MultiselectGroup[]) {
		return [...model].map(group => ({
			...group,
			items: this.extendModel(group.items, group.type),
		}));
	}

	changeOverlayVisibility(visibility: boolean) {
		if (!this._overlayIsOpened && visibility) {
			this.updatePosition();
			this.showHandler?.emit();
		} else if (this._overlayIsOpened && !visibility) {
			this.hideHandler?.emit();
		}

		this.overlayIsOpened$.next(visibility);
		this.changeDetector.detectChanges();

		// если оверлей с подсказками всплывает вверх, то скроллим список в нижнее положение
		if (this.position === MultiselectOverlayPosition.above) {
			const ulElement = this.listContainer?.nativeElement;
			ulElement?.scrollTo(0, ulElement.scrollHeight);
		}
	}

	toggleOverlay() {
		if (this.isDisabled) {
			return;
		}

		this.markAsTouched();
		this.changeOverlayVisibility(!this._overlayIsOpened);
	}


	documentListener = (event) => {
		const isBeyondBorders = !this.elementRef?.nativeElement?.contains(event.target as HTMLElement);

		if (isBeyondBorders) {
			this.changeOverlayVisibility(false);
		}
	};

	trackByElements = (index, element) => {
		return this.uniqueElementPropFn(element);
	};

	trackByGroups = (index, group) => {
		return this.uniqueGroupPropFn(group);
	};

	public hideOverlay() {
		this.overlayIsOpened$.next(false);
	}

	getTriggerElement() {
		return this.triggerButton?.nativeElement;
	}

	getDropdownOverlay() {
		return this.overlay?.nativeElement;
	}

}
