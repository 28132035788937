import { Pipe, PipeTransform } from '@angular/core';
import { SharedFilter } from './models/SharedFilter';

@Pipe({
	name: 'sharedFilter',
	pure: true,
})
export class SharedFilterPipe implements PipeTransform {

	transform<T>(items: ReadonlyArray<T>, filter: SharedFilter<T>, ...args: any[]): T[] {
		return items.filter(item => filter(item, ...args));
	}

}
