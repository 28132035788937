<div *ngFor="let chip of chips; trackBy: trackByChips" [attr.class]="wrapperClass">
	<label [attr.class]="labelClass">
		<input
			[attr.type]="type"
			[attr.class]="inputClass"
			[attr.name]="inputsName"
			[value]="chip"
			(change)="changeHandler($event)"
			sharedKeyboardControl
			(activeElementKeyHandler)="changeHandler($event)"
			[attr.disabled]="disabled || null"
			#inputsElements
		>
		<span [attr.class]="contentClass">{{ chip }}</span>
	</label>
</div>
