/** Режим работы директивы */
export enum ImageType {
	/** изображение для тега <img> */
	img = 'img',
	/** изображение для любого DOM-элемента через background-image */
	background = 'background',
}

/** Форматы изображений */
export enum ImageExtension {
	avif = 'avif',
	webp = 'webp',
	jpg = 'jpg',
	jpeg = 'jpeg',
	png = 'png',
	svg = 'svg',
}
