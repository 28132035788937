import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VideoDirective } from './video.directive';

@NgModule({
	declarations: [
		VideoDirective,
	],
	imports: [
		CommonModule,
	],
	exports: [
		VideoDirective,
	],
})
export class VideoModule {}
