import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class ScriptLazyLoadingService {
	loadedLibraries: { [url: string]: ReplaySubject<void> } = {};
	private renderer: Renderer2;

	constructor(
		@Inject(DOCUMENT) private readonly document: any,
		private rendererFactory: RendererFactory2,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	loadScript(url: string): Observable<any> {
		if (this.loadedLibraries[url]) {
			return this.loadedLibraries[url].asObservable();
		}

		this.loadedLibraries[url] = new ReplaySubject();

		const script = this.renderer.createElement('script');
		script.type = 'text/javascript';
		script.src = url;

		script.onload = () => {
			this.loadedLibraries[url].next();
			this.loadedLibraries[url].complete();
		};

		script.onerror = () => {
			this.loadedLibraries[url].error(`Ошибка во время загрузки скрипта: ${url}`);
		};

		this.renderer.appendChild(this.document.body, script);

		return this.loadedLibraries[url].asObservable();
	}
}
