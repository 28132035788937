import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fromEvent } from 'rxjs';

@Directive({
	selector: '[sharedFormValidation]',
})
export class FormValidationDirective implements OnInit {
	@Input() formGroup: UntypedFormGroup;

	constructor(private host: ElementRef<HTMLFormElement>, private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		fromEvent(this.host.nativeElement, 'submit').subscribe(() =>
			this.validateAllFormFields(this.formGroup),
		);
	}

	private validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsDirty({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			}
		});

		this.cdr.detectChanges();
	}
}
