import {
	ChangeDetectionStrategy,
	Component,
	Input,
	SimpleChange,
} from '@angular/core';
import { SharedService } from '../../../services/shared/shared.service';
import { currencySymbols } from '../../../types';
import { Currency } from '../../../types/currency';
import {AmountRoundingMode, CurrencyPosition, CurrencyView} from './amount.types';

@Component({
	selector: '[b-shared-amount]',
	templateUrl: './amount.component.html',
	styleUrls: ['./amount.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'amount',
})
export class AmountComponent {

	/** Денежная сумма */
	@Input() get value(): number | null {
		return this._rawValue;
	}
	set value(value: string | number | null) {
		let rawValue: string | number | null = value;

		if (rawValue === null || rawValue === undefined || rawValue === '') {
			rawValue = 0;
		}

		rawValue = typeof rawValue === 'string'
			? this.stringToNumber(rawValue)
			: rawValue;

		if (typeof rawValue !== 'number' || isNaN(rawValue)) {
			rawValue = 0;
		}

		this._rawValue = rawValue;

		let formattedValue: string | number = Math.abs(rawValue);
		formattedValue = this.round(formattedValue);
		const thousandSeparator = this.getThousandSeparator(formattedValue);
		formattedValue = formattedValue.toString().replace('.', this.decimalSeparator);
		formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);

		this.formattedValue = formattedValue;
		this.splittedValue = thousandSeparator ?
			formattedValue.split(thousandSeparator) :
			[formattedValue];
	}

	/** ISO-код валюты */
	@Input() currency: Currency = Currency.RUB;

	/** Тип отображения валюты */
	@Input() currencyView: CurrencyView = CurrencyView.symbol;

	/** Позиция символа или кода валюты относительно денежной суммы */
	@Input() currencyPosition: CurrencyPosition = CurrencyPosition.after;

	/** Флаг для отображения пробела между суммой и валютой */
	@Input() space: boolean = true;

	/** Разделитель между каждым третьим символом (тысячи) в целом числе */
	@Input() get thousandSeparator(): string {
		return this._thousandSeparator;
	}
	set thousandSeparator(value: string) {
		this.isDefaultThousandSeparator = false;
		this._thousandSeparator = value;
		this.actualizeValue();
	}

	/** Разделитель между целой и десятичной частью числа */
	@Input() get decimalSeparator(): string {
		return this._decimalSeparator;
	}
	set decimalSeparator(value: string) {
		this._decimalSeparator = value;
		this.actualizeValue();
	}

	/** Режим округления и вывода дробной части числа */
	@Input() get roundingMode(): AmountRoundingMode | keyof typeof AmountRoundingMode {
		return this._roundingMode;
	}
	set roundingMode(value: AmountRoundingMode | keyof typeof AmountRoundingMode) {
		this._roundingMode = value;
		this.actualizeValue();
	}

	/** Флаг для отображения математического символа (+/−) */
	@Input() mathSymbol: boolean = false;

	@Input() set precision(value: number) {
		console.warn('b-shared-amount: Параметр precision устарел, используйте roundingMode');
	}

	currencyPositionEnum = CurrencyPosition;
	private _rawValue: number | null = null;
	public isDefaultThousandSeparator: boolean = true;
	public formattedValue: string | null = null;
	private _thousandSeparator: string | null = '&nbsp;';
	public splittedValue: string[] | null = null;
	private _decimalSeparator: string = '.';
	private _roundingMode: AmountRoundingMode | keyof typeof AmountRoundingMode = AmountRoundingMode.floor;

	constructor(
		private sharedService: SharedService,
	) {}

	isNewValue(change: SimpleChange): boolean {
		return change?.currentValue !== change?.previousValue;
	}

	stringToNumber(value: string): number {
		return Number(value.replace(/[^0-9.,-]+/g, '').replace(',', '.'));
	}

	get currencySymbol(): string | null {
		if (!this.currency || this.currencyView === CurrencyView.none) {
			return null;
		}

		const currency = {
			code: this.currency,
			symbol: currencySymbols?.[this.currency] || this.currency,
		};

		if (!currency) {
			console.warn(`Компонент b-shared-amount: не найден указанный ISO-код валюты - ${this.currency}`);
			return null;
		}

		switch (this.currencyView) {
		case CurrencyView.code:
			return currency.code;
		case CurrencyView.symbol:
		default:
			return currency.symbol;
		}
	}

	get canShowMathSymbol(): boolean {
		return this.mathSymbol && this.value !== 0;
	}

	get canShowPlusSymbol(): boolean {
		return this.canShowMathSymbol && this.value > 0;
	}

	get canShowMinusSymbol(): boolean {
		return this.canShowMathSymbol && this.value < 0;
	}

	private getThousandSeparator(value: number | string): string {
		const integerLength = Number.parseInt(value.toString()).toString().length;
		const { minLengthForIncludeSeparator } = this.sharedService.config.amount;

		return integerLength >= minLengthForIncludeSeparator ?
			this.thousandSeparator :
			'';
	}

	private round(value: number): string {
		let result: number | string;

		switch (this.roundingMode) {
		case AmountRoundingMode.fraction10:
			result = (value >= 10 || Number.isInteger(value)) ?
				Math.floor(value) :
				value.toFixed(2);
			break;
		case AmountRoundingMode.twoFractionalDigits:
			result = value.toFixed(2);
			break;
		case AmountRoundingMode.round:
			result = Math.round(value);
			break;
		case AmountRoundingMode.ceil:
			result = Math.ceil(value);
			break;
		case AmountRoundingMode.trunc:
			result = Math.trunc(value);
			break;
		case AmountRoundingMode.floor:
		default:
			result = Math.floor(value);
			break;
		}

		return result.toString();
	}

	public get valueContainsFraction(): boolean {
		return this.formattedValue && this.formattedValue
			.replace(',', '.')
			.split('.')
			.filter(part => Boolean(part.length))
			.length > 1;
	}

	private actualizeValue() {
		this.value = this._rawValue;
	}

}
