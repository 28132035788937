import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeyboardControlDirective } from './keyboard-control.directive';

@NgModule({
	declarations: [
		KeyboardControlDirective,
	],
	imports: [
		CommonModule,
	],
	exports: [
		KeyboardControlDirective,
	],
})
export class KeyboardControlModule {}
