<div class="container">
	<div class="lg:mx-16 mx-12">
		<ng-container *ngIf="beforeContentRef" [ngTemplateOutlet]="beforeContentRef"></ng-container>

		<div class="row row--g-0 mb-20" *ngIf="sharedService?.config?.analytics?.toggler">
			<div class="col-6">
				<label class="checkbox">
					<input
						class="checkbox__input"
						type="checkbox"
						[(ngModel)]="gtmValue"
						(ngModelChange)="gtmValueChanged()"
					>
					<svg
						b-shared-svg
						name="checkbox_md"
						class="checkbox__box"
						height="24"
					></svg>
					<span class="checkbox__label">
						Google Tag Manager
					</span>
				</label>
			</div>
			<div class="col-6">
				<label class="checkbox">
					<input
						class="checkbox__input"
						type="checkbox"
						[(ngModel)]="exponeaValue"
						(ngModelChange)="exponeaValueChanged()"
					>
					<svg
						b-shared-svg
						name="checkbox_md"
						class="checkbox__box"
						height="24"
					></svg>
					<span class="checkbox__label">
						Exponea
					</span>
				</label>
			</div>
		</div>
	</div>

	<ng-container *ngIf="contentRef" [ngTemplateOutlet]="contentRef"></ng-container>

	<ng-container *ngIf="deviceData | async as data">
		<h4 class="h4 mt-20 mb-12 lg:mx-16 mx-12">Устройство</h4>

		<div class="data-widget text-sm text-light">
			<p class="my-4" *ngIf="hostname">Стейдж:
				<span class="text-black">{{hostname}}</span>
			</p>
			<p class="my-4" *ngIf="data.browser">Браузер:
				<span class="text-black">{{data.browser}}</span>
			</p>
			<p class="my-4" *ngIf="data.os">ОС:
				<span class="text-black">{{data.os}}</span>
			</p>
			<p class="my-4" *ngIf="data.device">Устройство:
				<span class="text-black">{{data.device}}</span>
			</p>
			<p class="my-4" *ngIf="data.viewportSize">Viewport:
				<span class="text-black">{{data.viewportSize}}</span>
			</p>
			<p class="my-4" *ngIf="data.userAgent">UserAgent:
				<span class="text-black">{{data.userAgent}}</span>
			</p>
		</div>
	</ng-container>
</div>
