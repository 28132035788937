import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, inject} from '@angular/core';
import {DeviceService} from '../../../services';

@Directive({
	selector: '[sharedSectionViewport]'
})
export class SectionViewportDirective implements OnDestroy, AfterViewInit {
	private _intersectionObserver: IntersectionObserver;

	/** Корневой элемент, который используется в качестве области просмотра для проверки целевого элемента */
	/** По умолчанию область видимости браузера */
	@Input() root: Element | Document | null = null;

	/** Отступы вокруг области просмотра (root) */
	/** Пример: '10px 20px 30px 40px'. Поддерживаются % значения */
	@Input() rootMargin: string = '0px 25px 0px 0px';

	/** Число или массив чисел, указывающий при каком значении видимости целевого элемента сработает callback */
	/** Например threshold = 25,callback будет вызываться каждые 25% видимости целевого элемента */
	@Input() threshold: number | number[] = 0.01;

	/** Необходимо ли проверять когда целевой элемент вышел из зоны просмотра */
	@Input() checkSectionOut: boolean = false;

	/** Отписываться от наблюдением за целевым элементом после срабатывания пересечения  */
	@Input() unobserveAfterFirstIntersection: boolean = true;

	/** Коллбек функция. Вызывается в момент, когда целевой элемент появился во вьюпорте  */
	@Output() sectionInViewportCallback: EventEmitter<any> = new EventEmitter<any>();

	/** Коллбек функция. Вызывается в момент, когда целевой элемент вышел из вьюпорта  */
	@Output() sectionOutViewportCallback?: EventEmitter<any> = new EventEmitter<any>();

	private deviceService = inject(DeviceService);

	constructor(private _elRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		if(!this.deviceService.isServer) {
			this._intersectionObserver = new IntersectionObserver((entries, observer) => {
				const targetEntry = entries.find((entry) => entry.target === this._elRef.nativeElement);
	
				if (!targetEntry) return;
	
				if (targetEntry.isIntersecting && this.unobserveAfterFirstIntersection) {
					observer?.unobserve(targetEntry.target);
					this.sectionInViewportCallback.emit();
				}
	
				if (targetEntry.isIntersecting && !this.unobserveAfterFirstIntersection) {
					this.sectionInViewportCallback.emit();
				}
	
				if (!targetEntry.isIntersecting && this.checkSectionOut) {
					this.sectionOutViewportCallback.emit();
				}
			}, {
				root: this.root,
				rootMargin: this.rootMargin,
				threshold: this.threshold
			});
	
			this._intersectionObserver.observe(this._elRef.nativeElement);
		}
	}

	ngOnDestroy(): void {
		this._intersectionObserver?.unobserve(this._elRef.nativeElement);
	}
}
