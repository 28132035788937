import {isPlatformServer} from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, Input, PLATFORM_ID, Renderer2 } from '@angular/core';

@Component({
	selector: '[b-shared-svg]',
	templateUrl: './svg.component.html',
})
export class SvgComponent {

	/**
	 * Путь до SVG-спрайта
	 *
	 *	@required
	 */
	@Input() path = 'sprite/sprite.svg';

	/**
	 * Имя SVG
	 *
	 *	@required
	 */
	private _name: string;
	@Input() get name(): string {
		return this._name;
	}
	set name(value: string) {
		this._name = value;
		this.appendNewSvg();
	}

	/**
	 * Название при наведении и для скринридеров
	 *
	 */
	@Input() title?: string;

	/**
	 * Id элемента с названием SVG в случае отсутствия aria-label
	 *
	 */
	@Input() 'aria-labelledby'?: string;

	/**
	 * Высота SVG
	 *
	 */
	@Input() height: number;

	/**
	 * Ширина SVG
	 *
	 */
	@Input() width: number;

	@HostBinding('attr.role') get role(): string {
		return this.title || this['aria-labelledby'] ? 'img' : null;
	}

	@HostBinding('class.svg') class = 'svg';

	// @HostBinding('attr.width') width = '16';

	@HostBinding('attr.width') get widthAttr(): number {
		return this.height || this.width ? this.width : 16;
	}

	@HostBinding('attr.height') get heightAttr(): number {
		return this.height || this.width ? this.height : null;
	}

	@Input() 'aria-hidden'?: string | null = 'true';

	@HostBinding('attr.aria-hidden') get ariaHidden(): string | null {
		return this['aria-hidden'] || null;
	}

	useElement: HTMLElement | null = null;

	constructor(
		private elementRef: ElementRef<SVGElement>,
		private renderer: Renderer2,
		@Inject(PLATFORM_ID) private platformId: any,
	) {}

	appendNewSvg() {
		if(isPlatformServer(this.platformId)) return;
		const svgElement: SVGElement = this.elementRef?.nativeElement;

		if (!svgElement) return;
		
		const childElements: Element[] = Object.values(svgElement.children);

		this.useElement?.remove?.();
		this.useElement = null;

		childElements
			.filter(element => element?.tagName?.toLowerCase() !== 'title')
			.forEach(element => element?.remove());

		this.useElement = this.renderer.createElement('use');
		this.renderer.setAttribute(this.useElement, 'xlink:href', `${this.path}#${this.name}`);

		svgElement.append(this.useElement);
	}

}
