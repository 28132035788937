import { ModuleWithProviders, NgModule } from '@angular/core';
import { AnalyticsService } from './analytics.service';

@NgModule()
export class AnalyticsModule {
	public static forRoot(environment: any): ModuleWithProviders<AnalyticsModule> {
		return {
			ngModule: AnalyticsModule,
			providers: [
				AnalyticsService,
				{
					provide: 'env',
					useValue: environment,
				},
			],
		};
	}
}
