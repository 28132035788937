export enum Project {
	client = 'client',
	landing = 'landing',
	sso = 'sso',
	identity = 'identity',
	agent = 'agent',
	'agent-landing' = 'agent-landing',
	'agent-landing-internal' = 'agent-landing-internal',
	compliance = 'compliance',
	banner = 'banner',
	'3ds' = '3ds',
}
