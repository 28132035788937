import {
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	Input,
	OnInit,
	ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationErrorComponent } from '../components/validation-error/validation-error.component';

@Directive({
	selector: '[sharedInputValidation]',
})
export class InputValidationDirective implements OnInit {
	@Input('sharedInputValidation') errors: { [key: string]: string };

	ref: ComponentRef<ValidationErrorComponent>;

	constructor(
		private control: NgControl,
		private vcr: ViewContainerRef,
		private componentFactoryResolver: ComponentFactoryResolver,
	) {}

	ngOnInit() {
		this.ref = this.vcr.createComponent(
			this.componentFactoryResolver.resolveComponentFactory(ValidationErrorComponent),
		);
		this.ref.instance.control = this.control;
		this.ref.instance.errors = this.errors;
	}
}
