import {Directive, Inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';

class SharedLetContext<T> {
	constructor(
		private readonly internalDirectiveInstance: LetDirective<T>
	) {}

	get $implicit(): T {
		return this.internalDirectiveInstance.sharedLet;
	}

	get sharedLet(): T {
		return this.internalDirectiveInstance.sharedLet;
	}
}

/**
 * @example
	<ng-container *sharedLet="payment$ | async as paymentData">
		{{paymentData}}
		<component [data]="paymentData"></component>
	</ng-container>
 */
@Directive({
	selector: '[sharedLet]',
})
export class LetDirective<T> {
    @Input() sharedLet!: T;

    constructor(
        @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
        @Inject(TemplateRef) templateRef: TemplateRef<SharedLetContext<T>>,
    ) {
    	viewContainer.createEmbeddedView(templateRef, new SharedLetContext<T>(this));
    }

    static ngTemplateContextGuard<T>(
    	_dir: LetDirective<T>,
    	_ctx: any,
    ): _ctx is LetDirective<Exclude<T, null | undefined>> {
    	return true;
    }
}
