import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DeviceService } from '../../../services/device.service';
import { BehaviorSubject, filter, from, mergeMap, Observable, of, take, tap } from 'rxjs';
import { ImageCache } from './models/ImageCache';

@Injectable({
	providedIn: 'root',
})
export class ImageService {
	private renderer: Renderer2;

	avifSupported: boolean;
	private avifInProgress$ = new BehaviorSubject<boolean>(false);
	public avifInProgress = this.avifInProgress$.asObservable();

	webpSupported: boolean;
	private webpInProgress$ = new BehaviorSubject<boolean>(false);
	public webpInProgress = this.webpInProgress$.asObservable();

	private cachedImages$ = new BehaviorSubject<ImageCache>({});
	public cachedImages = this.cachedImages$.asObservable();
	public get _cachedImages(): ImageCache {
		return this.cachedImages$.getValue();
	}

	private downloadableImages$ = new BehaviorSubject<string[]>([]);
	public downloadableImages = this.downloadableImages$.asObservable();
	public get _downloadableImages(): string[] {
		return this.downloadableImages$.getValue();
	}

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private deviceService: DeviceService,
		private rendererFactory: RendererFactory2,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	saveImage(path: string, dataUrl: string) {
		const newCache = {...this._cachedImages};
		newCache[path] = dataUrl;

		this.cachedImages$.next(newCache);

		this.removeDownloadableImage(path);
	}

	getCachedImage(path: string): string | undefined {
		return this._cachedImages[path];
	}

	addDownloadableImage(path: string) {
		const newList = [...this._downloadableImages];
		newList.push(path);

		this.downloadableImages$.next(newList);
	}

	removeDownloadableImage(path: string) {
		const newList = [...this._downloadableImages];
		const targetIndex = newList.findIndex(imagePath => imagePath === path);

		if (targetIndex !== -1) {
			newList.splice(targetIndex, 1);
			this.downloadableImages$.next(newList);
		}
	}

	imageIsDownloadable(path: string): boolean {
		return this._downloadableImages.includes(path);
	}

	checkAvifSupport(): Observable<boolean> {
		try {
			if (this.deviceService.isServer) return of(false);
			if (this.avifInProgress$.getValue()) return this.avifInProgress.pipe(
				filter(avifInProgress => !avifInProgress),
				take(1),
				mergeMap(() => of(this.avifSupported))
			);
			if (typeof this.avifSupported === 'boolean') return of(this.avifSupported);

			return from(
				new Promise<boolean>((resolve) => {
					this.avifInProgress$.next(true);

					try {
						if (this.deviceService.isServer) {
							this.renderer.addClass(this.document.documentElement, 'no-avif');
							this.avifSupported = false;
							resolve(this.avifSupported);
						}

						let avifImage = new Image();

						avifImage.onload = avifImage.onerror = () => {
							if (avifImage.height === 2) {
								this.renderer.addClass(this.document.documentElement, 'avif');
								this.avifSupported = true;
							} else {
								this.renderer.addClass(this.document.documentElement, 'no-avif');
								this.avifSupported = false;
							}
							avifImage.remove();
							avifImage = null;

							resolve(this.avifSupported);
						};

						avifImage.src = 'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
					} catch {
						this.renderer.addClass(this.document.documentElement, 'no-avif');
						this.avifSupported = false;
						resolve(this.avifSupported);
					}
				})
			).pipe(
				tap({
					next: () => {
						this.avifInProgress$.next(false);
					}
				}),
				take(1),
			);
		} catch {
			this.renderer.addClass(this.document.documentElement, 'no-avif');
			this.avifSupported = false;
			return of(this.avifSupported);
		}
	}

	checkWebpSupport(): Observable<boolean> {
		try {
			if (this.deviceService.isServer) return of(false);
			if (this.webpInProgress$.getValue()) return this.webpInProgress.pipe(
				filter(webpInProgress => !webpInProgress),
				take(1),
				mergeMap(() => of(this.webpSupported))
			);
			if (typeof this.webpSupported === 'boolean') return of(this.webpSupported);

			return from(
				new Promise<boolean>((resolve) => {
					this.webpInProgress$.next(true);

					try {
						if (this.deviceService.isServer) {
							this.renderer.addClass(this.document.documentElement, 'no-webp');

							this.webpSupported = false;
							resolve(this.webpSupported);
						}

						let webpImage = new Image();

						webpImage.onload = webpImage.onerror = () => {
							if (webpImage?.width === 1) {
								this.renderer.addClass(this.document.documentElement, 'webp');
								this.webpSupported = true;
							} else {
								this.renderer.addClass(this.document.documentElement, 'no-webp');
								this.webpSupported = false;
							}
							webpImage.remove();
							webpImage = null;

							resolve(this.webpSupported);
						};

						webpImage.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
					} catch {
						this.renderer.addClass(this.document.documentElement, 'no-webp');
						this.webpSupported = false;
						resolve(this.webpSupported);
					}
				})
			).pipe(
				tap({
					next: () => {
						this.webpInProgress$.next(false);
					}
				}),
				take(1),
			);
		} catch {
			this.renderer.addClass(this.document.documentElement, 'no-webp');
			this.webpSupported = false;
			return of(this.webpSupported);
		}
	}

}
