import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, fromEvent, Subscription } from 'rxjs';
import { SharedService } from '../../../../services/shared/shared.service';
import { AnalyticsService } from '../../../../modules/analytics/analytics.service';

import { DeviceData } from '../models/DeviceData';
import { WINDOW } from 'projects/shared/src/lib/tokens';
import { DeviceService } from '../../../../services';



@Component({
	selector: 'b-shared-toolbar-content',
	templateUrl: './toolbar-content.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarContentComponent implements OnInit, OnDestroy {

	subscription = new Subscription();
	hostname = new URL(location.href).hostname;
	gtmValue: boolean = false;
	exponeaValue: boolean = false;

	readonly deviceData$ = new BehaviorSubject<DeviceData | null>(null);
	public deviceData = this.deviceData$
		.asObservable()
		.pipe(distinctUntilChanged());

	@ContentChild('content') contentRef: TemplateRef<any>;
	@ContentChild('beforeContent') beforeContentRef: TemplateRef<any>;

	constructor(
		private deviceService: DeviceService,
		public elementRef: ElementRef,
		private analyticsService: AnalyticsService,
		public sharedService: SharedService,
		@Inject(WINDOW) private _window: Window,
	) {}

	ngOnInit(): void {
		this.gtmValue = this.analyticsService.gtmEnabled;
		this.exponeaValue = this.analyticsService.exponeaEnabled;

		this.updateDeviceData();

		this.subscription.add(
			fromEvent(this._window, 'resize')
				.subscribe({
					next: () => this.updateDeviceData()
				})
		);

		this.subscription.add(
			fromEvent(this._window, 'orientationchange')
				.subscribe({
					next: () => this.updateDeviceData()
				})
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	updateDeviceData() {
		this.deviceData$.next({
			browser: [this.deviceService.browser, this.deviceService.browser_version].join(' '),
			os: [this.deviceService.os, this.deviceService.os_version].join(', '),
			device: [this.deviceService.device, this.deviceService.deviceType].join(', '),
			userAgent: this.deviceService.userAgent,
			viewportSize: [this.deviceService.viewportWidth, this.deviceService.viewportHeight]
				.filter(Boolean)
				.map(size => `${size}px`)
				.join(' × ')
		});
	}

	gtmValueChanged() {
		if (this.gtmValue) localStorage.setItem('gtmEnabled', 'enabled');
		else localStorage.removeItem('gtmEnabled');

		location.reload();
	}

	exponeaValueChanged() {
		if (this.exponeaValue) localStorage.setItem('exponeaEnabled', 'enabled');
		else localStorage.removeItem('exponeaEnabled');

		location.reload();
	}

}
