import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'inn',
})
export class InnPipe implements PipeTransform {
	transform(rawNum:string) {
		if (rawNum?.length === 10) {
			return `${rawNum.slice(0,2)} ${rawNum.slice(2,9)} ${rawNum.slice(9,10)}`;
		} else if (rawNum?.length === 12) {
			return `${rawNum.slice(0,2)} ${rawNum.slice(2,9)} ${rawNum.slice(9,10)} ${rawNum.slice(10,12)}`;
		} else {
			return rawNum;
		}
	}
}
