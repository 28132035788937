import {ContentChild, Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
	selector: '[sharedInputAnimate]'
})
export class InputAnimateTitleDirective implements OnInit, OnDestroy {
	@HostBinding('class') get class() {
		return (this.isAnimateTitle || this.isFocused) ? this.classToAnimate : '';
	}

	@Input() classToAnimate = 'field--title';
	@Input() animateTrigger: UntypedFormControl;
	@Input() isFocused = false;
	@ContentChild('input') input: ElementRef;

	private isAnimateTitle = false;
	private triggerSubscription = new Subscription();

	ngOnInit() {
    	this.triggerSubscription.add(
    		this.animateTrigger?.valueChanges.subscribe((value) => {
    			this.isAnimateTitle = Boolean(value);
    		})
    	);
	}

	ngOnDestroy() {
    	this.triggerSubscription.unsubscribe();
	}
}
