import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { SvgModule } from '../svg/svg.module';
import { ButtonModule } from '../button/button.module';
import { LetModule, KeyboardControlModule } from '../../directives';
import { SharedClassModule } from '../../directives/shared-class/shared-class.module';

@NgModule({
	imports: [
		CommonModule,
		SvgModule,
		ButtonModule,
		LetModule,
		KeyboardControlModule,
		SharedClassModule,
	],
	exports: [
		FileUploadComponent,
	],
	declarations: [
		FileUploadComponent,
	],
})
export class FileUploadModule {}
