import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SharedService } from '../../../services/shared/shared.service';
import { ValidationMessages } from './validation.types';

type Position = 'top left' | 'bottom left' | 'top right' | 'bottom right';

// TODO: Зашить возможность позиционировать сообщение об ошибке справа и слева от инпута
@Component({
	selector: '[b-shared-validation]',
	templateUrl: './validation.component.html',
	styleUrls: ['./validation.component.scss'],
	animations: [
		trigger('lifecycleTrigger', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translate3d(0, 5%, 0)' }),
				animate('200ms ease-in', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0, transform: 'translate3d(0, 5%, 0)' })),
			]),
		]),
	],
})
export class ValidationComponent implements OnInit {
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input() validationType: ValidationMessages = ValidationMessages.tooltip;
	@Input() control: NgControl;
	@Input() errors: { [key: string]: TemplateRef<any> } = {};
	@Input() pos?: Position = 'top left';
	@Input() onlyWithFocus?: boolean = false;
	@Input() elementIsFocused: boolean;
	@Input() layerClass = 'chat';
	@Input() wrapperClass = 'chat__message chat__message--incoming chat__message--error field__error-message mb-0';
	@Input() containerClass = 'chat__message-body text-md';
	@Input() dirty = true;
	@Input() touched = false;

	classes = ['field__error'];
	validationMessages = ValidationMessages;

	constructor(
		private sharedService: SharedService,
	) {}

	ngOnInit() {
		if (!this.sharedService?.environment?.production) {
			console.warn('b-shared-validation устарел, используйте стандартный метод: https://angular.io/guide/form-validation');
		}

		this.pos.split(' ').forEach((item) =>
			this.classes.push(`field__error--${item}`)
		);

		this.classes.push(this.layerClass);
	}

	get errorVisibility(): boolean {
		return (
			this.errorTemplate &&
			(!this.dirty || this.control?.dirty) &&
			(!this.touched || this.control?.touched) &&
			this.control?.invalid &&
			(!this.onlyWithFocus || this.elementIsFocused)
		);
	}

	get errorTemplate(): TemplateRef<any> | null {
		const key = Object.keys(this.errors).find((errorKey) =>
			Boolean(this.control?.errors?.[errorKey]),
		);
		return key ? this.errors[key] : null;
	}
}
