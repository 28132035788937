<div
	class="field"
	[classList]="fieldClass"
	[ngClass]="fieldNgClass"
>
	<input
		class="field__control"
		[ngClass]="inputClass"
		[attr.type]="inputType"
		[attr.placeholder]="placeholder"
		[attr.autocomplete]="autocomplete"
		[attr.autofocus]="autofocus"
		[value]="fieldValue | async"
		(input)="inputHandler($event)"
		(focus)="focusInputHandler($event)"
		(blur)="blurInputHandler($event)"
		[disabled]="isDisabled"
		#input
		[attr.aria-required]="true"
	>

	<span
		*ngIf="fieldTitle"
		class="field__title"
	>
		{{ fieldTitle }}
	</span>

	<div class="layered-content">
		<div class="layered-content__item field__addon field__addon--icon" *ngIf="loader" [@lifecycleTrigger]>
			<svg
				b-shared-svg
				name="icons_cl_24_2_loader"
				class="icon icon--xl icon--cl rotating"
			></svg>
		</div>

		<ng-container *ngIf="toggleMask && !loader">
			<button
				b-shared-button
				[sharedClass]="[
					'layered-content__item field__addon btn mr-8 ml-12',
					toggleButtonClass
				]"
				[iconClass]="iconPasswordClass"
				[icon]="unmasked ? iconPasswordHide : iconPasswordShow"
				[title]="unmasked ? 'Скрыть пароль' : 'Показать пароль'"
				(click)="toggle()"
				role="switch"
				[attr.aria-pressed]="unmasked || null"
			></button>
		</ng-container>
	</div>
</div>

<ng-container *ngIf="canShowPasswordSecurityHints">
	<b-shared-range-slider
		[@lifecycleTrigger]
		class="range range--password"
		[ngClass]="rangeClass"
		[wrapperClass]="strengthIndicatorClass"
		orientation="horizontal"
		[(ngModel)]="strengthIndicatorValue"
		[min]="0"
		[max]="secureLength"
		[step]="1"
		[showThumbs]="false"
	></b-shared-range-slider>

	<div
		[sharedClass]="hintListClass"
	>
		<div
			*ngFor="let template of hintsTemplates"
			[@lifecycleTrigger]
			[class]="hintClass"
		>
			<ng-container [ngTemplateOutlet]="template"></ng-container>
		</div>
	</div>
</ng-container>
