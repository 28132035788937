import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpInterceptor, HttpEvent} from '@angular/common/http';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
	private ssoExcludeUrls = [
		'/api/sso/connect/userInfo',
		'/api/sso/profile/setEmail',
		'/api/sso/v2/password/set',
		'/api/sso/v2/password/change',
		'/api/sso/users/getUserNameByPhone',
		'/api/sso/profile/setFio',
		'/api/sso/profile/checkPassword',
		'/api/sso/v2/codeword/check',
		'/api/sso/profile/sendConfirmEmail',
		'/api/sso/v2/codeword/set',
	];

	constructor(
		private oidcSecurityService: OidcSecurityService,
	) {
	}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.oidcSecurityService.getAccessToken()
			.pipe(
				switchMap((token) => {
					const isSSOService = req.url.includes('/api/sso');

					const clonedRequest = isSSOService && !this.ssoExcludeUrls.includes(req.url) ?
						req.clone() : this.getRequestWithBearer(req, token);

					return next.handle(clonedRequest)
						.pipe(
							catchError(error => this.handleResponseError(error))
						);
				})
			);
	}

	getRequestWithBearer(request, token) {
		if (token) {
			return request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		}
		return request.clone();
	}

	handleResponseError(error) {
		if (error?.status === 401 || error?.status === 403) {
			location.reload();
		}

		return throwError(() => error);
	}

}
