<span class="field__title">
	{{ placeholder }}
</span>

<div
	class="calendar__trigger"
	(click)="toggleOverlay()"
	sharedKeyboardControl
	(activeElementKeyHandler)="toggleOverlay()"
	sharedArrowNavigation
	[groupId]="arrowNavigationGroup"
	tabindex="0"
	[ngClass]="inputTriggerClasses"
	#inputTrigger
>
	<ng-container *ngIf="inputValue | async as inputStringValue">{{ inputStringValue }}</ng-container>
</div>

<div
	*ngIf="(fieldIsClearable | async) === true; else dropIcon"
	class="field__addon field__addon--icon icon-button"
	(click)="resetField()"
	sharedKeyboardControl
	(activeElementKeyHandler)="resetField()"
	tabindex="0"
>
	<svg
		b-shared-svg
		name="icons_scaling_cross-round"
		class="icon icon--sm icon--cl"
	></svg>
</div>

<ng-template #dropIcon>
	<div
		class="field__addon field__addon--icon"
		(click)="toggleOverlay()"
	>
		<svg
			b-shared-svg
			name="icons_scaling_arrow-down-sm"
			class="drop__icon icon icon--sm"
			[ngClass]="{
				'drop__icon--rotated': (overlayIsOpened | async) === true
			}"
		></svg>
	</div>
</ng-template>

<div
	*ngIf="(overlayIsOpened | async) === true && (displayedData | async) as targetData"
	[@fadeIn]
	(@fadeIn.start)="calendarAnimationStart($event)"
	(@fadeIn.done)="calendarAnimationDone($event)"
	#calendarPanel
	class="calendar__panel"
	[ngClass]="calendarClasses"
	role="application"
	aria-label="Выбор календарной даты"
>
	<div class="calendar__header">
		<b-shared-dropdown
			[suggestions]="targetData.yearsList"
			[(ngModel)]="yearsDropdownModel"
			[heightToPageBorders]="false"
			(ngModelChange)="yearsDropdownModelChanged()"
			suggestPosition="under"
			class="calendar__drop drop"
			dropFieldWrapperClass="calendar__title"
			btnClass="calendar__drop-btn"
			overlayClass="pe-auto"
			[uniqueElementPropFn]="yearsUniquePropFn"
			[triggerNavigationGroup]="arrowNavigationGroup"
		>
			<ng-template let-date #dropdownItem>{{ date.getFullYear() }}</ng-template>
			<ng-template let-date #viewTemplate>{{ date | date : 'LLLL yyyy' | titlecase }}</ng-template>
		</b-shared-dropdown>
		<div class="calendar__controls">
			<button
				type="button"
				class="calendar__prev-btn icon-button"
				(click)="switchMonth(false)"
				sharedArrowNavigation
				[groupId]="arrowNavigationGroup"
			>
				<svg
					b-shared-svg
					name="icons_scaling_arrow-left"
					class="icon icon--sm"
					title="Назад"
					aria-hidden="false"
				></svg>
			</button>
			<button
				type="button"
				class="calendar__next-btn icon-button"
				(click)="switchMonth(true)"
				sharedArrowNavigation
				[groupId]="arrowNavigationGroup"
			>
				<svg
					b-shared-svg
					name="icons_scaling_arrow-right"
					class="icon icon--sm"
					title="Вперёд"
					aria-hidden="false"
				></svg>
			</button>
		</div>
	</div>
	<table class="calendar__content">
		<thead>
			<tr class="calendar__date-row">
				<th class="calendar__week-day">
					<span class="calendar__week-day-inner">
						пн
					</span>
				</th>
				<th class="calendar__week-day">
					<span class="calendar__week-day-inner">
						вт
					</span>
				</th>
				<th class="calendar__week-day">
					<span class="calendar__week-day-inner">
						ср
					</span>
				</th>
				<th class="calendar__week-day">
					<span class="calendar__week-day-inner">
						чт
					</span>
				</th>
				<th class="calendar__week-day">
					<span class="calendar__week-day-inner">
						пт
					</span>
				</th>
				<th class="calendar__week-day">
					<span class="calendar__week-day-inner">
						сб
					</span>
				</th>
				<th class="calendar__week-day">
					<span class="calendar__week-day-inner">
						вс
					</span>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let week of targetData.weeks; trackBy: trackByWeeks" class="calendar__date-row">
				<td
					*ngFor="let weekDay of week; trackBy: trackByWeekDay"
					class="calendar__day"
					[ngClass]="{
						'calendar__day--selected': weekDay?.isRange &&
							!weekDay?.isSelected &&
							!(weekDay?.isFromDate && weekDay?.isToDate),
						'calendar__day--disabled': weekDay?.isNeighboringMonth || weekDay?.isUnavailable,
						'calendar__day--selected-first': weekDay?.isSelected || weekDay?.isFromDate,
						'calendar__day--selected-last': weekDay?.isToDate
					}"
				>
					<span
						class="calendar__day-inner"
						[ngClass]="{
							'calendar__day-inner--curent': weekDay?.isToday
						}"
						(click)="selectDate(weekDay)"
						[tabindex]="(weekDay?.isNeighboringMonth || weekDay?.isUnavailable) ? -1 : 0"
						sharedKeyboardControl
						(activeElementKeyHandler)="selectDate(weekDay)"
						sharedArrowNavigation
						[groupId]="arrowNavigationGroup"
						[navigationEnabled]="!weekDay?.isNeighboringMonth && !weekDay?.isUnavailable"
					>
						{{ weekDay | date : 'd' }}
					</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>
