export enum Mime {
	'audio/aac' = 'audio/aac',
	'application/x-abiword' = 'application/x-abiword',
	'application/x-freearc' = 'application/x-freearc',
	'video/x-msvideo' = 'video/x-msvideo',
	'application/vnd.amazon.ebook' = 'application/vnd.amazon.ebook',
	'application/octet-stream' = 'application/octet-stream',
	'image/bmp' = 'image/bmp',
	'application/x-bzip' = 'application/x-bzip',
	'application/x-bzip2' = 'application/x-bzip2',
	'application/x-csh' = 'application/x-csh',
	'text/css' = 'text/css',
	'text/csv' = 'text/csv',
	'application/msword' = 'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-fontobject' = 'application/vnd.ms-fontobject',
	'application/epub+zip' = 'application/epub+zip',
	'application/gzip' = 'application/gzip',
	'image/gif' = 'image/gif',
	'text/html' = 'text/html',
	'image/vnd.microsoft.icon' = 'image/vnd.microsoft.icon',
	'text/calendar' = 'text/calendar',
	'application/java-archive' = 'application/java-archive',
	'image/jpeg' = 'image/jpeg',
	'text/javascript' = 'text/javascript',
	'application/json' = 'application/json',
	'application/ld+json' = 'application/ld+json',
	'audio/midi' = 'audio/midi',
	'audio/x-midi' = 'audio/x-midi',
	'audio/mpeg' = 'audio/mpeg',
	'video/mpeg' = 'video/mpeg',
	'application/vnd.apple.installer+xml' = 'application/vnd.apple.installer+xml',
	'application/vnd.oasis.opendocument.presentation' = 'application/vnd.oasis.opendocument.presentation',
	'application/vnd.oasis.opendocument.spreadsheet' = 'application/vnd.oasis.opendocument.spreadsheet',
	'application/vnd.oasis.opendocument.text' = 'application/vnd.oasis.opendocument.text',
	'audio/ogg' = 'audio/ogg',
	'video/ogg' = 'video/ogg',
	'video/quicktime' = 'video/quicktime',
	'video/mp4' = 'video/mp4',
	'application/ogg' = 'application/ogg',
	'audio/opus' = 'audio/opus',
	'font/otf' = 'font/otf',
	'image/png' = 'image/png',
	'application/pdf' = 'application/pdf',
	'application/x-httpd-php' = 'application/x-httpd-php',
	'application/vnd.ms-powerpoint' = 'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/vnd.rar' = 'application/vnd.rar',
	'application/rtf' = 'application/rtf',
	'application/x-sh' = 'application/x-sh',
	'image/svg+xml' = 'image/svg+xml',
	'application/x-shockwave-flash' = 'application/x-shockwave-flash',
	'application/x-tar' = 'application/x-tar',
	'image/tiff' = 'image/tiff',
	'video/mp2t' = 'video/mp2t',
	'font/ttf' = 'font/ttf',
	'text/plain' = 'text/plain',
	'application/vnd.visio' = 'application/vnd.visio',
	'audio/wav' = 'audio/wav',
	'audio/webm' = 'audio/webm',
	'video/webm' = 'video/webm',
	'image/webp' = 'image/webp',
	'font/woff' = 'font/woff',
	'font/woff2' = 'font/woff2',
	'application/xhtml+xml' = 'application/xhtml+xml',
	'application/vnd.ms-excel' = 'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/xml' = 'application/xml',
	'text/xml' = 'text/xml',
	'application/vnd.mozilla.xul+xml' = 'application/vnd.mozilla.xul+xml',
	'application/zip' = 'application/zip',
	'video/3gpp' = 'video/3gpp',
	'audio/3gpp' = 'audio/3gpp',
	'video/3gpp2' = 'video/3gpp2',
	'audio/3gpp2' = 'audio/3gpp2',
	'application/x-7z-compressed' = 'application/x-7z-compressed',
	'video/x-ms-wmv' = 'video/x-ms-wmv',
	'video/avi' = 'video/avi',
}

export enum Format {
	aac = '.aac',
	abw = '.abw',
	arc = '.arc',
	avi = '.avi',
	azw = '.azw',
	bin = '.bin',
	bmp = '.bmp',
	bz = '.bz',
	bz2 = '.bz2',
	csh = '.csh',
	css = '.css',
	csv = '.csv',
	doc = '.doc',
	docx = '.docx',
	eot = '.eot',
	epub = '.epub',
	gz = '.gz',
	gif = '.gif',
	html = '.html',
	ico = '.ico',
	ics = '.ics',
	jar = '.jar',
	jpg = '.jpg',
	jpeg = '.jpeg',
	mjs = '.mjs',
	json = '.json',
	jsonld = '.jsonld',
	midi = '.midi',
	mp3 = '.mp3',
	mpeg = '.mpeg',
	mpkg = '.mpkg',
	odp = '.odp',
	ods = '.ods',
	odt = '.odt',
	oga = '.oga',
	ogv = '.ogv',
	ogx = '.ogx',
	opus = '.opus',
	otf = '.otf',
	png = '.png',
	pdf = '.pdf',
	php = '.php',
	ppt = '.ppt',
	pptx = '.pptx',
	rar = '.rar',
	rtf = '.rtf',
	sh = '.sh',
	svg = '.svg',
	swf = '.swf',
	tar = '.tar',
	tiff = '.tiff',
	ts = '.ts',
	ttf = '.ttf',
	txt = '.txt',
	vsd = '.vsd',
	wav = '.wav',
	weba = '.weba',
	webm = '.webm',
	webp = '.webp',
	woff = '.woff',
	woff2 = '.woff2',
	xhtml = '.xhtml',
	xls = '.xls',
	xlsx = '.xlsx',
	xml = '.xml',
	xul = '.xul',
	zip = '.zip',
	'3gp' = '.3gp',
	'3g2' = '.3g2',
	'7z' = '.7z',
	mp4 = '.mp4',
	wmv = '.wmv',
	mov = '.mov',
	jfif = '.jfif',
	jpe = '.jpe',
	heif = '.heif',
	arj = '.arj',
	cab = '.cab',
}

/** mime типы и соответствующие им форматы файлов */
export const mimeTypes = {
	[Mime['audio/aac']]: Format.aac,
	[Mime['application/x-abiword']]: Format.abw,
	[Mime['application/x-freearc']]: Format.arc,
	[Mime['video/x-msvideo']]: Format.avi,
	[Mime['application/vnd.amazon.ebook']]: Format.azw,
	[Mime['application/octet-stream']]: Format.bin,
	[Mime['image/bmp']]: Format.bmp,
	[Mime['application/x-bzip']]: Format.bz,
	[Mime['application/x-bzip2']]: Format.bz2,
	[Mime['application/x-csh']]: Format.csh,
	[Mime['text/css']]: Format.css,
	[Mime['text/csv']]: Format.csv,
	[Mime['application/msword']]: Format.doc,
	[Mime['application/vnd.openxmlformats-officedocument.wordprocessingml.document']]: Format.docx,
	[Mime['application/vnd.ms-fontobject']]: Format.eot,
	[Mime['application/epub+zip']]: Format.epub,
	[Mime['application/gzip']]: Format.gz,
	[Mime['image/gif']]: Format.gif,
	[Mime['text/html']]: Format.html,
	[Mime['image/vnd.microsoft.icon']]: Format.ico,
	[Mime['text/calendar']]: Format.ics,
	[Mime['application/java-archive']]: Format.jar,
	[Mime['image/jpeg']]: Format.jpeg,
	[Mime['text/javascript']]: Format.mjs,
	[Mime['application/json']]: Format.json,
	[Mime['application/ld+json']]: Format.jsonld,
	[Mime['audio/midi audio/x-midi']]: Format.midi,
	[Mime['audio/mpeg']]: Format.mp3,
	[Mime['video/mpeg']]: Format.mpeg,
	[Mime['application/vnd.apple.installer+xml']]: Format.mpkg,
	[Mime['application/vnd.oasis.opendocument.presentation']]: Format.odp,
	[Mime['application/vnd.oasis.opendocument.spreadsheet']]: Format.ods,
	[Mime['application/vnd.oasis.opendocument.text']]: Format.odt,
	[Mime['audio/ogg']]: Format.oga,
	[Mime['video/ogg']]: Format.ogv,
	[Mime['application/ogg']]: Format.ogx,
	[Mime['audio/opus']]: Format.opus,
	[Mime['font/otf']]: Format.otf,
	[Mime['image/png']]: Format.png,
	[Mime['application/pdf']]: Format.pdf,
	[Mime['application/x-httpd-php']]: Format.php,
	[Mime['application/vnd.ms-powerpoint']]: Format.ppt,
	[Mime['application/vnd.openxmlformats-officedocument.presentationml.presentation']]: Format.pptx,
	[Mime['application/vnd.rar']]: Format.rar,
	[Mime['application/rtf']]: Format.rtf,
	[Mime['application/x-sh']]: Format.sh,
	[Mime['image/svg+xml']]: Format.svg,
	[Mime['application/x-shockwave-flash']]: Format.swf,
	[Mime['application/x-tar']]: Format.tar,
	[Mime['image/tiff']]: Format.tiff,
	[Mime['video/mp2t']]: Format.ts,
	[Mime['font/ttf']]: Format.ttf,
	[Mime['text/plain']]: Format.txt,
	[Mime['application/vnd.visio']]: Format.vsd,
	[Mime['audio/wav']]: Format.wav,
	[Mime['audio/webm']]: Format.weba,
	[Mime['video/webm']]: Format.webm,
	[Mime['image/webp']]: Format.webp,
	[Mime['font/woff']]: Format.woff,
	[Mime['font/woff2']]: Format.woff2,
	[Mime['application/xhtml+xml']]: Format.xhtml,
	[Mime['application/vnd.ms-excel']]: Format.xls,
	[Mime['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']]: Format.xlsx,
	[Mime['application/xml']]: Format.xml,
	[Mime['text/xml']]: Format.xml,
	[Mime['application/vnd.mozilla.xul+xml']]: Format.xul,
	[Mime['application/zip']]: Format.zip,
	[Mime['video/3gpp']]: Format['3gp'],
	[Mime['audio/3gpp']]: Format['3gp'],
	[Mime['video/3gpp2']]: Format['3g2'],
	[Mime['audio/3gpp2']]: Format['3g2'],
	[Mime['application/x-7z-compressed']]: Format['7z'],
	[Mime['video/mp4']]: Format['mp4'],
	[Mime['video/quicktime']]: Format['mov'],
	[Mime['video/x-ms-wmv']]: Format['wmv'],
	[Mime['video/avi']]: Format['avi'],
};
