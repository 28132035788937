<div #container [ngClass]="{'p-sidebar':true, 'p-sidebar-active': visible,
	'p-sidebar-left': (position === 'left' && !fullScreen), 'p-sidebar-right': (position === 'right' && !fullScreen),
	'p-sidebar-top': (position === 'top' && !fullScreen), 'p-sidebar-bottom': (position === 'bottom' && !fullScreen),
	'p-sidebar-full': fullScreen}"  *ngIf="visible"
	[@panelState]="{value: 'visible', params: {transform: transformOptions, transition: transitionOptions}}"
	(@panelState.start)="onAnimationStart($event)" (@panelState.done)="onAnimationEnd($event)"
	[ngStyle]="style" [class]="styleClass"  role="complementary" [attr.aria-modal]="modal">
	<div class="p-sidebar-header">
		<button type="button" class="p-sidebar-close p-sidebar-icon p-link" (click)="close($event)"
			(keydown.enter)="close($event)" [attr.aria-label]="ariaCloseLabel" *ngIf="showCloseIcon">
			<span class="p-sidebar-close-icon pi pi-times"></span>
		</button>
	</div>
	<div class="sidebar__wrapper">
		<ng-content></ng-content>
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</div>
</div>
