import {from, MonoTypeOperatorFunction, Observable, of, pipe, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, mergeMap} from 'rxjs/operators';

// оператор для установки минимального времени выполнения стрима, с учётом:
// - начала выполнения стрима,
// - возможности выброса ошибки стримом
export const minTime = <T>(delayInMs: number = 1000) => {
	const timer = new Promise(resolve => {
		setTimeout(() => resolve(delayInMs), delayInMs);
	});

	return pipe(
		mergeMap<T, Observable<T>>(response => from(timer).pipe(
			mergeMap(() => of(response))
		)),
		catchError<T, Observable<T>>(error => from(timer).pipe(
			mergeMap(() => throwError(() => error))
		)),
	);
};


export const regExpToString = (regExp: RegExp): string => {
	return regExp.toString().slice(1, -1);
};

export const unionRegExps = (...regExps: RegExp[]): RegExp => {
	if (regExps.length === 0) return;

	const unionRegexpString = regExps.map((regexp) => regExpToString(regexp)).join('|');

	return new RegExp(unionRegexpString);
};

export const distinctUntilKeysChanged: <T>(...keys: string[]) => MonoTypeOperatorFunction<T> = <T>(...keys: string[]) => {
	return distinctUntilChanged<T>((old, current) =>
		!keys
			.map(key => old[key] === current[key])
			.includes(false)
	);
};
