<div class="field__textarea-wrapper">
	<span [ngClass]="titleClass" *ngIf="animatedTitle && placeholder">{{ placeholder }}</span>
	<textarea
		#textareaElement
		[ngClass]="textareaClass"
		[placeholder]="animatedTitle ? '' : placeholder"
		(focus)="fieldFocused()"
		(blur)="fieldBlured()"
		(input)="fieldChanged($event)"
		[disabled]="disabled"
		[attr.maxlength]="maxlength"
		[autofocus]="autofocus"
		[rows]="rows"
		[cols]="cols"
	></textarea>
	<div class="field__footer">
		<div *ngIf="chipsLabels?.length">
			<div *ngIf="fieldValue | async as value" class="field__footer-label">
				<ng-container *ngIf="value?.chips?.label">
					{{ value.chips.label }}
				</ng-container>
			</div>
			<div
				b-shared-chips
				class="field__chips"
				wrapperClass="field__chips-item"
				[chips]="chipsLabels"
				type="radio"
				[(ngModel)]="activeChip"
				(ngModelChange)="chipsChanged()"
			></div>
		</div>
		<div *ngIf="canShowCounter" [ngClass]="counterClass">{{ counter }}</div>
	</div>
</div>
