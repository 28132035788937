import {
	Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation,
	ElementRef, ChangeDetectorRef, Input, Output, EventEmitter, ViewChild,
	ContentChild, TemplateRef, AfterContentInit, Inject, AfterContentChecked, Renderer2, RendererStyleFlags2
} from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { OverlayService2 } from './overlay.service';
import { LoaderType, OverlayType, SidebarPosition, ScrollDestination, QueryParams } from './overlay.types';
import { DOCUMENT } from '@angular/common';
import { TouchService, SwipeSide } from '../../../services/touch.service';
import {
	BehaviorSubject,
	Observable,
	Subscription,
	filter,
	tap,
	distinctUntilChanged,
	merge,
	map,
	shareReplay
} from 'rxjs';
import { take } from 'rxjs/operators';
import { ScrollService } from '../../../services/scroll.service';
import { SharedService } from '../../../services/shared/shared.service';
import { ArrowNavigationService } from '../../directives/arrow-navigation/arrow-navigation.service';
import { Project } from '../../../services/shared/models/Project';
import { ButtonComponent } from '../button/button.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { SimplebarAngularComponent } from 'simplebar-angular';
import {DeviceService} from '../../../services';


declare const Modernizr: any;

@Component({
	selector: 'b-shared-overlay',
	templateUrl: './overlay.component.html',
	animations: [
		trigger('overlayLifecycleAnimation', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateX({{translateX}})' }),
				animate('280ms cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: 'translateX(0)' })),
			], {params : { translateX: '0%' }}),
			transition(':leave', [
				animate('240ms cubic-bezier(0.4, 0, 1, 1)', style({ opacity: 0, transform: 'translateX({{translateX}})' })),
			], {params : { translateX: '0%' }}),
		]),
		trigger('fade', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('300ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('300ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class OverlayComponent implements OnInit, AfterContentInit, OnDestroy, AfterContentChecked {
	/** Тип оверлея: сайдбар, попап и т.д. */
	@Input() type: OverlayType | keyof typeof OverlayType = OverlayType.sidebar;

	/**
	 * overlayId оверлея
	 * Используется при маршрутизации оверлеев
	 */
	@Input() overlayId: string = null;

	/** Флаг для вкл/выкл блокировки скролла страницы при открытом слое */
	@Input() blockScroll: boolean = true;

	/** Флаг для вкл/выкл отображения фона при открытом слое */
	@Input() showBackground: boolean = true;

	/** Флаг для отображения полупрозрачной, блокирующей контент, заглушки поверх контента оверлея */
	private _blockContent = false;
	@Input() get blockContent(): boolean {
		return this._blockContent;
	}
	set blockContent(visibility: boolean) {
		this._blockContent = visibility;

		this.updateParanjaProperties();
	}

	/**
	 * Флаг для вкл/выкл маршрутизируемого режима оверлея
	 */
	@Input() routable: boolean = true;

	/* Флаг для вкл/выкл режима закрытия слоя при нажатии на Escape **/
	@Input() closeOnEsc: boolean = true;

	/** Флаг для вкл/выкл режима закрытия слоя при клике или таче вне слоя */
	@Input() closeOnOutsideClick: boolean = true;

	/** Флаг для установки лоадера */
	@Input() loader: boolean = false;

	/** Флаг для вкл/выкл режима закрытия слоя при свайпе */
	@Input() closingBySwipe: boolean = false;

	/** Сторона начала Drag&drop'a (закрытие оверлея по свайпу) */
	@Input() swipeSide: SwipeSide = SwipeSide.top;

	/** Тип отображения лоадера */
	@Input() loaderType: LoaderType = LoaderType.translucent;

	/** Флаг для отображения кнопки закрытия в хэдере оверлея */
	@Input() showCloseIcon: boolean = true;

	/** Флаг для отображения кнопки закрытия рядом с оверлеем */
	@Input() showOuterCloseIcon: boolean = false;

	/** флаг для включения позиционирования относительно элемента-триггера */
	@Input() positioningRelativeTrigger: boolean = false;

	/**
	 * z-index для оверлея
	 *
	 * Передавать zIndex нужно в особых случаях, т.к. по умолчанию z-index
	 * рассчитывается автоматически для каждого оверлея по мере открытия слоёв
	*/
	@Input() zIndex: number = null;

	/** Позиционирование сайдбара */
	@Input() sidebarPosition: SidebarPosition = SidebarPosition.right;

	/** CSS класс для оверлея */
	@Input() overlayClass: string | string[];

	/** CSS класс для контейнера оверлея */
	@Input() containerClass: string | string[];

	/** CSS класс для футера оверлея */
	@Input() footerClass: string | string[];

	/** CSS класс для контента оверлея */
	@Input() contentClass: string | string[] | {
		[cssClass: string]: any;
	};

	/** CSS класс для контента хэдера */
	@Input() headerClass: string | string[];

	/** CSS класс для закрыть кнопки */
	@Input() closeBtnClass: string | string[];

	/** CSS класс для враппера оверлея */
	@Input() wrapperClass: string | string[];

	/** CSS класс для контролов в футере оверлея */
	@Input() sidebarControlsClass: string | string[];

	/** Фоновая иконка */
	@Input() bgIcon: string;

	/** Блок с кастомным скроллом */
	@Input() customScroll: ScrollDestination = ScrollDestination.wrapper;

	/** Размещение кнопки закрытия оверлея внутри .sidebar */
	@Input() isCloseBtnInside: boolean = false;
	/** Показывать паранжу или нет в body */
	@Input() showParanja: boolean = false;

	/** триггер для автоскролла контента оверлея в нижнее положение */
	@Input() set scrollTrigger(trigger: Observable<boolean>) {
		this.scrollTriggerSubscription?.unsubscribe();

		this.scrollTriggerSubscription = trigger
			.pipe(distinctUntilChanged())
			.subscribe({
				next: needScroll => {
					if (needScroll) {
						const simplebar = this.scrollbarComponentRef?.SimpleBar?.contentWrapperEl;
						simplebar?.scrollTo(0, simplebar.scrollHeight);
						this.contentInner?.scrollTo(0, this.contentInner.scrollHeight);
						this.wrapper?.scrollTo(0, this.wrapper.scrollHeight);
					}
				}
			});
	}

	/** Метод, вызывающийся при открытии оверлея */
	@Output() showHandler = new EventEmitter();

	/** Метод, вызывающийся при закрытии оверлея */
	@Output() hideHandler = new EventEmitter();

	/** Метод, вызывающийся при скролле контента оверлея */
	@Output() scrollHandler = new EventEmitter<Event>();

	/** Метод, вызывающийся в начале анимации инициализации оверлея */
	@Output() initAnimationStart = new EventEmitter();

	/** Метод, вызывающийся в конце анимации инициализации оверлея */
	@Output() initAnimationEnd = new EventEmitter();

	/** Метод, вызывающийся в начале анимации дестроя оверлея */
	@Output() destroyAnimationStart = new EventEmitter();

	/** Метод, вызывающийся в конце анимации дестроя оверлея */
	@Output() destroyAnimationEnd = new EventEmitter();

	/** Метод, вызывающийся при скроле всего оверлея */
	@Output() scrollOverlayHandler = new EventEmitter();

	/**
	 * Элемент, клик за пределами которого будет триггерить закрытие оверлея.
	 * Также на этом элементе срабатывает закрытие по свайпу
	 */
	@ViewChild('overlayContent') overlayContent: ElementRef<HTMLElement>;

	@ViewChild('overlay') overlay: ElementRef<HTMLElement>;
	@ViewChild('overlayContentInner') overlayContentInner: SimplebarAngularComponent | ElementRef<HTMLElement>;
	@ViewChild('overlayWrapper') overlayWrapper: SimplebarAngularComponent | ElementRef<HTMLElement>;
	@ViewChild('contentElement') contentElement: SimplebarAngularComponent | ElementRef<HTMLElement>;
	@ViewChild('headerElement') headerElement: ElementRef<HTMLElement>;
	@ViewChild('footerElement') footerElement: ElementRef<HTMLElement>;
	@ViewChild('closeButton') closeButton: ButtonComponent;
	@ViewChild('aside') aside: ElementRef<HTMLElement>;

	@ContentChild('header') headerRef: TemplateRef<any>;
	@ContentChild('content') contentRef: TemplateRef<any>;
	@ContentChild('footer') footerRef: TemplateRef<any>;
	@ContentChild('overlayAside') overlayAsiderRef: TemplateRef<any>;
	@ContentChild('targetPosition') targetPosition: ElementRef<HTMLElement>;

	private targetPositionRef$ = new BehaviorSubject<HTMLElement | null>(null);
	public targetPositionRef = this.targetPositionRef$.asObservable().pipe(distinctUntilChanged());
	public get _targetPositionRef(): HTMLElement | null {
		return this.targetPositionRef$.getValue();
	}

	private overlayContentInited$ = new BehaviorSubject<boolean>(false);
	public overlayContentInited = this.overlayContentInited$
		.asObservable()
		.pipe(distinctUntilChanged());
	public get _overlayContentInited(): boolean {
		return this.overlayContentInited$.getValue();
	}

	private hiddenScrollChecked = new BehaviorSubject<boolean>(false);

	canShowExceptionParanja = merge(
		this.overlayService.openedOverlays,
		this.overlayContentInited,
	)
		.pipe(
			map(() => {
				if (!this.isMultiColumnException || !this._overlayContentInited) return false;

				const openedOverlays = this.overlayService._openedOverlays;
				const isTopOverlay = openedOverlays?.length ?
					openedOverlays[openedOverlays.length - 1] === this :
					false;

				return isTopOverlay;
			}),
			distinctUntilChanged()
		);

	public _zIndex: number = null;
	public visibility: boolean = false;
	public overlayTypes = OverlayType;
	private element: HTMLElement;
	public sidebarContentIsScrolled: boolean = false;
	private swipeSubscription: Subscription;
	private showSubscription: Subscription;
	private actualOverlayContent: HTMLElement;
	private subscriptions = new Subscription();
	public isTopOverlay: boolean = false;
	public templateContext: any = {};
	public hidingButtonByDrops$: Observable<boolean>;
	public firstElementWasFocused: boolean = false;
	private overlayNavigationMutationObserver: MutationObserver | null = null;
	public initAnimationInProcess: boolean = false;
	public destroyAnimationInProcess: boolean = false;
	private scrollTriggerSubscription: Subscription;
	public overlayHoveredAfterOpen: boolean = false;
	public isPointed: boolean = false;
	public scrollDestinations = ScrollDestination;
	public noHiddenScroll: boolean = false;

	constructor(
		private changeDetector: ChangeDetectorRef,
		private overlayService: OverlayService2,
		public el: ElementRef<HTMLElement>,
		private touchService: TouchService,
		private scrollService: ScrollService,
		@Inject(DOCUMENT) private document: Document,
		private sharedService: SharedService,
		private arrowNavigationService: ArrowNavigationService,
		private renderer: Renderer2,
		private deviceService: DeviceService,
	) {}

	ngOnInit(): void {
		if (!this.overlayId) {
			console.error('Укажите overlayId для SidebarComponent');
			return;
		}

		this.hidingButtonByDrops$ = this.overlayService.hidingButtonByDrops;
		this.element = this.el.nativeElement;

		/** Перемещаем компонент в body */
		this.document?.body?.prepend?.(this.element);

		/**
		 * При инициализации компонента вызываем метод для "регистрации" нового доступного для открытия оверлея,
		 * чтобы при маршруте вида /path?overlays=popup1,sidebar1 контент оверлея мог сразу отобразиться на странице:
		 */
		this.overlayService.register(this);

		this.subscriptions.add(
			this.overlayService.openedOverlays
				.subscribe(openedOverlays => {
					this.prioritizeNavigation();

					const newZIndex = this.calculateZIndex();
					const isTop = openedOverlays?.length ?
						openedOverlays[openedOverlays.length - 1] === this :
						false;

					const needDetectChanges = newZIndex !== this._zIndex || isTop !== this.isTopOverlay;

					this._zIndex = newZIndex;
					this.isTopOverlay = isTop;

					if (needDetectChanges) {
						this.changeDetector.detectChanges();
					}
				})
		);

		this.subscriptions.add(
			this.overlayService.openedOverlaysLinkedToAnimationLifecycle.subscribe({
				next: () => {
					this.updateParanjaProperties();
				}
			})
		);

		if(!this.deviceService.isServer) {
			Modernizr?.on('hiddenscroll', (result) => {
				if (!result) {
					this.noHiddenScroll = true;
				}
	
				this.hiddenScrollChecked.next(true);
			});
		}
		
	}

	ngAfterContentInit() {
		// чтобы актуализировать темплейты, (headerRef, contentRef) полученные через @ContentChild:
		this.changeDetector.detectChanges();
	}

	ngAfterContentChecked(): void {
		if (this.targetPosition?.nativeElement && this.el.nativeElement.contains(this.targetPosition.nativeElement)) {
			this.targetPositionRef$.next(this.targetPosition.nativeElement);
		}
	}

	ngOnDestroy(): void {
		this.overlayContentInited$.next(false);
		this.element?.remove();
		this.overlayService.remove(this.overlayId);
		this.swipeSubscription?.unsubscribe();
		this.showSubscription?.unsubscribe();
		this.subscriptions?.unsubscribe();
		this.scrollTriggerSubscription?.unsubscribe();

		this.keyboardFocusableElements?.forEach(element => {
			this.arrowNavigationService.removeNavigationElement(this.arrowNavigationGroup, { element });
		});
	}

	/**
	 * Метод для открытия оверлея. Используется только для отображения содержимого
	 * Для правильного открытия оверлея нужно использовать метод open из overlayService
	 */
	public open(event?: Event): Observable<void> {
		if (this.positioningRelativeTrigger && !event) {
			console.error('b-shared-overlay: при открытии оверлея с параметром positioningRelativeTrigger необходимо передавать event, чтобы получить доступ к триггер-элементу');
			return;
		}

		this.visibility = true;
		this.firstElementWasFocused = false;
		this._zIndex = this.calculateZIndex();
		this.changeDetector.detectChanges();
		if (this.showParanja) {
			this.overlayService.addColumnsToOverlays();
			this.overlayService.addParanjaToOverlays();
		}

		this.registerNavigationMutationObserver();
		this.prioritizeNavigation();
		this.addArrowNavigation();

		if (this.positioningRelativeTrigger && event) {
			this.positionRelativeTrigger(event.currentTarget as HTMLElement);
		}

		if (this.closingBySwipe) {
			this.swipeSubscription = this.touchService.addCloseEventWhenSwiping({
				targetElement: this.overlayContent?.nativeElement,
				onSwipe: () => this.overlayService.close(this.overlayId),
				startDragging: 0.1,
				swipeLength: 0.05,
				hideElement: true,
				swipeSide: this.swipeSide,
			});
		}

		this.actualOverlayContent = this.overlayContent?.nativeElement;

		this.overlayContentInited$.next(true);

		if (this.hiddenScrollChecked.getValue()) {
			this.showHandler?.emit();

			if (!this.scrollbarComponentRef) return;
			this.scrollbarComponentRef?.SimpleBar?.contentWrapperEl.removeAttribute('tabindex');
		} else {
			this.showSubscription = this.hiddenScrollChecked
				.pipe(filter(Boolean), take(1))
				.subscribe({
					next: () => {
						this.showHandler?.emit();

						if (!this.scrollbarComponentRef) return;

						this.changeDetector.detectChanges();
						this.scrollbarComponentRef?.SimpleBar?.contentWrapperEl?.removeAttribute('tabindex');
					}
				});
		}

		/** Возвращаем observable, выполняющийся в конце анимации инициализации оверлея, чтобы можно было отследить это событие: */
		return this.initAnimationEnd.pipe(take(1));
	}

	/**
	 * Метод для закрытия оверлея. Используется только для скрытия содержимого
	 * Для правильного закрытия оверлея нужно использовать метод close из overlayService
	 */
	public close(): Observable<void> {
		this.actualOverlayContent = this.overlayContent?.nativeElement;
		this.visibility = false;
		this.firstElementWasFocused = false;
		this.hideHandler?.emit();
		this.swipeSubscription?.unsubscribe();
		this.showSubscription?.unsubscribe();
		this.unregisterNavigationMutationObserver();

		this.changeDetector.detectChanges();

		this.targetPositionRef$.next(null);
		this.overlayContentInited$.next(false);

		/** Возвращаем observable, выполняющийся в начале анимации дестроя оверлея, чтобы можно было отследить это событие: */
		return this.destroyAnimationStart.pipe(take(1));
	}

	/** Метод для получения z-index оверлея */
	calculateZIndex(): number {
		if (!this.visibility) return null;
		/**
		 * если указали zIndex в параметрах, используем его:
		 *
		 * Передавать zIndex нужно в особых случаях, т.к. по умолчанию z-index
		 * рассчитывается автоматически для каждого оверлея по мере открытия слоёв
		*/
		if ((typeof this.zIndex === 'number' || typeof this.zIndex === 'string') && !isNaN(this.zIndex)) {
			return Number(this.zIndex);
		} else {
			/**
			 * z-index рассчитывается автоматически для каждого оверлея по мере открытия слоёв
			 * Принцип:
			 * - "выше" по z-index тот оверлей, который открылся позже
			 * - "ниже" по z-index тот оверлей, который открылся раньше
			*/
			const baseIndex = 1000;
			const stepIndex = 10;
			const overlayIndex = this.overlayService._openedOverlays.findIndex(overlay => this.overlayId === overlay.overlayId);

			if (overlayIndex === -1) return baseIndex;

			return baseIndex + (overlayIndex * stepIndex);
		}
	}

	public get overlayContainerClass(): string[] {
		const classList: string[] = [];

		if (this.type === OverlayType.sidebar) {
			switch (this.sidebarPosition) {
			case SidebarPosition.left:
				classList.push('sidebar--left');
				break;
			case SidebarPosition.right:
			default:
				classList.push('sidebar--right');
				break;
			}
		}

		if (Array.isArray(this.containerClass)) {
			classList.push(...this.containerClass);
		} else if (typeof this.containerClass === 'string') {
			classList.push(this.containerClass);
		}

		if (this.blockContent) {
			classList.push('sidebar--loading');
		}

		return classList;
	}

	public closeOverlay() {
		this.overlayService.close(this.overlayId);
	}

	/**
	 * Параметры angular-анимации для оверлея
	 * с учётом его позиционирования и этапа жизненного цикла
	 */
	public get animationParams() {
		let translateX: string = '0%';

		if ([
			OverlayType.sidebarSecondary,
			OverlayType.sidebar,
			OverlayType.dropdownSidebar,
			OverlayType.successScreen,
		].includes(this.type as OverlayType)) {
			switch (this.sidebarPosition) {
			case SidebarPosition.right:
				translateX = '100%';
				break;
			case SidebarPosition.left:
			default:
				translateX = '-100%';
				break;
			}
		}

		return {
			value: this.visibility ? ':enter' : ':leave',
			params: {
				translateX,
			},
		};
	}

	/** Обработчик начала анимации оверлея */
	public animationStart(event: AnimationEvent) {
		const isInitAnimationStart = event.fromState === 'void' && event.phaseName === 'start';

		if (isInitAnimationStart) {
			this.initAnimationInProcess = true;
			this.initAnimationStart?.emit();
			this.changeOverlayMargin();
			return;
		}

		const isDestroyAnimationStart = event.toState === 'void' && event.phaseName === 'start';

		if (isDestroyAnimationStart) {
			this.destroyAnimationInProcess = true;
			this.destroyAnimationStart?.emit();
			this.changeOverlayMargin();
		}
	}

	/** Обработчик завершения анимации оверлея */
	public animationDone(event: AnimationEvent) {
		const isInitAnimationEnd = event.fromState === 'void' && event.phaseName === 'done';

		if (isInitAnimationEnd) {
			this.initAnimationInProcess = false;
			this.initAnimationEnd?.emit();
			this.clearOverlayMargin();
			return;
		}

		const isDestroyAnimationEnd = event.toState === 'void' && event.phaseName === 'done';

		if (isDestroyAnimationEnd) {
			this.destroyAnimationInProcess = false;
			this.destroyAnimationEnd?.emit();
		}
	}

	/** Метод для изменения margin-right оверлея */
	private changeOverlayMargin() {
		const scrollbarWidth = this.scrollService.calculateScrollbarWidth();

		/**
		 * Для сайдбаров с right-позиционированием и попапов + с блокировкой скролла + при наличии скроллбара:
		 * Добавляем отрциательный margin-right, чтобы страница не дёргалась при появлении/исчезновении скроллбара страницы
		 */
		if (
			(this.type !== OverlayType.sidebarSecondary && this.type !== OverlayType.dropdownSidebar && this.type !== OverlayType.successScreen) &&
			scrollbarWidth > 0 &&
			(this.type !== OverlayType.sidebar || this.sidebarPosition === SidebarPosition.right) &&
			this.blockScroll
		) {
			this.actualOverlayContent.style.marginRight = `-${scrollbarWidth}px`;
		}
	}

	/** Метод для удаления margin-right оверлея */
	clearOverlayMargin() {
		if (
			(this.type !== OverlayType.sidebarSecondary && this.type !== OverlayType.dropdownSidebar && this.type !== OverlayType.successScreen) &&
			(this.type !== OverlayType.sidebar || this.sidebarPosition === SidebarPosition.right) &&
			this.blockScroll
		) {
			this.actualOverlayContent.style.marginRight = '0px';
		}
	}

	/** Обработчик скролла контентной части сайдбара */
	public sidebarContentScrollHandler(event: Event) {
		const newValue = (event.target as HTMLDivElement).scrollTop > 0;
		const needDetectChanges = newValue !== this.sidebarContentIsScrolled;
		this.sidebarContentIsScrolled = newValue;

		if (needDetectChanges) {
			this.changeDetector.detectChanges();
		}

		this.scrollHandler.emit(event);
	}

	/** метод для позиционирования содержимого относительно триггер-элемента */
	private positionRelativeTrigger(targetElement?: HTMLElement) {
		this.renderer.addClass(this.overlayContent.nativeElement, 'invisible');

		this.targetPositionRef
			.pipe(
				filter(Boolean),
				take(1),
				tap({
					next: () => {
						const overlay = this.overlayContent.nativeElement;
						const overlayRect = overlay.getBoundingClientRect();
						const { viewportHeight } = this.scrollService.getViewportSize();

						if (overlayRect.height >= viewportHeight) {
							this.renderer.addClass(overlay, 'h-100p');
							return;
						}

						const positionedElementRect = this.targetPosition.nativeElement.getBoundingClientRect();
						const triggerElementRect = targetElement.getBoundingClientRect();


						const transformValue = triggerElementRect.top -
							(parseInt(getComputedStyle(this.targetPosition.nativeElement).marginTop) -
							parseInt(getComputedStyle(this.targetPosition.nativeElement).paddingTop)) -
							positionedElementRect.top -
							parseInt(getComputedStyle(this.targetPosition.nativeElement).marginTop) -
							(parseInt(getComputedStyle(this.targetPosition.nativeElement).paddingTop) / 2);

						const margin = parseFloat(this.sharedService?.config?.overlay?.minDropdownMargins);
						const bottomBorder = viewportHeight - margin;

						const isBeyondTheTop = (overlayRect.top + transformValue) < margin;
						const isBeyondTheBottom = (overlayRect.bottom + transformValue) > bottomBorder;

						if (!isBeyondTheTop && !isBeyondTheBottom) {
							overlay.style.transform = `translateY(${transformValue}px)`;
						}

						if (isBeyondTheBottom) {
							this.renderer.addClass(overlay, 'mt-auto');
						} else if (isBeyondTheTop) {
							this.renderer.addClass(overlay, 'mb-auto');
						}
					}
				})
			)
			.subscribe({
				next: () => {
					this.renderer.removeClass(this.overlayContent.nativeElement, 'invisible');
				}
			});
	}

	get keyboardFocusableElements(): HTMLElement[] {
		return Object.values(this.el?.nativeElement?.querySelectorAll<HTMLElement>(
			'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
		));
	}

	/** Метод для приоритезации навигации внутри оверлея: проставляем tab-index для фокусируемых элементов */
	prioritizeNavigation(tabIndex: number = this.calculateTabIndex()) {
		this.keyboardFocusableElements.forEach(navElement => {
			const tabIndexValue = tabIndex + Number(navElement?.dataset?.overlayTabIndexIncrement || 0);

			this.renderer.setAttribute(
				navElement,
				'tabindex',
				tabIndexValue.toString()
			);
		});
	}

	addArrowNavigation() {
		let focusableElements = this.keyboardFocusableElements;
		const openedDrops = this.overlayService._dropdownOverlays
			?.map(drop => drop?.getDropdownOverlay())
			?.filter(Boolean);

		if (openedDrops?.length) {
			focusableElements = focusableElements.filter(
				element => !openedDrops?.some(drop => drop?.contains(element))
			);
		}

		focusableElements
			.forEach(element => {
				this.arrowNavigationService.addNavigationElement(this.arrowNavigationGroup, { element });
			});
	}

	/** Метод для получения значения атрибута tab-index навигационных элементов оверлея */
	calculateTabIndex(): number {
		if (!this.visibility) return 0;

		const baseIndex = 1;
		const overlayIndex = this.overlayService._openedOverlays.findIndex(overlay => this.overlayId === overlay.overlayId);

		if (overlayIndex === -1) return baseIndex;

		return baseIndex + overlayIndex;
	}

	get arrowNavigationGroup() {
		return `${this.overlayId}ArrowNavigation`;
	}

	get isWidget() {
		return Boolean(this.overlayContent?.nativeElement?.querySelector('.status-widget'));
	}

	public getWidgetHoverAreaElements() {
		return [
			this.headerElement?.nativeElement,
			this.footerElement?.nativeElement,
			this.overlayContent?.nativeElement?.querySelector('.status-widget'),
			this.overlayContent?.nativeElement?.querySelector('.drop-list'),
		].filter(Boolean);
	}

	public getWidgetHoverAreaRects(): DOMRect[] {
		return this.getWidgetHoverAreaElements()
			.map(i => i?.getBoundingClientRect())
			.filter(Boolean);
	}

	public getOverlayContentRect(): DOMRect {
		this.changeDetector.detectChanges();

		if (this.type === OverlayType.successScreen) return this.footerElement?.nativeElement?.getBoundingClientRect();

		return this.overlayContent?.nativeElement?.getBoundingClientRect();
	}

	public getCloseButtonRect(): DOMRect {
		this.changeDetector.detectChanges();
		return this.closeButton?.elementRef?.nativeElement?.getBoundingClientRect();
	}

	/** Метод для эмита при скролле всего оверлея */
	public scrollOverlay(event: Event): void {
		this.scrollOverlayHandler.emit(event);
	}

	private _queryParams = {};

	public get queryParams(): QueryParams {
		return this._queryParams;
	}

	public get isClientEnvironment(): boolean {
		return Boolean(this.sharedService?.environment?.project) &&
			this.sharedService.environment.project === Project.client;
	}

	public set queryParams(value: QueryParams) {
		this._queryParams = value;
		this.templateContext = {
			...this.templateContext,
			queryParams: value,
		};
		this.changeDetector.detectChanges();
	}

	registerNavigationMutationObserver() {
		this.overlayNavigationMutationObserver = new MutationObserver(records => {
			if (records.some(record => Boolean(record?.addedNodes?.length))) {
				this.prioritizeNavigation();
				this.addArrowNavigation();
			}
		});

		this.overlayNavigationMutationObserver.observe(this.el.nativeElement, {
			subtree: true,
			childList: true,
			attributes: false,
			characterData: false,
		});
	}

	unregisterNavigationMutationObserver() {
		this.overlayNavigationMutationObserver.disconnect();
		this.overlayNavigationMutationObserver = null;
	}

	get isXs(): boolean {
		return this.overlayContent?.nativeElement.classList.contains('sidebar--xs');
	}

	get isSm(): boolean {
		return this.overlayContent?.nativeElement.classList.contains('sidebar--sm');
	}

	get isLarge(): boolean {
		return !this.isXs && !this.isSm;
	}

	/** участвует ли оверлей в мультиколоночной системе */
	get isPartOfMultiColumnSystem(): boolean {
		return (
			this.showParanja &&
			this.type === OverlayType.sidebarSecondary &&
			!this.isSidebarTransparent
		);
	}

	get isMultiColumnException() {
		return this.sharedService.config.overlay.multiColumnSystem &&
			(
				this.type === OverlayType.popup ||
				(
					([OverlayType.dropdownSidebar, OverlayType.successScreen, OverlayType.sidebarSecondary].some(type => type === this.type)) &&
					this.isSidebarTransparent
				)
			);
	}

	get isSidebarTransparent() {
		return this.overlayContent?.nativeElement?.classList?.contains('sidebar--transparent');
	}

	clearColumns(exceptions: string[] = []) {
		this.overlayContent.nativeElement?.classList.forEach(cssClass => {
			const isOffsetClass = cssClass.startsWith('sidebar--offset-');
			if (isOffsetClass && !exceptions.includes(cssClass)) {
				this.renderer.removeClass(this.overlayContent.nativeElement, cssClass);
			}
		});
	}

	changeColumn(column: number) {
		if (!column || column === 0) {
			this.clearColumns();
			return;
		}

		const overlayElement = this.overlayContent?.nativeElement;

		if (column < 0) {
			const negativeColumnClass = `sidebar--offset-n${Math.abs(column)}`;
			this.clearColumns([negativeColumnClass]);

			if (overlayElement && !overlayElement?.classList?.contains(negativeColumnClass)) {
				this.renderer.addClass(overlayElement, negativeColumnClass);
			}
		} else {
			const columnClass = `sidebar--offset-${column}`;
			this.clearColumns([columnClass]);

			if (overlayElement && !overlayElement?.classList?.contains(columnClass)) {
				this.renderer.addClass(overlayElement, columnClass);
			}
		}
	}

	get column() {
		const classesList = this.overlayContent?.nativeElement?.classList.value.split(' ');
		const negativeOffsetClass = classesList.find(i => i.startsWith('sidebar--offset-n'));
		const positiveOffsetClass = classesList.find(
			i => i.startsWith('sidebar--offset-') &&
				!i.startsWith('sidebar--offset-n')
		);

		if (negativeOffsetClass) {
			return -parseInt(negativeOffsetClass.substring(negativeOffsetClass.length - 1)) || 0;
		} else if (positiveOffsetClass) {
			return parseInt(positiveOffsetClass.substring(positiveOffsetClass.length - 1)) || 0;
		} else {
			return 0;
		}
	}

	get wrapper(): HTMLElement {
		return this.overlayWrapper instanceof SimplebarAngularComponent ?
			this.overlayWrapper?.elRef?.nativeElement :
			this.overlayWrapper?.nativeElement;
	}

	get contentInner(): HTMLElement {
		return this.overlayContentInner instanceof SimplebarAngularComponent ?
			this.overlayContentInner?.elRef?.nativeElement :
			this.overlayContentInner?.nativeElement;
	}

	clearParanja() {
		const paranjaClasses = ['paranja', 'paranja--hover'];
		const wrapper = this.wrapper;

		if (wrapper && paranjaClasses.some(i => wrapper?.classList.contains(i))) {
			paranjaClasses.forEach(cssClass => {
				this.renderer.removeClass(wrapper, cssClass);
			});
		}

		this.wrapper?.classList.forEach(cssClass => {
			const paranjaClass = cssClass.startsWith('paranja--');
			if (paranjaClass) {
				this.renderer.removeClass(wrapper, cssClass);
			}
		});
		this.updateParanjaProperties();
	}

	setParanjaHover() {
		if (this.isSidebarTransparent) return;

		const hoverClass = 'paranja--hover';
		const wrapper = this.wrapper;

		if (wrapper && !wrapper?.classList.contains(hoverClass)) {
			this.renderer.addClass(wrapper, hoverClass);
		}
	}

	setParanjaHoverSecondary() {
		const hoverClass = 'paranja--hover-secondary';
		const wrapper = this.wrapper;

		if (wrapper && !wrapper?.classList.contains(hoverClass)) {
			this.renderer.addClass(wrapper, hoverClass);
		}
	}

	removeParanjaHover() {
		if (this.isSidebarTransparent) return;

		const hoverClasses = ['paranja--hover', 'paranja--hover-secondary'];
		const wrapper = this.wrapper;

		hoverClasses.forEach(hoverClass => {
			if (wrapper?.classList?.contains(hoverClass)) {
				this.renderer.removeClass(wrapper, hoverClass);
			}
		});
	}

	changeParanja(paranja: number) {
		if (!paranja || paranja <= 0) {
			this.clearParanja();
			return;
		}

		let value = paranja;
		if (value > 4) value = 4;

		const hoverClass = 'paranja--hover';
		const paranjaClass = 'paranja';
		const paranjaValueClass = `paranja--${value}`;
		const wrapper = this.wrapper;

		if (wrapper?.classList?.contains(hoverClass)) {
			this.renderer.removeClass(wrapper, hoverClass);
		}

		if (wrapper && !wrapper?.classList?.contains(paranjaClass)) {
			this.renderer.addClass(wrapper, paranjaClass);
		}

		this.wrapper?.classList.forEach(cssClass => {
			const paranjaClass = cssClass.startsWith('paranja--');
			if (paranjaClass && cssClass !== paranjaValueClass) {
				this.renderer.removeClass(wrapper, cssClass);
			}
		});

		if (wrapper && !wrapper?.classList?.contains(paranjaValueClass)) {
			this.renderer.addClass(wrapper, paranjaValueClass);
		}

		this.updateParanjaProperties();
	}

	get paranja(): number {
		const classesList = this.wrapper?.classList?.value?.split(' ');
		const paranjaClass = classesList?.find(i => i.startsWith('paranja--') && i !== 'paranja--hover');

		return paranjaClass ?
			parseInt(paranjaClass?.substring(paranjaClass.length - 1)) || 0 :
			0;
	}

	get overlayIsUnderException(): boolean {
		const overlayIndex = this.overlayService.sortedOpenedOverlays.findIndex(overlay => overlay?.overlayId === this.overlayId);

		if (overlayIndex === -1) return false;

		const topOverlays = this.overlayService.sortedOpenedOverlays.filter((overlay, index) => index < overlayIndex);

		return topOverlays.some(overlay => overlay?.isMultiColumnException);
	}

	get paranjaVisibility() {
		return Boolean(this.blockContent || this.paranja > 0 || this.overlayIsUnderException);
	}

	updateParanjaProperties() {
		const overlayInner = this.contentInner;

		if (this.paranjaVisibility) {
			const maxScrollbarWidth = Math.max(
				this.scrollService.calculateScrollbarWidth(overlayInner),
				this.scrollService.calculateScrollbarWidth(this.wrapper)
			);

			if (overlayInner) {
				this.renderer.setStyle(overlayInner, '--scrollbar-size', '0', RendererStyleFlags2.DashCase);
				this.renderer.setStyle(overlayInner, '--sidebar-wrapper-pr', maxScrollbarWidth.toString(), RendererStyleFlags2.DashCase);
			}
		} else {
			overlayInner?.style?.removeProperty('--scrollbar-size');
			overlayInner?.style?.removeProperty('--sidebar-wrapper-pr');
		}
	}

	get sidebarParanjaNgClass() {
		if (!this.blockContent) return null;

		const openedCustomDropdown: DropdownComponent = this.overlayService?._dropdownOverlays?.find(
			dropdown => dropdown instanceof DropdownComponent && dropdown?.isCustom
		);

		return {
			'sidebar__paranja--secondary': Boolean(openedCustomDropdown?.elementRef?.nativeElement) &&
				this.el.nativeElement.contains(openedCustomDropdown.elementRef.nativeElement)
		};
	}

	get scrollbarComponentRef(): SimplebarAngularComponent | undefined {
		return [this.overlayContentInner, this.overlayWrapper, this.contentElement]
			.find(ref => ref instanceof SimplebarAngularComponent) as SimplebarAngularComponent;
	}

	get isHiddenScrollChecked$(): Observable<boolean> {
		return this.hiddenScrollChecked.asObservable().pipe(
			shareReplay()
		);
	}
}
