import {NgModule} from '@angular/core';
import {SectionViewportDirective} from './section-viewport.directive';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [SectionViewportDirective],
	imports: [CommonModule],
	exports: [SectionViewportDirective]
})
export class SectionViewportModule {
}
