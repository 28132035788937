import { DOCUMENT } from '@angular/common';
import {
	Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation,
	Input, Output, ViewChild, EventEmitter, ElementRef, ChangeDetectorRef, AfterViewInit, Renderer2, Inject,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TextareaValue, CalculatedNodeHeights, ChipsItem, HIDDEN_TEXTAREA_STYLE, SizingData, SizingProps, SIZING_STYLE } from './textarea.types';
import { WINDOW } from '../../../tokens';


@Component({
	selector: 'b-shared-textarea',
	templateUrl: './textarea.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: TextareaComponent,
		}
	],
})
export class TextareaComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

	fieldValue$ = new BehaviorSubject<TextareaValue | null>(null);
	fieldValue = this.fieldValue$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _fieldValue(): TextareaValue | null {
		return this.fieldValue$.getValue();
	}

	isFocused$ = new BehaviorSubject<boolean>(false);
	isFocused = this.isFocused$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _isFocused(): boolean {
		return this.isFocused$.getValue();
	}

	subscriptions = new Subscription();
	chipDynamicValue: Subscription;
	onChange = (value) => {};
	onTouched = () => {};
	touched = false;
	fieldIsDisabled = false;
	counter: number;

	hiddenTextarea: HTMLTextAreaElement | null = null;
	heightRef: number = 0;
	chipsLabels: string[] | null = null;
	activeChip: string | null = null;

	@Input() placeholder: string = '';
	@Input() rows: number = null;
	@Input() cols: number = null;
	@Input() minRows: number = null;
	@Input() maxRows: number = null;
	@Input() textareaClass: string = null;
	@Input() counterClass: string = null;
	@Input() titleClass: string = null;
	@Input() disabled = false;
	@Input() maxlength: number = null;
	@Input() autofocus: boolean = false;
	@Input() withCounter: boolean = false;
	@Input() autoResize: boolean = false;
	@Input() chips: ChipsItem[] | null = null;

	// флаг для отображения анимированного placeholder'а
	@Input() animatedTitle: boolean = false;

	@Output() blurHandler = new EventEmitter();
	@Output() focusHandler = new EventEmitter();
	@Output() heightChangeHandler = new EventEmitter<({ height: number, rowHeight: number })>();

	@ViewChild('textareaElement', { static: true }) textareaElement: ElementRef<HTMLTextAreaElement>;

	constructor(
		private changeDetector: ChangeDetectorRef,
		private elementRef: ElementRef,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
		@Inject(WINDOW) private _window: Window,
	) {}

	ngOnInit() {
		if (this.withChips) {
			this.chipsLabels = this.chips.map(i => i.chip);
		}

		this.subscriptions.add(
			this.fieldValue.subscribe(value => {
				if (this.autoResize) {
					this.resizeTextarea();
				}

				if (this.canShowCounter) {
					let textareaValue = '';

					if (typeof value === 'string') {
						textareaValue = value as string ?? '';
					} else {
						textareaValue = value?.textarea ?? '';
					}

					const newValueOfCounter = this.maxlength - textareaValue.length;
					const needDetectChanges = newValueOfCounter !== this.counter;
					this.counter = newValueOfCounter;

					if (needDetectChanges) this.changeDetector.detectChanges();
				}
			})
		);

		if (this.autoResize) {
			// ожидаем первое изменение css классов у textarea, чтобы получить актуальную высоту поля
			const observer = new MutationObserver((ev) => {
				this.resizeTextarea();
				observer?.disconnect();
			});
			observer.observe(this.textareaElement.nativeElement, { attributeFilter: ['class'], attributes: true });

			this._window?.addEventListener?.('resize', this.resizeTextarea);
		}
	}

	ngAfterViewInit() {
		if (this._fieldValue) {
			this.setFieldValue(this._fieldValue);
		}

		if (this.autofocus && !this.isDisabled) {
			this.textareaElement?.nativeElement?.focus();
		}
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
		this.chipDynamicValue?.unsubscribe?.();
		this._window?.removeEventListener?.('resize', this.resizeTextarea);
	}

	writeValue(value: TextareaValue | null = '') {
		this.fieldValue$.next(value);
		this.setFieldValue(value);
		this.animateFieldTitle();
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.fieldIsDisabled = disabled;

		this.changeDetector.detectChanges();
	}

	setFieldValue(value: TextareaValue | null) {
		if (!this.textareaElement?.nativeElement) return;

		if (typeof value === 'string' || !this.withChips) {
			this.textareaElement.nativeElement.value = value as string || '';
		} else {
			this.textareaElement.nativeElement.value = value?.textarea || '';
			this.activeChip = value?.chips?.chip || null;
			this.chipsChanged();
		}
	}

	chipsChanged() {
		if (typeof this._fieldValue === 'string') return;

		const targetChip = this.chips?.find(i => i.chip === this.activeChip);

		this.chipDynamicValue?.unsubscribe?.();

		if (targetChip?.dynamicValue) {
			this.chipDynamicValue = targetChip.dynamicValue.subscribe({
				next: label => {
					if (typeof this._fieldValue === 'string') return;

					const value = {
						...this._fieldValue,
						chips: {
							chip: this.activeChip,
							label,
						}
					};

					this.fieldValue$.next(value);
					this.onChange(value);
				}
			});
		} else {
			const value = {
				...this._fieldValue,
				chips: {
					chip: this.activeChip,
					label: null,
				}
			};

			this.fieldValue$.next(value);
			this.onChange(value);
		}
	}

	get isDisabled() {
		return this.fieldIsDisabled || this.disabled;
	}

	get canShowCounter(): boolean {
		return this.withCounter
			&& typeof this.maxlength === 'number';
	}

	get withChips(): boolean {
		return Boolean(this.chips?.length);
	}

	fieldFocused() {
		if (this.autoResize) {
			this.resizeTextarea();
		}

		this.focusHandler?.emit();
		this.isFocused$.next(true);
		this.animateFieldTitle();
	}

	fieldBlured() {
		this.markAsTouched();

		if (this.autoResize) {
			this.resizeTextarea();
		}

		this.blurHandler?.emit();
		this.isFocused$.next(false);
		this.animateFieldTitle();
	}

	fieldChanged(event) {
		let value: TextareaValue;

		if (this.withChips && typeof this._fieldValue !== 'string') {
			value = {
				...this._fieldValue,
				textarea: event.target.value,
			};
		} else {
			value = event.target.value;
		}

		this.fieldValue$.next(value);
		this.onChange(value);
	}

	public focus() {
		this.textareaElement?.nativeElement?.focus();
	}

	public blur() {
		this.textareaElement?.nativeElement?.blur();
	}

	// https://www.npmjs.com/package/react-textarea-autosize

	pick = <Obj extends { [key: string]: any }, Key extends keyof Obj>(
		props: Key[],
		obj: Obj,
	): Pick<Obj, Key> => {
		return props.reduce((acc, prop) => {
			acc[prop] = obj[prop];
			return acc;
		}, {} as Pick<Obj, Key>);
	};

	getSizingData = (node: HTMLElement): SizingData => {
		const style = this._window.getComputedStyle(node);

		if (style === null) {
			return null;
		}

		const sizingStyle = this.pick((SIZING_STYLE as unknown) as SizingProps[], style);
		const { boxSizing } = sizingStyle;

		// probably node is detached from DOM, can't read computed dimensions
		if (boxSizing === '') {
			return null;
		}

		const paddingSize = parseFloat(sizingStyle.paddingBottom!) + parseFloat(sizingStyle.paddingTop!);

		const borderSize = parseFloat(sizingStyle.borderBottomWidth!) + parseFloat(sizingStyle.borderTopWidth!);

		return {
			sizingStyle,
			paddingSize,
			borderSize,
		};
	};

	getHeight = (node: HTMLElement, sizingData: SizingData): number => {
		const height = node.scrollHeight;

		if (sizingData.sizingStyle.boxSizing === 'border-box') {
			// border-box: add border, since height = content + padding + border
			return height + sizingData.borderSize;
		}

		// remove padding, since height = content
		return height - sizingData.paddingSize;
	};

	forceHiddenStyles = (node: HTMLElement) => {
		Object.keys(HIDDEN_TEXTAREA_STYLE).forEach((key) => {
			node.style.setProperty(
				key,
				HIDDEN_TEXTAREA_STYLE[key as keyof typeof HIDDEN_TEXTAREA_STYLE],
				'important',
			);
		});
	};

	calculateNodeHeight(
		sizingData: SizingData,
		value: string,
		minRows = 1,
		maxRows = Infinity,
	): CalculatedNodeHeights {
		if (!this.hiddenTextarea) {
			this.hiddenTextarea = this.renderer.createElement('textarea');
			this.renderer.setAttribute(this.hiddenTextarea, 'tabindex', '-1');
			this.renderer.setAttribute(this.hiddenTextarea, 'aria-hidden', 'true');
			this.forceHiddenStyles(this.hiddenTextarea);
		}

		if (this.hiddenTextarea.parentNode === null) {
			this.renderer.appendChild(this.document.body, this.hiddenTextarea);
		}

		const { paddingSize, borderSize, sizingStyle } = sizingData;
		const { boxSizing } = sizingStyle;

		Object.keys(sizingStyle).forEach((_key) => {
			const key = _key as keyof typeof sizingStyle;
			this.hiddenTextarea!.style[key] = sizingStyle[key] as any;
		});

		this.forceHiddenStyles(this.hiddenTextarea);

		this.hiddenTextarea.value = value;
		let height = this.getHeight(this.hiddenTextarea, sizingData);

		// measure height of a textarea with a single row
		this.hiddenTextarea.value = 'x';
		const rowHeight = this.hiddenTextarea.scrollHeight - paddingSize;

		let minHeight = rowHeight * minRows;
		if (boxSizing === 'border-box') {
			minHeight = minHeight + paddingSize + borderSize;
		}
		height = Math.max(minHeight, height);

		let maxHeight = rowHeight * maxRows;
		if (boxSizing === 'border-box') {
			maxHeight = maxHeight + paddingSize + borderSize;
		}
		height = Math.min(maxHeight, height);

		return [height, rowHeight];
	}

	resizeTextarea = () => {
		const node = this.textareaElement.nativeElement!;
		const nodeSizingData = this.getSizingData(node);

		if (!nodeSizingData) {
			return;
		}

		const [height, rowHeight] = this.calculateNodeHeight(
			nodeSizingData,
			node.value || node.placeholder || 'x',
			this.minRows,
			this.maxRows,
		);

		if (this.heightRef !== height) {
			this.heightRef = height;
			node.style.setProperty('height', `${height}px`, 'important');
			this.heightChangeHandler?.emit?.({ height, rowHeight });
		}
	};

	animateFieldTitle() {
		if (!this.animatedTitle) return;

		const titleIsSmall = Boolean(this._fieldValue) || this._isFocused;
		const textarea = this.elementRef?.nativeElement;

		if (!textarea) return;

		if (titleIsSmall) {
			this.renderer.addClass(textarea, 'field--title');
		} else {
			this.renderer.removeClass(textarea, 'field--title');
		}
	}

}
