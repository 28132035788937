import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone, Renderer2, RendererFactory2, afterNextRender } from '@angular/core';
import { ActivatedRoute, Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ScrollService } from '../../../services/scroll.service';
import { BehaviorSubject, fromEvent, map, take } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { QueryParams } from './overlay.types';
import { SharedService } from '../../../services/shared/shared.service';

@Injectable({ providedIn: 'root' })
export class OverlayService2 {

	public backgroundElement: HTMLElement;
	private backgroundAnimation: Animation;
	private renderer: Renderer2;

	/**
	 * "Зарегистрированные" оверлеи, находящиеся в DOM.
	 * Для корректной работы маршрутизируемых оверлеев их нужно заранее "регистрировать"
	 */
	private overlays$ = new BehaviorSubject<any[]>([]);
	public overlays = this.overlays$.asObservable();
	public get _overlays(): any[] {
		return this.overlays$.getValue();
	}

	/**
	 * Отркытые оверлеи
	 * Представлены в порядке их "слойности" по z-index (в порядке открытия) на странице
	 */
	private openedOverlays$ = new BehaviorSubject<any[]>([]);
	public openedOverlays = this.openedOverlays$.asObservable();
	public get _openedOverlays(): any[] {
		return this.openedOverlays$.getValue();
	}

	/**
	 * Список отркытых оверлеев, обновляющийся в начале анимации дестроя и в конце анимации инициализации оверлеев
	 * Оверлеи представлены в порядке их "слойности" по z-index (в порядке открытия) на странице
	 */
	private openedOverlaysLinkedToAnimationLifecycle$ = new BehaviorSubject<any[]>([]);
	public openedOverlaysLinkedToAnimationLifecycle = this.openedOverlaysLinkedToAnimationLifecycle$.asObservable();
	public get _openedOverlaysLinkedToAnimationLifecycle(): any[] {
		return this.openedOverlaysLinkedToAnimationLifecycle$.getValue();
	}

	/** Признак видимости полупрозрачного фона. Зависит от наличия открытых оверлеев с параметром showBackground */
	private backgroundVisibility$ = new BehaviorSubject<boolean>(false);
	public backgroundVisibility = this.backgroundVisibility$
		.asObservable()
		.pipe(distinctUntilChanged());
	public get _backgroundVisibility(): boolean {
		return this.backgroundVisibility$.getValue();
	}

	/** признак того, что навигация в процессе и не завершена */
	private navigationInProgress$ = new BehaviorSubject<boolean>(false);
	public navigationInProgress = this.navigationInProgress$
		.asObservable()
		.pipe(distinctUntilChanged());

	waitForEndNavigation = this.navigationInProgress.pipe(
		filter(navigationInProgress => !navigationInProgress),
		take(1),
	);

	/**
	 * Очередь оверлеев для навигации
	 * Необходима для того, чтобы работа всегда осуществлялась с актуальными query-параметрами после навигации
	 */
	private overlayNavigationQueue$ = new BehaviorSubject<string[]>([]);
	public overlayNavigationQueue = this.overlayNavigationQueue$.asObservable();
	public get _overlayNavigationQueue(): string[] {
		return this.overlayNavigationQueue$.getValue();
	}


	/**
	 * Оверлеи-дропдауны, находящиеся в DOM. (autocomplete, dropdown, multiselect и т.д.)
	 */
	private dropdownOverlays$ = new BehaviorSubject<any[]>([]);
	public dropdownOverlays = this.dropdownOverlays$.asObservable();
	public get _dropdownOverlays(): any[] {
		return this.dropdownOverlays$.getValue();
	}

	public hidingButtonByDrops = this.dropdownOverlays$.asObservable()
		.pipe(
			map(drops => drops.some(d => d?.hideCloseButtons))
		);

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private ngZone: NgZone,
		private scrollService: ScrollService,
		private sharedService: SharedService,
		private rendererFactory: RendererFactory2,
	) {
		afterNextRender(() => {
			this.renderer = this.rendererFactory.createRenderer(null, null);

			this.router.events.subscribe((event: RouterEvent) => {
				if (event instanceof NavigationStart) {
					this.navigationInProgress$.next(true);
				}
				if (event instanceof NavigationEnd) {
					this.navigationInProgress$.next(false);
				}
				if (event instanceof NavigationCancel) {
					this.navigationInProgress$.next(false);
				}
				if (event instanceof NavigationError) {
					this.navigationInProgress$.next(false);
				}
			});

			fromEvent(this.document, 'pointerdown')
				.pipe(
					filter(() => Boolean(this._openedOverlays.length)),
				)
				.subscribe((event: PointerEvent) => {
				/**
				 * Закрываем все верхние по z-index оверлеи при клике за их пределами,
				 * если в них разрешено подобное закрытие: (параметр closeOnOutsideClick)
				*/
					if (
						!this._overlays.some(overlay => overlay?.initAnimationInProcess || overlay?.destroyAnimationInProcess) &&
					!this._dropdownOverlays?.some(i => i?.isCustom)
					) {
						const topOverlay = this.sortedOpenedOverlays[0];

						if (topOverlay.type === 'successScreen') {
							const aside = topOverlay.aside?.nativeElement;

							if (!aside || !aside.contains(event.target)) {
								return this.close(topOverlay.overlayId);
							}

							return;
						}
						let targetOverlayIndex = this.sortedOpenedOverlays.findIndex(overlay => {
							const clientRect = overlay.overlayContent.nativeElement?.getBoundingClientRect();
							return this.scrollService.coordsAreInsideRect(event?.x, event?.y, clientRect);
						});

						if (targetOverlayIndex === -1) targetOverlayIndex = this.sortedOpenedOverlays.length;

						const topOverlays = this.sortedOpenedOverlays.filter(
							(el, index) => index < targetOverlayIndex
						);
						topOverlays.forEach((overlay) => {
							if (overlay.closeOnOutsideClick) this.close(overlay.overlayId);
						});
					}
				});

			this.openedOverlays.subscribe(openedOverlays => {
				this.backgroundVisibility$.next(
					openedOverlays.some(overlay => Boolean(overlay.showBackground))
				);

				this.sortedOpenedOverlays.forEach((overlay, index) => {
					if (index === 0) return;
					overlay.firstElementWasFocused = false;
				});
				this.switchBlockOfScroll(false);
			});

			this.openedOverlaysLinkedToAnimationLifecycle.subscribe(openedOverlays => {
			/**
			 * Вкл/выкл возможность скроллить страницу в зависимости от наличия
			 * на странице хотя бы одного открытого оверлея, который блокирует скролл страницы параметром blockScroll:
			*/

				if (openedOverlays.some(overlay => Boolean(overlay.blockScroll))) {
					this.scrollService.blockScroll();
				} else {
					this.scrollService.unblockScroll();
				}

			});

			/** При изменении query-параметров синхронизируем открытые/закрытые оверлеи и query-параметр "overlays": */
			this.activatedRoute.queryParams.subscribe(() => {
				this.synchronizeParamsAndOverlays();
			});

			this.backgroundVisibility.subscribe(isVisible => {
				if (isVisible) {
					this.createBackground();
				} else {
					this.removeBackground();
				}
			});
		});
	}

	switchBlockOfScroll(isClosing = false) {
		const openedOverlays = this.openedOverlays$.getValue();
		/**
		 * Вкл/выкл возможность скроллить страницу в зависимости от наличия
		 * на странице хотя бы одного открытого оверлея, который блокирует скролл страницы параметром blockScroll:
		 */
		if (openedOverlays.length && openedOverlays.some(overlay => Boolean(overlay.blockScroll))) {
			this.scrollService.blockScroll();
			this.document.body.style.overflowY = 'hidden';
		} else {
			this.scrollService.unblockScroll();
			this.document.body.removeAttribute('style');
		}
	}

	moveOverlaysBack(overlays = this.sortedOpenedOverlays) {
		const list = [...overlays]
			.filter(overlay => overlay?.isPartOfMultiColumnSystem);

		const overlaysWithColumns = this.getDefaultColumnsOfOverlays()
			.map(({ overlay, column }) => {
				if (list.some(i => i?.overlayId === overlay?.overlayId)) {
					return {
						overlay,
						column: column - 1,
					};
				}

				return { overlay, column };
			});

		overlaysWithColumns.forEach(({ overlay, column }) => {
			if (column === 0) {
				overlay.clearColumns();
			} else {
				overlay.changeColumn(column);
			}
		});
	}

	getDefaultColumnsOfOverlays() {
		if (!this.sortedOpenedOverlays?.length) return [];

		const list = this.sortedOpenedOverlays.filter(overlay => overlay?.isPartOfMultiColumnSystem);

		return list
			.reduce((accum, overlay, index) => {
				const isTopOverlay = index === 0;

				if (isTopOverlay) {
					accum.push({
						overlay,
						column: 0
					});
					return accum;
				}

				const overlayOverTheCurrentOne = accum?.[accum.length - 1];

				const widthOfOverlayOverTheCurrentOne = this.getWidthOfOverlay(overlayOverTheCurrentOne.overlay);
				const widthOfOverlay = this.getWidthOfOverlay(overlay);

				const isDoubleColumns = overlayOverTheCurrentOne?.isXs && overlay?.isXs;

				let increment = 1;
				if (isDoubleColumns) increment = 2;

				let column = widthOfOverlayOverTheCurrentOne + increment + overlayOverTheCurrentOne.column - widthOfOverlay;
				if (column > 6) column = 6;
				if (column <= 0) column = 0;

				accum.push({ overlay, column });

				return accum;
			}, []);
	}

	addColumnsToOverlays() {
		const overlays = this.getDefaultColumnsOfOverlays();

		overlays.forEach(({ overlay, column }) =>
			column === 0 ? overlay.clearColumns() : overlay.changeColumn(column)
		);
	}

	getWidthOfOverlay(overlay) {
		let width = 0;
		if (overlay?.isXs) width = 5;
		else if (overlay?.isSm) width = 6;
		else if (overlay?.isLarge) width = 10;
		return width;
	}

	clearBackgroundParanja() {
		const paranjaClasses = ['paranja', 'paranja--hover'];

		if (paranjaClasses.some(i => this.backgroundElement?.classList?.contains(i))) {
			paranjaClasses.forEach(cssClass => {
				this.renderer.removeClass(this.backgroundElement, cssClass);
			});
		}

		this.backgroundElement?.classList?.forEach(cssClass => {
			const isParanjaClass = cssClass.startsWith('paranja--');
			if (isParanjaClass) {
				this.renderer.removeClass(this.backgroundElement, cssClass);
			}
		});
	}

	setParanjaHoverToBackground() {
		const topLayer = this._openedOverlays[this._openedOverlays.length - 1];

		const hoverClass = topLayer.isSidebarTransparent
			? 'paranja--hover-secondary' : 'paranja--hover';

		if (this.backgroundElement && !this.backgroundElement?.classList?.contains(hoverClass)) {
			this.renderer.addClass(this.backgroundElement, hoverClass);
		}
	}

	removeParanjaHoverFromBackground() {
		const hoverClasses = ['paranja--hover', 'paranja--hover-secondary'];

		hoverClasses.forEach(hoverClass => {
			if (this.backgroundElement?.classList?.contains(hoverClass)) {
				this.renderer.removeClass(this.backgroundElement, hoverClass);
			}
		});
	}

	changeBackgroundParanja(paranja: number) {
		this.clearBackgroundParanja();

		if (!paranja || paranja <= 0) return;

		let value = paranja;
		if (value > 4) value = 4;

		const paranjaClass = 'paranja';
		const paranjaValueClass = `paranja--${value}`;

		if (this.backgroundElement && !this.backgroundElement?.classList?.contains(paranjaClass)) {
			this.renderer.addClass(this.backgroundElement, paranjaClass);
		}

		if (this.backgroundElement && !this.backgroundElement?.classList?.contains(paranjaValueClass)) {
			this.renderer.addClass(this.backgroundElement, paranjaValueClass);
		}
	}

	get backgroundParanja(): number {
		const classesList = this.backgroundElement?.classList?.value.split(' ');
		const paranjaClass = classesList?.find(i => i.startsWith('paranja--') && i !== 'paranja--hover');

		return paranjaClass ?
			parseInt(paranjaClass.substring(paranjaClass.length - 1)) || 0 :
			0;
	}

	setParanjaHoverToOverlays(overlays = this.sortedOpenedOverlays) {
		overlays.forEach(overlay => overlay.setParanjaHover());
	}

	setParanjaHoverSecondaryToOverlays(overlays = this.sortedOpenedOverlays) {
		overlays.forEach(overlay => overlay.setParanjaHoverSecondary());
	}

	removeParanjaHoverFromOverlays(overlays = this.sortedOpenedOverlays) {
		overlays.forEach(overlay => overlay.removeParanjaHover());
	}

	addParanjaToOverlays() {
		if (!this.sortedOpenedOverlays?.length) return;

		const list = this.sortedOpenedOverlays.filter(
			(overlay) =>
				(overlay?.isPartOfMultiColumnSystem && !overlay?.isMultiColumnException) ||
				overlay.isSidebarTransparent
		);

		if (!list?.length) return;

		list
			.forEach((overlay, index) => {
				const isTopOverlay = index === 0;

				if (isTopOverlay) {
					overlay.clearParanja();
					return;
				}

				const overlayOverTheCurrentOne = list?.[index - 1];

				let paranja = overlayOverTheCurrentOne.paranja + 1;
				if (paranja > 4) paranja = 4;
				overlay.changeParanja(paranja);
			});

		const lastParanja = list[list.length - 1]?.paranja || 0;
		const bgParanja = lastParanja + 1;
		this.changeBackgroundParanja(bgParanja > 4 ? 4 : bgParanja);
	}

	sortByParams(list: any[]) {
		const params = this.currentOverlaysParam.filter(i => list.find(overlay => overlay?.overlayId === i));

		let index = 0;

		return [...list].map(overlay => {
			if (!params.includes(overlay?.overlayId)) return overlay;

			const overlayId = params?.[index];
			index = index + 1;

			return overlayId ?
				(list.find(i => i?.overlayId === overlayId) || overlay) :
				overlay;
		});
	}

	/**
	 * Метод для "регистрации" новых доступных для открытия оверлеев.
	 * "Регистрируем" оверлей, чтобы при маршруте вида /path?overlays=popup1,sidebar1 контент оверлея мог сразу отобразиться на странице.
	 */
	public register(overlay: any) {
		if (this._overlays.find(i => i.overlayId === overlay.overlayId)) {
			console.error(`Оверлей с таким overlayId уже зарегестрирован: ${overlay.overlayId}. У каждого оверлея должен быть уникальный overlayId`);
			return;
		}

		this.overlays$.next([
			...this._overlays,
			overlay,
		]);

		/**
		 * При каждой регистрации нового оверлея, доступного для открытия, синхронизируем оверлеи и query-параметр "overlays":
		 */
		this.waitForEndNavigation.pipe(take(1)).subscribe({
			next: () => {
				this.synchronizeParamsAndOverlays();
			}
		});
	}

	public remove(overlayId: string) {
		this.removeElement(overlayId, this.overlays$);
		this.removeElement(overlayId, this.openedOverlays$);
		this.removeElement(overlayId, this.openedOverlaysLinkedToAnimationLifecycle$);
	}

	/**
	 * Метод для открытия оверлея
	 */
	public open({
		overlayId,
		event,
		queryParams = {},
	}: {
		overlayId: string,
		event?: Event,
		queryParams?: { [param in string]: string },
	}) {
		if (
			this._openedOverlays.find(i => i.overlayId === overlayId) ||
			this._overlayNavigationQueue.find(i => i === `${overlayId}-open`)
		) {
			return;
		}

		this.overlayNavigationQueue$.next([
			...this._overlayNavigationQueue,
			`${overlayId}-open`,
		]);

		//ждём завершения навигации, пока оверлей не станет первым в очереди на навигацию, чтобы работать с актуальными query параметрами
		this.overlayNavigationQueue
			.pipe(
				filter(quene => quene?.[0] === `${overlayId}-open`),
				take(1),
			)
			.subscribe({
				next: () => {
					const targetOverlay = this._overlays.find(overlay => overlay.overlayId === overlayId);

					if (targetOverlay) {
						(this.document?.activeElement as HTMLElement)?.blur();
						targetOverlay.queryParams = queryParams;

						/**
						 * Если открывшийся оверлей маршрутизируемый, то добавляем его id в query-параметр "overlays":
						 */
						if (targetOverlay.routable) {
							this.addOverlayParams(overlayId, queryParams);
						} else {
							this.removeOverlayFromNavigationQuene(`${overlayId}-open`);
						}

						this.openedOverlays$.next(this.sortByParams([
							...this._openedOverlays,
							targetOverlay,
						]));

						targetOverlay.open(event)?.subscribe(() => {
							this.openedOverlaysLinkedToAnimationLifecycle$.next([
								...this._openedOverlaysLinkedToAnimationLifecycle,
								targetOverlay,
							]);
						});

						this._openedOverlays.forEach(o => {
							o.overlayHoveredAfterOpen = false;
						});
					} else {
						this.removeOverlayFromNavigationQuene(`${overlayId}-open`);
					}

				}
			});
	}

	/**
	 * Метод для закрытия оверлея
	 */
	public close(overlayId: string) {
		if (this._overlayNavigationQueue.find(i => i === `${overlayId}-close`)) return;

		this.overlayNavigationQueue$.next([
			...this._overlayNavigationQueue,
			`${overlayId}-close`,
		]);

		//ждём завершения навигации, пока оверлей не станет первым в очереди на навигацию, чтобы работать с актуальными query параметрами
		this.overlayNavigationQueue
			.pipe(
				filter(quene => quene?.[0] === `${overlayId}-close`),
				take(1),
			)
			.subscribe({
				next: () => {
					const targetOverlay = this._openedOverlays.find(overlay => overlay.overlayId === overlayId);

					if (targetOverlay) {
						targetOverlay.queryParams = {};

						/**
						 * Если закрывшийся оверлей маршрутизируемый, то удаляем его id в query-параметре "overlays":
						 */
						if (targetOverlay.routable) {
							this.deleteOverlayParams([targetOverlay]);
						} else {
							this.removeOverlayFromNavigationQuene(`${overlayId}-close`);
						}

						targetOverlay.close().subscribe(() => {
							this.removeElement(overlayId, this.openedOverlaysLinkedToAnimationLifecycle$);
						});

						this.removeElement(overlayId, this.openedOverlays$);

						if (this.sharedService.config.overlay.multiColumnSystem) {
							this.addColumnsToOverlays();
							this.addParanjaToOverlays();
						}
					} else {
						this.deleteOverlayParams([{ overlayId }]);
					}

				}
			});
	}

	/** Метод для закрытия всех оверлеев */
	closeAll(exceptions: string[] = []) {
		this._openedOverlays
			.filter(overlay => !exceptions.includes(overlay?.overlayId))
			.forEach(overlay => this.close(overlay?.overlayId));
	}

	private removeElement(overlayId: string, listSubject: BehaviorSubject<any[]>) {
		const list = listSubject.getValue();
		const targetIndex = list.findIndex(i => i.overlayId === overlayId);

		if (targetIndex !== -1) {
			const newValue = [...list];
			newValue.splice(targetIndex, 1);
			listSubject.next(newValue);
		}
	}

	private removeOverlayFromNavigationQuene(overlayId: string) {
		const quene = this._overlayNavigationQueue;
		const targetIndex = quene.findIndex(i => i === overlayId);

		if (targetIndex !== -1) {
			const newValue = [...quene];
			newValue.splice(targetIndex, 1);
			this.overlayNavigationQueue$.next(newValue);
		}
	}

	/**
	 * Открытые оверлеи, отсортированные по порядку их "слойности" по z-index:
	 */
	get sortedOpenedOverlays(): any[] {
		return [...this._openedOverlays].sort((a, b) => b._zIndex - a._zIndex);
	}

	/**
	 * Метод для синхронизации оверлеев и query-параметра "overlays".
	 * Вызывается при изменении query-параметров и при каждой регистрации нового оверлея, доступного для открытия
	 */
	public synchronizeParamsAndOverlays() {
		this._openedOverlays
			.filter(overlay => overlay.routable)
			.forEach(overlay => {
				/** Закрываем те маршрутизируемые открытые слои, id которых нет в актуальном query-параметре "overlays": */
				if (!this.currentOverlaysParam.includes(overlay.overlayId)) {
					this.close(overlay.overlayId);
				}
			});

		this.currentOverlaysParam.forEach((param: string) => {
			const targetOverlay = this._openedOverlays.find(i => i?.overlayId === param);
			const isAlreadyOpened = Boolean(targetOverlay);
			const registeredOverlay = this._overlays.find(i => i.overlayId === param);
			const isRegistered = Boolean(registeredOverlay);
			const isRoutable = Boolean(registeredOverlay?.routable);

			/** Открываем те зарегестрированные маршрутизируемые оверлеи, id-шники которых есть в query-параметре "overlays", но которые ещё не открыты: */
			if (isRegistered && !isAlreadyOpened && isRoutable) {
				const params = this.sharedService.overlayRoutingConfig?.[param];
				let queryParams = {};

				if (params) {
					const currentParams = this.activatedRoute.snapshot.queryParams;
					params.forEach(i => {
						const param = currentParams?.[i];
						if (param) queryParams[i] = param;
					});
				}

				this.open({
					overlayId: param,
					queryParams,
				});
			}
		});
	}

	/**
	 * Метод для удаления идентификатора оверлея из query-параметра "overlays"
	 */
	public deleteOverlayParams(overlays: any[]) {
		const newQueryParams = overlays.reduce((accum, overlay) => {
			const overlayId = overlay?.overlayId;
			const overlaysParam = accum?.overlays ?
				[...accum.overlays.split(',')] :
				[];
			const targetIndex = overlaysParam.findIndex(i => i === overlayId);

			if (targetIndex === -1) return accum;

			overlaysParam.splice(targetIndex, 1);
			accum.overlays = overlaysParam?.length ? overlaysParam.join(',') : null;

			const otherParams = overlaysParam.reduce((accum, id) => {
				const params = this.sharedService?.overlayRoutingConfig?.[id];
				if (params) {
					accum.push(...params);
				}
				return accum;
			}, []);

			const queryParams = this.sharedService?.overlayRoutingConfig?.[overlayId] || [];
			const params = queryParams
				.filter(key => !otherParams?.includes(key))
				.reduce((accum, key) => {
					accum[key] = null;
					return accum;
				}, {});

			accum = {
				...accum,
				...params,
			};

			return accum;
		}, {...this.activatedRoute.snapshot.queryParams});

		this.ngZone.run(() => {
			this.router.navigate([], {
				queryParams: newQueryParams,
				relativeTo: this.activatedRoute,
				queryParamsHandling: 'merge',
				/** чтобы при обновлении роута и наличии fragment в url не прокидывало к якорьной ссылке: */
				preserveFragment: false,
			}).then(() => {
				overlays.forEach(overlay => {
					this.removeOverlayFromNavigationQuene(`${overlay?.overlayId}-close`);
				});
			});
		});
	}

	/**
	 * Метод для добавления идентификатора оверлея в query-параметр "overlays"
	 */
	public addOverlayParams(overlayId: string, queryParams: QueryParams = {}) {
		this.ngZone.run(() => {
			const overlays = [...this.currentOverlaysParam];

			if (overlays.find(id => id === overlayId)) {
				this.removeOverlayFromNavigationQuene(`${overlayId}-open`);
				return;
			}

			overlays.push(overlayId);

			const params = Object.entries(queryParams || {}).reduce((accum, [paramKey, paramValue]) => {
				if (paramValue) {
					accum[paramKey] = paramValue;
				}
				return accum;
			}, {});

			this.router.navigate([], {
				queryParams: {
					...this.activatedRoute.snapshot.queryParams,
					overlays: overlays?.length ? overlays.join(',') : null,
					...params,
				},
				relativeTo: this.activatedRoute,
				queryParamsHandling: 'merge',
				/** чтобы при обновлении роута и наличии fragment в url не прокидывало к якорьной ссылке: */
				preserveFragment: false,
			}).then(() => {
				this.removeOverlayFromNavigationQuene(`${overlayId}-open`);
			});
		});
	}

	/**
	 * Метод для получения списка уникальных идентификаторов оверлеев из query-параметра "overlays"
	 */
	public get currentOverlaysParam(): string[] {
		const overlaysParam = this.activatedRoute.snapshot.queryParams?.overlays;

		const paramsList: string[] = overlaysParam ?
			[...overlaysParam.split(',')] :
			[];

		return [...new Set(paramsList)];
	}

	/**
	 * Метод для запуска destroy-анимации и удаления полупрозрачного фона из DOM дерева
	 */
	private removeBackground() {
		if (!this.backgroundElement) {
			return;
		}

		const keyframes = this.getBackgroundAnimation(true);

		if (this.backgroundAnimation) {
			this.backgroundAnimation.reverse();
			this.backgroundAnimation.onfinish = () => {
				this.backgroundAnimation = null;
				this.backgroundElement?.remove();
				this.backgroundElement = null;
			};

			return;
		}

		this.backgroundAnimation = this.backgroundElement.animate(
			keyframes,
			{
				duration: 240,
				iterations: 1,
				easing: 'ease-out',
			}
		);

		this.backgroundAnimation.onfinish = () => {
			this.backgroundElement?.remove();
			this.backgroundElement = null;
			this.backgroundAnimation = null;
		};
	}

	/**
	 * Метод для запуска init-анимации и добавления полупрозрачного фона в DOM дерево
	 */
	private createBackground() {
		if (this.backgroundElement) {
			if (this.backgroundAnimation) {
				this.backgroundAnimation.reverse();
				this.backgroundAnimation.onfinish = () => {
					this.backgroundAnimation = null;
				};
			}

			return;
		}

		const keyframes = this.getBackgroundAnimation();
		

		if (!this.backgroundElement) {
			const overlayClasses = ['overlay', this.sharedService.config?.overlay?.backgroundClass].filter(Boolean);
			
			this.backgroundElement = this.renderer.createElement('div');
			overlayClasses.forEach(cssClass => {
				this.renderer.addClass(this.backgroundElement, cssClass);
			});
			this.document?.body?.append?.(this.backgroundElement);
		}

		this.backgroundAnimation = this.backgroundElement.animate(
			keyframes,
			{
				duration: 240,
				iterations: 1,
				easing: 'ease-in',
			}
		);

		this.backgroundAnimation.onfinish = () => {
			this.backgroundAnimation = null;
		};
	}

	private getBackgroundAnimation(forDestroy = false) {
		const lastOpacity = this.backgroundElement ?
			(getComputedStyle(this.backgroundElement).opacity || 0.9) :
			0.9;

		const result = [
			{ opacity: 0 },
			{ opacity: lastOpacity },
		];

		return forDestroy ? result.reverse() : result;
	}

	public registerDropdownOverlay(dropdownOverlay: any) {
		this.dropdownOverlays$.next([
			dropdownOverlay,
			...this._dropdownOverlays,
		]);
	}

	public removeDropdownOverlay(dropdownOverlay: any) {
		const targetIndex = this._dropdownOverlays.findIndex(i => i === dropdownOverlay);

		if (targetIndex !== -1) {
			const newValue = [...this._dropdownOverlays];
			newValue.splice(targetIndex, 1);
			this.dropdownOverlays$.next(newValue);
		}
	}

	elementIsChildOfOverlay(element: HTMLElement) {
		return this._openedOverlays.some(openedOverlay => openedOverlay.el.nativeElement.contains(element));
	}

	get highestParanja() {
		return Math.max(
			...this._openedOverlays.map(i => i.paranja),
			this.backgroundParanja,
			0,
		);
	}

}
