<input
	class="checkbox__input"
	type="checkbox"
	[formControl]="control"
	[checked]="checked"
>
<svg
	b-shared-svg
	name="checkbox_{{ size }}"
	class="checkbox__box"
	height="{{ height }}"
></svg>
<span class="checkbox__label">
	<ng-content></ng-content>
</span>
