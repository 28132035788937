<ng-content></ng-content>

<ng-container *ngIf="marksState">
	<ng-container *ngFor="let mark of marksState; trackBy: trackByMarks">
		<div
			class="hot-spot"
			(mouseenter)="onMouseEnter(mark)"
			(mouseleave)="onMouseLeave(mark)"
			[ngStyle]="markStyles[mark.id]"
			#hotSpotsList
		>
			<button
				(click)="selectMark(mark)"
				class="hot-spot__btn"
				[ngClass]="commonButtonClass"
				[title]="mark.title"
			>
				<svg b-shared-svg name="marketing_mark-plus" class="hot-spot__mark" [aria-hidden]="true"></svg>
			</button>
			<div
				*ngIf="mark.isActive"
				class="hot-spot__container"
				[ngClass]="{
					'hot-spot__container--top': mark.popupPosition.y === 'top',
					'hot-spot__container--bottom': mark.popupPosition.y === 'bottom',
					'hot-spot__container--left': mark.popupPosition.x === 'left',
					'hot-spot__container--right': mark.popupPosition.x === 'right'
				}"
				[@lifecycleTrigger]
			>
				<div class="hot-spot__content widget">
					<button
						b-shared-button
						icon="icons_24_2_cross"
						iconClass="icon--xl"
						class="btn text-light lg:d-none d-block pos-absolute pos-right-0 pos-top-0 m-20"
						title="Закрыть"
						(click)="closeLayer(mark)"
					></button>
					<ng-container *ngTemplateOutlet="mark.template"></ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>
