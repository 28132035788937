import { ChangeDetectionStrategy, Component, ContentChild, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Subscription, switchMap, tap, timer } from 'rxjs';
import { FileService } from '../../../services';
import { ToolbarContentComponent } from './toolbar-content/toolbar-content.component';

@Component({
	selector: 'b-shared-toolbar',
	templateUrl: './toolbar.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnDestroy {

	subscription = new Subscription();

	readonly dataCopied$ = new BehaviorSubject<boolean>(false);
	public dataCopied = this.dataCopied$
		.asObservable()
		.pipe(distinctUntilChanged());

	@ContentChild('content') contentRef: TemplateRef<any>;
	@ContentChild('beforeContent') beforeContentRef: TemplateRef<any>;
	@ViewChild('toolbarContent', { static: false }) toolbarContent: ToolbarContentComponent;

	constructor(
		private fileService: FileService,
	) {}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	copy() {
		const content = this.toolbarContent.elementRef.nativeElement;
		const text = content?.innerText || content?.textContent;

		this.subscription.add(
			this.fileService.copyText(text.replaceAll('\n\n', '\n'))
				.pipe(
					tap(() => this.dataCopied$.next(true)),
					switchMap(() => timer(2000))
				)
				.subscribe({
					complete: () => this.dataCopied$.next(false)
				})
		);
	}

}
