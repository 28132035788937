import {
	Directive, ElementRef, Input, OnDestroy, OnInit,
} from '@angular/core';
import { ArrowNavigationService } from './arrow-navigation.service';
import { Exceptions } from './models/Exceptions';

@Directive({
	selector: '[sharedArrowNavigation]'
})
export class ArrowNavigationDirective implements OnInit, OnDestroy {

	@Input() exceptions: Exceptions | null = null;

	/**
	 * Идентификатор группы элементов, в рамках которой должна работать навигация.
	 * Стрелочная навигация будет работать только между элементами с одинаковым groupId.
	 */
	@Input() groupId: string | string[] | null = 'global';

	@Input() navigationEnabled: boolean = true;

	constructor(
		private elementRef: ElementRef,
		private arrowNavigationService: ArrowNavigationService,
	) {}

	ngOnInit(): void {
		if (!this.navigationEnabled || !this.groupId) return;

		if (Array.isArray(this.groupId)) {
			this.groupId.forEach(id => {
				if (!id) return;

				this.arrowNavigationService.addNavigationElement(id, {
					element: this.elementRef.nativeElement,
					exceptions: this.exceptions,
				});
			});
			return;
		}

		this.arrowNavigationService.addNavigationElement(this.groupId, {
			element: this.elementRef.nativeElement,
			exceptions: this.exceptions,
		});
	}

	ngOnDestroy(): void {
		if (!this.groupId) return;

		if (Array.isArray(this.groupId)) {
			this.groupId.forEach(id => {
				if (!id) return;

				this.arrowNavigationService.removeNavigationElement(id, {
					element: this.elementRef.nativeElement,
				});
			});
			return;
		}

		this.arrowNavigationService.removeNavigationElement(this.groupId, {
			element: this.elementRef.nativeElement,
		});
	}

}
