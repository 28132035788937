import { ModuleWithProviders, NgModule } from '@angular/core';
import { MockService } from './mock.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpMock } from '../../types';

@NgModule()
export class MockModule {
	public static forRoot(mockedResponses: HttpMock[]): ModuleWithProviders<MockModule> {
		return {
			ngModule: MockModule,
			providers: [
				{
					provide: HTTP_INTERCEPTORS,
					useClass: MockService,
					multi: true,
				},
				{
					provide: 'mockedResponses',
					useValue: mockedResponses,
				},
			],
		};
	}
}
