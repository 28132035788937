import { Directive, Input, AfterViewInit, TemplateRef, ViewContainerRef, OnDestroy, EmbeddedViewRef } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, startWith, Subscription } from 'rxjs';
import { View } from './models/View';

class ViewContext<T> {
	public $implicit: View<T>;
}

/**
 * Директива для отображения состояний Observable: основной контент, загрузка, ошибка
 *
 * @example
 * ```
 *	<ng-container
 *		*sharedAsyncView="stream$; content contentTemplate; error errorTemplate; loading loaderTemplate"
 *	></ng-container>
 *
 *	<ng-template #contentTemplate let-context>
 *		<h4>Title</h4>
 *		<p *ngFor="let item of context">{{ item }}</p>
 *	</ng-template>
 *
 *	<ng-template #errorTemplate let-context>
 *		<div>Error: {{ context?.error?.error?.message || 'Default error text' }}</div>
 *	</ng-template>
 *
 *	<ng-template #loaderTemplate let-context>
 *		<div>Loading...</div>
 *	</ng-template>
 * ```
 */
@Directive({
	selector: '[sharedAsyncView]'
})
export class AsyncViewDirective<T> implements AfterViewInit, OnDestroy {

	private context: ViewContext<T> = new ViewContext<T>();
	private contentTemplateRef: TemplateRef<ViewContext<T>> = null;
	private errorTemplateRef: TemplateRef<ViewContext<T>> = null;
	private loaderTemplateRef: TemplateRef<ViewContext<T>> = null;
	private currentTemplate: TemplateRef<ViewContext<T>> = null;
	private subscription = new Subscription();
	private view: EmbeddedViewRef<ViewContext<T>> | null = null;

	constructor(
		private viewContainer: ViewContainerRef,
	) {}

	ngAfterViewInit(): void {
		if (!this.contentTemplateRef) throw new Error('sharedAsyncView: не передан content template');
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	@Input() set sharedAsyncView(observable: Observable<View<T>> | BehaviorSubject<View<T>>) {
		this.subscription?.unsubscribe();

		if (!observable) {
			this.viewContainer.clear();
			this.currentTemplate = null;
			this.view = null;
			return;
		}

		let _observable = observable;

		if (observable instanceof BehaviorSubject) {
			_observable = observable.asObservable();
		}

		this.subscription = _observable
			.pipe(
				startWith({ loader: true }),
				catchError(error => of({ error })),
			)
			.subscribe({
				next: context => {
					this.context.$implicit = context;
					this.view?.detectChanges();
					this.updateTemplate();
				},
				error: error => {
					this.context.$implicit = error;
					this.view?.detectChanges();
					this.updateTemplate();
				},
			});
	}

	@Input() set sharedAsyncViewContent(templateRef: TemplateRef<any>) {
		this.contentTemplateRef = templateRef;
		this.updateTemplate();
	}

	@Input() set sharedAsyncViewError(templateRef: TemplateRef<any>) {
		this.errorTemplateRef = templateRef;
		this.updateTemplate();
	}

	@Input() set sharedAsyncViewLoading(templateRef: TemplateRef<any>) {
		this.loaderTemplateRef = templateRef;
		this.updateTemplate();
	}

	updateTemplate() {
		const context = this.context.$implicit;
		let newTemplate = null;

		if (!context || context?.loader) {
			newTemplate = this.loaderTemplateRef;
		} else if (context?.error) {
			newTemplate = this.errorTemplateRef;
		} else if (context) {
			newTemplate = this.contentTemplateRef;
		}

		if (!newTemplate) {
			this.viewContainer.clear();
			this.currentTemplate = null;
			this.view = null;
			return;
		}

		if (this.currentTemplate === newTemplate) return;

		this.viewContainer.clear();
		this.view = this.viewContainer.createEmbeddedView(newTemplate, this.context);
		this.view.detectChanges();
		this.currentTemplate = newTemplate;
	}

	public static ngIfUseIfTypeGuard: void;

	static ngTemplateGuard_ngIf: 'binding';

	static ngTemplateContextGuard<T>(
		dir: ViewContext<T>,
		ctx: any,
	): ctx is ViewContext<Exclude<T, null | undefined>> {
		return true;
	}

}
