import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class ParseInterceptor implements HttpInterceptor {

	intercept(request: HttpRequest<any>, next: HttpHandler): any {
		const responseFormat = request?.headers?.get('responseFormat');
		if (responseFormat === 'standart') {
			request?.headers?.delete('responseFormat');
			return next.handle(request);
		}

		return next.handle(request).pipe(
			map((event) => {
				if (event instanceof HttpResponse) {
					return event.clone({ ...event, body: event.body.value });
				}

				return event;
			}),
			catchError((event: HttpErrorResponse) =>
				throwError(
					() => new HttpErrorResponse({
						...event,
						error: event?.error?.error,
					}),
				),
			),
		);
	}
}
