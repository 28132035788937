import { Directive, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/** Директива служит для отключения фокуса при клике по элементу, но сам фокус работает */

@Directive({
	selector: '[sharedClick]'
})
export class ClickDirective {
	constructor(
		@Inject(DOCUMENT) private document: Document
	) {}

	@HostListener('mouseup')
	clickHandle(): void {
		window.requestAnimationFrame(() => {
			(this.document.activeElement as HTMLElement).blur();
		});
	}
}
