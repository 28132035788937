import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'timeCounter',
})
export class TimeCounterPipe implements PipeTransform {
	parse(value) {
		return value.toString().length === 1 ? `0${value}` : value;
	}

	transform(seconds: number, withHours: boolean = false) {
		const roundedSeconds = Math.ceil(seconds);
		const hours: number = withHours ? Math.floor(roundedSeconds / 60 / 60) : 0;
		const minutes: number = Math.floor(roundedSeconds / 60 - hours * 60);
		const remainingSeconds: number = roundedSeconds - hours * 60 * 60 - minutes * 60;
		const elements = [minutes, remainingSeconds];
		if (withHours) {
			elements.unshift(hours);
		}
		return elements.map(i => this.parse(i)).join(':');
	}
}
