import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface MessageState {
	severity: string;
	summary: string;
	detail: string;
}

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	private msgSubject = new Subject<MessageState>();
	msgState = this.msgSubject.asObservable();

	success(title: string, message: string): void {
		this.msgSubject.next({ severity: 'success', summary: title, detail: message } as MessageState);
	}

	error(title: string, message: string): void {
		this.msgSubject.next({ severity: 'error', summary: title, detail: message } as MessageState);
	}
}
