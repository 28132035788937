import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, fromEvent } from 'rxjs';
export { OrientationType } from 'ngx-device-detector';

import { Request } from 'express';
import { SharedService } from './shared/shared.service';
import { REQUEST, WINDOW } from '../tokens';


export enum Viewport {
	sm = 'sm',
	md = 'md',
	lg = 'lg',
	xl = 'xl',
	xxl = 'xxl',
	xxxl = 'xxxl',
}

export enum ClientViewport {
	sm = 'sm',
	md = 'md',
	lg = 'lg',
}

// Перед изменениями сопоставить с аналогичными CSS переменными
export const sizes = {
	[Viewport.sm]: 464,
	[Viewport.md]: 768,
	[Viewport.lg]: 1024,
	[Viewport.xl]: 1200,
	[Viewport.xxl]: 1440,
	[Viewport.xxxl]: 1920,
};

// Перед изменениями сопоставить с аналогичными CSS переменными
export const clientSizes = {
	[ClientViewport.sm]: 1440,
	[ClientViewport.md]: 1920,
	[ClientViewport.lg]: 6000,
};

type ViewportState = {
	[key in Viewport]?: boolean;
}

type ClientViewportState = {
	[key in ClientViewport]?: boolean;
}

interface DeviceViewport {
	max: Partial<ViewportState> & Partial<ClientViewportState>;
	min: Partial<ViewportState> & Partial<ClientViewportState>;
}

@Injectable({
	providedIn: 'root',
})
export class DeviceService extends DeviceDetectorService {
	isServer: boolean;
	isMobileDevice: boolean;
	isDesktopDevice: boolean;
	deviceOrientation: OrientationType;
	viewportWidth: number;
	viewportHeight: number;
	viewport: DeviceViewport = { max: {}, min: {} };
	private renderer: Renderer2;

	private _isMobileDevice = new BehaviorSubject<boolean>(false);
	private _isDesktopDevice = new BehaviorSubject<boolean>(false);
	private _deviceOrientation = new BehaviorSubject<OrientationType>(null);
	private _viewportWidth = new BehaviorSubject<number>(null);
	private _viewportHeight = new BehaviorSubject<number>(null);
	private _viewport = new BehaviorSubject<DeviceViewport>(this.viewport);
	private _emojiSupported = new BehaviorSubject<boolean>(null);

	readonly isMobileDevice$ = this._isMobileDevice.asObservable();
	readonly isDesktopDevice$ = this._isDesktopDevice.asObservable();
	readonly deviceOrientation$ = this._deviceOrientation.asObservable();
	readonly viewportWidth$ = this._viewportWidth.asObservable();
	readonly viewportHeight$ = this._viewportHeight.asObservable();
	readonly viewport$ = this._viewport.asObservable();
	readonly emojiSupported$ = this._emojiSupported.asObservable();

	constructor(
		@Inject(PLATFORM_ID) platformId: any,
		@Inject(DOCUMENT) private document: Document,
		@Inject(WINDOW) private _window: Window,
		@Optional() @Inject(REQUEST) request: Request,
		private sharedService: SharedService,
		private rendererFactory: RendererFactory2,
	) {
		super(platformId);
		this.renderer = this.rendererFactory.createRenderer(null, null);

		this.isServer = isPlatformServer(platformId);

		if (this.isServer) {
			super.setDeviceInfo((request?.headers['user-agent'] as string) || '');
		}

		this.updateInfo();

		if (!this.isServer) {
			fromEvent(this._window, 'orientationchange')
				.subscribe(() => this.updateInfo());

			fromEvent(this._window, 'resize')
				.subscribe(() => this.updateInfo());

			Object.keys(this.targetSizes).forEach(
				point => {
					const minViewport = this.targetSizes[point];
					const maxViewport = this.targetSizes[point] - 1;

					const minMediaQuery = this._window.matchMedia(`(min-width: ${minViewport}px)`);
					const maxMediaQuery = this._window.matchMedia(`(max-width: ${maxViewport}px)`);

					this.viewport.min[point] = minMediaQuery.matches;
					this.viewport.max[point] = maxMediaQuery.matches;
					this._viewport.next(this.viewport);

					fromEvent(minMediaQuery, 'change')
						.subscribe((event: MediaQueryListEvent) => {
							this.viewport.min[point] = event.matches;
							this._viewport.next(this.viewport);
						});

					fromEvent(maxMediaQuery, 'change')
						.subscribe((event: MediaQueryListEvent) => {
							this.viewport.max[point] = event.matches;
							this._viewport.next(this.viewport);
						});
				}
			);
		}
	}

	updateInfo() {
		this.isDesktopDevice = this.isDesktop();
		this.isMobileDevice = this.isMobile() || this.isTablet();
		this.deviceOrientation = this.orientation as OrientationType;
		this.viewportWidth = this.isServer
			? 0
			: Math.max(this.document?.documentElement?.clientWidth || 0, this._window?.innerWidth || 0);
		this.viewportHeight = this.isServer
			? 0
			: Math.max(this.document?.documentElement?.clientHeight || 0, this._window?.innerHeight || 0);

		this._isDesktopDevice.next(this.isDesktopDevice);
		this._isMobileDevice.next(this.isMobileDevice);
		this._deviceOrientation.next(this.deviceOrientation);
		this._viewportWidth.next(this.viewportWidth);
		this._viewportHeight.next(this.viewportHeight);
	}

	detectIE() {
		const userAgent = this.isServer ? this.userAgent : this._window.navigator.userAgent;

		if (!userAgent) {
			return false;
		}

		const msie = userAgent.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10);
		}

		const trident = userAgent.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			const rv = userAgent.indexOf('rv:');
			return parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10);
		}

		// other browser
		return false;
	}

	getMobileOS(): 'Android' | 'iPadOS' | 'iOS' | 'Huawei' | null {
		type oldBrowsersWindow = Window & typeof globalThis & { MSStream: any, opera: any };

		const userAgent = this.isServer
			? this.userAgent
			: this._window.navigator.userAgent || navigator.vendor || (this._window as oldBrowsersWindow).opera;

		if (!userAgent) {
			return null;
		}

		const ua = userAgent?.toLowerCase();

		if (/huawei|honor|hmscore|hms/.test(ua)) {
			return 'Huawei';
		}

		if (/android/i.test(ua)) {
			return 'Android';
		}

		if (
			/ipad/i.test(ua) || (
				navigator?.maxTouchPoints &&
				navigator?.maxTouchPoints > 2 &&
				/MacIntel/.test(navigator.platform)
			)
		) {
			return 'iPadOS';
		}

		if (/iphone|ipod/.test(ua) && !(this._window as oldBrowsersWindow)?.MSStream) {
			return 'iOS';
		}

		return null;
	}

	/** Метод, проверяющий поддержку отображения emoji символов (✋) на устройстве */
	isEmojiSupported(): boolean {
		let isSupported = this._emojiSupported.getValue();

		if (isSupported !== null) {
			return isSupported;
		}

		if (typeof this._window === 'undefined' || this.isServer) {
			return false;
		}

		const node = this.renderer.createElement('canvas');
		const ctx = node.getContext('2d') as any;

		if (!ctx) {
			return false;
		}

		const backingStoreRatio =
			ctx.webkitBackingStorePixelRatio ||
			ctx.mozBackingStorePixelRatio ||
			ctx.msBackingStorePixelRatio ||
			ctx.oBackingStorePixelRatio ||
			ctx.backingStorePixelRatio ||
			1;

		const offset = 12 * backingStoreRatio;

		ctx.fillStyle = '#f00';
		ctx.textBaseline = 'top';
		ctx.font = '32px Arial';
		ctx.fillText('\ud83d\udc28', 0, 0);

		isSupported = ctx.getImageData(offset, offset, 1, 1).data[0] !== 0;

		this._emojiSupported.next(isSupported);

		return isSupported;
	}

	/**
	 * Метод, проверяющий поддержку отображения ЦВЕТНЫХ emoji символов (✋) на устройстве
	 * FYI: на некоторых устройствах отображаются emoji символы, но только чёрно-белые.
	 */
	сolorEmojiIsSupported() {
		const userAgent = this.isServer ? this.userAgent : this._window.navigator.userAgent;

		if (!userAgent) {
			return false;
		}

		const onIE = /\bTrident\b/.test(userAgent) || /\bMSIE\b/.test(userAgent);
		const onWindows7 = /\bWindows NT 6.1\b/.test(userAgent);
		const onWindows8 = /\bWindows NT 6.2\b/.test(userAgent);
		const onWindows81 = /\bWindows NT 6.3\b/.test(userAgent);
		const onFreeBSD = /\bFreeBSD\b/.test(userAgent);
		const onLinux = /\bLinux\b/.test(userAgent) && !/\bAndroid\b/.test(userAgent);

		return !(onIE || onWindows7 || onWindows8 || onWindows81 || onLinux || onFreeBSD);
	}

	get targetSizes() {
		let target: any = sizes;

		if (this.sharedService?.environment?.project === 'client') {
			target = clientSizes;
		}

		return target;
	}

	get targetViewports() {
		let target: typeof Viewport | typeof ClientViewport = Viewport;

		if (this.sharedService?.environment?.project === 'client') {
			target = ClientViewport;
		}

		return target;
	}

	get openedInIframe(): boolean {
		return Boolean(this._window?.frameElement) ||
			this._window !== this._window?.parent ||
			this._window?.location !== this._window?.parent?.location ||
			this._window?.self !== this._window?.top;
	}

	get aspectRatio(): number {
		return this.viewportWidth / this.viewportHeight;
	}

	get detectSafari(): boolean {
		const userAgent = this.isServer ? this.userAgent : this._window.navigator.userAgent;

		if (!userAgent) {
			return false;
		}

		return userAgent.indexOf('Safari') > 0 && userAgent.indexOf('Chrome') === -1;
	}
}
