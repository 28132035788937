import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component, ElementRef, EventEmitter,
	Input, NgZone,
	OnDestroy, Output,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Message} from './message.service';

@Component({
	selector: 'b-shared-toast-item',
	templateUrl: './toast-item.component.html',
	animations: [
		trigger('messageState', [
			state('visible', style({
				transform: 'translateY(0)',
				opacity: 1
			})),
			transition('void => *', [
				style({transform: 'translateY(100%)', opacity: 0}),
				animate('300ms ease-out')
			]),
			transition('* => void', [
				animate(('250ms ease-in'), style({
					height: 0,
					opacity: 0,
					transform: 'translateY(-100%)'
				}))
			])
		])
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastItemComponent implements AfterViewInit, OnDestroy {

	@Input() message: Message;
	@Input() index: number;

	@Output() closeEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild('container') containerViewChild: ElementRef;

	timeout: any;

	constructor(private zone: NgZone) {}

	ngAfterViewInit(): void {
		this.initTimeout();
	}

	initTimeout(): void {
		if (!this.message.sticky) {
			this.zone.runOutsideAngular(() => {
				this.timeout = setTimeout(() => {
					this.closeEvent.emit({
						index: this.index,
						message: this.message
					});
				}, this.message.life || 3000);
			});
		}
	}

	clearTimeout(): void {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}

	onMouseEnter(): void {
		this.clearTimeout();
	}

	onMouseLeave(): void {
		this.initTimeout();
	}

	onCloseIconClick(event): void {
		this.clearTimeout();

		this.closeEvent.emit({
			index: this.index,
			message: this.message
		});

		event.preventDefault();
	}

	ngOnDestroy(): void {
		this.clearTimeout();
	}
}
