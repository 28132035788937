import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

/**
	* Предоставляет различные методы по безопасности
	*/
@Injectable({
	providedIn: 'root',
})
export class SecurityService {

	constructor(
		private http: HttpClient,
	) {}

	/**
		* Защита от ботов при логине
		*/
	getGuardHashV1(phoneNumber: string): Observable<string> {
		return this.http.post<{token: string}>('/api/sso/v1/entrance/token', {phoneNumber})
			.pipe(
				map(({token}) => this.getHashByGuardToken(token))
			);
	}

	getGuardHashV2(phoneNumber: string): Observable<string> {
		return this.http.post<{token: string}>('/api/sso/v2/entrance/token', {phoneNumber})
			.pipe(
				map(({token}) => this.getHashByGuardToken(token))
			);
	}

	getHashByGuardToken(token: string): string {
		let result = 2166136261 | 0;

		const encodedToken: Uint8Array = new TextEncoder().encode(token);

		let hashCode = encodedToken.reduce((hc: number, bt: number) => (33 * hc) ^ bt, 0);

		let newInput = new Uint8Array([
			(hashCode & 0xff000000) >> 24,
			(hashCode & 0x00ff0000) >> 16,
			(hashCode & 0x0000ff00) >> 8,
			(hashCode & 0x000000ff)
		]).reverse();

		newInput.forEach((bt) => result = Math.imul((result ^ bt), 16777619));

		return result.toString();
	}
}
