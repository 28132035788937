import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker.component';
import { SvgModule } from '../svg/svg.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FormsModule } from '@angular/forms';
import { ArrowNavigationModule, KeyboardControlModule } from '../../directives';

@NgModule({
	declarations: [
		DatePickerComponent,
	],
	imports: [
		CommonModule,
		SvgModule,
		DropdownModule,
		FormsModule,
		KeyboardControlModule,
		ArrowNavigationModule,
	],
	exports: [
		DatePickerComponent,
	],
})
export class DatePickerModule {}
