import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
	Inject,
	Injectable,
	OnDestroy,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
} from '@angular/core';
import { audit, auditTime, BehaviorSubject, debounceTime, filter, fromEvent, map, mergeMap, of, startWith, Subject, Subscription, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { ScrollService } from '../scroll.service';
import { OverlayService2 } from '../../ui/components/overlay/overlay.service';
import { ArrowNavigationService } from '../../ui/directives/arrow-navigation/arrow-navigation.service';
import { SharedService } from '../shared/shared.service';
import { findLastIndex } from '../../utils/arrays/findLastIndex';
import { InactivityTracker } from './models/InactivityTracker';
import { Guid } from 'guid-typescript';

/** Сервис с глобальными прослушивателями DOM событий */
@Injectable({
	providedIn: 'root',
})
export class DomEventsService implements OnDestroy {

	subscriptions = new Subscription();
	focusedOverlay;
	excludedOverlayId: string | null = null;
	private renderer: Renderer2;

	/**
	 * Экземпляры управляемых с клавиатуры элементов, находящихся в DOM
	 */
	private keyboardControls$ = new BehaviorSubject<any[]>([]);
	public keyboardControls = this.keyboardControls$.asObservable();
	public get _keyboardControls(): any[] {
		return this.keyboardControls$.getValue();
	}

	/**
	 * Экземпляры тултипов
	 */
	private tooltips$ = new BehaviorSubject<any[]>([]);
	public tooltips = this.tooltips$.asObservable();
	public get _tooltips(): any[] {
		return this.tooltips$.getValue();
	}

	/**
	 * Экземпляры компонента range-slider
	 */
	private rangeSliders$ = new BehaviorSubject<any[]>([]);
	public rangeSliders = this.rangeSliders$.asObservable();
	public get _rangeSliders(): any[] {
		return this.rangeSliders$.getValue();
	}

	/**
	 * Отслеживатели бездействия пользователя
	 */
	private inactivityTrackers: { [key in string]: InactivityTracker } = {};

	private mouseAllreadyOnCloseButtonArea = false;
	private closeButtonAreaHeight = 0;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private overlayService: OverlayService2,
		@Inject(PLATFORM_ID) platformId: any,
		private arrowNavigationService: ArrowNavigationService,
		private scrollService: ScrollService,
		private sharedService: SharedService,
		private rendererFactory: RendererFactory2,
	) {
		if (isPlatformServer(platformId)) return;

		this.renderer = this.rendererFactory.createRenderer(null, null);

		this.subscriptions.add(
			fromEvent(this.document, 'keydown').subscribe({
				next: (event: KeyboardEvent) => {
					this.updateInactivityDates();

					/** Обрабатываем keydown для focused элемента, управляемого с клавиатуры: */
					const activeKeyboardControl: any = this._keyboardControls.find(control => control.elementRef.nativeElement === this.document.activeElement);
					if (
						activeKeyboardControl &&
						activeKeyboardControl.activeElementKeys.some(key => event.key === key || event.code === key)
					) {
						const element = activeKeyboardControl?.elementRef?.nativeElement;
						const isLink = element.tagName?.toLowerCase() === 'a';

						if (!isLink) {
							event?.preventDefault();
						}

						if (activeKeyboardControl?.activeElementKeyHandler) {
							activeKeyboardControl.activeElementKeyHandler?.emit(event);
						} else {
							[
								'click',
								'pointerup',
							].forEach(eventName => element?.dispatchEvent(new Event(eventName)));
						}
					}

					if (['Esc', 'Escape'].some(i => i === event.key || i === event.code)) {
						/** При нажатии на Escape блюрим элемент, который находится в фокусе */
						(this.document?.activeElement as HTMLElement)?.blur();

						const visibleTooltip = this._tooltips.find(tooltip => tooltip._visibility);
						if (visibleTooltip) {
							visibleTooltip.visibility$.next(false);
							return;
						}

						/** При нажатии на Escape закрываем самый верхний оверлей, если в нём разрешено подобное закрытие (параметр closeOnEsc) */
						const closableDrops = [...this.overlayService?._dropdownOverlays].filter(drop => drop?.closeOnEsc);
						const closableOverlays = [...this.overlayService?.sortedOpenedOverlays].filter(overlay => overlay?.closeOnEsc);
						if (
							[closableDrops, closableOverlays].some(i => i?.length)
						) {
							/** Выбираем приоритетный оверлей для закрытия: */
							const targetElement: any =
								closableDrops?.find(i => this.overlayService?.elementIsChildOfOverlay(i?.elementRef?.nativeElement)) || // dropdown-оверлей, внутри попапа/сайдбара
								closableOverlays?.[0] || // верхний попап/сайдбар
								closableDrops?.[0]; // верхний dropdown-овелрей (вне попапа/сайдбара)

							if (targetElement && targetElement.closeOnEsc) {
								if (targetElement?.overlayId) {
									this.overlayService?.close(targetElement.overlayId);
								} else {
									targetElement?.hideOverlay();
								}
							}
						}
					}

					/** Стрелочная навигация по элементам */
					if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.code)) {
						const activeArrowNavigationElement = [].concat(...Object.values(this.arrowNavigationService._navigationElements)).find(
							navElement => navElement.element === this.document.activeElement as HTMLElement
						);

						if (activeArrowNavigationElement) {
							const activeElementSide = this.arrowNavigationService.sides[event.code].active;
							const elementsSide = this.arrowNavigationService.sides[event.code].elements;
							const targetException = activeArrowNavigationElement?.exceptions?.[activeElementSide];

							if (targetException) {
								event?.preventDefault(); // предотвращаем скролл страницы
								targetException?.focus?.();
								targetException?.nativeElement?.focus?.();
								this.document.getElementById(targetException)?.focus?.();
								return;
							}

							const groups = [...Object.values(this.arrowNavigationService._navigationElements)]
								.filter(group => group
									.map(navElement => navElement.element)
									.includes(activeArrowNavigationElement.element)
								)
								.map(group => {
									const elements = [...group];
									const targetIndex = elements.findIndex(navElement => navElement.element === this.document.activeElement);
									elements.splice(targetIndex, 1);
									return elements.filter(el => !el.element.hasAttribute('disabled') && !el.element.getAttribute('aria-hidden'));
								})
								.map(group => {
									const targetElements = group.filter(navElement => {
										const elementValue = navElement.element.getBoundingClientRect()[elementsSide];
										const activeElementValue = this.document.activeElement.getBoundingClientRect()[activeElementSide];

										return ['ArrowUp', 'ArrowLeft'].includes(event.code) ?
											elementValue <= activeElementValue :
											elementValue >= activeElementValue;
									});

									return { group, targetElements };
								})
								.sort((a, b) => {
									const aIsDropGroup = this.containsInDrop(
										a.targetElements.map(i => i.element)
									);

									const bIsDropGroup = this.containsInDrop(
										b.targetElements.map(i => i.element)
									);

									if (aIsDropGroup && bIsDropGroup) return 0;
									if (aIsDropGroup) return -1;
									if (bIsDropGroup) return 1;
								});

							const targetGroup = groups.find(group => group.targetElements?.length);
							const targetElements = targetGroup?.targetElements || [];

							if (!targetElements?.length) return;

							const targetElementsDistance = targetElements
								.map(navElement => {
									return {
										element: navElement.element,
										distance: this.scrollService.getDistanceBetweenElements(
											this.document.activeElement as HTMLElement,
											navElement.element,
										),
									};
								})
								.sort((a, b) => a.distance - b.distance);

							const targetElement = targetElementsDistance?.[0]?.element;

							if (targetElement?.focus) {
								event?.preventDefault(); // предотвращаем скролл страницы
								targetElement.focus();

								const someDropFocused = this.overlayService._dropdownOverlays.some(
									overlay => overlay?.elementRef?.nativeElement?.contains(targetElement)
								);

								if (!someDropFocused) {
									this.closeOpenedDrops();
								}
							}
						}

						const activeElement = this.document.activeElement as HTMLElement;
						const activeSliderThumb = this._rangeSliders
							.filter(i => !i?.disabled)
							.find(slider => {
								return [
									slider.singleThumb?.nativeElement,
									slider.startThumb?.nativeElement,
									slider.endThumb?.nativeElement
								].filter(Boolean).includes(activeElement);
							});

						if (activeSliderThumb) {
							event.preventDefault();

							const isAdd = ['ArrowUp', 'ArrowRight'].includes(event.code);
							const isSubtract = ['ArrowDown', 'ArrowLeft'].includes(event.code);
							const startValue = activeSliderThumb.value[0];
							const endValue = activeSliderThumb.value[1];
							const withPoints = activeSliderThumb.points?.length;
							let targetValue: number;
							let newValue: number;

							if (activeElement === activeSliderThumb.singleThumb?.nativeElement) targetValue = activeSliderThumb.value as number;
							else if (activeElement === activeSliderThumb.startThumb?.nativeElement) targetValue = activeSliderThumb.value[0];
							else if (activeElement === activeSliderThumb.endThumb?.nativeElement) targetValue = activeSliderThumb.value[1];

							if (withPoints) {
								const currentIndex = activeSliderThumb.points.findIndex(i => i === targetValue);
								let newIndex: number;

								if (isAdd) newIndex = currentIndex + 1;
								else if (isSubtract) newIndex = currentIndex - 1;

								const lastIndex = activeSliderThumb.points.length - 1;
								if (newIndex < 0) newIndex = 0;
								if (newIndex > lastIndex) newIndex = lastIndex;

								newValue = activeSliderThumb.points[newIndex];
							} else {
								if (isAdd) newValue = targetValue + activeSliderThumb.step;
								else if (isSubtract) newValue = targetValue - activeSliderThumb.step;
							}

							newValue = activeSliderThumb.normalizeValue(newValue);

							if (activeElement === activeSliderThumb.singleThumb?.nativeElement) {
								activeSliderThumb.changeValue(newValue);
							} else if (activeElement === activeSliderThumb.startThumb?.nativeElement) {
								newValue = newValue >= endValue ? endValue : newValue;
								activeSliderThumb.changeValue([newValue, endValue]);
							} else if (activeElement === activeSliderThumb.endThumb?.nativeElement) {
								newValue = newValue <= startValue ? startValue : newValue;
								activeSliderThumb.changeValue([startValue, newValue]);
							}
						}
					}

					const isTab = event.key?.toLowerCase() === 'tab' ||
						event.code?.toLowerCase() === 'tab';

					if (isTab) {
						const openedOverlayWithFocusedTrigger = this.overlayService._dropdownOverlays.find(overlay => {
							const trigger = overlay?.getTriggerElement();
							return trigger && trigger === this.document.activeElement;
						});

						if (openedOverlayWithFocusedTrigger) {
							const dropdownOverlay = openedOverlayWithFocusedTrigger?.getDropdownOverlay();
							const focusableElements = this.getChildFocusableElements(dropdownOverlay);

							if (dropdownOverlay && focusableElements?.length) {
								event.preventDefault();
								focusableElements[0]?.focus();
								return;
							}
						}

						const openedOverlayWithFocusedLastElement = this.overlayService._dropdownOverlays.find(overlay => {
							const dropdownOverlay = overlay?.getDropdownOverlay();
							const focusableElements = this.getChildFocusableElements(dropdownOverlay);

							return Boolean(
								dropdownOverlay &&
								focusableElements?.length &&
								focusableElements[focusableElements.length - 1] === this.document.activeElement
							);
						});

						if (openedOverlayWithFocusedLastElement) {
							openedOverlayWithFocusedLastElement?.hideOverlay();
							openedOverlayWithFocusedLastElement?.changeDetector?.detectChanges();

							const trigger = openedOverlayWithFocusedLastElement?.getTriggerElement();
							const nextFocusableElement = this.getNextFocusableElement(
								trigger,
								element => !openedOverlayWithFocusedLastElement.elementRef?.nativeElement?.contains(element) || element === trigger
							);

							event.preventDefault();
							nextFocusableElement?.focus();
							return;
						}

						if (!this.overlayService?._openedOverlays?.length) return;

						const topOverlay = this.overlayService?.sortedOpenedOverlays?.[0];

						if (topOverlay.firstElementWasFocused) return;

						const focusableElements = topOverlay.el?.nativeElement?.querySelectorAll(
							'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
						);
						const activeElementIsChildOfTopOverlay = Object.values(focusableElements)
							.some(focusableElement => focusableElement === this.document.activeElement);

						if (activeElementIsChildOfTopOverlay) {
							topOverlay.firstElementWasFocused = true;
							return;
						}

						event.preventDefault();
						(this.document?.activeElement as HTMLElement)?.blur();
						focusableElements[0]?.focus();
						topOverlay.firstElementWasFocused = true;
					}
				}
			})
		);

		this.subscriptions.add(
			fromEvent<PointerEvent>(this.document, 'pointermove')
				.pipe(
					auditTime(15),
					filter(() => Boolean(
						this._rangeSliders?.some(i => !i.disabled) ||
						Object.keys(this.inactivityTrackers)?.length ||
						(
							this.sharedService.config.overlay.multiColumnSystem &&
							this.overlayService._openedOverlays?.length
						)
					)),
					audit(() => {
						const draggingSlider = this._rangeSliders
							?.filter(i => !i.disabled)
							?.find(i => i.singleDragging || i.rangeDragging);
						return timer(Boolean(draggingSlider) ? 0 : 50); //дебаунса нет, когда происходит Drag&Drop range-слайдеров
					}),
					mergeMap(event => {
						if (this.sharedService.config.overlay.multiColumnSystem) {
							return this.updateMultiColumnSystem(event) || of(event);
						}
						return of(event);
					})
				)
				.subscribe({
					next: event => {
						this.updateInactivityDates();

						const enabledSliders = this._rangeSliders?.filter(i => !i.disabled);

						if (enabledSliders?.length) {
							const singleDraggingSlider = enabledSliders.find(i => i.singleDragging);
							const rangeDraggingSlider = enabledSliders.find(i => i.rangeDragging);

							if (singleDraggingSlider) {
								event.preventDefault();
								singleDraggingSlider.singleThumb?.nativeElement?.focus();
								// singleDraggingSlider.calculateValue(event, '--range-end-value');
								singleDraggingSlider.pointermoveHandler(event);
							} else if (rangeDraggingSlider) {
								event.preventDefault();
								rangeDraggingSlider.targetRangeThumb.targetThumb?.focus();
								// rangeDraggingSlider.calculateValue(event, rangeDraggingSlider.targetRangeThumb.propertyName);
								rangeDraggingSlider.pointermoveHandler(event);
							}
						}
					}
				})
		);

		this.subscriptions.add(
			fromEvent<PointerEvent>(this.document, 'pointerup')
				.pipe(
					filter(() => Boolean(
						this._rangeSliders?.some(i => !i.disabled) ||
						Object.keys(this.inactivityTrackers)?.length
					)),
				)
				.subscribe({
					next: event => {
						this.updateInactivityDates();

						const enabledSliders = this._rangeSliders?.filter(i => !i.disabled);

						enabledSliders.forEach(slider => {
							if (slider.singleDragging || slider.rangeDragging) {
								slider?.pointerUpHandler?.emit(event);

								if (slider.isThumbDragging) {
									slider?.thumbPointerUpHandler?.emit(event);
								}

								if (slider.isFocused) {
									slider?.pointerupHandler(event);
								}
							}
							slider.singleDragging = false;
							slider.rangeDragging = false;
							slider.isThumbDragging = false;
							slider.isFocused = false;
							slider.targetRangeThumb = null;
						});
					}
				})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions?.unsubscribe();
	}

	public registerKeyboardControl(keyboardControl) {
		this.keyboardControls$.next([
			...this._keyboardControls,
			keyboardControl,
		]);
	}

	public removeKeyboardControl(keyboardControl) {
		this.removeElement(keyboardControl, this.keyboardControls$);
	}

	public registerTooltip(tooltip) {
		this.tooltips$.next([
			...this._tooltips,
			tooltip,
		]);
	}

	public removeTooltip(tooltip) {
		this.removeElement(tooltip, this.tooltips$);
	}

	public registerRangeSlider(rangeSlider) {
		this.rangeSliders$.next([
			...this._rangeSliders,
			rangeSlider,
		]);
	}

	public removeRangeSlider(rangeSlider) {
		this.removeElement(rangeSlider, this.rangeSliders$);
	}

	private removeElement<T = any>(element: T, listSubject: BehaviorSubject<T[]>) {
		if (!element) return;

		const list = listSubject.getValue();
		const targetIndex = list.findIndex(i => i === element);

		if (targetIndex !== -1) {
			const newValue = [...list];
			newValue.splice(targetIndex, 1);
			listSubject.next(newValue);
		}
	}

	updateMultiColumnSystem(event: PointerEvent) {
		const overlays = this.overlayService._openedOverlays;

		if (!overlays?.length || this.overlayService._dropdownOverlays.some(i => i?.isCustom)) return;

		const topOverlay = overlays[overlays.length - 1];

		if (topOverlay?.isMultiColumnException && !topOverlay?.isSidebarTransparent) {
			topOverlay.isPointed = false;

			if (topOverlay.isWidget) {
				const hoverAreaRects = topOverlay?.getWidgetHoverAreaRects();
				topOverlay.isPointed = hoverAreaRects.some(rect => this.scrollService.coordsAreInsideRect(event?.x, event?.y, rect));
			} else {
				const overlayContentRect = topOverlay?.getOverlayContentRect();
				topOverlay.isPointed = this.scrollService.coordsAreInsideRect(event?.x, event?.y, overlayContentRect);
			}

			const topOverlayElement = topOverlay?.overlay?.nativeElement;

			if (!topOverlayElement) return;

			if (topOverlay.isPointed) {
				this.renderer.removeClass(topOverlayElement, 'paranja--hover-secondary');
			} else {
				this.renderer.addClass(topOverlayElement, 'paranja--hover-secondary');
			}

			return;
		}

		overlays
			.forEach(
				(overlay, index) => {
					overlay.isPointed = false;
					const closeButtonAreaRect = overlay?.getCloseButtonRect();
					const overlayContentRect = overlay?.getOverlayContentRect();
					let cursorOnCloseButton = false;

					if (closeButtonAreaRect) {
						closeButtonAreaRect.width = closeButtonAreaRect.width + (overlayContentRect.left - closeButtonAreaRect.right);
						cursorOnCloseButton = this.scrollService.coordsAreInsideRect(event?.x, event?.y, closeButtonAreaRect);

						if (!this.mouseAllreadyOnCloseButtonArea) {
							const sydebarStyle = window.getComputedStyle(overlay.overlayContent?.nativeElement);
							const triangleIndent = parseInt(sydebarStyle.getPropertyValue('--sidebar-triangle-indent')) || 0;

							this.closeButtonAreaHeight = event?.y + triangleIndent;
						}
						closeButtonAreaRect.height = this.closeButtonAreaHeight;
					}

					const cursorOnCloseButtonArea = this.scrollService.coordsAreInsideTriangleFromRight(event?.x, event?.y, closeButtonAreaRect) || cursorOnCloseButton;

					if (cursorOnCloseButtonArea && Boolean(this.excludedOverlayId) && this.excludedOverlayId === overlay?.overlayId) {
						return overlay.isPointed = false;
					}

					const fromBackground = !this.focusedOverlay;
					const fromBottomOverlay = Boolean(this.focusedOverlay?.overlayId) &&
						this.focusedOverlay?.overlayId === overlays[index - 1]?.overlayId;
					const pointedAtClosingButtonFromBottom = cursorOnCloseButtonArea && (fromBackground || fromBottomOverlay);

					if (pointedAtClosingButtonFromBottom) {
						this.excludedOverlayId = overlay?.overlayId;
						return overlay.isPointed = false;
					}

					if (cursorOnCloseButtonArea) {
						return overlay.isPointed = true;
					} else {
						this.excludedOverlayId = null;
					}

					if (overlay.isWidget) {
						const hoverAreaRects = overlay?.getWidgetHoverAreaRects();

						return overlay.isPointed = hoverAreaRects.some(rect => this.scrollService.coordsAreInsideRect(event?.x, event?.y, rect));
					}

					if (overlayContentRect && overlay?.isPartOfMultiColumnSystem) {
						const viewportSize = this.scrollService.getViewportSize();

						overlayContentRect.y = 0;
						overlayContentRect.height = viewportSize.viewportHeight;
						overlayContentRect.width = viewportSize.viewportWidth - overlayContentRect.x;
					}

					return overlay.isPointed = this.scrollService.coordsAreInsideRect(event?.x, event?.y, overlayContentRect);
				}
			);

		overlays.forEach(overlay => {
			if (overlay?.isPointed) overlay.overlayHoveredAfterOpen = true;
		});

		const targetOverlayIndex = topOverlay.overlayHoveredAfterOpen ?
			findLastIndex(overlays, i => i.isPointed) :
			overlays.length - 1;

		const mouseOnTopLayer = targetOverlayIndex === (overlays.length - 1);
		const mouseOnLayer = targetOverlayIndex !== -1;
		const focusedOverlay = mouseOnLayer ? overlays[targetOverlayIndex] : null;

		this.focusedOverlay = focusedOverlay;

		if (!topOverlay?.isPartOfMultiColumnSystem) {
			if (mouseOnTopLayer) {
				this.overlayService.removeParanjaHoverFromOverlays();
				this.overlayService.removeParanjaHoverFromBackground();
			} else {
				topOverlay.isSidebarTransparent
					? this.overlayService.setParanjaHoverSecondaryToOverlays()
					: this.overlayService.setParanjaHoverToOverlays();
				this.overlayService.setParanjaHoverToBackground();
			}
			return;
		}

		const dynamicOverlays = [...this.overlayService.sortedOpenedOverlays]
			.filter(overlay => overlay?.isPartOfMultiColumnSystem);

		if (mouseOnTopLayer) {
			const mouseLeaveCloseButtonArea = new Subject();
			const currentOverlay = overlays[overlays.length - 1];

			if (this.mouseAllreadyOnCloseButtonArea) return;

			this.mouseAllreadyOnCloseButtonArea = true;
			// если в зоне крестика и не в минимальной зоне - анимация задвигания шторки после дебаунса
			return fromEvent<PointerEvent>(this.document, 'pointermove').pipe(
				startWith(event),
				takeUntil(mouseLeaveCloseButtonArea),
				tap(event => {
					const closeButtonAreaRect = currentOverlay?.getCloseButtonRect();
					const overlayContentRect = topOverlay?.getOverlayContentRect();
					if (closeButtonAreaRect) {
						closeButtonAreaRect.width = closeButtonAreaRect.width + (overlayContentRect.left - closeButtonAreaRect.right);
						closeButtonAreaRect.height = this.closeButtonAreaHeight;
					}
					const cursorOnCloseButtonArea = this.scrollService.coordsAreInsideTriangleFromRight(event?.x, event?.y, closeButtonAreaRect);
					if (!cursorOnCloseButtonArea){
						this.mouseAllreadyOnCloseButtonArea = false;
						mouseLeaveCloseButtonArea.next(true);
						this.overlayService.removeParanjaHoverFromOverlays();
						this.overlayService.removeParanjaHoverFromBackground();
						this.overlayService.addColumnsToOverlays();
						this.overlayService.addParanjaToOverlays();
					}
				}),
				debounceTime(300),
				filter(event => {
					const closeButtonAreaRectMini = currentOverlay?.getCloseButtonRect();
					const overlayContentRect = topOverlay?.getOverlayContentRect();
					if (closeButtonAreaRectMini)
						closeButtonAreaRectMini.width = closeButtonAreaRectMini.width + (overlayContentRect.left - closeButtonAreaRectMini.right);

					const cursorOnCloseButton = this.scrollService.coordsAreInsideRect(event?.x, event?.y, closeButtonAreaRectMini);
					if (closeButtonAreaRectMini) closeButtonAreaRectMini.height = 74; //sidebar title height
					const cursorOnCloseButtonAreaMini = this.scrollService.coordsAreInsideTriangleFromRight(event?.x, event?.y, closeButtonAreaRectMini);
					return !(cursorOnCloseButtonAreaMini || cursorOnCloseButton);
				} ),
				tap(() => {
					const currentOverlayIndex = dynamicOverlays
						.findIndex(i => i?.overlayId === currentOverlay?.overlayId);
					const focusedOverlayIndex = currentOverlayIndex + 1;
					const topOverlays = dynamicOverlays.filter((overlay, index) => index <= focusedOverlayIndex);
					const bottomOverlays = dynamicOverlays.filter((overlay, index) => index > focusedOverlayIndex);

					this.overlayService.removeParanjaHoverFromBackground();
					this.overlayService.setParanjaHoverToOverlays(topOverlays);
					this.overlayService.removeParanjaHoverFromOverlays(bottomOverlays);
					this.overlayService.moveOverlaysBack([currentOverlay]);

					this.mouseAllreadyOnCloseButtonArea = false;
					mouseLeaveCloseButtonArea.next(true);
				})
			);
		};

		if (mouseOnLayer) {
			const focusedOverlayIndex = dynamicOverlays
				.findIndex(i => i?.overlayId === focusedOverlay?.overlayId);
			const topOverlays = dynamicOverlays.filter((overlay, index) => index < focusedOverlayIndex);
			const bottomOverlays = dynamicOverlays.filter((overlay, index) => index > focusedOverlayIndex);
			const hoveredOverlays = [...topOverlays, focusedOverlay]
				.filter(overlay => overlay?.isPartOfMultiColumnSystem);

			this.overlayService.removeParanjaHoverFromBackground();
			this.overlayService.setParanjaHoverToOverlays(hoveredOverlays);
			this.overlayService.removeParanjaHoverFromOverlays(bottomOverlays);
			this.overlayService.moveOverlaysBack(topOverlays);
		} else {
			this.overlayService.moveOverlaysBack();
			this.overlayService.setParanjaHoverToOverlays(dynamicOverlays);
			this.overlayService.setParanjaHoverToBackground();
		}
	}

	getNextFocusableElement(element: HTMLElement, filter?: (element: HTMLElement) => boolean): HTMLElement | null {
		let focusableElements = this.getChildFocusableElements(this.document);

		if (filter) {
			focusableElements = focusableElements.filter(filter);
		}

		const targetElementIndex = focusableElements.findIndex(el => el === element);

		if (targetElementIndex === -1) return null;

		return focusableElements[targetElementIndex + 1];
	}

	getChildFocusableElements(element: HTMLElement | Document): HTMLElement[] {
		if (!element) return [];

		return Object.values(element?.querySelectorAll<HTMLElement>(
			'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
		));
	}

	closeOpenedDrops() {
		this.overlayService._dropdownOverlays.forEach(drop => {
			drop?.hideOverlay();
			drop?.changeDetector?.detectChanges();
		});
	}

	containsInDrop(elements: HTMLElement[]) {
		return this.overlayService._dropdownOverlays.some(drop => {
			const dropdownOverlay = drop?.getDropdownOverlay();
			return elements.some(element => dropdownOverlay?.contains(element));
		});
	}

	updateInactivityDates() {
		Object.entries(this.inactivityTrackers).forEach(([trackId, tracker]) => {
			this.inactivityTrackers[trackId].inactivityDate = new Date(new Date().getTime() + tracker.idleTime);
		});
	}

	trackInactivity(idleTime: number = 1800000) {
		const trackId = Guid.create().toString();

		this.inactivityTrackers[trackId] = {
			idleTime,
			inactivityDate: new Date(new Date().getTime() + idleTime),
		};

		return timer(0, 1000)
			.pipe(
				map(() => {
					const track = this.inactivityTrackers[trackId];

					return {
						...track,
						timeToInactivity: track.inactivityDate.getTime() - new Date().getTime(),
					};
				}),
				takeWhile(track => track.timeToInactivity > 0),
				tap({
					complete: () => {
						delete this.inactivityTrackers[trackId];
					}
				}),
			);
	}

}
