import * as i0 from '@angular/core';
import { Injectable, Optional, Inject, NgModule } from '@angular/core';
class GoogleTagManagerService {
  constructor(config = {
    id: null
  }, googleTagManagerId, googleTagManagerAuth, googleTagManagerPreview, googleTagManagerResourcePath, googleTagManagerCSPNonce) {
    this.config = config;
    this.googleTagManagerId = googleTagManagerId;
    this.googleTagManagerAuth = googleTagManagerAuth;
    this.googleTagManagerPreview = googleTagManagerPreview;
    this.googleTagManagerResourcePath = googleTagManagerResourcePath;
    this.googleTagManagerCSPNonce = googleTagManagerCSPNonce;
    this.isLoaded = false;
    this.browserGlobals = {
      windowRef() {
        return window;
      },
      documentRef() {
        return document;
      }
    };
    if (this.config == null) {
      this.config = {
        id: null
      };
    }
    this.config = {
      ...this.config,
      id: googleTagManagerId || this.config.id,
      gtm_auth: googleTagManagerAuth || this.config.gtm_auth,
      gtm_preview: googleTagManagerPreview || this.config.gtm_preview,
      gtm_resource_path: googleTagManagerResourcePath || this.config.gtm_resource_path
    };
    if (this.config.id == null) {
      throw new Error('Google tag manager ID not provided.');
    }
  }
  getDataLayer() {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }
  pushOnDataLayer(obj) {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }
  addGtmToDom() {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        return resolve(this.isLoaded);
      }
      const doc = this.browserGlobals.documentRef();
      this.pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;
      gtmScript.src = this.applyGtmQueryParams(this.config.gtm_resource_path ? this.config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js');
      gtmScript.addEventListener('load', () => {
        return resolve(this.isLoaded = true);
      });
      gtmScript.addEventListener('error', () => {
        return reject(false);
      });
      if (this.googleTagManagerCSPNonce) {
        gtmScript.setAttribute('nonce', this.googleTagManagerCSPNonce);
      }
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
    });
  }
  pushTag(item) {
    return new Promise((resolve, reject) => {
      if (!this.isLoaded) {
        this.addGtmToDom().then(() => {
          this.pushOnDataLayer(item);
          return resolve();
        }).catch(() => reject());
      } else {
        this.pushOnDataLayer(item);
        return resolve();
      }
    });
  }
  applyGtmQueryParams(url) {
    if (url.indexOf('?') === -1) {
      url += '?';
    }
    return url + Object.keys(this.config).filter(k => this.config[k]).map(k => `${k}=${this.config[k]}`).join('&');
  }
}
/** @nocollapse */ /** @nocollapse */
GoogleTagManagerService.ɵfac = function GoogleTagManagerService_Factory(t) {
  return new (t || GoogleTagManagerService)(i0.ɵɵinject('googleTagManagerConfig', 8), i0.ɵɵinject('googleTagManagerId', 8), i0.ɵɵinject('googleTagManagerAuth', 8), i0.ɵɵinject('googleTagManagerPreview', 8), i0.ɵɵinject('googleTagManagerResourcePath', 8), i0.ɵɵinject('googleTagManagerCSPNonce', 8));
};
/** @nocollapse */ /** @nocollapse */
GoogleTagManagerService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: GoogleTagManagerService,
  factory: GoogleTagManagerService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleTagManagerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: ['googleTagManagerConfig']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: ['googleTagManagerId']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: ['googleTagManagerAuth']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: ['googleTagManagerPreview']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: ['googleTagManagerResourcePath']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: ['googleTagManagerCSPNonce']
      }]
    }];
  }, null);
})();
class GoogleTagManagerModule {
  static forRoot(config) {
    return {
      ngModule: GoogleTagManagerModule,
      providers: [{
        provide: 'googleTagManagerConfig',
        useValue: config
      }]
    };
  }
}
/** @nocollapse */ /** @nocollapse */
GoogleTagManagerModule.ɵfac = function GoogleTagManagerModule_Factory(t) {
  return new (t || GoogleTagManagerModule)();
};
/** @nocollapse */ /** @nocollapse */
GoogleTagManagerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: GoogleTagManagerModule
});
/** @nocollapse */ /** @nocollapse */
GoogleTagManagerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleTagManagerModule, [{
    type: NgModule
  }], null, null);
})();

/*
 * Public API Surface of angular-google-tag-manager
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GoogleTagManagerModule, GoogleTagManagerService };