<ng-container *ngIf="splittedValue?.length">
	<ng-container *ngIf="canShowMinusSymbol; else plusSymbol">&minus;&nbsp;</ng-container>
	<ng-template #plusSymbol><ng-container *ngIf="canShowPlusSymbol">&plus;&nbsp;</ng-container></ng-template>
	<ng-container *ngIf="currencySymbol && currencyPosition === currencyPositionEnum.before">
		{{ currencySymbol }}<ng-container *ngIf="space">&nbsp;</ng-container>
	</ng-container>
	<ng-container
		*ngFor="let separatedPart of splittedValue; let last = last;"
	>{{ separatedPart }}<ng-container *ngIf="!last">
		<ng-container *ngIf="isDefaultThousandSeparator; else customThousandSeparator">
			<span class="amount__six-per">&nbsp;</span>
		</ng-container>
		<ng-template #customThousandSeparator>{{ thousandSeparator }}</ng-template></ng-container></ng-container>
	<ng-container *ngIf="currencySymbol && currencyPosition === currencyPositionEnum.after">
	<ng-container *ngIf="space">
		<span class="amount__six-per">&nbsp;</span></ng-container>{{ currencySymbol }}</ng-container>
</ng-container>
