import {
	AfterViewInit,
	ChangeDetectionStrategy, ChangeDetectorRef,
	Component, ContentChild,
	ElementRef,
	EventEmitter,
	Injector,
	Input, OnInit, Output,
	TemplateRef,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, distinctUntilChanged, Subscription} from 'rxjs';
import { ErrorMode } from './models/ErrorMode';
import { sharedMemo } from '../../utils/memo/memo.decorator';

@Component({
	selector: 'b-shared-text-input',
	templateUrl: './text-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: TextInputComponent,
		}
	],
})
export class TextInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
	@Output()
		focusHandle: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
	@Output()
		blurHandle: EventEmitter<any> = new EventEmitter<any>();

	@Output() maskFilled: EventEmitter<any> = new EventEmitter<any>();

	// NgxMask параметры:
	@Input() public mask: string | null = null;
	@Input() public prefix: string = '';
	@Input() public suffix: string = '';
	@Input() public thousandSeparator: string = ' ';
	@Input() public dropSpecialCharacters: boolean | string[] | null = null;
	@Input() public hiddenInput: boolean | undefined | null = null;
	@Input() public showMaskTyped: boolean | null = null;
	@Input() public clearIfNotMatch: boolean | null = null;
	@Input() public validation: boolean | null = false;
	@Input() public decimalMarker: '.' | ',' | ['.' | ','] = ',';

	@Input() wrapperClasses: string;
	@Input() iconClasses: string = 'icon--sm';
	@Input() iconDisabledName: string = 'icons_scaling_lock';
	@Input() iconLoaderName: string = 'icons_scaling_loader';
	@Input() iconLoaderClasses: string = 'icon--sm';
	@Input() disabled: boolean = false;
	@Input() readonly: boolean | null = null;
	@Input() animatedTitle: boolean = true;
	@Input() showDisabledIcon: boolean = true;
	@Input() hideTemplatesWhenDisabled: boolean = true;
	@Input() placeholder: string = '';
	@Input() isLoading: boolean = false;
	@Input() success: boolean = false;
	@Input() autofocus: boolean = false;

	/** Режим отображения ошибки */
	@Input() errorMode: ErrorMode | keyof typeof ErrorMode = ErrorMode.touchedAndDirty;

	@ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

	@ContentChild('placeholder')
		placeholderTemplate: TemplateRef<any>;
	@ContentChild('before')
		beforeContentTemplate: TemplateRef<any>;
	@ContentChild('after')
		afterContentTemplate: TemplateRef<any>;

	private onChange = (_: string | null) => {};
	private onTouched = () => {};
	private subscription = new Subscription();

	isFocused: boolean = false;
	value: string = '';
	isDisabled: boolean = false;
	private touched: boolean = false;
	private control: UntypedFormControl;

	fieldValue$ = new BehaviorSubject<string>(null);
	fieldValue = this.fieldValue$.asObservable().pipe(distinctUntilChanged());

	constructor(
		private cdr: ChangeDetectorRef,
		private injector: Injector,
	) {}

	ngOnInit() {
		const ngControl = this.injector.get(NgControl);

		if (ngControl instanceof FormControlName) {
			this.control = this.injector
				.get(FormGroupDirective)
				.getControl(ngControl);
		} else {
			this.control = (ngControl as FormControlDirective).form;
		}

		this.subscription.add(
			this.fieldValue
				.subscribe({
					next: (value) => {
						this.value = value;
						this.onChange(value);
					}
				})
		);

		if (this.control) {
			this.subscription.add(
				this.control.statusChanges.subscribe({
					next:() => {
						this.cdr.detectChanges();
					}
				})
			);
		}
	}

	ngAfterViewInit(): void {
		if (this.autofocus) {
			this.focusInput();
		}
	}

	get isFieldDisabled(): boolean {
		return this.isDisabled || this.disabled;
	}

	get invalid(): boolean {
		return this.control ? this.control.invalid : false;
	}

	get showError(): boolean {
		if (!this.control) {
			return false;
		}

		const { dirty, touched } = this.control;

		return this.invalid ?
			this.checkErrorVisibility(this.errorMode, touched, dirty) :
			false;
	}

	@sharedMemo
	checkErrorVisibility(
		errorMode: ErrorMode | keyof typeof ErrorMode,
		touched: boolean,
		dirty: boolean,
	): boolean {
		switch (errorMode) {
		case ErrorMode.onlyTouched:
			return touched;
		case ErrorMode.onlyDirty:
			return dirty;
		case ErrorMode.touchedOrDirty:
			return touched || dirty;
		case ErrorMode.touchedAndDirty:
		default:
			return touched && dirty;
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	changeHandler(value: string) {
		this.fieldValue$.next(value);
	}

	writeValue(value: string): void {
		this.fieldValue$.next(value);
	}

	setDisabledState(isDisabled: boolean) {
		this.isDisabled = isDisabled;
		this.cdr.detectChanges();
	}

	focusHandler(event: FocusEvent) {
		this.isFocused = true;
		this.focusHandle.emit(event);
	}

	blurHandler(event: Event) {
		this.isFocused = false;
		this.markAsTouched();
		this.blurHandle.emit(event);
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	public focusInput(): void {
		this.inputElement?.nativeElement?.focus();
	}

}
