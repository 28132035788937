<span
	[sharedClass]="[
		'btn-component__inner',
		btnInnerClass
	]"
>
	<ng-template #svg let-right="iconRight">
		<svg
			b-shared-svg
			*ngIf="icon && right"
			[name]="icon"
			[path]="spritePath"
			[sharedClass]="[
				'btn-component__icon icon',
				iconClass
			]"
		></svg>
	</ng-template>

	<ng-container *ngTemplateOutlet="svg; context: { iconRight: !iconRight }"> </ng-container>

	<span
		[sharedClass]="[
			'btn-component__text',
			textClass
		]"
	>
		<ng-container *ngIf="loading; else content">
			<svg
				b-shared-svg
				[name]="isClientEnvironment ? 'icons_scaling_loader' : 'icons_cl_32_loader'"
				[sharedClass]="[
					'btn__loader icon rotating',
					{
						'icon--2xl icon--cl': !isClientEnvironment,
						'icon--sm': isClientEnvironment
					}
				]"
				aria-hidden="false"
				title="Загрузка"
			></svg>
		</ng-container>
		<ng-template #content>
			<ng-content></ng-content>
		</ng-template>
	</span>

	<ng-container *ngTemplateOutlet="svg; context: { iconRight: iconRight }"></ng-container>
</span>
