import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { SvgModule } from '../svg/svg.module';
import { SeparateInputModule } from '../separate-input/separate-input.module';
import { SafeHtmlPipeModule, TimeCounterModule } from '../../../pipes';
import { SmsConfirmComponent } from './sms-confirm.component';
import { SharedTemplateModule } from '../../directives';

@NgModule({
	declarations: [
		SmsConfirmComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SeparateInputModule,
		SvgModule,
		ButtonModule,
		TimeCounterModule,
		SharedTemplateModule,
		SafeHtmlPipeModule,
	],
	exports: [
		SmsConfirmComponent,
	]
})
export class SmsConfirmModule {}
