import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Inject, afterNextRender } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AnalyticsService } from '../../../modules/analytics/analytics.service';
import { DeviceService } from '../../../services/device.service';
import { WINDOW } from '../../../tokens';


@Component({
	selector: '[b-shared-store-buttons]',
	templateUrl: './store-buttons.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreButtonsComponent {

	@Input() appStoreLink = 'https://go.blanc.ru/X5O6/75a23aa1';
	@Input() googlePlayLink = '/android';
	@Input() setDefaultLinksForDesktop = true;

	@Input() commonClass = 'btn-service';
	@Input() target = '_blank';
	@Input() showByPlatform = false;

	mobileOs: string = null;

	constructor(
		private deviceService: DeviceService,
		private analyticsService: AnalyticsService,
		private cookieService: CookieService,
		@Inject(WINDOW) private _window: Window,
	) {
		afterNextRender(() => {
			if (!this.showByPlatform) {
				this.mobileOs = this.deviceService.getMobileOS();
				this.appStoreLink = decodeURIComponent(this.getUrlWithCookies(this.appStoreLink).href);
			} else if (this.setDefaultLinksForDesktop) {
				this.appStoreLink = 'https://apps.apple.com/us/app/бланк-для-бизнеса/id1558900694';
			}
		});
	}

	getUrlWithCookies(url: string): URL {
		const sbCurrentCookie = this.cookieService.get('sbjs_current');
		const sbCookies = this.analyticsService.splitSourcebusterCookies(sbCurrentCookie);
		return this.analyticsService.addSourcebusterCookiesToUrl(url, sbCookies, {
			mdm: 'af_sub1',
			src: 'af_sub2',
			cmp: 'af_sub3',
			cnt: 'af_sub4',
			trm: 'af_sub5',
		});
	}

	openIosLink(event: Event) {
		if (!this.showByPlatform) {
			return;
		}

		event.preventDefault();

		this.appStoreLink = decodeURIComponent(this.getUrlWithCookies(this.appStoreLink).href);
		this._window.open(this.appStoreLink, '_blank');
	}
}
