import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from './overlay.component';
import { ButtonModule } from '../button/button.module';
import { SvgModule } from '../svg/svg.module';
import { KeyboardControlModule } from '../../directives/keyboard-control/keyboard-control.module';
import { ArrowNavigationModule } from '../../directives/arrow-navigation/arrow-navigation.module';
import { SimplebarAngularModule } from 'simplebar-angular';

@NgModule({
	declarations: [
		OverlayComponent,
	],
	imports: [
		CommonModule,
		ButtonModule,
		SvgModule,
		KeyboardControlModule,
		ArrowNavigationModule,
		SimplebarAngularModule
	],
	exports: [
		OverlayComponent,
	],
})
export class OverlayModule {}
