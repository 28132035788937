<!-- TEMPORARY REMOVED -->
<!-- <a
	b-shared-button
	*ngIf="!showByPlatform || (mobileOs === 'iOS' || mobileOs === 'iPadOS' || !mobileOs)"
	[class]="commonClass"
	[href]="appStoreLink"
	[target]="target"
	rel="noopener noreferrer"
	title="Загрузите в App Store"
	(click)="openIosLink($event)"
>
	<svg
		b-shared-svg
		name="stores_app-store-btn"
		class="btn-service__img"
		height="22"
	></svg>
</a> -->

<a
	b-shared-button
	*ngIf="!showByPlatform || (mobileOs === 'Android' || !mobileOs)"
	[class]="commonClass"
	[href]="googlePlayLink"
	rel="noopener noreferrer"
	title="Доступно на Android"
>
	<svg
		b-shared-svg
		name="stores_app-android-btn"
		class="btn-service__img"
		height="22"
	></svg>
</a>

<!-- TEMPORARY REMOVED -->
<!--<a
	b-shared-button
	*ngIf="!showByPlatform || (mobileOs === 'Huawei' || !mobileOs)"
	[class]="commonClass"
	[routerLink]="appGalleryLink"
	[target]="target"
	rel="noopener noreferrer"
	title="Откройте в AppGallery"
>
	<svg
		b-shared-svg
		name="stores_app-gallery-btn"
		class="btn-service__img"
		height="22"
	></svg>
</a>-->
