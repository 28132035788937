import { ModuleWithProviders, NgModule } from '@angular/core';
import { DynamicModules } from './models';
import { Config } from './models/Config';
import { OverlayRouting } from './models/OverlayRouting';
import { SharedService } from './shared.service';

@NgModule({
	providers: [
		SharedService,
	],
})
export class SharedModule {
	public static forRoot({
		environment = {},
		sharedConfig = {},
		overlayRouting = {},
		dynamicModules = {},
	}: {
		environment: any,
		sharedConfig?: Config,
		overlayRouting?: OverlayRouting,
		dynamicModules?: DynamicModules,
	}): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [
				{
					provide: 'env',
					useValue: environment,
				},
				{
					provide: 'sharedConfig',
					useValue: sharedConfig,
				},
				{
					provide: 'overlayRouting',
					useValue: overlayRouting,
				},
				{
					provide: 'dynamicModules',
					useValue: dynamicModules,
				},
			],
		};
	}
}
