import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordComponent } from './password.component';
import { ButtonModule } from '../button/button.module';
import { SvgModule } from '../svg/svg.module';
import { RangeSliderModule } from '../range-slider/range-slider.module';
import { SharedClassModule } from '../../directives';

@NgModule({
	declarations: [
		PasswordComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SvgModule,
		ButtonModule,
		RangeSliderModule,
		SharedClassModule,
	],
	exports: [
		PasswordComponent,
	]
})
export class PasswordModule {}
