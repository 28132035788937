import {
	ChangeDetectionStrategy, OnDestroy,
	Component, ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding, Injectable,
	Injector,
	Input, OnInit,
	Output, ViewChild, ChangeDetectorRef
} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgControl, ControlValueAccessor} from '@angular/forms';

export const RADIO_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => RadioButtonComponent),
	multi: true
};

@Injectable({
	providedIn: 'root',
})
export class RadioControlRegistry {
	private accessors: any[] = [];

	add(control: NgControl, accessor: RadioButtonComponent) {
		this.accessors.push([control, accessor]);
	}

	remove(accessor: RadioButtonComponent) {
		this.accessors = this.accessors.filter((c) => {
			return c[1] !== accessor;
		});
	}

	select(accessor: RadioButtonComponent) {
		this.accessors.forEach((c) => {
			if (this.isSameGroup(c, accessor) && c[1] !== accessor) {
				c[1].writeValue(accessor.value);
			}
		});
	}

	private isSameGroup(controlPair: [NgControl, RadioButtonComponent], accessor: RadioButtonComponent): boolean {
		if (!controlPair[0].control) {
			return false;
		}

		return controlPair[0].control.root === accessor.control.control.root && controlPair[1].name === accessor.name;
	}
}

@Component({
	selector: '[b-shared-radiobutton]',
	templateUrl: './radiobutton.component.html',
	providers: [RADIO_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonComponent implements ControlValueAccessor, OnInit, OnDestroy {
	@HostBinding('class') class = 'radio';

	@Input() formControlName: string;
	@Input() value: any;
	@Input() name: string;
	@Input() labelClass: string;
	@Input() disabled: boolean;
	@Output() clickEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild('rb') inputViewChild: ElementRef;

	public onModelChange: Function = () => {};
	public onModelTouched: Function = () => {};

	public checked: boolean;
	control: NgControl;

	constructor(public cd: ChangeDetectorRef, private injector: Injector, private registry: RadioControlRegistry) {}

	ngOnInit() {
		this.control = this.injector.get(NgControl);
		this.registry.add(this.control, this);
	}

	select(event) {
		if (!this.disabled) {
			this.inputViewChild.nativeElement.checked = true;
			this.checked = true;
			this.onModelChange(this.value);
			this.registry.select(this);
			this.clickEvent.emit(event);
		}
	}

	onChange(event) {
		this.select(event);
	}

	handleClick(event, radioButton, focus) {
		event.preventDefault();

		if (this.disabled) {
			return;
		}

		this.select(event);

		if (focus) {
			radioButton.focus();
		}
	}

	writeValue(value: any) : void {
		this.checked = (value == this.value);

		if (this.inputViewChild && this.inputViewChild.nativeElement) {
			this.inputViewChild.nativeElement.checked = this.checked;
		}

		this.cd.markForCheck();
	}

	registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onModelTouched = fn;
	}

	ngOnDestroy() {
		this.registry.remove(this);
	}
}
