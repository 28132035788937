import {
	ComponentRef,
	createNgModuleRef,
	Injectable,
	Injector,
	ViewContainerRef,
} from '@angular/core';
import { from, map, Observable, throwError } from 'rxjs';
import { SharedService } from './shared/shared.service';

/**
 * Сервис для ленивой загрузки модулей с последующим рендером корневого компонента
 */
@Injectable({
	providedIn: 'root',
})
export class LazyLoadService {

	private readonly loadedRootComponents: Record<string, ComponentRef<unknown>> = {};

	constructor(
		private sharedSerivce: SharedService,
		private injector: Injector,
	) {}

	/** Метод для ленивой загрузки модуля и рендера его корневого компонента в переданный контейнер */
	public renderModule(
		moduleKey: string,
		container: ViewContainerRef,
		props?: Record<string, any>,
	): Observable<ComponentRef<unknown> | null> {
		if (!container) {
			return throwError(() => {
				console.error(`LazyLoadService: не найден контейнер для модуля "${moduleKey}"`);
				return null;
			});
		}

		const loadTargetModule = this.sharedSerivce.dynamicModulesConfig?.[moduleKey];

		if (!loadTargetModule) {
			return throwError(() => {
				console.error(`LazyLoadService: не найден модуль "${moduleKey}" в dynamicModules.ts`);
				return null;
			});
		}

		return from(
			loadTargetModule()
		)
			.pipe(
				map(module => {
					if (!module?.rootComponent) {
						console.error(`LazyLoadService: не найдено свойство rootComponent в модуле "${moduleKey}"`);
						return null;
					}

					container?.clear();
					const moduleRef = createNgModuleRef(module, this.injector);
					const componentRef = container?.createComponent(module.rootComponent, { ngModuleRef: moduleRef });
					this.changeProps(componentRef.instance, props);
					this.loadedRootComponents[moduleKey] = componentRef;

					return componentRef;
				})
			);
	  }

	/** Метод для изменения параметров экземпляра корневого компонента модуля */
	public changePropsOfRootComponent(
		moduleKey: string,
		props: Record<string, any>,
	): void {
		const componentRef = this.loadedRootComponents?.[moduleKey];

		if (!componentRef) {
			console.error(`LazyLoadService: корневой компонент модуля "${moduleKey}" ещё не был вставлен в DOM в рамках этой сессии`);
			return;
		}

		this.changeProps(componentRef.instance, props);
	}

	private changeProps(
		instance: unknown,
		props: Record<string, any>,
	): void {
		if (props && typeof props === 'object') {
			Object.entries(props).forEach(([propName, propValue]) => {
				instance[propName] = propValue;
			});
		}
	}

}
