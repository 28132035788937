<form *ngIf="smsForm" [formGroup]="smsForm" (ngSubmit)="confirmSms()" class="d-flex flex-column">
	<div class="d-flex flex-column">
		<b-shared-separate-input
			*ngIf="separateInput; else defaultInput"
			#separateSmsInput
			[attr.placeholder]="placeholder"
			inputmode="numeric"
			inputClass="field__control"
			[fieldNgClass]="{
				'field--error': fieldWithError
			}"
			[autofocus]="true"
			[onlyNumbers]="true"
			[fieldsCount]="codeLength"
			formControlName="smsCode"
			class="order-first"
			[containerClass]="isClientEnvironment ?
				'd-grid grid-af-col lg:gap-20 md:gap-16 gap-12' :
				'row row--auto-cols sm:row--gx-12 row--gx-0 row-cols-auto ' +
				'lg:flex-jc-start sm:flex-jc-center flex-jc-between flex-nowrap'
			"
		></b-shared-separate-input>

		<ng-template #defaultInput>
			<div
				class="field"
				[ngClass]="{
					'field--error': fieldWithError,
					'field--title': smsField.value || inputIsFocused
				}"
			>
				<p class="field__title">
					<ng-container
						*ngIf="titleTemplate; else defaultTitle"
						[ngTemplateOutlet]="titleTemplate"
					></ng-container>
					<ng-template #defaultTitle>
						{{ placeholder }}
					</ng-template>
				</p>
				<input
					#defaultSmsInput
					[attr.maxlength]="codeLength"
					type="text"
					inputmode="numeric"
					[attr.autofocus]="true"
					formControlName="smsCode"
					class="field__control"
					(focus)="inputFocused()"
					(blur)="inputBlured()"
				>
				<svg
					b-shared-svg
					*ngIf="confirmLoader || sendCodeLoader"
					[@lifecycleTrigger]
					name="icons_cl_32_loader-black"
					class="icon icon--2xl rotating"
				></svg>
			</div>
		</ng-template>

		<ng-container *ngIf="!sendCodeLoader && !isServerThrottle">
			<div
				*ngIf="isThrottled && timeToUnlockThrottle; else resendCodeButton"
				[ngClass]="hintClass"
			>
				<ng-container
					*ngIf="throttledTemplate; else defaultThrottledTemplate"
					[ngTemplateOutlet]="throttledTemplate"
					[ngTemplateOutletContext]="{$implicit: timeToUnlockThrottle}"
				></ng-container>
				<ng-template #defaultThrottledTemplate>
					Отправить код повторно через
					<span
						[ngClass]="{
							'text-disabled': !isClientEnvironment,
							'text-secondary': isClientEnvironment
						}"
					>
						{{ timeToUnlockThrottle | timeCounter }}
					</span>
				</ng-template>
			</div>
		</ng-container>

		<div
			*ngIf="errors?.length"
			[@lifecycleTrigger]
			class="hint hint--error"
			[ngClass]="hintErrorClass"
		>
			<ng-container *ngFor="let errorData of errors; trackBy: trackByErrors">
				<ng-container *ngIf="errorData?.template; else payloadData">
					<ng-container
						[ngTemplateOutlet]="errorData.template"
						[ngTemplateOutletContext]="{
							$implicit: {
								timeToUnlockThrottle: timeToUnlockThrottle,
								timeToUnlockBlocking: timeToUnlockBlocking,
								codeEntryBlocked: codeEntryBlocked,
								isThrottled: isThrottled,
								response: errorData?.response,
								message: errorData?.message
							}
						}"
					></ng-container>
				</ng-container>
				<ng-template #payloadData>
					<span *ngIf="errorData?.message" [innerHtml]="errorData.message | safeHtml"></span>
				</ng-template>
			</ng-container>
		</div>
	</div>
</form>

<ng-template #resendCodeButton>
	<ng-container *ngIf="!sendCodeLoader && !codeEntryBlocked">
		<button
			b-shared-button
			[class]="resendBtnClass"
			[ngClass]="{
				'link': !isClientEnvironment,
				'btn-link lg:mt-20 md:mt-16 mt-12': isClientEnvironment,
				'flex-as-start': true
			}"
			(click)="sendCode(true)"
		>
			<ng-container
				*ngIf="resendButtonTemplate; else defaultResendButtonTemplate"
				[ngTemplateOutlet]="resendButtonTemplate"
			></ng-container>
			<ng-template #defaultResendButtonTemplate>
				<div
					[ngClass]="hintClass"
				>
					<span>Отправить код ещё раз</span>
				</div>
			</ng-template>
		</button>
	</ng-container>
</ng-template>

<!--
	Дефолтные темплейты ошибок:
	(преопределить можно через customErrorTemplates)
-->
<ng-template sharedTemplate="ServiceUnavailable" templatesGroup="smsDefaultErrors">
	Сервис временно недоступен.
	<ng-container
		[ngTemplateOutlet]="resendCodeButton"
	></ng-container>
</ng-template>

<ng-template sharedTemplate="EntryBlocked" templatesGroup="smsDefaultErrors">
	Мы&nbsp;временно заблокировали ввод кода из&nbsp;смс
	<ng-container *ngIf="codeEntryBlocked && timeToUnlockBlocking">
		<br>
		Попробуй снова через {{ timeToUnlockBlocking | timeCounter }}
	</ng-container>
</ng-template>

<ng-template sharedTemplate="CodeNotSentOrOutdated" let-context>
	<ng-container *ngIf="context?.message">
		{{context?.message}}
	</ng-container>
	<ng-container *ngIf="codeEntryBlocked && timeToUnlockBlocking">
		<br>
		Попробуй снова через {{ timeToUnlockBlocking | timeCounter }}
	</ng-container>
</ng-template>

<ng-template sharedTemplate="InvalidCode" let-context>
	<ng-container *ngIf="context?.message">
		{{context?.message}}
	</ng-container>
	<ng-container *ngIf="codeEntryBlocked && timeToUnlockBlocking">
		<br>
		Попробуй снова через {{ timeToUnlockBlocking | timeCounter }}
	</ng-container>
</ng-template>

<ng-template sharedTemplate="IncorrectSignature" let-context>
	<ng-container *ngIf="context?.message">
		{{context?.message}}
	</ng-container>
	<ng-container *ngIf="codeEntryBlocked && timeToUnlockBlocking">
		<br>
		Попробуй снова через {{ timeToUnlockBlocking | timeCounter }}
	</ng-container>
</ng-template>

<ng-template sharedTemplate="Throttled" templatesGroup="smsDefaultErrors">
	Мы&nbsp;временно заблокировали повторную отправку SMS.
	<ng-container *ngIf="isThrottled && timeToUnlockThrottle">
		Отправить код повторно через
		<span
			[ngClass]="{
				'text-disabled': !isClientEnvironment,
				'text-secondary': isClientEnvironment
			}"
		>
			{{ timeToUnlockThrottle | timeCounter }}
		</span>
	</ng-container>
</ng-template>

<ng-template sharedTemplate="AlreadySended" templatesGroup="smsDefaultErrors">
	Мы&nbsp;временно заблокировали повторную отправку SMS.
	<ng-container *ngIf="isThrottled && timeToUnlockThrottle">
		Отправить код повторно через
		<span
			[ngClass]="{
				'text-disabled': !isClientEnvironment,
				'text-secondary': isClientEnvironment
			}"
		>
			{{ timeToUnlockThrottle | timeCounter }}
		</span>
	</ng-container>
</ng-template>

<ng-template sharedTemplate="InvalidConfirmCode" templatesGroup="smsDefaultErrors">
	Неверный код
</ng-template>

<ng-template sharedTemplate="SmsCodeExpired" templatesGroup="smsDefaultErrors">
	Запроси код ещё раз, предыдущий уже устарел :(
</ng-template>
