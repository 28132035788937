import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, ChangeDetectorRef, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnInit, TemplateRef, ContentChildren, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, distinctUntilChanged, map, merge } from 'rxjs';
import { lengthRegexp } from '../../../helpers/regexp/length';
import {numberRegexp, latinAndCyrillic} from '../../../helpers/regexp/symbols';
import { ClassNames } from '../../directives/shared-class/models/ClassNames';
import { SharedTemplateDirective } from '../../directives/shared-template/shared-template.directive';
import { PasswordSecurityHint } from './models/PasswordStrength';
import { SecurityLevels } from './models/SecurityLevels';

@Component({
	selector: 'b-shared-password',
	templateUrl: './password.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PasswordComponent,
		}
	],
	animations: [
		trigger('lifecycleTrigger', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translate3d(0, 10%, 0)' }),
				animate('300ms ease-in', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
			]),
			transition(':leave', [
				animate('300ms ease-out', style({ opacity: 0, transform: 'translate3d(0, 10%, 0)' })),
			]),
		]),
	],
})
export class PasswordComponent implements ControlValueAccessor, AfterViewInit, OnInit {

	defaultSecurityLevels: SecurityLevels[] = [
		{
			anyRegisterAndNumberAndMinOf10: {
				regExp: [lengthRegexp.minOf10, latinAndCyrillic.anyRegisterAndNumber],
			},
			anyRegisterAndNumberAndFrom6To9: {
				regExp: [lengthRegexp.from6To9, latinAndCyrillic.anyRegisterAndNumber],
			},
			from0To5: {
				regExp: lengthRegexp.from0To5,
			},
		},
		{
			anyRegisterAndNumber: {
				regExp: latinAndCyrillic.anyRegisterAndNumber,
				progressBarClass: 'range__bar--strong',
			},
			anyRegister: {
				regExp: latinAndCyrillic.anyRegister,
				progressBarClass: 'range__bar--medium',
			},
			lowercaseAndNumber: {
				regExp: latinAndCyrillic.lowercaseAndNumber,
				progressBarClass: 'range__bar--medium',
			},
			uppercaseAndNumber: {
				regExp: latinAndCyrillic.uppercaseAndNumber,
				progressBarClass: 'range__bar--medium',
			},
			atLeastOneUppercase: {
				regExp: latinAndCyrillic.atLeastOneUppercase,
				progressBarClass: 'range__bar--weak',
			},
			atLeastOneLowercase: {
				regExp: latinAndCyrillic.atLeastOneLowercase,
				progressBarClass: 'range__bar--weak',
			},
			atLeastOneNumber: {
				regExp: numberRegexp.atLeastOneNumber,
				progressBarClass: 'range__bar--weak',
			},
			lackNumbersAndAnyCaseSymbols: {
				regExp: latinAndCyrillic.lackNumbersAndAnyCaseSymbols,
			},
		}
	];

	@Input() disabled: boolean = false;
	@Input() loader: boolean = false;
	@Input() toggleMask: boolean = true;
	@Input() inputClass: string;
	@Input() hintListClass: ClassNames = '';
	@Input() hintClass: string = '';
	@Input() rangeClass: string = '';
	@Input() fieldClass: string = '';
	@Input() fieldNgClass: string | {[key: string]: boolean} = '';
	@Input() iconPasswordHide: string = 'icons_16_2_eye-left-top';
	@Input() iconPasswordShow: string = 'icons_16_2_eye-right-bottom';
	@Input() iconPasswordClass: string = '';
	@Input() placeholder: string | null = null;
	@Input() autocomplete: 'current-password' | 'new-password' | null = 'current-password';
	@Input() fieldTitle: string | null = 'Пароль';
	@Input() autofocus: boolean = false;
	@Input() toggleButtonClass: string = '';
	@Input() hintsVisibility: boolean = true;

	/** Режим отображения подсказок о надёжности пароля */
	@Input() securityHintMode: PasswordSecurityHint | keyof typeof PasswordSecurityHint = PasswordSecurityHint.notDisplay;

	/** Правила безопасности пароля */
	@Input() securityLevels: SecurityLevels[] = this.defaultSecurityLevels;

	/** Длина безопасного пароля: максимальное значение progress bar'а */
	@Input() secureLength: number | null = 6;

	@ViewChild('input') input: ElementRef;
	@ContentChildren(SharedTemplateDirective) templates: QueryList<SharedTemplateDirective>;

	@Output() focusHandler: EventEmitter<any> = new EventEmitter();
	@Output() blurHandler: EventEmitter<any> = new EventEmitter();

	focused: boolean = false;
	unmasked: boolean = false;
	touched: boolean = false;
	dirty: boolean = false;
	fieldIsDisabled: boolean = false;
	onChange = (value: string | null) => {};
	onTouched = () => {};
	hintsTemplates: (TemplateRef<any> | null)[] = [];
	strengthIndicatorValue: number = 0;
	strengthIndicatorClass: string | null = null;

	fieldValue$ = new BehaviorSubject<string | null>(null);
	fieldValue = this.fieldValue$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _fieldValue(): string | null {
		return this.fieldValue$.getValue();
	}

	isFocused$ = new BehaviorSubject<boolean>(false);
	isFocused = this.isFocused$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _isFocused(): boolean {
		return this.isFocused$.getValue();
	}

	public smallTextVisibility = merge(
		this.fieldValue,
		this.isFocused,
	).pipe(
		map(() => Boolean(this._isFocused) || Boolean(this._fieldValue)),
		distinctUntilChanged(),
	);

	constructor(
		private changeDetector: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.fieldValue.subscribe({
			next: value => this.updateHints(value),
		});
	}

	ngAfterViewInit(): void {
		this.updateHints(this._fieldValue);
		if (this.autofocus) {
			this.input?.nativeElement?.focus();
		}
	}

	updateHints(value: string): void {
		this.updateStrengthIndicatorValue();

		this.hintsTemplates = [];

		if (!this.templates?.length) {
			this.changeDetector.detectChanges();
		}
		if (this.templates?.length) {
			this.strengthIndicatorClass = null;

			this.securityLevels.forEach(securityLevel => {
				const firstValidRule = Object.entries(securityLevel).find(([templateId, rule]) =>
					Array.isArray(rule.regExp) ?
						rule.regExp.every(exp => exp.test(value || '')) :
						rule.regExp.test(value || '')
				);

				if (!firstValidRule) {
					this.strengthIndicatorClass = null;
					return;
				}

				const templateId = firstValidRule[0];
				const rule = firstValidRule[1];

				if (rule?.progressBarClass) {
					this.strengthIndicatorClass = rule.progressBarClass;
				}

				const templateRef = this.templates.find(template => template?.getName?.() === templateId);

				if (templateRef) {
					this.hintsTemplates.push(templateRef.template);
				}
			});
			this.hintsTemplates = this.hintsTemplates.filter(Boolean);
		}
	}

	updateStrengthIndicatorValue() {
		const currentLength = this._fieldValue?.length || 0;
		this.strengthIndicatorValue = this.secureLength < currentLength ? this.secureLength : currentLength;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	inputHandler(event: InputEvent) {
		const targetElement = event.target as HTMLInputElement;
		this.fieldValue$.next(targetElement.value);
		this.onChange(targetElement.value);
		this.dirty = true;
	}

	focusInputHandler(event: FocusEvent) {
		this.focused = true;
		this.isFocused$.next(true);
		this.focusHandler.emit(event);
	}

	blurInputHandler(event: Event) {
		this.focused = false;
		this.isFocused$.next(false);
		this.blurHandler.emit(event);
		this.markAsTouched();
	}

	writeValue(value: any): void {
		this.fieldValue$.next(value);
		this.changeDetector.detectChanges();
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean) {
		this.fieldIsDisabled = disabled;

		this.changeDetector.detectChanges();
	}

	get inputType(): string {
		return this.unmasked ? 'text' : 'password';
	}

	get isDisabled() {
		return this.fieldIsDisabled || this.disabled;
	}

	toggle() {
		const element: HTMLInputElement = this.input?.nativeElement;
		const end = element?.value?.length || 0;

		this.unmasked = !this.unmasked;
		this.changeDetector.detectChanges();

		element?.setSelectionRange(end, end);
		element?.focus();
	}

	public get canShowPasswordSecurityHints(): boolean {
		if (!this.hintsVisibility) return false;

		switch (this.securityHintMode) {
		case PasswordSecurityHint.alwaysDisplay:
			return true;
		case PasswordSecurityHint.whenFieldNotEmpty:
			return Boolean(this._fieldValue);
		case PasswordSecurityHint.whenFieldDirty:
			return this.dirty;
		case PasswordSecurityHint.whenFieldTouched:
			return this.touched;
		case PasswordSecurityHint.notDisplay:
		default:
			return false;
		}
	}

}
