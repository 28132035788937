<ng-template #liItems>
	<li
		*ngFor="let option of suggestions; trackBy: trackBySuggests"
		role="option"
		[ngClass]="listItemClass"
		(click)="selectOption(option)"
		sharedKeyboardControl
		(activeElementKeyHandler)="selectOption(option)"
		sharedArrowNavigation
		[groupId]="arrowNavigationGroup"
	>
		<div
			*ngIf="isClientEnvironment && !canShowInlineSuggest; else defaultOption"
			class="btn-cell__inner flex-column flex-grow-1 flex-ai-stretch py-0"
		>
			<div class="
				btn-cell__divided-content drop__item-inner
				lg:py-24 md:py-20 py-16 px-0
			">
				<div
					class="d-flex flex-jc-between w-100p"
					[ngClass]="autocompleteItemContentClass"
				>
					<ng-container
						*ngIf="optionTemplateRef; else defaultOptionTemplate"
						[ngTemplateOutlet]="optionTemplateRef"
						[ngTemplateOutletContext]="{$implicit: option}"
					></ng-container>
					<ng-template #defaultOptionTemplate>{{ option }}</ng-template>
				</div>
			</div>
		</div>

		<ng-template #defaultOption>
			<ng-container
				*ngIf="optionTemplateRef; else defaultOptionTemplate"
				[ngTemplateOutlet]="optionTemplateRef"
				[ngTemplateOutletContext]="{$implicit: option}"
			></ng-container>
			<ng-template #defaultOptionTemplate>{{ option }}</ng-template>
		</ng-template>
	</li>
</ng-template>


<ng-template #autocompleteListTemplate>
	<div
		*ngIf="suggestVisibility"
		[ngClass]="overlayNgClass"
		class="drop__content"
		[@fadeIn]="animationParams"
		(@fadeIn.start)="overlayAnimationStart($event)"
		(@fadeIn.done)="overlayAnimationEnd($event)"
		#overlayRef
	>
		<ng-container *ngIf="isNotEmptyList; else emptyList">
			<ng-container
				*ngIf="contentBeforeList"
				[ngTemplateOutlet]="contentBeforeList"
			></ng-container>

			<ul
				role="listbox"
				[ngClass]="listNgClass"
				id="activeAutocomplete"
				#listContainer
			>
				<ng-content select="beforeSuggestionsContent"></ng-content>

				<ng-container *ngIf="!grouped; else groupedSuggestions">
					<ng-container [ngTemplateOutlet]="liItems"></ng-container>
				</ng-container>

				<ng-template #groupedSuggestions>
					<li
						*ngFor="let group of suggestions; trackBy: trackByGroups"
					>

						<div
							*ngIf="group.items.length"
							class="title-list"
						>
							<ng-container
								*ngIf="groupTemplateRef; else defaultGroupTemplate"
								[ngTemplateOutlet]="groupTemplateRef"
								[ngTemplateOutletContext]="{$implicit: group}"
							></ng-container>
							<ng-template #defaultGroupTemplate>{{ group?.label || '' }}</ng-template>
						</div>

						<ul class="ul ul--no-marker">
							<li
								*ngFor="let option of group.items; trackBy: trackBySuggests"
								role="option"
								[ngClass]="listItemClass"
								(click)="selectOption(option)"
								sharedKeyboardControl
								(activeElementKeyHandler)="selectOption(option)"
								sharedArrowNavigation
								[groupId]="arrowNavigationGroup"
							>
								<div
									[ngClass]="{
										'btn-cell__inner flex-column flex-grow-1 flex-ai-stretch py-0':
										isClientEnvironment
									}"
								>
									<div
										[ngClass]="{
											'btn-cell__divided-content drop__item-inner lg:py-24 md:py-20 py-16 px-0':
											isClientEnvironment
										}"
									>
										<div
											class="d-flex flex-jc-between w-100p"
											[ngClass]="autocompleteItemContentClass"
										>
											<ng-container
												*ngIf="optionTemplateRef; else defaultOptionTemplate"
												[ngTemplateOutlet]="optionTemplateRef"
												[ngTemplateOutletContext]="{$implicit: option}"
											></ng-container>
											<ng-template #defaultOptionTemplate>{{ option }}</ng-template>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</li>
				</ng-template>
			</ul>
		</ng-container>

		<ng-template #emptyList>
			<ng-container
				*ngIf="emptyListContentRef"
				[ngTemplateOutlet]="emptyListContentRef"
			></ng-container>
		</ng-template>
	</div>

	<div
		*ngIf="canShowOverlayLoader"
		[@fadeIn]="animationParams"
		(@fadeIn.start)="overlayAnimationStart($event)"
		(@fadeIn.done)="overlayAnimationEnd($event)"
		[ngClass]="overlayNgClass"
		class="drop__content pos-absolute w-100p"
	>
		<div class="drop__loader-wrapper">
			<svg
				b-shared-svg
				[name]="overlayLoaderIcon"
				class="icon icon--cl rotating z-20 mx-auto my-12"
				[ngClass]="overlayLoaderIconClass"
				aria-hidden="false"
				title="Загрузка"
			></svg>
		</div>
	</div>
</ng-template>

<input
	*ngIf="!mask; else maskedInput"
	#inputRef
	aria-autocomplete="list"
	role="combobox"
	aria-haspopup="true"
	class="field__control"
	autocomplete="off"
	aria-owns="activeAutocomplete"
	type="text"
	[attr.aria-expanded]="true"
	[attr.placeholder]="placeholder"
	[attr.inputmode]="inputmode"
	[disabled]="isDisabled"
	[(ngModel)]="inputValueModel"
	(ngModelChange)="inputChange($event)"
	(focus)="inputFocused()"
	(blur)="inputBlured()"
	sharedArrowNavigation
	[groupId]="arrowNavigationGroup"
/>
<ng-template #maskedInput>
	<input
		#inputRef
		aria-autocomplete="list"
		role="combobox"
		aria-haspopup="true"
		class="field__control"
		autocomplete="off"
		aria-owns="activeAutocomplete"
		type="text"
		[attr.aria-expanded]="true"
		[attr.placeholder]="placeholder"
		[attr.inputmode]="inputmode"
		[disabled]="isDisabled"
		[(ngModel)]="inputValueModel"
		(ngModelChange)="inputChange($event)"
		(focus)="inputFocused()"
		(blur)="inputBlured()"
		sharedArrowNavigation
		[groupId]="arrowNavigationGroup"
		[mask]="mask"
		[prefix]="prefix"
		[suffix]="suffix"
		[showMaskTyped]="showMaskTyped"
		[clearIfNotMatch]="clearIfNotMatch"
		[thousandSeparator]="thousandSeparator"
		[hiddenInput]="hiddenInput"
		[dropSpecialCharacters]="dropSpecialCharacters"
		[validation]="validation"
	/>
</ng-template>

<span
	*ngIf="fieldTitle"
	class="field__title"
>
	{{ fieldTitle }}
</span>
<div
	class="field__addon field__addon--icon layered-content d-grid"
	*ngIf="searchIcon || canShowInputLoader || clearable"
>
	<svg
		*ngIf="searchIcon && !canShowInputLoader && (!clearable || fieldIsEmpty)"
		[@fadeAnimation]
		b-shared-svg
		[name]="isClientEnvironment ? 'icons_scaling_search' : 'icons_24_2_search'"
		class="layered-content__item icon"
		[ngClass]="{
			'icon--xl': !isClientEnvironment,
			'icon--sm': isClientEnvironment
		}"
	></svg>
	<svg
		*ngIf="canShowInputLoader"
		[@fadeAnimation]
		b-shared-svg
		[name]="isClientEnvironment ? 'icons_scaling_loader' : 'icons_cl_24_2_loader'"
		class="layered-content__item icon icon--cl rotating"
		[ngClass]="{
			'icon--xl': !isClientEnvironment,
			'icon--sm': isClientEnvironment
		}"
	></svg>
	<button
		*ngIf="clearable && !canShowInputLoader && !fieldIsEmpty"
		[@fadeAnimation]
		b-shared-button
		(click)="clearInput()"
		class="layered-content__item"
		[ngClass]="{
			'btn': !isClientEnvironment,
			'p-0 border-none bg-none cursor-pointer': isClientEnvironment
		}"
		[icon]="isClientEnvironment ? 'icons_scaling_cross-round' : 'icons_24_2_cross-round'"
		[iconClass]="isClientEnvironment ? 'icon--sm icon--cl' : 'icon--xl'"
		title="Очистить поле"
		sharedArrowNavigation
		[groupId]="arrowNavigationGroup"
	></button>
</div>

<!-- результаты поиска: overlay -->
<ng-container
	*ngIf="canShowOverlaySuggest; else inlineSuggest"
>
	<div
		*ngIf="!isClientEnvironment; else defaultList"
		class="drop__wrapper pe-none"
		[ngClass]="overlayNgClass"
	>
		<ng-container
			[ngTemplateOutlet]="autocompleteListTemplate"
		></ng-container>
	</div>

	<ng-template #defaultList>
		<ng-container
			[ngTemplateOutlet]="autocompleteListTemplate"
		></ng-container>
	</ng-template>
</ng-container>

<!-- результаты поиска: inline -->
<ng-template #inlineSuggest>
	<ng-container *ngIf="canShowInlineSuggest">

		<ng-container *ngIf="suggestVisibility && !canShowInlineLoader && !canShowInputLoader">
			<ng-container *ngIf="isNotEmptyList; else emptyList">
				<ng-container
					*ngIf="contentBeforeList"
					[ngTemplateOutlet]="contentBeforeList"
				></ng-container>

				<ul
					role="listbox"
					[ngClass]="listNgClass"
					id="activeAutocomplete"
					#listContainer
					[@fadeAnimation]
				>
					<ng-container *ngIf="!grouped; else groupedSuggestions">
						<ng-container [ngTemplateOutlet]="liItems"></ng-container>
					</ng-container>

					<ng-template #groupedSuggestions>
						<li
							*ngFor="let group of suggestions; trackBy: trackByGroups"
						>
							<ng-container
								*ngIf="groupTemplateRef; else defaultGroupTemplate"
								[ngTemplateOutlet]="groupTemplateRef"
								[ngTemplateOutletContext]="{$implicit: group}"
							>
							</ng-container>
							<ng-template #defaultGroupTemplate>{{ group?.label || '' }}</ng-template>

							<ul class="ul ul--no-marker">
								<li
									*ngFor="let option of group.items; trackBy: trackBySuggests"
									role="option"
									[class]="autocompleteListItemClass"
									(click)="selectOption(option)"
									sharedKeyboardControl
									(activeElementKeyHandler)="selectOption(option)"
									sharedArrowNavigation
									[groupId]="arrowNavigationGroup"
								>
									<ng-container
										*ngIf="optionTemplateRef; else defaultOptionTemplate"
										[ngTemplateOutlet]="optionTemplateRef"
										[ngTemplateOutletContext]="{$implicit: option}"
									>
									</ng-container>
									<ng-template #defaultOptionTemplate>{{ option }}</ng-template>
								</li>
							</ul>
						</li>
					</ng-template>
				</ul>
			</ng-container>

			<ng-template #emptyList>
				<ng-container
					*ngIf="emptyListContentRef"
					[ngTemplateOutlet]="emptyListContentRef"
				></ng-container>
			</ng-template>
		</ng-container>

		<div *ngIf="canShowInlineLoader && inlineLoaderContentRef" [@fadeAnimation]>
			<ng-container
				*ngIf="inlineLoaderContentRef"
				[ngTemplateOutlet]="inlineLoaderContentRef"
			></ng-container>
		</div>

	</ng-container>
</ng-template>

