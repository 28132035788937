import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OverlayRouting, Config, DynamicModules, EnvironmentConfig } from './models';
import { isPlatformServer } from '@angular/common';


@Injectable({
	providedIn: 'root',
})
export class SharedService {

	private defaultConfig: Config = {
		amount: {
			minLengthForIncludeSeparator: 0,
		},
		overlay: {
			backgroundClass: '',
			minDropdownMargins: '24px',
			multiColumnSystem: false,
		},
		analytics: {
			toggler: false,
		}
	};

	public readonly environment: EnvironmentConfig;
	public readonly config: Config = {};
	public readonly overlayRoutingConfig: OverlayRouting = {};
	public readonly dynamicModulesConfig: DynamicModules = {};

	constructor(
		@Inject('env') private env: EnvironmentConfig,
		@Inject('sharedConfig') private sharedConfig,
		@Inject('overlayRouting') private overlayRouting,
		@Inject('dynamicModules') private dynamicModules: DynamicModules,
		@Inject(PLATFORM_ID) private platformId: any,
	) {
		this.environment = this.env;
		this.config = {
			...this.defaultConfig,
			...this.sharedConfig,
		};
		this.overlayRoutingConfig = this.overlayRouting;
		this.dynamicModulesConfig = this.dynamicModules;
	}

	get isUrlOfProd(): boolean {
		if(isPlatformServer(this.platformId)) return;
		return this.environment?.productionUrl?.hostname === location.hostname;
	}

}
