import {
	AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild,
	ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef,
	ViewChild, ViewChildren, ViewEncapsulation,
} from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription, take } from 'rxjs';
import { Format, Mime, mimeTypes } from '../../../types/mimeTypes';
import { FileUploadValue } from './models/FileUploadValue';
import { FileListPosition } from './models/FileListPosition';
import { FileService } from '../../../services';
import { ExtendedFile } from './models/ExtendedFile';
import { WINDOW } from '../../../tokens';


@Component({
	selector: 'b-shared-file-upload',
	templateUrl: './file-upload.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: FileUploadComponent,
		}
	],
	animations: [
		trigger('fade', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('300ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('300ms ease-out', style({ opacity: 0 })),
			]),
		]),
	]
})
export class FileUploadComponent implements OnInit, AfterContentInit, OnDestroy {

	/** доступные расширения файлов и MIME-типы для инпута */
	@Input() get inputAccept(): (Mime | Format)[] {
		return this._inputAccept;
	}
	set inputAccept(value: (Mime | Format)[]) {
		this._inputAccept = value;
		this.formats = value.join(',') || null;
	}

	/** доступные расширения файлов и MIME-типы для Drag&Drop */
	@Input() dragAccept: (Mime | Format)[] = [];

	/** флаг для включения поддержки выбора и Drag&Drop-а нескольких файлов */
	@Input() multiple: boolean = false;

	/** флаг для отображения иконки */
	@Input() showIcon: boolean = true;

	/** флаг для отображения лейбла */
	@Input() showLabel: boolean = true;

	/** флаг для отображения файлов, загрузка которых не удалась из за ошибки */
	@Input() showFailedFiles: boolean = false;

	/** флаг для включения пополнямости поля новыми файлами */
	@Input() replenishable: boolean = false;

	/** флаг для отображения списка выбранных файлов */
	@Input() fileListVisibility: boolean = false;

	/** предотвращение удаления файла для кастомной логики удаления */
	@Input() preventFileDeletion: boolean = false;

	/** максимальный размер файлов в байтах */
	@Input() maxFileSize: number | null = null;

	@Input() fileListPosition: FileListPosition | keyof typeof FileListPosition = FileListPosition.before;

	@Input() deletableFiles: boolean = true;

	@Input() asyncFileDeletion: boolean = false;

	@Input() fileDeletionStream: (file: ExtendedFile) => Observable<any>;

	@Input() loadableFiles: boolean | Format[] = true;

	@Input() openableFiles: boolean | Format[] = false;

	@Input() asyncFileUpload: boolean = false;

	@Input() filesUploadStreams: (files: File[]) => {
		file: File,
		stream: Observable<any>,
	}[];

	@Input() filesMultiUploadStream: (files: File[]) => {
		files: File[],
		stream: Observable<any>,
	};

	@Input() hiddenInput: boolean = false;

	@Input() formDataName: string = 'files[]';

	@Input() openTarget: '_self' | '_blank' | '_parent' | '_top' = '_self';

	/** Находится ли поле загрузки в одном списке с файлами */
	@Input() isUploadFieldInList: boolean = false;

	/** Добавляется ли внутренняя обертка пунктам в списке */
	@Input() isContentWrapper: boolean = false;

	/** Максимальное количество файлов, которое можно выбрать */
	@Input() maxFilesCount: number | null = null;

	@Input() areaClass: string | null = '';
	@Input() areaDisabledClass: string | null = 'uploading-area--disabled';
	@Input() inputClass: string | null = 'uploading-area__control';
	@Input() fileItemBtnsWrapperClass: string | null = 'file-upload-list__item-btns';
	@Input() fileLoaderWrapperClass: string | null = 'd-flex';
	@Input() iconClass: string | null = 'icon icon--md';
	@Input() iconName: string | null = 'icons_scaling_plus-in-circle';
	@Input() iconLoaderName: string | null = 'icons_cl_24_2_loader';
	@Input() iconLoaderClass: string | null = 'icon--cl icon--xl mr-12 ml-auto';
	@Input() labelClass: string | null = 'uploading-area__label';
	@Input() fileListClass: string | null = 'file-upload-list';
	@Input() fileItemClass: string | null = 'file-upload-list__item';
	@Input() failedFileItemClass: string | null = 'uploading-area--error';
	@Input() fileItemContentClass: string | null = '';
	@Input() uploadFileItemClass: string | null = '';
	@Input() deleteButtonClass: string | null = 'btn va-t';
	@Input() failedFileDeleteButtonClass: string | null = 'btn va-t';
	@Input() deleteButtonIcon: string | null = 'icons_24_2_trash';
	@Input() deleteButtonIconClass: string | null = 'icon--xl';
	@Input() uploadButtonClass: string | null = 'btn btn--secondary btn--sm sm:w-120 w-100p mt-8';

	/** классы, которые добавляются, когда файл находится над Drag&Drop областью (событие dragenter) */
	@Input() areaActiveClass: string | null = 'uploading-area--activated';
	@Input() inputActiveClass: string | null = null;
	@Input() iconActiveClass: string | null = null;
	@Input() labelActiveClass: string | null = null;

	@Output() newFilesSelectHandler = new EventEmitter();
	@Output() inputClicked = new EventEmitter();
	@Output() fileClicked = new EventEmitter();
	@Output() deleteButtonClicked = new EventEmitter<ExtendedFile>();
	@Output() limitOfMaxFilesCountIsExceeded = new EventEmitter();
	@Output() extensionsCheckedHandler = new EventEmitter();
	@Output() filesSizeCheckedHandler = new EventEmitter();

	@ContentChild('label') labelRef: TemplateRef<any>;
	@ContentChild('uploadButton') uploadButton: TemplateRef<any>;
	@ContentChild('fileView') fileView: TemplateRef<any>;
	@ViewChildren('deleteButton') deleteButtons: QueryList<ElementRef<HTMLElement>>;
	@ViewChild('uploadInput') uploadInput: ElementRef<HTMLInputElement>;

	fieldValue$ = new BehaviorSubject<FileUploadValue | null>(null);
	fieldValue = this.fieldValue$
		.asObservable()
		.pipe(
			distinctUntilChanged()
		);
	get _fieldValue() {
		return this.fieldValue$.getValue();
	}

	asyncFileList$ = new BehaviorSubject<ExtendedFile[]>([]);
	asyncFileList = this.asyncFileList$
		.asObservable()
		.pipe(
			distinctUntilChanged(),
		);
	get _asyncFileList() {
		return this.asyncFileList$.getValue();
	}

	private readonly failedFileList$ = new BehaviorSubject<ExtendedFile[]>([]);
	public readonly failedFileList = this.failedFileList$
		.asObservable()
		.pipe(
			distinctUntilChanged(),
		);
	private get _failedFileList() {
		return this.failedFileList$.getValue();
	}

	uploadedFilesList$ = new BehaviorSubject<ExtendedFile[]>([]);
	uploadedFilesList = this.uploadedFilesList$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _uploadedFilesList() {
		return this.uploadedFilesList$.getValue();
	}

	elementInDropTarget$ = new BehaviorSubject<boolean>(false);
	elementInDropTarget = this.elementInDropTarget$
		.asObservable()
		.pipe(distinctUntilChanged());

	onChange = (value: FileUploadValue | null) => {};
	onTouched = () => {};
	touched = false;
	disabled = false;
	subscriptions = new Subscription();
	private _inputAccept: (Mime | Format)[] | null = [];
	public formats: string | null = null;
	public fileModel: FileUploadValue['files'] | null = null;
	public fileListPositionEnum = FileListPosition;

	public areaNgClass = {};
	public inputNgClass = {};
	public iconNgClass = {
		'icon': true
	};
	public labelNgClass = {};

	constructor(
		private fileService: FileService,
		private changeDetector: ChangeDetectorRef,
		@Inject(WINDOW) private _window: Window,
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.fieldValue.subscribe({
				next: value => {
					this.onChange(value);
				}
			})
		);

		if (
			this.areaActiveClass ||
			this.inputActiveClass ||
			this.iconActiveClass ||
			this.labelActiveClass
		) {
			this.subscriptions.add(
				this.elementInDropTarget.subscribe(inDrop => {
					if (this.areaActiveClass) this.areaNgClass[this.areaActiveClass] = inDrop;
					if (this.inputActiveClass) this.inputNgClass[this.inputActiveClass] = inDrop;
					if (this.iconActiveClass) this.iconNgClass[this.iconActiveClass] = inDrop;
					if (this.labelActiveClass) this.labelNgClass[this.labelActiveClass] = inDrop;

					this.changeDetector.detectChanges();
				})
			);
		}
	}

	ngAfterContentInit(): void {
		this.changeDetector.detectChanges(); // чтобы актуализировать ContentChild темплейты
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	writeValue(value: FileUploadValue | null) {
		this.fieldValue$.next(value);
		this.fileModel = value?.files || [];
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
		this.areaNgClass[this.areaDisabledClass] = disabled;
		this.changeDetector.detectChanges();
	}

	blurInputHandler() {
		this.markAsTouched();
	}

	getAllowedFiles(fileList: FileList, event: Event | DragEvent) {
		const allowedFiles: File[] = [];
		const accepts = event instanceof DragEvent ?
			this.dragAccept :
			this.inputAccept;

		for (let i = 0; i < fileList.length; i++) {
			const file = fileList[i];

			if (!accepts?.length) {
				allowedFiles.push(file);
			} else {
				const isAllowed = this.checkAccept(accepts, file);

				if (isAllowed) {
					allowedFiles.push(file);
				}
			}
		}

		return allowedFiles;
	}

	checkAccept(acceptList: (Mime | Format)[], file: File): boolean {
		const type = file?.type as Mime;

		if (!type) return false;

		const extFromMime = mimeTypes?.[type];

		return (
			acceptList.includes(type) ||
			(Boolean(extFromMime) && acceptList.includes(extFromMime))
		);
	}

	fileSelectHandler(event: Event | DragEvent) {
		event?.preventDefault?.();

		if (this.disabled) return;

		const files = (event as DragEvent)?.dataTransfer?.files ||
			(event?.target as HTMLInputElement)?.files;

		if (!files?.length) return; // если нету файлов. или если перетащили не файл, а элемент с атрибутом draggable

		const newFiles = this.getFilteredListOfNewFiles(files);
		const allowedFiles = this.getAllowedFiles(newFiles, event);
		this.extensionsCheckedHandler.emit({ files, allowedFiles });

		if (!allowedFiles?.length) return;

		let targetFiles: File[] = [];

		if (this.multiple) {
			targetFiles = allowedFiles;
		} else {
			const firstAllowedFile = allowedFiles.find(file => this.checkAccept(
				[...this.inputAccept, ...this.dragAccept],
				file,
			));

			targetFiles = firstAllowedFile ? [firstAllowedFile] : [allowedFiles[0]];
		}

		if (this.maxFileSize) {
			targetFiles = targetFiles.filter(file => file.size <= this.maxFileSize);
		}

		this.filesSizeCheckedHandler.emit({ files, targetFiles });
		if (!targetFiles?.length) return;

		if (this.maxFilesCount) {
			const totalFilesCount = (this._fieldValue?.files?.length || 0) + (this._asyncFileList?.length || 0) + targetFiles.length;
			if (totalFilesCount > this.maxFilesCount) {
				this.limitOfMaxFilesCountIsExceeded.emit();
				return;
			}
		}

		this.newFilesSelectHandler.emit(targetFiles);

		if (this.asyncFileUpload) {
			if (this.filesUploadStreams) {
				this.filesUploadStreams(targetFiles).forEach(({ stream, file }) => {
					this.addAsyncFile(file);
					this.addUploadedFile(file);

					stream.subscribe({
						next: response => {
							(<File & {apiData: any}>file).apiData = response;
							this.removeUploadedFile(file);
							this.removeAsyncFile(file);
							this.addFilesToModel([file]);
						},
						error: () => {
							this.removeUploadedFile(file);
							this.removeAsyncFile(file);

							if (this.showFailedFiles) {
								this.addFailedFile(file);
							}
						},
					});
				});
			} else if (this.filesMultiUploadStream) {
				const uploadData = this.filesMultiUploadStream(targetFiles);

				uploadData.files.forEach(file => {
					this.addAsyncFile(file);
					this.addUploadedFile(file);
				});

				uploadData.stream.subscribe({
					next: response => {
						uploadData.files.forEach(file => {
							(<File & {apiData: any}>file).apiData = response;
							this.removeUploadedFile(file);
							this.removeAsyncFile(file);
							this.addFilesToModel([file]);
						});
					},
					error: () => {
						uploadData.files.forEach(file => {
							this.removeUploadedFile(file);
							this.removeAsyncFile(file);

							if (this.showFailedFiles) {
								this.addFailedFile(file);
							}
						});
					},
				});
			}
		} else {
			this.addFilesToModel(targetFiles);
		}
	}

	get fileModelArray(): ExtendedFile[] {
		return Array.isArray(this.fileModel) ?
			this.fileModel :
			[this.fileModel].filter(Boolean);
	}

	dragenterHandler(event: DragEvent) {
		this.elementInDropTarget$.next(true);
		event.dataTransfer.dropEffect = 'copy';
	}

	dragleaveHandler(event: DragEvent) {
		this.elementInDropTarget$.next(false);
	}

	dragoverHandler(event: DragEvent) {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'copy';
	}

	dropHandler(event: DragEvent) {
		event?.preventDefault();
		event?.stopPropagation();
		this.elementInDropTarget$.next(false);
		this.fileSelectHandler(event);
	}

	dragendHandler(event: DragEvent) {
		this.elementInDropTarget$.next(false);
	}

	dragexitHandler(event: DragEvent) {
		this.elementInDropTarget$.next(false);
	}

	deleteFile(file: ExtendedFile) {
		const isFailedFile = this._failedFileList.includes(file);

		if (isFailedFile) {
			this.removeFailedFile(file);
			return;
		}

		if (this.asyncFileDeletion && this.fileDeletionStream) {
			this.addUploadedFile(file);

			this.fileDeletionStream(file).pipe(take(1)).subscribe({
				next: () => {
					this.removeUploadedFile(file);
					this.deleteFileFromModel(file);
				},
				error: () => {
					this.removeUploadedFile(file);
				}
			});
		} else {
			this.deleteFileFromModel(file);
		}
	}

	deleteButtonClickHandler(event: Event, file: ExtendedFile) {
		event?.stopPropagation();
		this.deleteButtonClicked?.emit(file);

		if (!file || this.preventFileDeletion) return;

		this.deleteFile(file);
	}

	addFilesToModel(files: ExtendedFile[]) {
		this.fileModel = this.replenishable ?
			[...this.fileModelArray, ...files] :
			[...files];

		const inputValue = this.fileModel.filter(file => file instanceof File);
		this.updateInputValue(inputValue as File[]);

		const formData = new FormData();

		this.fileModelArray.forEach(allowedFile => {
			if (allowedFile instanceof File) {
				formData.append(this.formDataName, allowedFile, allowedFile.name);
			}
		});

		this.fieldValue$.next({
			form: formData,
			files: [...this.fileModel],
		});
	}

	deleteFileFromModel(file: ExtendedFile) {
		const deletedFileIndex = this.fileModel.findIndex(i => i === file);

		if (deletedFileIndex !== -1) {
			const newFileModel = [...this.fileModelArray];
			newFileModel.splice(deletedFileIndex, 1);

			const formData = new FormData();

			newFileModel.forEach(i => {
				if (i instanceof File) {
					formData.append(this.formDataName, i, i.name);
				}
			});

			this.fieldValue$.next({
				form: formData,
				files: [...newFileModel],
			});

			this.fileModel = newFileModel;
		}
	}

	fileClickHandler(event: MouseEvent, file: ExtendedFile) {
		const targetElement = event.target as HTMLElement;
		const deleteButtons = this.deleteButtons?.toArray()?.map(i => i.nativeElement);

		if (deleteButtons.length && deleteButtons.includes(targetElement)) return;

		this.fileClicked.emit(file);

		if (
			(!this.loadableFiles && !this.openableFiles) ||
			this._uploadedFilesList.includes(file)
		) return;

		let extension: Format;

		if (file instanceof File) {
			extension = mimeTypes?.[file.type];
		} else {
			extension = file.extension;
		}

		const needDownload: boolean = Array.isArray(this.loadableFiles) ?
			this.loadableFiles.map(i => i.toLowerCase()).includes(extension.toLowerCase()) :
			this.loadableFiles;

		const needOpen: boolean = Array.isArray(this.openableFiles) ?
			this.openableFiles.map(i => i.toLowerCase()).includes(extension.toLowerCase()) :
			this.openableFiles;

		if (!needDownload && !needOpen) return;

		if (file instanceof File) {
			this.fileService.downloadFile({
				fileName: file.name,
				loadableFile: file,
				download: needDownload,
				open: needOpen,
				openTarget: this.openTarget,
			});
		} else {
			this.addUploadedFile(file);

			this.fileService.downloadFile({
				fileName: file?.fileName || file?.name,
				loadableFile: file.downloadLink,
				download: needDownload,
				open: needOpen,
				openTarget: this.openTarget,
			})
				.subscribe({
					next: () => {
						this.removeUploadedFile(file);
					},
					error: () => {
						this.removeUploadedFile(file);
					},
				});
		}
	}

	addUploadedFile(file: ExtendedFile) {
		this.uploadedFilesList$.next([
			...this._uploadedFilesList,
			file,
		]);
	}

	removeUploadedFile(file: ExtendedFile) {
		const fileIndex = this._uploadedFilesList.findIndex(i => i === file);
		if (fileIndex !== -1) {
			const files = [...this._uploadedFilesList];

			files.splice(fileIndex, 1);

			this.uploadedFilesList$.next([...files]);
		}
	}

	addAsyncFile(file: ExtendedFile) {
		this.asyncFileList$.next([
			...this._asyncFileList,
			file,
		]);
	}

	removeAsyncFile(file: ExtendedFile) {
		const fileIndex = this._asyncFileList.findIndex(i => i === file);
		if (fileIndex !== -1) {
			const files = [...this._asyncFileList];

			files.splice(fileIndex, 1);

			this.asyncFileList$.next([...files]);
		}
	}

	addFailedFile(file: ExtendedFile) {
		this.failedFileList$.next([
			...this._failedFileList,
			file,
		]);
	}

	removeFailedFile(file: ExtendedFile) {
		const fileIndex = this._failedFileList.findIndex(i => i === file);
		if (fileIndex !== -1) {
			const files = [...this._failedFileList];

			files.splice(fileIndex, 1);

			this.failedFileList$.next([...files]);
		}
	}

	uploadButtonClick() {
		this.uploadInput?.nativeElement?.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: this._window,
			})
		);
	}

	inputClickHandler(event: MouseEvent) {
		const input = event.target as HTMLInputElement;
		input.value = null; // чтобы отрабатывало событие change при повторном выборе того же набора файлов

		this.inputClicked.emit();

		if (this.maxFilesCount) {
			const totalFilesCount = (this._fieldValue?.files?.length || 0) + (this._asyncFileList?.length || 0);
			if (totalFilesCount >= this.maxFilesCount) {
				event.preventDefault();
				this.limitOfMaxFilesCountIsExceeded.emit();
				return;
			}
		}
	}

	updateInputValue(value: File[] | null): void {
		const dataTransfer = new DataTransfer();

		if (Array.isArray(value)) {
			value.forEach(file => dataTransfer.items.add(file));
		}

		this.uploadInput.nativeElement.files = dataTransfer.files;
	}

	getFilteredListOfNewFiles(fileList: FileList): FileList {
		const dataTransfer = new DataTransfer();

		for (let i = 0; i < fileList.length; i++) {
			const file = fileList[i];

			if (!this.fileModel.includes(file)) {
				dataTransfer.items.add(file);
			}
		}

		return dataTransfer.files;
	}

}
