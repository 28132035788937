/** Формат отображения валюты */
export enum CurrencyView {
	/** Символ валюты. Например, € для евро */
	symbol = 'symbol',
	/** ISO-код валюты. Например, RUB для рубля */
	code = 'code',
	/** Валюта не отображается */
	none = 'none',
}

/** Позиция символа или кода валюты относительно денежной суммы */
export enum CurrencyPosition {
	/** Символ или код валюты расположен после денежной суммы */
	after = 'after',
	/** Символ или код валюты расположен перед денежной суммой */
	before = 'before',
}

/** Режим округления и вывода дробной части числа */
export enum AmountRoundingMode {
	/** два числа в дробной части */
	twoFractionalDigits = 'twoFractionalDigits',
	/** дробная часть только для нецелых чисел меньше 10. для остальных чисел округление до ближайшего меньшего целого числа */
	fraction10 = 'fraction10',
	/** округление до ближайшего меньшего целого числа */
	floor = 'floor',
	/** округление до ближайшего целого числа */
	round = 'round',
	/** округление до следующего наибольшего целого числа */
	ceil = 'ceil',
	/** удаление всех дробных знаков без округления */
	trunc = 'trunc',
}
