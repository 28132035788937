import { Observable } from 'rxjs';

export const SIZING_STYLE = [
	'borderBottomWidth',
	'borderLeftWidth',
	'borderRightWidth',
	'borderTopWidth',
	'boxSizing',
	'fontFamily',
	'fontSize',
	'fontStyle',
	'fontWeight',
	'letterSpacing',
	'lineHeight',
	'paddingBottom',
	'paddingLeft',
	'paddingRight',
	'paddingTop',
	// non-standard
	'tabSize',
	'textIndent',
	// non-standard
	'textRendering',
	'textTransform',
	'width',
	'wordBreak',
] as const;


export const HIDDEN_TEXTAREA_STYLE = {
	'min-height': '0',
	'max-height': 'none',
	height: '0',
	visibility: 'hidden',
	overflow: 'hidden',
	position: 'absolute',
	'z-index': '-1000',
	top: '0',
	right: '0',
} as const;

export type SizingProps = Extract<
	typeof SIZING_STYLE[number],
	keyof CSSStyleDeclaration
>;

export type SizingStyle = Pick<CSSStyleDeclaration, SizingProps>;

export type CalculatedNodeHeights = number[];

export type SizingData = {
	sizingStyle: SizingStyle;
	paddingSize: number;
	borderSize: number;
};

export interface ChipsItem {
	chip: string;
	dynamicValue?: Observable<string>;
}

export type TextareaValue = string | {
	textarea: string;
	chips: {
		chip: string;
		label: string | null;
	}
};
