export const validLengthOgrn = (ogrn) => ogrn.length === 13 || ogrn.length === 15;

export const fifteenDightOgrn = (ogrn) =>
	ogrn.slice(-1) === (ogrn.slice(0, -1) % 13).toString().slice(-1);

export const thirteenDightOgrn = (ogrn) =>
	ogrn.slice(-1) === (ogrn.slice(0, -1) % 11).toString().slice(-1);

export const validOgrnValue = (ogrn) => {
	if (!ogrn || !validLengthOgrn(ogrn) || !/^\d+$/.test(ogrn)) {
		return false;
	}
	if (ogrn.length === 15) {
		return fifteenDightOgrn(ogrn);
	} else if (ogrn.length === 13) {
		return thirteenDightOgrn(ogrn);
	}
};

export const validOgrn = (ogrn) => (ogrn.length === 13 ? validOgrnValue(ogrn) : true);

export const validOgrnip = (ogrn) => (ogrn.length === 15 ? validOgrnValue(ogrn) : true);
