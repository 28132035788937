import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';

export const WINDOW = new InjectionToken<Window | null>(
	'Абстракция над глобальным объектом window',
	{
		factory: () => {
			return inject(DOCUMENT)?.defaultView || null;
		},
	},
);
