
import { inject, InjectionToken } from '@angular/core';

import { WINDOW } from '../window/window';

export const LOCAL_STORAGE = new InjectionToken<Storage | null>(
	'Абстракция над объектом window.localStorage',
	{
		factory: () => {
			try {
				return inject(WINDOW)?.localStorage || null;
			} catch {
				return null;
			}
		},
	},
);
