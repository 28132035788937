var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var canUseDOM = function () {
    return typeof window !== 'undefined' && !!window.document && !!window.document.createElement;
};
var DisableScroll = /** @class */ (function () {
    function DisableScroll() {
        var _this = this;
        this.handleWheel = function (e) {
            e.preventDefault();
        };
        this.handleScroll = function () {
            window.scrollTo.apply(window, _this.lockToScrollPos);
        };
        this.handleKeydown = function (e) {
            var keys = _this.options.keyboardKeys;
            /* istanbul ignore else */
            if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
                keys = keys.filter(function (key) { return !_this.options.authorizedInInputs.includes(key); });
            }
            /* istanbul ignore else */
            if (keys.includes(e.keyCode)) {
                e.preventDefault();
            }
        };
        this.element = null;
        this.lockToScrollPos = [0, 0];
        this.options = {
            authorizedInInputs: [32, 37, 38, 39, 40],
            disableKeys: true,
            disableScroll: true,
            disableWheel: true,
            keyboardKeys: [32, 33, 34, 35, 36, 37, 38, 39, 40],
        };
        /* istanbul ignore else */
        if (canUseDOM()) {
            this.element = document.scrollingElement;
        }
    }
    /**
     * Disable Page Scroll
     */
    DisableScroll.prototype.on = function (element, options) {
        var _a, _b, _c, _d;
        if (!canUseDOM())
            return;
        this.element = element || this.element;
        this.options = __assign(__assign({}, this.options), options);
        var _e = this.options, disableKeys = _e.disableKeys, disableScroll = _e.disableScroll, disableWheel = _e.disableWheel;
        /* istanbul ignore else */
        if (disableWheel) {
            document.addEventListener('wheel', this.handleWheel, { passive: false });
            document.addEventListener('touchmove', this.handleWheel, { passive: false });
        }
        /* istanbul ignore else */
        if (disableScroll) {
            this.lockToScrollPos = [(_b = (_a = this.element) === null || _a === void 0 ? void 0 : _a.scrollLeft) !== null && _b !== void 0 ? _b : 0, (_d = (_c = this.element) === null || _c === void 0 ? void 0 : _c.scrollTop) !== null && _d !== void 0 ? _d : 0];
            document.addEventListener('scroll', this.handleScroll, { passive: false });
        }
        /* istanbul ignore else */
        if (disableKeys) {
            document.addEventListener('keydown', this.handleKeydown, { passive: false });
        }
    };
    /**
     * Re-enable page scrolls
     */
    DisableScroll.prototype.off = function () {
        if (!canUseDOM())
            return;
        document.removeEventListener('wheel', this.handleWheel);
        document.removeEventListener('touchmove', this.handleWheel);
        document.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('keydown', this.handleKeydown);
    };
    return DisableScroll;
}());
export default new DisableScroll();
