import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from './multiselect.component';
import { ButtonModule } from '../button/button.module';
import { SvgModule } from '../svg/svg.module';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		ButtonModule,
		SvgModule,
		FormsModule,
	],
	exports: [
		MultiSelectComponent,
	],
	declarations: [
		MultiSelectComponent,
	],
})
export class MultiSelectModule {}
