import { AnimationEvent, group, animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, fromEvent, Observable, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, filter, tap, debounce } from 'rxjs/operators';
import { OverlayService2 } from '../overlay/overlay.service';
import { ScrollService } from '../../../services/scroll.service';
import { AutocompleteLoaderType, OverlayAnimationLifecycleStep, SuggestPosition, SuggestView } from './autocomplete.types';
import { SharedService } from '../../../services/shared/shared.service';
import { Project } from '../../../services/shared/models/Project';

@Component({
	selector: 'b-shared-autocomplete',
	templateUrl: './autocomplete.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: AutocompleteComponent,
		}
	],
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translate3d(0, {{translateY}}, 0)' }),
				group([
					animate('400ms ease-in', style({
						transform: 'translate3d(0, 0, 0)',
					})),
					animate('200ms ease-in', style({
						opacity: 1,
					})),
				]),
			], {params : { translateY: '2%' }}),
			transition(':leave', [
				animate('400ms ease-in', style({ opacity: 0, transform: 'translate3d(0, {{translateY}}, 0)' })),
			], {params : { translateY: '2%' }}),
		]),
		trigger('fadeAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('200ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
})
export class AutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {

	fieldValue$ = new BehaviorSubject<string | Object>(null);
	fieldValue = this.fieldValue$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _fieldValue(): string | Object {
		return this.fieldValue$.getValue();
	}

	isFocused$ = new BehaviorSubject<boolean>(false);
	isFocused = this.isFocused$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _isFocused(): boolean {
		return this.isFocused$.getValue();
	}

	subscriptions = new Subscription();
	completeSub$: Subscription;
	onChange = (value) => {};
	onTouched = () => {};
	touched = false;
	fieldIsDisabled = false;
	selectedOption: any;
	suggestVisibility = false;
	inputChangedAfterSelection = false;
	suggestPositionType = SuggestPosition;
	position: SuggestPosition | keyof typeof SuggestPosition;
	loading = false;
	fieldIsEmpty: boolean = true;
	firstSearchWasLaunched = false;
	suggestions: any[] = [];
	public fieldWasFocused = false;
	animationLifecycleStep: OverlayAnimationLifecycleStep = OverlayAnimationLifecycleStep.destroyed;
	arrowNavigationGroup = `${Guid.create().toString()}-Dropdown`;
	inputValueModel: string = '';
	private animationInProcess: boolean = false;

	// type="number" передавать нельзя: в некоторых браузерах в инпуте появятся ненужные контролы.
	// вместо type="number" юзать [onlyNumbers]="true"
	@Input() onlyNumbers = false;

	// режим позиционирования выпадающего слоя:
	// вверх/вниз или автоматический режим, при котором слой будет всплывать туда, где больше места в видимой области окна браузера
	@Input() suggestPosition: SuggestPosition | keyof typeof SuggestPosition = SuggestPosition.auto;

	/** режим отображения результатов поиска */
	@Input() suggestView: SuggestView | keyof typeof SuggestView = SuggestView.overlay;

	// режим лоадера при загрузке выпадающего списка
	@Input() loaderType: AutocompleteLoaderType = AutocompleteLoaderType.input;

	// функция, определяющая значение инпута при выбранном элементе из списка
	@Input() displayWith: (option: any) => string = option => option;

	// флаг, указывающий на то, должен ли игнорироваться новый поиск
	// например: нажали на кнопку "нет моей компании в списке"
	@Input() ignoreSearch: boolean = false;

	// функция, возвращающая Observable для загрузки списка
	@Input() completeStream: (value: string | number) => Observable<any[]>;

	// обособленный лоадер, применяющийся в особых случаях. например:
	// выбрали элемент в списке > отправили запрос на сервер для отображения нового списка
	@Input() separateLoader: boolean = false;

	/** Флаг, включающий скрытие оверлея при клике вне компонента */
	@Input() hideOverlayWhenOutsideClicked: boolean = true;

	/* Флаг для вкл/выкл режима закрытия слоя при нажатии на Escape **/
	@Input() closeOnEsc: boolean = true;

	/* Флаг для вкл/выкл режима сгруппированных подсказок **/
	@Input() grouped: boolean = false;

	/** флаг для вкл/выкл поиска при пустом поле ввода */
	@Input() ignoreWhenEmpty: boolean = true;

	/** флаг для скрытия кнопок закрытия оверлеев при расскрытом автокомплите */
	@Input() hideCloseButtons: boolean = false;

	/** симуляция проекта */
	@Input() projectSimulation: Project | keyof typeof Project | null = null;

	/** минимальная длина строки для осуществления поиска */
	@Input() minSearchLength: number | string | null = null;

	/** флаг для установки высоты оверлея до конца видимой области */
	@Input() heightToPageBorders: boolean = this.isClientEnvironment;

	@Input() trackBySuggests = (index, option) => {
		console.warn('b-shared-autocomplete: необходимо указать параметр trackBySuggests');
		return option?.id ||
			option?.inn ||
			option?.ogrn ||
			[option?.address, option?.ogrCategory, option?.ogranization].filter(Boolean).join('') ||
			Guid.create().toString();
	};
	@Input() trackByGroups = (index, group) => group?.label || group?.id || Guid.create().toString();
	@Input() placeholder: string = null;
	@Input() inputmode = 'search';
	@Input() autofocus = false;
	@Input() disabled = false;
	@Input() debounceTime: number = 400;
	@Input() clearable: boolean = false;
	@Input() searchIcon: boolean = false; // опеределяет наличие иконки лупы в инпуте
	@Input() fieldTitle: string = null;
	@Input() overlayClass: string | null = null;
	@Input() autocompleteListClass: string;
	@Input() autocompleteListItemClass: string;
	@Input() autocompleteItemContentClass: string;
	@Input() searchOnlyAfterFirstFocus: boolean = true;
	@HostBinding('class') @Input() class = 'drop__inner';

	// NgxMask параметры:
	@Input() public mask: string | null = null;
	@Input() public prefix: string = '';
	@Input() public suffix: string = '';
	@Input() public thousandSeparator: string = ' ';
	@Input() public dropSpecialCharacters: boolean | string[] | null = null;
	@Input() public hiddenInput: boolean | undefined | null = null;
	@Input() public showMaskTyped: boolean | null = null;
	@Input() public clearIfNotMatch: boolean | null = null;
	@Input() public validation: boolean | null = false;

	@Output() showHandler = new EventEmitter();
	@Output() hideHandler = new EventEmitter();
	@Output() selectHandler = new EventEmitter();
	@Output() blurHandler = new EventEmitter();
	@Output() focusHandler = new EventEmitter();
	@Output() inputChangedHandler = new EventEmitter();
	@Output() fieldChangedHandler = new EventEmitter();
	@Output() overlayAnimationEndHandler = new EventEmitter<AnimationEvent>();
	@Output() clearHandler = new EventEmitter();

	@ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;
	@ViewChild('overlayRef') overlayRef: ElementRef<HTMLElement>;
	@ViewChild('listContainer') listContainer: ElementRef<HTMLElement>;
	@ContentChild('optionTemplate') optionTemplateRef: TemplateRef<any>;
	@ContentChild('contentBeforeList') contentBeforeList: TemplateRef<any>;
	@ContentChild('groupTemplate') groupTemplateRef: TemplateRef<any>;
	@ContentChild('emptyListContent') emptyListContentRef: TemplateRef<any>;
	@ContentChild('inlineLoaderContent') inlineLoaderContentRef: TemplateRef<any>;

	constructor(
		public changeDetector: ChangeDetectorRef,
		@Inject(DOCUMENT) private document: Document,
		private scrollService: ScrollService,
		public elementRef: ElementRef,
		private overlayService: OverlayService2,
		private sharedService: SharedService,
		private renderer: Renderer2,
	) {}

	ngOnInit() {
		if (this.hideOverlayWhenOutsideClicked && this.suggestView !== SuggestView.inline) {
			this.subscriptions.add(
				fromEvent(this.document, 'pointerdown').subscribe(this.documentListener)
			);
		}

		this.subscriptions.add(
			this.fieldValue
				.pipe(
					tap(value => {
						this.fieldChangedHandler?.emit?.(value);

						this.completeSub$?.unsubscribe();
						this.loading = false;
						this.hideOverlay();

						this.fieldIsEmpty = !value;
					}),
					filter(value => {
						return (value || !this.ignoreWhenEmpty)
							&& !this.ignoreSearch
							&& !this.inputChangedAfterSelection
							&& (!this.searchOnlyAfterFirstFocus || this.fieldWasFocused);
					}),
					debounce(() => timer(this.firstSearchWasLaunched ? this.debounceTime : 0)),
				)
				.subscribe({
					next: value => {
						this.autocomplete(this.parseValue(value));
					},
				})
		);
	}

	ngAfterViewInit() {
		this.updatePosition();

		if (this._fieldValue) {
			this.setFieldValue(this._fieldValue);
		}

		if (this.autofocus) {
			this.focus();
		}
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
		this.completeSub$?.unsubscribe();
	}

	updatePosition() {
		this.position = this.suggestPosition === SuggestPosition.auto
			? this.getSuggestAutoPosition()
			: this.suggestPosition;
	}

	autocomplete(value: string | number) {
		this.completeSub$?.unsubscribe();
		this.suggestions = [];
		this.changeOverlayVisibility(false);

		if (!this.isMinLength) {
			this.changeDetector.detectChanges();
			return;
		}

		this.loading = true;
		this.firstSearchWasLaunched = true;
		this.changeDetector.detectChanges();

		this.completeSub$ = this.completeStream(value).subscribe({
			next: suggestions => {
				this.loading = false;
				const newValue = [...(suggestions || [])];

				// если оверлей с подсказками всплывает вверх, то переворачиваем список:
				this.suggestions = this.position === SuggestPosition.above
					? newValue.reverse()
					: newValue;

				this.changeOverlayVisibility();
				this.changeDetector.detectChanges();
			},
			error: () => {
				this.loading = false;
				this.suggestions = [];
				this.changeOverlayVisibility(false);
				this.changeDetector.detectChanges();
			}
		});
	}

	writeValue(value: any = '') {
		this.selectedOption = null;
		this.setFieldValue(value);
	}

	setFieldValue(value: string | Object = '') {
		if (this.inputRef?.nativeElement) {
			this.inputValueModel = this.parseValue(value);
			this.changeDetector.detectChanges();
		}

		if (typeof value === 'string') {
			this.inputChangedAfterSelection = false;
		}

		this.fieldValue$.next(value);

		return value;
	}

	parseValue(value: string | Object): string {
		return typeof value === 'string' ?
			value :
			this.displayWith?.(value);
	}

	get overlayNgClass() {
		const result = {
			'pos-top-100p flex-jc-start': this.position === SuggestPosition.under,
			'pos-bottom-100p flex-jc-end': this.position === SuggestPosition.above,
		};

		if (this.overlayClass) {
			result[this.overlayClass] = true;
		}

		return result;
	}

	get listNgClass() {
		const result = {
			'drop__list': true,
			'autocomplete-list': !this.isClientEnvironment
		};

		if (this.autocompleteListClass) {
			result[this.autocompleteListClass] = true;
		}

		return result;
	}

	get listItemClass() {
		const result = {
			'btn-cell btn-cell--interactive': this.isClientEnvironment && !this.canShowInlineSuggest,
			'autocomplete-list__item': !this.isClientEnvironment
		};

		if (this.autocompleteListItemClass) {
			result[this.autocompleteListItemClass] = true;
		}

		return result;
	}

	get overlayLoaderIcon(): string {
		return this.isClientEnvironment ?
			'icons_scaling_loader' :
			'icons_cl_32_loader-black';
	}

	get overlayLoaderIconClass() {
		const result = {};

		if (this.isClientEnvironment) {
			result['icon--sm'] = true;
		} else {
			result['icon--2xl'] = true;
		}

		return result;
	}

	get overlayAnimationTranlateY() {
		if (this.isClientEnvironment) return '2%';
		return '100%';
	}

	get animationParams() {
		return {
			value: this.suggestVisibility ? ':enter' : ':leave',
			params: {
				translateY: this.position === SuggestPosition.under ? `-${this.overlayAnimationTranlateY}` : this.overlayAnimationTranlateY,
			},
		};
	}

	get canShowInputLoader() {
		return (this.loading || this.separateLoader) && this.loaderType === AutocompleteLoaderType.input;
	}

	get canShowOverlayLoader() {
		return (this.loading || this.separateLoader) && this.loaderType === AutocompleteLoaderType.overlay;
	}

	get canShowInlineLoader(): boolean {
		return (this.loading || this.separateLoader) && this.loaderType === AutocompleteLoaderType.inline;
	}

	getSuggestAutoPosition() {
		const viewportSize = this.scrollService.getViewportSize();
		const inputRect = this.inputRef.nativeElement.getBoundingClientRect();
		const under = viewportSize.viewportHeight - inputRect.top - inputRect.height;
		const above = inputRect.top;

		return under > above
			? SuggestPosition.under
			: SuggestPosition.above;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.fieldIsDisabled = disabled;

		if (disabled) {
			this.inputRef?.nativeElement?.blur();
		}

		this.changeDetector.detectChanges();
	}

	inputChange() {
		this.inputChangedHandler?.emit?.({
			newValue: this.inputValueModel,
			oldValue: this._fieldValue,
		});
		this.markAsTouched();

		this.selectedOption = null;

		let newValue = this.inputValueModel;

		if (this.onlyNumbers) {
			this.inputValueModel = newValue = newValue?.replace(/[^0-9]+/g, '');
			this.changeDetector.detectChanges();
		}

		this.setFieldValue(newValue);
		this.onChange(newValue);
	}

	get isDisabled() {
		return this.fieldIsDisabled || this.disabled;
	}

	changeOverlayVisibility(
		visibility: boolean = Boolean(
			((this._isFocused && (this._fieldValue || !this.ignoreWhenEmpty)) || this.suggestView === SuggestView.inline) &&
			!this.loading &&
			!this.separateLoader &&
			(this.emptyListContentRef || this.suggestions?.length)
		)
	) {
		if (!this.suggestVisibility && visibility) {
			this.updatePosition();
			this.showHandler?.emit();
		} else if (this.suggestVisibility && !visibility) {
			this.hideHandler?.emit();
		}

		this.suggestVisibility = visibility;
		this.changeDetector.detectChanges();

		if (visibility && this.heightToPageBorders && this.suggestView === SuggestView.overlay) {
			this.scrollService.setDropHeightToPageBorders({
				drop: this.overlayRef.nativeElement,
				input: this.inputRef.nativeElement,
				position: this.position,
			});
		}

		// если оверлей с подсказками всплывает вверх, то скроллим список в нижнее положение
		if (this.position === SuggestPosition.above) {
			const ulElement = this.listContainer?.nativeElement;
			ulElement?.scrollTo(0, ulElement.scrollHeight);
		}

		if (visibility) {
			this.overlayService.registerDropdownOverlay(this);
		} else {
			this.overlayService.removeDropdownOverlay(this);
		}
	}

	inputFocused() {
		this.markAsTouched();

		this.focusHandler?.emit();
		this.isFocused$.next(true);

		if (this.ignoreSearch || !this.isMinLength) {
			this.fieldWasFocused = true;
			return;
		}

		if (
			(this._fieldValue || !this.ignoreWhenEmpty) &&
			(this.inputChangedAfterSelection || !this.fieldWasFocused)
		) {
			this.autocomplete(this.parseValue(this._fieldValue));
		} else {
			this.changeOverlayVisibility();
		}

		this.fieldWasFocused = true;
		this.changeDetector.detectChanges();
	}

	inputBlured() {
		this.blurHandler?.emit();
		this.isFocused$.next(false);
	}

	selectOption(option: any) {
		if (this.animationInProcess) return;

		this.selectedOption = option;
		this.inputChangedAfterSelection = true;

		this.onChange(option);
		this.setFieldValue(option);

		this.changeOverlayVisibility(this.suggestView !== SuggestView.overlay);
		this.selectHandler?.emit(option);
	}

	documentListener = (event) => {
		const isBeyondBorders = [
			this.overlayRef?.nativeElement,
			this.inputRef?.nativeElement,
			this.elementRef?.nativeElement,
		].every(i => !i?.contains(event.target as HTMLElement) && event.target !== i);

		if (isBeyondBorders) {
			this.changeOverlayVisibility(false);
		}
	};

	overlayAnimationStart(event: AnimationEvent) {
		const isInitAnimationStart = event.fromState === 'void' && event.phaseName === 'start';
		const isDestroyAnimationStart = event.toState === 'void' && event.phaseName === 'start';

		if (isInitAnimationStart || isDestroyAnimationStart) {
			this.animationInProcess = true;
		}

		if (isInitAnimationStart) {
			this.animationLifecycleStep = OverlayAnimationLifecycleStep.initAnimation;
		} else if (isDestroyAnimationStart) {
			this.animationLifecycleStep = OverlayAnimationLifecycleStep.destroyAnimation;
		}

		/*this.renderer.addClass(event.element?.parentElement, 'ov-hidden');*/

		this.changeDetector.detectChanges();
	}

	overlayAnimationEnd(event: AnimationEvent) {
		const isInitAnimationEnd = event.fromState === 'void' && event.phaseName === 'done';
		const isDestroyAnimationEnd = event.toState === 'void' && event.phaseName === 'done';
		const parentElement = event?.element?.parentElement;

		if (isInitAnimationEnd || isDestroyAnimationEnd) {
			this.animationInProcess = false;
		}

		if (isInitAnimationEnd) {
			this.animationLifecycleStep = OverlayAnimationLifecycleStep.static;
		} else if (isDestroyAnimationEnd) {
			this.animationLifecycleStep = OverlayAnimationLifecycleStep.destroyed;
		}

		/*if (parentElement) {
			this.renderer.removeClass(parentElement, 'ov-hidden');
		}*/

		this.overlayAnimationEndHandler?.emit?.(event);

		this.changeDetector.detectChanges();
	}

	// публичные методы также предназачены для вызовов из родителя через ref:

	public focus() {
		if (this.inputRef?.nativeElement?.focus) {
			this.markAsTouched();
			this.inputRef.nativeElement.focus();
		}
	}

	public blur() {
		this.inputRef?.nativeElement?.blur();
	}

	public hideOverlay() {
		this.changeOverlayVisibility(false);
	}

	public showSuggest() {
		this.changeOverlayVisibility(true);
	}

	public clearInput() {
		this.selectedOption = null;
		this.inputChangedAfterSelection = false;

		this.markAsTouched();
		this.onChange('');
		this.setFieldValue('');
		this.focus();

		this.clearHandler?.emit?.();
	}

	public updateSuggestions(list: any[]) {
		this.suggestions = [...list];
	}

	public get canShowOverlaySuggest(): boolean {
		return this.suggestView === SuggestView.overlay;
	}

	public get canShowInlineSuggest(): boolean {
		return this.suggestView === SuggestView.inline;
	}

	public get isClientEnvironment(): boolean {
		const project = this.projectSimulation || this.sharedService?.environment?.project;

		return Boolean(project) && project === Project.client;
	}

	get isNotEmptyList(): boolean {
		return Boolean(this.suggestions?.length) &&
			(
				!this.grouped ||
				this.suggestions.some(i => Boolean(i.items?.length))
			);
	}

	private get isMinLength(): boolean {
		if (!this.minSearchLength) return true;

		const minLength = typeof this.minSearchLength === 'number' ?
			Math.trunc(this.minSearchLength) :
			parseInt(this.minSearchLength);

		const fieldValue = this.parseValue(this._fieldValue) || '';

		return !minLength || fieldValue?.length >= minLength;
	}

	getTriggerElement() {
		return this.inputRef?.nativeElement;
	}

	getDropdownOverlay() {
		return this.overlayRef?.nativeElement || this.listContainer?.nativeElement;
	}

}
