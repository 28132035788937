export const emailRegexp = {
	/** наличие символа @ */
	containsAtSign: /[@]/,
	/** полная проверка адреса, включа кириллицу */
	validEmail: /^[A-Z0-9._%!+\-А-Я$]+@[A-Z0-9.\-А-Я]+\.[A-ZРФ]{2,}$/i,
};

export const passwordRegexp = {
	/** миниум 6 символов, обязательное наличие заглавной и строчной буквы ,и цифры */
	min6UpperLowerSymbolsNumber: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
	/** миниум 6 символов, обязательное наличие заглавной и строчной буквы ,и цифры ,и любые спец символы */
	min6UpperLowerSymbolsNumberSpec: /^(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])(?=.*\d)[a-zA-Z\d\W_]{6,}$/,
};
