<div [class]="containerClass">
	<div *ngFor="let field of fieldsList; trackBy: trackByFields">
		<div class="field field--secondary field--character" [ngClass]="fieldNgClass">
			<input
				type="text"
				[attr.inputmode]="inputmode"
				[attr.placeholder]="field.placeholder"
				[class]="inputClass"
				[ngClass]="inputNgClass"
				[disabled]="isDisabled || null"
				maxlength="1"
				(input)="inputChange($event)"
				(focus)="inputFocused(field)"
				(blur)="inputBlured(field)"
				(keydown)="inputKeyDown($event)"
				#inputRef
			/>
		</div>
	</div>
</div>
