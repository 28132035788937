import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {BehaviorSubject, filter, Observable, switchMap, take} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class FingerprintInterceptor implements HttpInterceptor {
	private fingerprint: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor(@Inject(PLATFORM_ID) platformId: any,) {
		if(isPlatformBrowser(platformId)) {
			FingerprintJS.load()
				.then(fp => fp.get())
				.then(result => this.fingerprint.next(result.visitorId));
		}
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.fingerprint.pipe(
			filter(Boolean),
			take(1),
			switchMap((fingerprint) => {
				const clonedRequest = request.clone({
					setHeaders: {
						'Device-fp': fingerprint || '',
					},
				});

				return next.handle(clonedRequest);
			})
		);
	}
}
