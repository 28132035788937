import {
	Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation,
	Input, Output, ViewChild, EventEmitter, ElementRef, ChangeDetectorRef,
	Inject, AfterViewInit, ContentChild, TemplateRef, QueryList, ViewChildren, Renderer2, RendererStyleFlags2,
} from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, fromEvent, interval, Subscription, mergeMap, takeUntil, map, merge } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownType, LoaderType, SuggestPosition } from './dropdown.types';
import { DOCUMENT } from '@angular/common';
import { ScrollService } from '../../../services/scroll.service';
import { Guid } from 'guid-typescript';
import { OverlayService2 } from '../overlay/overlay.service';
import { SharedService } from '../../../services/shared/shared.service';
import { Project } from '../../../services/shared/models/Project';

@Component({
	selector: 'b-shared-dropdown',
	templateUrl: './dropdown.component.html',
	animations: [
		trigger('fadeInY', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translate3d(0, {{translateY}}, 0)' }),
				animate('200ms ease-in', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
			], {params : { translateY: '2%' }}),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0, transform: 'translate3d(0, {{translateY}}, 0)' })),
			], {params : { translateY: '2%' }}),
		]),
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('300ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('300ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: DropdownComponent,
		}
	],
})
export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

	fieldValue$ = new BehaviorSubject<any>(null);
	fieldValue = this.fieldValue$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _fieldValue(): any {
		return this.fieldValue$.getValue();
	}

	overlayIsOpened$ = new BehaviorSubject<boolean>(false);
	overlayIsOpened = this.overlayIsOpened$
		.asObservable()
		.pipe(distinctUntilChanged());
	get _overlayIsOpened(): boolean {
		return this.overlayIsOpened$.getValue();
	}

	subscriptions = new Subscription();
	onChange = (value) => {};
	onTouched = () => {};
	touched = false;
	fieldIsDisabled = false;
	selectedOption: any;
	position: SuggestPosition;
	loaderDots: string = '•••';
	arrowNavigationGroup = `${Guid.create().toString()}-Dropdown`;
	dropdownType = DropdownType;
	customScrollSubscription: Subscription;
	triggerButtonVisibility: boolean = true;

	// режим позиционирования выпадающего слоя:
	// вверх/вниз или автоматический режим, при котором слой будет всплывать туда, где больше места в видимой области окна браузера
	@Input() suggestPosition: SuggestPosition = SuggestPosition.auto;

	/* Флаг для вкл/выкл режима закрытия слоя при нажатии на Escape **/
	@Input() closeOnEsc: boolean = true;

	/** флаг для скрытия кнопок закрытия оверлеев при открытом дропе */
	@Input() hideCloseButtons: boolean = false;

	// режим лоадера при загрузке выпадающего списка
	@Input() loaderType: LoaderType = LoaderType.input;

	/** флаг для включения позиционирования относительно элемента-триггера */
	@Input() positioningRelativeTrigger: boolean = false;

	/** флаг для включения кастомного скролла дроп-оверлея */
	@Input() customScroll: boolean = false;

	/** флаг для изменения видимости кнопки-триггера при открытом оверлее */
	@Input() toggleButton: boolean = false;

	// функция, определяющая отображаемое значение инпута при выбранном элементе из списка
	@Input() displayWith: (option: any) => string = option => option;

	// css-класс для оверлэя
	@Input() overlayClass: string;

	// css-класс для списка
	@Input() dropListClass: string;

	// css-класс для элемента
	@Input() dropElementClass: string = '';

	// css-класс для внутренней обёртки элемента
	@Input() dropElementInnerClass: string = '';

	// css-класс для выбранного элемента
	@Input() activeElementClass: string = 'drop__item--active';

	/** css класс для задизейбленного элемента списка */
	@Input() disabledElementClass: string | null = null;

	// css-класс для поля в кнопке
	@Input() dropFieldWrapperClass: string;

	// css-класс для placeholder'а
	@Input() dropFieldClass: string;

	// css-класс для стрелочки
	@Input() dropIconClass: string = '';

	// css-класс для лоадера
	@Input() dropLoaderClass: string = '';

	// флаг для отображения анимированного placeholder'а
	@Input() animatedTitle: boolean = false;

	// иконка стрелочки
	@Input() dropIconName: string = 'icons_24_1-5_arrow-down';

	// иконка выбранного пункта
	@Input() dropIconActiveClass: string = '';

	@Input() reverseAtTopPosition: boolean = true;

	@Input() triggerNavigationGroup: string | null = null;

	/** флаг для установки высоты оверлея до конца видимой области */
	@Input() heightToPageBorders: boolean = this.isClientEnvironment;

	@Input() showArrowIcon: boolean = true;
	@Input() showActiveIcon: boolean = true;
	@Input() isBtnCell: boolean = true;
	@Input() overlayClassInOpenState: string | null = null;
	@Input() liTabindex: string | null = '0';

	_suggestions: any[];
	@Input() set suggestions(value) {
		this._suggestions = [...(value || [])];
	}
	get suggestions() {
		const value = [...(this._suggestions || [])];

		if (!this.reverseAtTopPosition) return value;

		// если оверлей с подсказками всплывает вверх, то переворачиваем список:
		return this.position === SuggestPosition.above
			? value.reverse()
			: value;
	}

	@Input() type: DropdownType | keyof typeof DropdownType = DropdownType.default;

	@Input() placeholder: string | null = null;
	@Input() disabled = false;
	@Input() loader: boolean = false;
	@Input() dropdownClass = '';
	@Input() btnClass = '';
	@Input() classWhenOpen: string | null = null;
	@Input() uniqueElementPropFn = option => {
		console.error('b-shared-dropdown: необходимо указать параметр uniqueElementPropFn');
		return option?.id || option?.inn || option?.ogrn || Guid.create().toString();
	};

	@Input() customTogglingChecking: (dropRef: DropdownComponent) => boolean;

	@Output() customToggling = new EventEmitter<DropdownComponent>();
	@Output() showHandler = new EventEmitter();
	@Output() hideHandler = new EventEmitter();
	@Output() selectHandler = new EventEmitter();

	@ViewChild('listContainer') listContainer: ElementRef<HTMLElement>;
	@ViewChild('listWrapper') listWrapper: ElementRef<HTMLElement>;
	@ViewChild('valueView') valueView: ElementRef<HTMLElement>;
	@ViewChild('triggerButton') triggerButton: ElementRef<HTMLElement>;
	@ViewChildren('listItem') listItems: QueryList<ElementRef<HTMLLIElement>>;

	@ContentChild('dropdownItem') dropdownItem: TemplateRef<any>;
	@ContentChild('viewTemplate') viewTemplate: TemplateRef<any>;
	@ContentChild('afterViewTemplate') afterViewTemplate: TemplateRef<any>;
	@ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;

	constructor(
		public changeDetector: ChangeDetectorRef,
		@Inject(DOCUMENT) private document: Document,
		private scrollService: ScrollService,
		public elementRef: ElementRef<HTMLElement>,
		private overlayService: OverlayService2,
		private sharedService: SharedService,
		private renderer: Renderer2,
	) {}

	ngOnInit() {
		this.updatePosition();

		this.subscriptions.add(
			fromEvent(this.document, 'pointerdown').subscribe(this.documentListener)
		);

		this.subscriptions.add(
			this.fieldValue.subscribe(value => {
				this.selectedOption = value;
				this.changeDetector.detectChanges();
			})
		);

		this.subscriptions.add(
			this.overlayIsOpened.subscribe({
				next: overlayIsOpened => {
					if (overlayIsOpened) {
						this.overlayService.registerDropdownOverlay(this);
					} else {
						this.overlayService.removeDropdownOverlay(this);
					}
				}
			})
		);

		this.subscriptions.add(
			interval(500)
				.pipe(
					filter(() => this.loader)
				)
				.subscribe(() => {
					this.loaderDots = this.loaderDots.length === 3 ? '•' : `${this.loaderDots}•`;
					this.changeDetector.detectChanges();
				})
		);
	}

	ngAfterViewInit() {
		this.updatePosition();
		this.changeDetector.detectChanges(); // чтобы отловить templateRef's
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	getParentOverlay() {
		return this.overlayService._openedOverlays
			.find(overlay => overlay.el?.nativeElement?.contains(this.elementRef?.nativeElement));
	}

	private get borderElementsRects() {
		const listItems = this.listItems?.toArray?.()?.map(li => li.nativeElement);
		const firstElement: HTMLLIElement = listItems[0];
		const lastElement: HTMLLIElement = listItems[listItems.length - 1];

		const firstElementRect: DOMRect = firstElement.getBoundingClientRect();
		const lastElementRect: DOMRect = lastElement.getBoundingClientRect();

		return { firstElementRect, lastElementRect };
	}

	subscribeCustomScroll(triggerRect: DOMRect) {
		const listWrapper = this.listWrapper?.nativeElement;

		if (!listWrapper || !triggerRect) return;

		const move$ = fromEvent<TouchEvent>(this.document, 'touchmove');
		const down$ = fromEvent<TouchEvent>(listWrapper, 'touchstart');
		const up$ = fromEvent<TouchEvent>(this.document, 'touchend');

		const touchDrag$ = down$.pipe(
			map(downEvent => {
				const borderElementsRects = this.borderElementsRects;
				return {
					downEvent,
					transformInitialValue: this.elementRef.nativeElement.style.getPropertyValue('--drop-flows-inner-scroll').replace(/[^0-9\.\-]+/g, ''),
					firstElementTopCoords: borderElementsRects.firstElementRect.top,
					lastElementTopCoords: borderElementsRects.lastElementRect.top,
				};
			}),
			mergeMap(({ downEvent, transformInitialValue, firstElementTopCoords, lastElementTopCoords }) => move$.pipe(
				takeUntil(up$),
				map(moveEvent => ({ downEvent, moveEvent, transformInitialValue, firstElementTopCoords, lastElementTopCoords })),
			)),
		);

		const wheel$ = fromEvent<WheelEvent>(listWrapper, 'wheel');

		this.customScrollSubscription = merge(touchDrag$, wheel$)
			.subscribe({
				next: (data: WheelEvent | {
					downEvent: TouchEvent,
					moveEvent: TouchEvent,
					transformInitialValue: string,
					firstElementTopCoords: number,
					lastElementTopCoords: number,
				}) => {
					const isWheel = data instanceof WheelEvent;
					let deltaY: number = 0;

					if (isWheel) {
						deltaY = data?.deltaY || 0;
					} else {
						deltaY = data.downEvent.changedTouches[0].clientY - data.moveEvent.changedTouches[0].clientY;
					}

					const borderElementsRects = this.borderElementsRects;

					const isBeyondTheTop = isWheel ?
						(borderElementsRects.firstElementRect.top - (deltaY || 0)) >= triggerRect.top :
						data.firstElementTopCoords - (deltaY || 0) >= triggerRect.top;

					const isBeyondTheBottom = isWheel ?
						(borderElementsRects.lastElementRect.top - (deltaY || 0)) <= triggerRect.top :
						data.lastElementTopCoords - (deltaY || 0) <= triggerRect.top;

					let transformValue: string | number;

					if (isBeyondTheTop) {
						transformValue = 0;
					} else if (isBeyondTheBottom) {
						const wrapperRect: DOMRect = listWrapper.getBoundingClientRect();
						const wrapperStyles = getComputedStyle(listWrapper);
						const wrapperHeight = wrapperRect.height - parseFloat(wrapperStyles.paddingBottom) - parseFloat(wrapperStyles.paddingTop);
						transformValue = -(wrapperHeight - triggerRect.height);
					} else {
						if (isWheel) {
							const newValue = this.elementRef.nativeElement.style.getPropertyValue('--drop-flows-inner-scroll').replace(/[^0-9\.\-]+/g, '');
							transformValue = parseFloat(newValue) - (deltaY || 0);
						} else {
							transformValue = (parseFloat(data.transformInitialValue) || 0) - (deltaY || 0);
						}
					}

					this.renderer.setStyle(this.elementRef.nativeElement, '--drop-flows-inner-scroll', `${transformValue}px`, RendererStyleFlags2.DashCase);
				}
			});

		this.subscriptions.add(this.customScrollSubscription);
	}

	unsubscribeCustomScroll() {
		this.customScrollSubscription?.unsubscribe?.();
	}

	updatePosition() {
		this.position = this.suggestPosition === SuggestPosition.auto
			? this.getSuggestAutoPosition()
			: this.suggestPosition;
	}

	get viewValue(): string {
		return this.selectedOption ?
			this.parseValue(this.selectedOption) :
			(this.placeholder || '');
	}

	writeValue(value: any = '') {
		this.fieldValue$.next(value);
	}

	parseValue(value: string | Object): string {
		return typeof value === 'string' ?
			value :
			this.displayWith?.(value);
	}

	get overlayNgClass() {
		return {
			'drop__wrapper drop__content': true,
			'pos-top-100p': this.position === SuggestPosition.under,
			'pos-bottom-100p': this.position === SuggestPosition.above,
		};
	}

	get animationParams() {
		return {
			value: this._overlayIsOpened ? ':enter' : ':leave',
			params: {
				translateY: this.position === SuggestPosition.under ? '-2%' : '2%',
			},
		};
	}

	get canShowInputLoader() {
		return this.loader && this.loaderType === LoaderType.input;
	}

	get dropIcon() {
		return this.isClientEnvironment ? 'icons_scaling_arrow-down-sm' : this.dropIconName;
	}

	getSuggestAutoPosition() {
		const viewportSize = this.scrollService?.getViewportSize();
		const inputRect = this.valueView?.nativeElement?.getBoundingClientRect();
		const under = viewportSize?.viewportHeight - inputRect?.top - inputRect?.height;
		const above = inputRect?.top;

		return under > above
			? SuggestPosition.under
			: SuggestPosition.above;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.fieldIsDisabled = disabled;

		this.changeDetector.detectChanges();
	}

	get isDisabled() {
		return this.fieldIsDisabled || this.disabled;
	}

	getItemNgClass(option) {
		const ngClass = {
			'drop__item': true,
			'btn-cell btn-cell--interactive': this.isClientEnvironment && this.isBtnCell
		};

		if (this.activeElementClass) {
			ngClass[this.activeElementClass] = this.isSelectedOption(option);
		}

		if (this.disabledElementClass) {
			ngClass[this.disabledElementClass] = option?.isDisabledDropElement;
		}

		return ngClass;
	}

	isSelectedOption(option): boolean {
		return Boolean(
			this._fieldValue &&
			(
				this.uniqueElementPropFn(option) === this.uniqueElementPropFn(this._fieldValue) ||
				option === this._fieldValue
			)
		);
	}

	changeOverlayVisibility(
		visibility: boolean = Boolean(this.fieldValue && !this.loader),
		event?: MouseEvent,
	) {
		if (!this._overlayIsOpened && visibility) {
			this.updatePosition();
			this.showHandler?.emit();
		} else if (this._overlayIsOpened && !visibility) {
			this.hideHandler?.emit();
			this.markAsTouched();
			this.unsubscribeCustomScroll();
		}

		this.overlayIsOpened$.next(visibility);
		this.changeDetector.detectChanges();

		if (visibility && this.heightToPageBorders && this.type === DropdownType.default) {
			this.scrollService.setDropHeightToPageBorders({
				drop: this.listWrapper.nativeElement,
				input: this.triggerButton.nativeElement,
				position: this.position,
			});
		}

		// если оверлей с подсказками всплывает вверх, то скроллим список в нижнее положение
		if (this.position === SuggestPosition.above) {
			const ulElement = this.listContainer?.nativeElement;
			ulElement?.scrollTo(0, ulElement.scrollHeight);
		}

		const targetRect: DOMRect = (event?.currentTarget as HTMLElement)?.getBoundingClientRect();

		if (targetRect && this.positioningRelativeTrigger) {
			this.positionRelativeTrigger(targetRect);
		}

		if (targetRect && this.customScroll) {
			this.unsubscribeCustomScroll();
			this.subscribeCustomScroll(targetRect);
		}

		if (this.classWhenOpen) {
			if (visibility) {
				this.renderer.addClass(this.elementRef.nativeElement, this.classWhenOpen);
			} else {
				this.renderer.removeClass(this.elementRef.nativeElement, this.classWhenOpen);
			}
		}

		if (this.toggleButton) {
			this.triggerButtonVisibility = !visibility;
			this.changeDetector.detectChanges();
		}
	}

	positionRelativeTrigger(triggerRect: DOMRect) {
		const listItems = this.listItems?.toArray?.()?.map(li => li.nativeElement);
		const selectedListItem = listItems?.find(li => Boolean(li.dataset.selected));

		if (!triggerRect || !selectedListItem) return;

		const selectedItemRect: DOMRect = selectedListItem.getBoundingClientRect();
		const transformValue = triggerRect.top - selectedItemRect.top;

		this.renderer.setStyle(this.elementRef.nativeElement, '--drop-flows-inner-scroll', `${transformValue}px`, RendererStyleFlags2.DashCase);
	}

	toggleOverlay(event: MouseEvent) {
		if (this.loader || this.isDisabled) {
			return;
		}

		if (this.customTogglingChecking?.(this)) {
			this.customToggling.emit(this);
			return;
		}

		this.changeOverlayVisibility(!this._overlayIsOpened, event);
	}

	selectOption(option: any) {
		if (option?.isDisabledDropElement) return;

		this.onChange(option);
		this.fieldValue$.next(option);

		this.changeOverlayVisibility(false);
		this.selectHandler?.emit(option);
	}

	documentListener = (event) => {
		const isBeyondBorders = !this.elementRef?.nativeElement?.contains(event.target as HTMLElement);

		if (isBeyondBorders) {
			this.changeOverlayVisibility(false);
		}
	};

	trackBySuggests = (index, option) => {
		return this.uniqueElementPropFn(option);
	};

	// публичные методы также предназачены для вызовов из родителя через ref:

	public hideOverlay() {
		this.changeOverlayVisibility(false);
	}

	public showSuggest() {
		this.changeOverlayVisibility(true);
	}

	public updateSuggestions(list: any[]) {
		this.suggestions = [...list];
	}

	public resetSelectedValue() {
		this.fieldValue$.next(null);
		this.onChange(null);
		this.changeDetector.detectChanges();
	}

	public get isClientEnvironment(): boolean {
		return Boolean(this.sharedService?.environment?.project) &&
			this.sharedService.environment.project === Project.client;
	}

	/** Обработчик начала анимации оверлея */
	public overlayAnimationStart(event: AnimationEvent) {
		const isInitAnimationStart = event.fromState === 'void' && event.phaseName === 'start';

		if (isInitAnimationStart && this.overlayClassInOpenState) {
			const parentOverlayContent = this.getParentOverlay()?.overlayContent?.nativeElement;
			const parentOverlayInnerContent = this.getParentOverlay()?.contentInner;

			if (parentOverlayContent) {
				this.renderer.addClass(parentOverlayContent, this.overlayClassInOpenState);
			}

			if (parentOverlayInnerContent) {
				this.renderer.addClass(parentOverlayInnerContent, this.overlayClassInOpenState);
			}
		}
	}

	/** Обработчик завершения анимации оверлея */
	public overlayAnimationDone(event: AnimationEvent) {
		const isDestroyAnimationEnd = event.toState === 'void' && event.phaseName === 'done';

		if (isDestroyAnimationEnd && this.overlayClassInOpenState) {
			const dropdownWithSameClassAlreadyOpened = this.overlayService._dropdownOverlays.some(
				overlay => overlay instanceof DropdownComponent &&
					overlay.overlayClassInOpenState &&
					overlay.overlayClassInOpenState === this.overlayClassInOpenState
			);

			if (!dropdownWithSameClassAlreadyOpened) {
				const parentOverlayContent = this.getParentOverlay()?.overlayContent?.nativeElement;
				const parentOverlayInnerContent = this.getParentOverlay()?.contentInner;

				if (parentOverlayContent) {
					this.renderer.removeClass(parentOverlayContent, this.overlayClassInOpenState);
				}

				if (parentOverlayInnerContent) {
					this.renderer.removeClass(parentOverlayInnerContent, this.overlayClassInOpenState);
				}
			}
		}
	}

	get isCustom(): boolean {
		return this.type === DropdownType.custom;
	}

	getTriggerElement() {
		return this.triggerButton?.nativeElement;
	}

	getDropdownOverlay() {
		return this.listWrapper?.nativeElement;
	}

}
