import {
	Directive,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	Renderer2,
	RendererStyleFlags2,
	SimpleChanges,
} from '@angular/core';
import { SkeletonAdaptiveConfig, SkeletonStyle, SkeletonProperties, StylesOfSkeletonProperties } from './models/SkeletonStyles';
import { Viewport, DeviceService, ClientViewport, clientSizes, sizes } from '../../../services/device.service';

@Directive({
	selector: '[sharedSkeleton]'
})
export class SkeletonDirective implements OnChanges {

	defaultStyles: Partial<SkeletonStyle> = {
		w: '100%',
		h: '100%',
		brs: '18px',
	};

	/** конфиг для адаптивных css свойств */
	@Input() styleConfig: SkeletonAdaptiveConfig | null = null;
	/**
	 * Брейкпоинты для styleConfig
	 * По умолчанию используются брейкпоинты текущего проекта (deviceService.targetViewports)
	 */
	@Input() breakpoints: { [key in string]: number } | null = null;

	@HostBinding('class') class = 'skeleton';

	constructor(
		private elementRef: ElementRef,
		private deviceService: DeviceService,
		private renderer: Renderer2,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.styleConfig.previousValue !== changes.styleConfig.currentValue) {
			this.updateStyleConfig();
			this.setProperties();
		}
	}

	getPointsData(): {
		propertiesStyles: SkeletonProperties,
		properties: StylesOfSkeletonProperties,
		} {
		if (!Object.keys(this.styleConfig).length) {
			console.error('sharedSkeleton: Необходимо указать конфиг для стилизации компонента Skeleton');
			return null;
		}

		const breakpoints = this.breakpoints || this.deviceService.targetViewports;

		const propertiesStyles: SkeletonProperties = Object.keys(breakpoints).reduce((accum, point: Viewport | ClientViewport): SkeletonProperties => {
			const styles: Partial<SkeletonStyle> = this.styleConfig?.[point] || {...this.defaultStyles};

			if (!styles?.w) styles.w = styles?.size || this.defaultStyles?.w;
			if (!styles?.h) styles.h = styles?.size || this.defaultStyles?.h;
			delete styles?.size;

			accum[point] = {
				...this.defaultStyles,
				...styles,
			};

			return accum;
		}, {});

		const properties = Object.entries(propertiesStyles).reduce((accum, [point, styles]) => {
			accum[point] = Object.entries(styles).reduce((stylesAccum, [key, value]) => {
				stylesAccum[`--${point}-skeleton-${key}`] = value;
				return stylesAccum;
			}, {});
			return accum;
		}, {});

		return {
			propertiesStyles,
			properties,
		};
	}

	setProperties() {
		const { properties } = this.getPointsData();

		Object.values(properties).forEach(values => {
			Object.entries(values).forEach(([key, value]) => {
				this.renderer.setStyle(this.elementRef.nativeElement, key, value, RendererStyleFlags2.DashCase);
			});
		});
	}

	private updateStyleConfig() {
		if (!this.styleConfig) return;

		const { targetSizes } = this.deviceService;
		const ports = this.breakpoints ? getPortsFromBreakpoints(this.breakpoints) : Object.keys(targetSizes).sort((a, b) => targetSizes[a] - targetSizes[b]);

		const resultStyleConfig: SkeletonAdaptiveConfig = {};
		let previousPointConfig = null;

		ports.forEach((viewPort) => {
			const currentPointConfig = this.styleConfig[viewPort] ? {...previousPointConfig, ...this.styleConfig[viewPort]} : previousPointConfig;
			resultStyleConfig[viewPort] = currentPointConfig;
			previousPointConfig = currentPointConfig;
		});
		this.styleConfig = resultStyleConfig;

		function getPortsFromBreakpoints(breakpoints: { [key in string]: number }) {
			return Object.keys(breakpoints).every((point) => !!clientSizes[point])
				? Object.keys(breakpoints).sort((a, b) => clientSizes[a] - clientSizes[b])
				: Object.keys(breakpoints).sort((a, b) => sizes[a] - sizes[b]);
		}
	}

}
