import { Currency } from './currency';

type CurrencySymbols = { [isoCode in Currency ]?: string };

/**
 * { ISO-код валюты: символ валюты }
 *
 * Исходник: https://github.com/smirzaei/currency-formatter/blob/master/currencies.json
 * В нашей версии местами отличаются символы валюты
 */
export const currencySymbols: CurrencySymbols = {
	[Currency.AED]: '.د.إ',
	[Currency.AFN]: '؋',
	[Currency.ALL]: 'L',
	[Currency.AMD]: 'դր.',
	[Currency.ANG]: 'ƒ',
	[Currency.AOA]: 'Kz',
	[Currency.ARS]: '$',
	[Currency.AUD]: '$',
	[Currency.AWG]: 'ƒ',
	[Currency.AZN]: '₼',
	[Currency.BAM]: 'KM',
	[Currency.BBD]: '$',
	[Currency.BDT]: '৳',
	[Currency.BGN]: 'лв',
	[Currency.BHD]: '.د.ب',
	[Currency.BIF]: 'FBu',
	[Currency.BMD]: '$',
	[Currency.BND]: '$',
	[Currency.BOB]: 'Bs.',
	[Currency.BOV]: 'Bov',
	[Currency.BRL]: 'R$',
	[Currency.BSD]: '$',
	[Currency.BTN]: 'Nu.',
	[Currency.BWP]: 'P',
	[Currency.BYN]: 'p.',
	[Currency.BYR]: 'p.',
	[Currency.BZD]: 'BZ$',
	[Currency.CAD]: '$',
	[Currency.CDF]: 'FC',
	[Currency.CHE]: 'CHE',
	[Currency.CHF]: '₣',
	[Currency.CHW]: 'CHW',
	[Currency.CLF]: 'UF',
	[Currency.CLP]: '$',
	[Currency.CNY]: '¥',
	[Currency.COP]: '$',
	[Currency.COU]: 'COU',
	[Currency.CRC]: '₡',
	[Currency.CUC]: 'CUC',
	[Currency.CUP]: '$MN',
	[Currency.CVE]: 'esc',
	[Currency.CZK]: 'Kč',
	[Currency.DJF]: 'Fdj',
	[Currency.DKK]: 'kr',
	[Currency.DOP]: 'RD$',
	[Currency.DZD]: '.د.ج',
	[Currency.EEK]: 'kr',
	[Currency.EGP]: '£',
	[Currency.ERN]: 'Nkf',
	[Currency.ETB]: 'Br',
	[Currency.EUR]: '€',
	[Currency.FJD]: '$',
	[Currency.FKP]: '£',
	[Currency.GBP]: '£',
	[Currency.GEL]: 'GEL',
	[Currency.GGP]: '£',
	[Currency.GHC]: '¢',
	[Currency.GHS]: 'GH₵',
	[Currency.GIP]: '£',
	[Currency.GMD]: 'D',
	[Currency.GNF]: 'GFr',
	[Currency.GTQ]: 'Q',
	[Currency.GYD]: '$',
	[Currency.HKD]: '$',
	[Currency.HNL]: 'L',
	[Currency.HRK]: 'kn',
	[Currency.HTG]: 'G',
	[Currency.HUF]: 'Ft',
	[Currency.IDR]: 'Rp',
	[Currency.ILS]: '₪',
	[Currency.IMP]: '£',
	[Currency.INR]: '₹',
	[Currency.IQD]: '.د.ع',
	[Currency.IRR]: '﷼',
	[Currency.ISK]: 'kr',
	[Currency.JEP]: '£',
	[Currency.JMD]: 'J$',
	[Currency.JOD]: '.د.إ',
	[Currency.JPY]: '¥',
	[Currency.KES]: 'KSh',
	[Currency.KGS]: 'сом',
	[Currency.KHR]: '៛',
	[Currency.KMF]: 'CF',
	[Currency.KPW]: '₩',
	[Currency.KRW]: '₩',
	[Currency.KWD]: '.د.ك',
	[Currency.KYD]: '$',
	[Currency.KZT]: '₸',
	[Currency.LAK]: '₭',
	[Currency.LBP]: '£',
	[Currency.LKR]: '₨',
	[Currency.LRD]: '$',
	[Currency.LSL]: 'LSL',
	[Currency.LTL]: 'Lt',
	[Currency.LVL]: 'Ls',
	[Currency.LYD]: '.د.ل',
	[Currency.MAD]: '.د.م',
	[Currency.MDL]: 'lei',
	[Currency.MGA]: 'Ar',
	[Currency.MKD]: 'ден',
	[Currency.MMK]: 'K',
	[Currency.MNT]: '₮',
	[Currency.MOP]: 'MOP$',
	[Currency.MRO]: 'ouguiya',
	[Currency.MUR]: '₨',
	[Currency.MVR]: 'MVR',
	[Currency.MWK]: 'MK',
	[Currency.MXN]: '$',
	[Currency.MXV]: 'UDI',
	[Currency.MYR]: 'RM',
	[Currency.MZN]: 'MT',
	[Currency.NAD]: '$',
	[Currency.NGN]: '₦',
	[Currency.NIO]: 'C$',
	[Currency.NOK]: 'kr',
	[Currency.NPR]: '₨',
	[Currency.NZD]: '$',
	[Currency.OMR]: '﷼',
	[Currency.PAB]: 'B/.',
	[Currency.PEN]: 'S/',
	[Currency.PGK]: 'K',
	[Currency.PHP]: '₱',
	[Currency.PKR]: '₨',
	[Currency.PLN]: 'zł',
	[Currency.PYG]: 'Gs',
	[Currency.QAR]: '﷼',
	[Currency.RON]: 'lei',
	[Currency.RSD]: 'Дин.',
	[Currency.RUB]: '₽',
	[Currency.RUR]: '₽',
	[Currency.RWF]: 'R₣',
	[Currency.SAR]: '﷼',
	[Currency.SBD]: '$',
	[Currency.SCR]: '₨',
	[Currency.SDG]: 'SDG',
	[Currency.SEK]: 'kr',
	[Currency.SGD]: '$',
	[Currency.SHP]: '£',
	[Currency.SLL]: 'Le',
	[Currency.SOS]: 'S',
	[Currency.SRD]: '$',
	[Currency.SSP]: 'SS£',
	[Currency.STD]: 'Db',
	[Currency.SVC]: '$',
	[Currency.SYP]: '£',
	[Currency.SZL]: 'L',
	[Currency.THB]: '฿',
	[Currency.TJS]: 'SM',
	[Currency.TMT]: 'T',
	[Currency.TND]: '.د.ت',
	[Currency.TOP]: 'T$',
	[Currency.TRL]: '₤',
	[Currency.TRY]: '₺',
	[Currency.TTD]: 'TT$',
	[Currency.TWD]: 'NT$',
	[Currency.TZS]: 'TSh',
	[Currency.UAH]: '₴',
	[Currency.UGX]: 'USh',
	[Currency.USD]: '$',
	[Currency.USN]: '$',
	[Currency.UYI]: '$U',
	[Currency.UYU]: '$U',
	[Currency.VEF]: 'Bs',
	[Currency.VES]: 'Bs',
	[Currency.VND]: '₫',
	[Currency.VUV]: 'VT',
	[Currency.WST]: 'WS$',
	[Currency.XAF]: 'FCFA',
	[Currency.XCD]: '$',
	[Currency.XDR]: 'SDR',
	[Currency.XOF]: 'CFA',
	[Currency.XPF]: '₣',
	[Currency.XSU]: 'XSU',
	[Currency.XUA]: 'XUA',
	[Currency.YER]: '﷼',
	[Currency.ZAR]: 'R',
	[Currency.ZMW]: 'K',
	[Currency.ZWD]: 'Z$',
	[Currency.ZWL]: 'Z$',
	[Currency.BTC]: '₿',
	[Currency.ETH]: 'Ξ',
	[Currency.LTC]: 'Ł',
};
