import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ArrowNavigationModule } from '../../directives/arrow-navigation/arrow-navigation.module';
import { KeyboardControlModule } from '../../directives/keyboard-control/keyboard-control.module';
import { ButtonModule } from '../button/button.module';
import { SvgModule } from '../svg/svg.module';
import { AutocompleteComponent } from './autocomplete.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
	declarations: [
		AutocompleteComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		SvgModule,
		ButtonModule,
		KeyboardControlModule,
		ArrowNavigationModule,
		NgxMaskModule.forRoot(),
	],
	exports: [
		AutocompleteComponent,
	],
})
export class AutocompleteModule {}
