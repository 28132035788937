import {
	ChangeDetectionStrategy,
	Component,
	ElementRef, Inject,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import Lottie, { AnimationItem } from 'lottie-web';
import {ConfigToken} from '../tokens/config.token';
import {ILottieConfig} from '../interfaces/config.interface';
import {DeviceService} from 'shared';

@Component({
	selector: '[b-ui-lottie]',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class LottieComponent implements OnChanges, OnDestroy {
	/** Provide JSON file name without extension (.json) */
	@Input() animation: string;
	@Input() loop: boolean = true;
	@Input() autoplay: boolean = true;


	lottie: AnimationItem;
	private deviceService = inject(DeviceService);

	constructor(
		private elementRef: ElementRef,
		@Inject(ConfigToken) private config: ILottieConfig,
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.animation) {
			this._loadAnimation();
		}
	}

	ngOnDestroy() {
		this._destroyAnimation();
	}

	private _destroyAnimation(): void {
		this.lottie?.destroy();
	}

	private _resolvePath(): string {
		if (!this.animation || !this.config.baseDir) {
			return;
		}

		return `${this.config.baseDir}${this.animation}.json`;
	}

	private _loadAnimation() {
		const path = this._resolvePath();

		if (!path) {
			return;
		}

		this._destroyAnimation();

		if(!this.deviceService.isServer) {
			this.lottie = Lottie.loadAnimation({
				container: this.elementRef.nativeElement,
				renderer: 'svg',
				loop: this.loop,
				autoplay: this.autoplay,
				path: path,
			});
		}
	}
}
