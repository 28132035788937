import {NgModule} from '@angular/core';
import {TextInputComponent} from './text-input.component';
import {CommonModule} from '@angular/common';
import {SvgModule} from '../svg/svg.module';
import {FormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';

@NgModule({
	declarations: [TextInputComponent],
	imports: [
		CommonModule,
		SvgModule,
		FormsModule,
		NgxMaskModule.forRoot()
	],
	exports: [TextInputComponent]
})
export class TextInputModule {}
