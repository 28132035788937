import { Component, ElementRef, HostBinding, Input, OnDestroy, afterNextRender } from '@angular/core';
import { DomEventsService } from '../../../services/dom-events/dom-events.service';
import { SharedService } from '../../../services/shared/shared.service';
import { Project } from '../../../services/shared/models/Project';

@Component({
	selector: '[b-shared-button]',
	templateUrl: './button.component.html',
})
export class ButtonComponent implements OnDestroy {
	@HostBinding('class') class = 'btn-component';

	/**
	 * Иконка в кнопке
	 *
	 */
	@Input() icon? = '';

	/**
	 * Расположение иконки справа
	 *
	 */
	@Input() iconRight? = false;

	/**
	 * Атрибут type
	 *
	 */
	@Input() type: string;

	/**
	 * Атрибут role
	 *
	 */
	@Input() role: string;

	/**
	 * Классы текста
	 *
	 */
	@Input() textClass: string;

	/**
	 * Классы иконки
	 *
	 */
	@Input() iconClass: string;

	/**
	 * Классы внутренней обертки
	 *
	 */
	@Input() btnInnerClass: string;

	/**
	 * Иконка загрузки
	 *
	 */
	@Input() iconLoader?: string;

	/**
	 * Классы иконки загрузки
	 *
	 */
	@Input() iconLoaderClass?: string;


	/**
	 * Путь до SVG-спрайта
	 *
	 */
	@Input() spritePath = 'sprite/sprite.svg';

	/**
	 * Флаг для отображения лоадера в кнопке
	 *
	 */
	@Input() loading: boolean = false;

	/**
	 * Идентификаторы клавиш, при нажатии на которые нужно сымитировать события click/pointerup.
	 * События имитируются только когда target-элемент (ElementRef) находится в фокусе (document.activeElement)
	 */
	@Input() activeElementKeys: string[] = this.isLink ? ['Space', ' '] : ['Enter', 'Space', ' '];

	/** симуляция проекта */
	@Input() projectSimulation: Project | keyof typeof Project | null = null;

	innerText: string;

	text: string;

	el: HTMLElement;

	constructor(
		public elementRef: ElementRef,
		private domEventsService: DomEventsService,
		private sharedService: SharedService,
	) {
		afterNextRender(() => {
			this.domEventsService.registerKeyboardControl(this);
		});
	}

	ngOnDestroy() {
		this.domEventsService?.removeKeyboardControl(this);
	}

	@HostBinding('attr.type') get typeAttr(): string {
		return this.type || (this.elementRef.nativeElement.nodeName?.toLowerCase() === 'button'
			? 'button'
			: null
		);
	}

	public get isClientEnvironment(): boolean {
		const project = this.projectSimulation || this.sharedService?.environment?.project;

		return Boolean(project) && project === Project.client;
	}

	get isLink(): boolean {
		return this.elementRef && this.elementRef.nativeElement.tagName?.toLowerCase() === 'a';
	}

}
