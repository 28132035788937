import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArrowNavigationDirective } from './arrow-navigation.directive';

@NgModule({
	declarations: [ArrowNavigationDirective],
	imports: [
		CommonModule,
	],
	exports: [ArrowNavigationDirective],
})
export class ArrowNavigationModule {}
