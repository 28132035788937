<div
	class="field {{wrapperClasses}}"
	[ngClass]="{
		'field--title': animatedTitle && (isFocused || !!value),
		'field--disabled': isFieldDisabled,
		'field--error': showError,
		'field--success': success
	}"
>
	<div
		*ngIf="animatedTitle && placeholderTemplate"
		class="field__title">
		<ng-container [ngTemplateOutlet]="placeholderTemplate"></ng-container>
	</div>

	<ng-container *ngIf="beforeContentTemplate && !(hideTemplatesWhenDisabled && isFieldDisabled)">
		<ng-container [ngTemplateOutlet]="beforeContentTemplate"></ng-container>
	</ng-container>

	<input
		[placeholder]="animatedTitle ? '' : placeholder"
		(focus)="focusHandler($event)"
		(blur)="blurHandler($event)"
		[value]="value"
		[disabled]="isFieldDisabled"
		[(ngModel)]="value"
		[mask]="mask"
		[prefix]="prefix"
		[suffix]="suffix"
		[showMaskTyped]="showMaskTyped"
		[clearIfNotMatch]="clearIfNotMatch"
		[thousandSeparator]="thousandSeparator"
		[hiddenInput]="hiddenInput"
		[dropSpecialCharacters]="dropSpecialCharacters"
		[validation]="validation"
		(maskFilled)="maskFilled.emit()"
		[decimalMarker]="decimalMarker"
		(ngModelChange)="changeHandler($event)"
		type="text"
		class="field__control"
		#inputElement
		[readonly]="readonly"
		[attr.autofocus]="autofocus || null"
	>

	<div
		class="field__addon field__addon--icon layered-content d-grid"
	>
		<svg
			*ngIf="isLoading"
			b-shared-svg
			[name]="iconLoaderName"
			class="layered-content__item icon rotating"
			[ngClass]="iconLoaderClasses"
		>
		</svg>

		<svg
			*ngIf="isFieldDisabled && showDisabledIcon"
			b-shared-svg
			[name]="iconDisabledName"
			class="layered-content__item icon"
			[ngClass]="iconClasses"
		></svg>

		<ng-container *ngIf="afterContentTemplate && !(hideTemplatesWhenDisabled && isFieldDisabled)">
			<ng-container [ngTemplateOutlet]="afterContentTemplate"></ng-container>
		</ng-container>
	</div>
</div>
