import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DownloadDirective } from './download.directive';

@NgModule({
	declarations: [DownloadDirective],
	imports: [
		CommonModule,
	],
	exports: [DownloadDirective],
})
export class DownloadModule {}
