import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EventChannelService} from '../modules/event-channel/event-channel.service';
import {inject} from '@angular/core';

/**
	* Базовый сервис, который предоставляет:
	* state и методы работы с ним
	* HttpClient для http-запросов
	* EventChannel шину событий для общения сервисов
	*/
export class BaseService<T> {
	private state$: BehaviorSubject<T>;

	protected http = inject(HttpClient);
	protected eventChannel = inject(EventChannelService);

	constructor(initialState: T) {
		this.state$ = new BehaviorSubject<T>(initialState);
	}

	protected get state(): T {
		return this.state$.getValue();
	}

	protected select<K>(mapFn: (state: T) => K): Observable<K> {
		return this.state$.asObservable().pipe(
			map((state: T) => mapFn(state)),
			distinctUntilChanged(),
		);
	}

	protected setState(newState: Partial<T>) {
		this.state$.next({
			...this.state,
			...newState,
		});
	}
}
