import {
	Directive,
	HostListener,
	Input,
} from '@angular/core';
import { FileService } from '../../../services';

@Directive({
	selector: '[sharedDownload]'
})
export class DownloadDirective {

	/**
	 * Файл для скачивания: ArrayBuffer, Blob или URL к файлу
	 *
	 * @example
	 * [loadbleFile]="ArrayBuffer | Blob"
	 * loadbleFile="/podarok-za-otzyv.pdf"
	 */
	@Input() loadbleFile: ArrayBuffer | Blob | string | null = null;

	/**
	 * mime type файла для скачивания. Используется, если в loadbleFile передан ArrayBuffer
	 */
	@Input() mime: string | null = 'application/pdf';

	/**
	 * Название файла для скачивания
	 */
	@Input() fileName: string = 'document.pdf';

	@HostListener('pointerup', []) pointerupHandler() {
		if (!this.loadbleFile || !this.fileName) return;

		this.fileService.downloadFile({
			loadableFile: this.loadbleFile,
			fileName: this.fileName,
			mime: this.mime,
		});
	}

	constructor(
		private fileService: FileService,
	) { }

}
