import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LottieComponent} from './components/lottie.component';
import {ILottieConfig} from './interfaces/config.interface';
import {ConfigToken} from './tokens/config.token';

@NgModule({
	declarations: [
		LottieComponent,
	],
	imports: [
		CommonModule,
	],
	exports: [
		LottieComponent,
	],
})
export class LottieModule {
	static forRoot(config: ILottieConfig = {baseDir: ''}): ModuleWithProviders<LottieModule> {
		return {
			ngModule: LottieModule,
			providers: [
				{
					provide: ConfigToken,
					useValue: config,
				},
			],
		};
	}
}
