import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[sharedTemplate]'
})
export class SharedTemplateDirective {

	@Input('sharedTemplate') name: string | null = null;
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('templatesGroup') group: string | null = null;
	@Input() disabled: boolean = false;

	constructor(public template: TemplateRef<any>) {}

	public getName(): string | null {
		return this.name;
	}

	public getGroup(): string | null {
		return this.group;
	}

}
