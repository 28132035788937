export enum MultiselectMode {
	checkbox = 'checkbox', // одна группа чекбоксов, без группировок, без радиобаттонов
	radio = 'radio', // одна группа радиобаттонов, без группировок, без чекбоксов
	grouped = 'grouped', // неограниченное количество сгруппированных чекбоксов и радиобаттонов
}

export enum MultiselectGroupType {
	checkbox = 'checkbox',
	radio = 'radio',
}

export interface MultiselectItem {
	id: string;
	label: string;
	checked?: boolean;
}

export type MultiselectItemsList = MultiselectItem[] & {
	// checkbox:
	selectedItems?: MultiselectItem[];
	selectedIds?: string[];

	// radiobutton:
	selectedRadio?: MultiselectItem;
	selectedRadioId?: string;
}

export interface MultiselectGroup {
	type: MultiselectGroupType;
	id: string;
	items: MultiselectItemsList;
	label?: string;
}

export enum MultiselectValueViewMode {
	list = 'list',
	chips = 'chips',
	count = 'count',
	placeholder = 'placeholder',
}

export enum MultiselectOverlayPosition {
	auto = 'auto', // слой будет всплывать туда, где больше места в видимой области окна браузера
	above = 'above', // вверх
	under = 'under', // вниз
}
