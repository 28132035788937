import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
	selector: '[sharedFocusDetect]'
})
export class FocusDetectDirective implements OnInit, OnDestroy {

	@Input('sharedFocusDetect') element: ElementRef;

	@Output() focusDetector: EventEmitter<boolean> = new EventEmitter<boolean>();

	subscribtions = new Subscription();

	constructor(
		private elementRef: ElementRef,
	) {}

	ngOnInit() {
		const elementRef = this.element || this.elementRef;

		this.subscribtions.add(
			fromEvent(elementRef.nativeElement, 'focus').subscribe(() => this.focusDetector.emit(true))
		);

		this.subscribtions.add(
			fromEvent(elementRef.nativeElement, 'blur').subscribe(() => this.focusDetector.emit(false))
		);
	}

	ngOnDestroy() {
		this.subscribtions?.unsubscribe();
	}

}
