import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { ClassNames } from './models/ClassNames';

/**
 * Директива, упрощающая установку атрибута class из объекта или массива имён CSS классов.
 * В отличии от ngClass, sharedClass имеет более широкую типизацию:
 *
 * @example
 * ```html
 * <div
 * 	[sharedClass]="[
 * 		'static classes check',
 * 		true && 'foo one',
 * 		false && 'not',
 * 		{ bar: true, biz: false, two: isTrue() },
 * 		[['baz', [['hello', { deep: true }], 'there']]]
 * 	]"
 * ></div>
 * ```
 * `Result:`
 * ```html
 * <div class="static classes check foo one bar two baz hello deep there"></div>
 * ```
 */
@Directive({
	selector: '[sharedClass]',
})
export class SharedClassDirective {

	/** CSS классы, которые уже были установлены директивой sharedClass */
	private readonly prevInstalledClasses = new Set<string>();

	private _sharedClass: ClassNames | null = null;
	@Input() get sharedClass(): ClassNames | null {
		return this._sharedClass;
	}
	set sharedClass(classes: ClassNames | null) {
		const classValue = this.convertClasses(classes);
		const classesList = classValue.split(' ');

		this.prevInstalledClasses.forEach(cssClass => {
			if (classesList.includes(cssClass)) return;
			this.renderer.removeClass(this.elementRef.nativeElement, cssClass);
		});

		if (!classValue) {
			return;
		}

		classesList.forEach(cssClass => {
			this.prevInstalledClasses.add(cssClass);
			this.renderer.addClass(this.elementRef.nativeElement, cssClass);
		});
	}

	constructor(
		private elementRef: ElementRef<HTMLElement>,
		private renderer: Renderer2,
	) {}

	convertClasses = (classes: ClassNames | null): string => {
		if (!classes) return '';
		if (typeof classes === 'string' || typeof classes === 'number') return classes.toString();

		let result = '';

		if (Array.isArray(classes)) {
			for (let i = 0, tmp; i < classes.length; i++) {
				if ((tmp = this.convertClasses(classes[i])) !== '') {
					result += (result && ' ') + tmp;
				}
			}
		} else {
			for (let k in classes) {
				if (classes[k]) result += (result && ' ') + k;
			}
		}

		return result;
	};

}
