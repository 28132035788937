import {Currency} from './currency';

export type CurrencyTranslate = { [key in Currency]?: string}

export const currencyTranslate: CurrencyTranslate = {
	[Currency.CHF]: 'Швейцарский франк',
	[Currency.CNY]: 'Юань',
	[Currency.EUR]: 'Евро',
	[Currency.GBP]: 'Фунт стерлингов',
	[Currency.RUB]: 'Российский рубль',
	[Currency.USD]: 'Доллар США',
};
