export const validLengthInn = ({
	lengthOfFiveIsAvailable = false,
	inn,
}: {
	lengthOfFiveIsAvailable?: boolean;
	inn: string;
}) => {
	const validLengths = [10, 12];
	if (lengthOfFiveIsAvailable) {
		validLengths.push(5);
	}
	return validLengths.includes(inn?.length);
};

export const innOnlyZeroes = (inn: string) => (inn.length === 5 ? !/^0+$/.test(inn) : true);

export const innTwoFirstCharactersIsZeroes = (inn: string) =>
	inn.length === 10 || inn.length === 12 ? !/^00.*$/.test(inn) : true;

export const validInn = (value: string) => {
	const valueLength = value?.toString()?.length;

	const isCorrectInn = (inn) => {
		const factor1 = [2, 4, 10, 3, 5, 9, 4, 6, 8];
		const factor2 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
		const factor3 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

		let sum = 0;
		let sum2 = 0;
		let result = false;
		let d;

		if (inn.length === 0) {
			result = true;
		} else if (inn.length === 10) {
			//юр. лицо
			sum = 0;
			for (let i = 0; i <= 8; i++) {
				d = inn.slice(i, i + 1);
				sum += d * factor1[i];
			}
			sum = sum % 11;
			sum = sum % 10;
			result = inn.slice(9, 10) * 1 === sum;
		} else if (inn.length === 12) {
			//физ. лицо и ИП
			sum = 0;
			for (let i = 0; i <= 9; i++) {
				d = inn.slice(i, i + 1);
				sum += d * factor2[i];
			}

			sum = sum % 11;
			sum = sum % 10;
			sum2 = 0;

			for (let i = 0; i <= 10; i++) {
				d = inn.slice(i, i + 1);
				sum2 += d * factor3[i];
			}
			sum2 = sum2 % 11;
			sum2 = sum2 % 10;
			result = inn.slice(10, 11) * 1 === sum && inn.slice(11, 12) * 1 === sum2;
		}

		return result;
	};

	return valueLength && isCorrectInn(value);
};
