<input
	#rb
	class="radio__input"
	type="radio"
	[checked]="checked"
	[attr.checked]="checked"
	[attr.name]="name"
	[disabled]="disabled"
	(change)="onChange($event)"
>
<span class="radio__label" [ngClass]="labelClass">
	<ng-content></ng-content>
</span>
