/** Enums фич для модуля регистрации */
export enum RegistrationFeatures {
	/** Возможность выбора ОПФ (ИП / ООО) при регистрации */
	OpenAccountsInBeginningOfRegistration = 'OpenAccountsInBeginningOfRegistration',
	/** Возможность добавления сотрудников, в компанию и использовать совместный доступ */
	RoleModelFeature = 'RoleModelFeature',
}

export enum SelfEmployerFeatures {
	SelfEmployedPartnerRegistersFeature= 'SelfEmployedPartnerRegistersFeature'
}

/** Enums фич для Веб ЛК */
export enum ClientFeatures {
	/** Отображение пункта меню Интеграции в профиле */
	IntegrationsShowcase = 'IntegrationsShowcase',
}

export enum SalesBaseFeature {
	SalesBaseFeature = ''
}

export enum CallbackToClientFeature {
	/** Отображение пункта меню Обратный звонок */
	CallbackToClientFeature = 'CallbackToClientFeature'
}
