import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextareaComponent } from './textarea.component';
import { ChipsModule } from '../chips/chips.module';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [
		TextareaComponent,
	],
	imports: [
		CommonModule,
		ChipsModule,
		FormsModule,
	],
	exports: [
		TextareaComponent,
	],
})
export class TextareaModule {}
