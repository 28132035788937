import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { SvgComponent } from './svg.component';

@NgModule({
	declarations: [SvgComponent],
	imports: [CommonModule],
	exports: [SvgComponent],
	schemas: [NO_ERRORS_SCHEMA],
})
export class SvgModule {}
