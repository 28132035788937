export const urlRegexp = {
	/** Проверка на прод */
	prod: /blanc\.ru$/,
	/** Проверка на стейдж */
	stage: /vestabankdev\.ru$/,
	/** Имя стейджа */
	stageName: /release|dev-\d+/,
	/** Проверка на начало адреса на наличие стейджового имени */
	urlStartWithStageName: /^(release|dev-\d+)/
};
