import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SeoMetaResolver {

	private _metas = [
		{name: 'description', selector: 'id=documentDescription', metaKey: 'name'},
		{name: 'keywords', selector: 'id=documentKeywords', metaKey: 'name'},
		{name: 'viewport', selector: 'name=viewport', metaKey: 'name'},
		{name: 'ogTitle', selector: 'property="og:title"', metaKey: 'property', metaName: 'og:title'},
		{name: 'ogDescription', selector: 'property="og:description"', metaKey: 'property', metaName: 'og:description'},
		{name: 'ogImage', selector: 'property="og:image"', metaKey: 'property', metaName: 'og:image'},
		{name: 'ogAlt', selector: 'property="og:image:alt"', metaKey: 'property', metaName: 'og:image:alt'},
		{name: 'ogUrl', selector: 'property="og:url"', metaKey: 'property', metaName: 'og:url'},
	];

	constructor(
		private titleService: Title,
		private meta: Meta,
	) {
	}

	resolve(route: ActivatedRouteSnapshot) {
		Object.keys(route.data).forEach((key) => {
			if (key === 'title') {
				this.titleService.setTitle(route.data[key]);
				return;
			}

			const element = this._metas.find((meta) => meta.name === key);

			if (!element) return;
			const metaTag = this.meta.getTag(element.selector);

			const metaContent = {
				[element.metaKey]: element.metaName || element.name,
				content: route.data[element.name]
			};

			if (metaTag) {
				this.meta.updateTag(metaContent, element.selector);
			} else {
				this.meta.addTag(metaContent, false);
			}
		});

		return of(route.data);
	}

}
