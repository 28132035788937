import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bankAccount',
})
export class BankAccountPipe implements PipeTransform {
	transform(rawNum:string) {
		return rawNum?.length === 20 ?
			`${rawNum.slice(0,5)} ${rawNum.slice(5,8)} ${rawNum.slice(8,9)} ${rawNum.slice(9,13)} ${rawNum.slice(13,20)}`
			: rawNum;
	}
}
