import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsComponent } from './chips.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardControlModule } from '../../directives/keyboard-control/keyboard-control.module';

@NgModule({
	declarations: [
		ChipsComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		KeyboardControlModule,
	],
	exports: [
		ChipsComponent,
	],
})
export class ChipsModule {}
