/** Цифры */
export const numberRegexp = {
	/** наличие цифры */
	atLeastOneNumber: /\d/,
};

/** Спец. символы */
export const specialSymbols = {
	/** Наличие любого спец. символа */
	anySymbols: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~±№œ∑´®¥¨ˆøπ“‘«æ…¬˚∆˙©ƒ₽∂ßå°≠™≈ç√∫˜µ≤≥÷]/,
	/** Отсутствие пробелов */
	withoutSpaces: /^\S*$/,
};

/** Латиница и кирллица */
export const latinAndCyrillic = {
	/** наличие латинской или русской строчной буквы */
	atLeastOneLowercase: /[а-яёa-z]/,
	/** наличие латинской или русской заглавной буквы */
	atLeastOneUppercase: /[А-ЯЁA-Z]/,
	/** наличие цифры и латинской или русской строчной буквы */
	lowercaseAndNumber: /^(?=.*[а-яёa-z])(?=.*[0-9])/,
	/** наличие цифры и латинской или русской заглавной буквы */
	uppercaseAndNumber: /^(?=.*[А-ЯЁA-Z])(?=.*[0-9])/,
	/** наличие латинской или русской строчной буквы и латинской или русской заглавной буквы */
	anyRegister: /^(?=.*[А-ЯЁA-Z])(?=.*[а-яёa-z])/,
	/** наличие цифры, латинской или русской строчной буквы и латинской или русской заглавной буквы */
	anyRegisterAndNumber: /^(?=.*[А-ЯЁA-Z])(?=.*[а-яёa-z])(?=.*[0-9])/,
	/** отсутствие цифр и латинских/кириллических символов в любом регистре */
	lackNumbersAndAnyCaseSymbols: /^[^A-Za-zА-ЯЁа-яё0-9]*$/
};

/** Латиница */
export const latin = {
	/** наличие латинской строчной буквы */
	atLeastOneLowercase: /[a-z]/,
	/** наличие латинской заглавной буквы */
	atLeastOneUppercase: /[A-Z]/,
	/** наличие цифры и латинской строчной буквы */
	lowercaseAndNumber: /^(?=.*[a-z])(?=.*[0-9])/,
	/** наличие цифры и латинской заглавной буквы */
	uppercaseAndNumber: /^(?=.*[A-Z])(?=.*[0-9])/,
	/** наличие латинской строчной и заглавной буквы */
	anyRegister: /^(?=.*[A-Z])(?=.*[a-z])/,
	/** наличие цифры, латинской строчной и заглавной буквы */
	anyRegisterAndNumber: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/,
	/** наличие латинской строчной/заглавной или цифр */
	anySymbols: /[a-zA-Z0-9]/,
	/** наличие одного слова из латинских букв + цифр */
	onlyCyrillicAndNumber: /^[a-zA-Z0-9]+$/,
	/** наличие латинской заглавной или строчной буквы */
	onlyLatin: /[a-zA-Z]/

};

/** Кириллица */
export const cyrillic = {
	/** наличие русской строчной буквы */
	atLeastOneLowercase: /[а-яё]/,
	/** наличие русской заглавной буквы */
	atLeastOneUppercase: /[А-ЯЁ]/,
	/** наличие цифры и русской строчной буквы */
	lowercaseAndNumber: /^(?=.*[а-яё])(?=.*[0-9])/,
	/** наличие цифры и русской заглавной буквы */
	uppercaseAndNumber: /^(?=.*[А-ЯЁ])(?=.*[0-9])/,
	/** наличие русской строчной и заглавной буквы */
	anyRegister: /^(?=.*[А-ЯЁ])(?=.*[а-яё])/,
	/** наличие цифры, русской строчной и заглавной буквы */
	anyRegisterAndNumber: /^(?=.*[А-ЯЁ])(?=.*[а-яё])(?=.*[0-9])/,
	/** наличие русской строчной/заглавной или цифр */
	anySymbols: /[а-яёА-ЯЁ0-9]/,
	/** наличие одного слова из русских букв + цифр */
	onlyCyrillicAndNumber: /^[а-яёА-ЯЁ0-9]+$/,
	/** наличие русской заглавной или строчной буквы */
	onlyCyrillic: /[а-яёА-ЯЁ]/,
	/** кириллица и пробелы */
	cyrillic: /^[а-яёА-ЯЁ\s]+$/,
	/** кириллица, цифры и пробелы */
	cyrillicAndNumbers: /^[а-яёА-ЯЁ0-9\s]+$/,
};
