import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmojiViewComponent } from './emoji-view.component';
import { EmojiComponent } from './emoji.component';

@NgModule({
	declarations: [EmojiViewComponent, EmojiComponent],
	imports: [CommonModule],
	exports: [EmojiComponent],
})
export class EmojiModule {}
