import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RadioButtonComponent } from './radiobutton.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [RadioButtonComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	exports: [RadioButtonComponent],
})
export class RadioButtonModule {}
