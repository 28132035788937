export const getPhoneWithMask = (onlyNumbers) => {
	if (onlyNumbers.length === 0) {
		return onlyNumbers;
	}

	let phoneNumber = onlyNumbers;
	if (onlyNumbers.length > 0) {
		phoneNumber = `(${onlyNumbers.substring(0, 3)}`;
	}
	if (onlyNumbers.length > 3) {
		phoneNumber = `${phoneNumber}) ${onlyNumbers.substring(3, 6)}`;
	}
	if (onlyNumbers.length > 6) {
		phoneNumber = `${phoneNumber}-${onlyNumbers.substring(6, 8)}`;
	}
	if (onlyNumbers.length > 8) {
		phoneNumber = `${phoneNumber}-${onlyNumbers.substring(8, 10)}`;
	}
	return phoneNumber;
};
