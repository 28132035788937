export const lengthRegexp = {
	/** 1+ символов */
	minOf1: /^.{1,}/,
	/** 0-5 символов */
	from0To5: /^.{0,5}$/,
	/** 6-9 символов */
	from6To9: /^.{6,9}$/,
	/** 6+ символов */
	minOf6: /^.{6,}$/,
	/** 10+ символов */
	minOf10: /^.{10,}$/,
};

export const stringLengthRegexp = (start, end: string | number = ''): RegExp => new RegExp(`^.{${start},${end}}$`); // Пример / ^.{0,10}$ /
