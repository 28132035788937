import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedTemplateDirective, SharedTemplateModule } from '../../directives';
import { ButtonModule } from '../button/button.module';
import { TabsComponent } from './tabs.component';

@NgModule({
	declarations: [TabsComponent],
	imports: [
		CommonModule,
		ButtonModule,
		SharedTemplateModule,
	],
	exports: [
		TabsComponent,
		SharedTemplateDirective,
	],
})
export class TabsModule {}
