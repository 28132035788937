import { FlattenArrayType } from '../../utils/ts-utils/arrays.utils';
import {RegistrationFeatures, SelfEmployerFeatures, ClientFeatures,SalesBaseFeature, CallbackToClientFeature} from './features.enums';

/** Enums фич */
export const FEATURES = {
	...RegistrationFeatures,
	...SelfEmployerFeatures,
	...ClientFeatures,
	...SalesBaseFeature,
	...CallbackToClientFeature,
};

/** Список фич */
export const FEATURES_LIST = Object.values(FEATURES);
export type Feature = FlattenArrayType<typeof FEATURES_LIST>
export type FeaturesState = Partial<Record<Feature, boolean>>;

/** Опции для метода опеределения активности определённой фичи */
export interface FeatureActivityOptions {
	/**
	 * Флаг для использования кэша
	 * Если true - запрос не будет отправлен повторно
	 * Если false - запрос будет отправляться каждый раз
	 */
	cache: boolean;
	/**
	 * Флаг для вкл/выкл проверки состояния фичи только в кэше (без http запроса)
	 * Если true - запрос не будет отправлен
	 * Если false - запрос будет отправлен (при необходимости)
	 */
	checkOnlyCache: boolean;
}
