import {DOCUMENT} from '@angular/common';
import {
	AfterContentChecked,
	AfterViewInit,
	Directive,
	ElementRef,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	Renderer2,
	TemplateRef,
	ViewContainerRef,
	afterNextRender
} from '@angular/core';
import {BehaviorSubject, filter, fromEvent, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {ScrollService} from '../../../services/scroll.service';
import {DeviceService} from '../../../services/device.service';
import {
	BreakpointPosition,
	TooltipHideTrigger,
	TooltipHideTriggers,
	TooltipHorizontalPosition,
	TooltipShowTrigger,
	TooltipShowTriggers,
	TooltipVerticalPosition
} from './tooltip.types';
import {DomEventsService} from '../../../services/dom-events/dom-events.service';
import {WINDOW} from '../../../tokens';


@Directive({
	selector: '[sharedTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy, AfterContentChecked {
	@HostBinding('class') class = 'tooltip';
	@HostBinding('attr.tabindex') tabIndex = '0';

	// флаг для жёсткого управления видимостью тултипа
	// если свойство указано, оно будет иметь приоритет над событиями, указанными в showTrigger/hideTrigger.
	// т.е. управление видимостью тултипа полностью передаётся под контроль данного флага
	_tooltipVisibility: boolean = false;
	@Input() set tooltipVisibility(value: boolean) {
		this.externalVisibilityControl = true;
		this._tooltipVisibility = Boolean(value);

		if (this._tooltipVisibility) {
			this.createTooltip();
		} else {
			this.removeTooltip();
		}
	}

	get tooltipVisibility(): boolean {
		return this._tooltipVisibility;
	}

	// ссылка на template для отображения сложной разметки внутри тултипа
	// tooltipText игнорируется при наличии tooltipTemplate
	@Input() tooltipTemplate: TemplateRef<any> = null;

	// отображаемый в тултипе текст
	// для отображения html разметки использовать tooltipTemplate
	@Input() tooltipText: string = null;

	// флаг наличия крестика для закрытия тултипа
	// @Input() closable: boolean = false;

	// задержка перед скрытием тултипа, когда сработал соответствующий триггер
	@Input() debounce: number = 100;

	// тип триггера для расскрытия тултипа
	// отдельно для мобильных touch устройств и для десктопов
	@Input() showTriggerMobile: TooltipShowTriggers = TooltipShowTrigger.pointerdown;
	@Input() showTriggerDesktop: TooltipShowTriggers = TooltipShowTrigger.mouseenter;

	// тип триггера для скрытия тултипа
	// отдельно для мобильных touch устройств и для десктопов
	@Input() hideTriggerMobile: TooltipHideTriggers = TooltipHideTrigger.pointerdown;
	@Input() hideTriggerDesktop: TooltipHideTriggers = TooltipHideTrigger.mouseleave;

	// Скрывать тултип при скроле страницы
	@Input() hideWhenScrolling: boolean = false;

	/**
	 * позиционирование тултипа относительно элемента
	 * @example tooltipPositionY="auto"
	 * @example tooltipPositionY="top"
	 * @example [tooltipPositionY]="{ '0': 'top', sm: 'top', md: 'bottom', lg: 'top', '1400': 'bottom' }"
	 */
	@Input() tooltipPositionY: TooltipVerticalPosition | BreakpointPosition<TooltipVerticalPosition> = TooltipVerticalPosition.auto;

	/**
	 * позиционирование стрелки относительно тултипа
	 * @example tooltipPositionX="center"
	 * @example [tooltipPositionX]="{ '0': 'right', sm: 'left', md: 'center', lg: 'right', '1400': 'left' }"
	 */
	@Input() tooltipPositionX: TooltipHorizontalPosition | BreakpointPosition<TooltipHorizontalPosition> = TooltipHorizontalPosition.center;

	/** CSS класс для контейнера тултипа */
	@Input() tooltipClass: string | null = null;

	// состояние активности триггера (тогглится при клике/таче)
	private triggerSelected: boolean = false;

	position: TooltipVerticalPosition;
	positionOfArrow: TooltipHorizontalPosition;
	subscriptions = new Subscription();
	container: HTMLDivElement;
	textContainer: HTMLDivElement;
	pointerShowTriggerSub: Subscription;
	pointerHideTriggerSub: Subscription;
	destroyAnimation: Animation;
	externalVisibilityControl = false;
	activeTooltipId = 'active-tooltip';

	visibility$ = new BehaviorSubject<boolean>(false);
	visibility = this.visibility$
		.asObservable()
		.pipe(distinctUntilChanged());

	get _visibility(): boolean {
		return this.visibility$.getValue();
	}

	constructor(
		private scrollService: ScrollService,
		private deviceService: DeviceService,
		private elementRef: ElementRef,
		private viewContainerRef: ViewContainerRef,
		@Inject(DOCUMENT) private document: Document,
		@Inject(WINDOW) private _window: Window,
		private domEventsService: DomEventsService,
		private renderer: Renderer2,
	) {
		afterNextRender(() => {
			this.domEventsService.registerTooltip(this);

			this.subscriptions.add(
				fromEvent(this.elementRef.nativeElement, 'focus').subscribe({
					next: () => {
						this.visibility$.next(true);
					}
				})
			);

			this.subscriptions.add(
				fromEvent(this.elementRef.nativeElement, 'blur').subscribe({
					next: () => {
						this.visibility$.next(false);
					}
				})
			);

			this.subscriptions.add(
				this.deviceService.viewportWidth$.subscribe(viewportWidth => {
					this.updatePosition(viewportWidth);
				})
			);

			this.subscriptions.add(
				this.visibility.subscribe(value => {
					this.updatePosition(this.deviceService.viewportWidth);

					if (this.externalVisibilityControl) return;

					if (value) {
						this.createTooltip();
					} else {
						this.removeTooltip();
					}
				})
			);

			if (this.hideWhenScrolling) {
				this.subscriptions.add(
					fromEvent(this._window, 'scroll')
						.subscribe({
							next: () => {
								if (this._visibility) {
									this.visibility$.next(false);
								}
							}
						})
				);
			}
		});
	}

	ngAfterViewInit() {
		this.updatePosition(this.deviceService.viewportWidth);
		this.registerTriggers();

	}

	ngAfterContentChecked() {
		if (this.showTriggerDesktop === TooltipShowTrigger.pointerdown) {
			Object.values(this.elementRef.nativeElement.children)
				.filter((child: HTMLElement) => child !== this.container && child !== this.textContainer)
				.forEach((child: HTMLElement) => {
					this.renderer.addClass(child, 'cursor-pointer');
				});
		}
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
		this.pointerShowTriggerSub?.unsubscribe();
		this.pointerHideTriggerSub?.unsubscribe();
		this.domEventsService.removeTooltip(this);
	}

	registerPointerShowTrigger() {
		if (this.pointerShowTriggerSub) {
			return;
		}

		this.pointerShowTriggerSub = fromEvent(this.elementRef.nativeElement, 'pointerdown').subscribe((event: PointerEvent) => {
			if (this.container && this.container.contains(event.target as HTMLElement)) {
				return;
			}

			this.triggerSelected = !this.triggerSelected;

			if (
				Array.isArray(this.showTriggerDesktop)
				&& this.showTriggerDesktop.includes(TooltipShowTrigger.mouseenter)
				&& this._visibility
			) {
				return;
			}

			this.visibility$.next(!this._visibility);
		});
	}

	registerPointerHideTrigger() {
		if (this.pointerHideTriggerSub) {
			return;
		}

		this.pointerHideTriggerSub = fromEvent(this.document, 'pointerdown').subscribe((event) => {
			if (!this.visibility) {
				return;
			}

			const isBeyondBorders = [
				this.container,
				this.elementRef?.nativeElement,
			].every(i => !i?.contains(event.target as HTMLElement));

			if (isBeyondBorders) {
				this.visibility$.next(false);
			}
		});
	}

	registerShowTrigger(showTrigger: TooltipShowTrigger | keyof typeof TooltipShowTrigger): void {
		if (this.deviceService.isDesktopDevice) {
			switch (showTrigger) {
			case TooltipShowTrigger.none:
				break;
			case TooltipShowTrigger.pointerdown:
				this.registerPointerShowTrigger();
				break;
			case TooltipShowTrigger.mouseenter:
			default:
				this.subscriptions.add(
					fromEvent(this.elementRef.nativeElement, 'mouseenter').subscribe(() => {
						this.visibility$.next(true);
					})
				);
				break;
			}
		} else if (this.deviceService.isMobileDevice) {
			switch (showTrigger) {
			case TooltipShowTrigger.pointerdown:
				this.registerPointerShowTrigger();
				break;
			case TooltipShowTrigger.mouseenter:
			case TooltipShowTrigger.none:
			default:
				break;
			}
		}
	}

	registerHideTrigger(hideTrigger: TooltipHideTrigger | keyof typeof TooltipHideTrigger) {
		if (this.deviceService.isDesktopDevice) {
			switch (hideTrigger) {
			case TooltipHideTrigger.none:
				break;
			case TooltipHideTrigger.pointerdown:
				this.registerPointerHideTrigger();
				break;
			case TooltipHideTrigger.mouseleave:
			default:
				this.subscriptions.add(
					fromEvent(this.elementRef.nativeElement, 'mouseleave')
						.pipe(filter(() => !this.triggerSelected))
						.subscribe(() => {
							this.visibility$.next(false);
						})
				);
				break;
			}
		} else if (this.deviceService.isMobileDevice) {
			switch (hideTrigger) {
			case TooltipHideTrigger.pointerdown:
				this.registerPointerHideTrigger();
				break;
			case TooltipHideTrigger.mouseleave:
			case TooltipHideTrigger.none:
			default:
				break;
			}
			return;
		}
	}

	registerTriggers() {
		const showTrigger = this.deviceService.isDesktopDevice ?
			this.showTriggerDesktop :
			(this.deviceService.isMobileDevice ? this.showTriggerMobile : null);

		const hideTrigger = this.deviceService.isDesktopDevice ?
			this.hideTriggerDesktop :
			(this.deviceService.isMobileDevice ? this.hideTriggerMobile : null);

		if (showTrigger) {
			Array.isArray(showTrigger)
				? showTrigger.forEach(trigger => this.registerShowTrigger(trigger))
				: this.registerShowTrigger(showTrigger);
		}

		if (hideTrigger) {
			Array.isArray(hideTrigger)
				? hideTrigger.forEach(trigger => this.registerHideTrigger(trigger))
				: this.registerHideTrigger(hideTrigger);
		}
	}

	createDebounceFunc(func: Function, timeout: () => number) {
		let timer: NodeJS.Timeout | undefined;
		return (...args: any[]) => {
			const next = () => func(...args);
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(next, timeout());
		};
	}

	removeTooltip = this.createDebounceFunc(() => {
		if (!this.container || (this.debounce > 0 && this._visibility)) {
			return;
		}

		this.destroyAnimation = this.container.animate(
			this.getTooltipAnimation(true),
			{
				duration: 100,
				iterations: 1,
				easing: 'ease-out',
			}
		);

		this.destroyAnimation.onfinish = () => {
			if (this.container) {
				this.container.remove();
			}
			this.container = null;
			this.destroyAnimation = null;
		};
	}, () => this.debounce || 0);

	createTooltip() {
		if (this.container) {
			if (this.destroyAnimation) {
				this.destroyAnimation.reverse();
				this.destroyAnimation.onfinish = () => {
					this.destroyAnimation = null;
				};
			}

			return;
		}

		this.container = this.renderer.createElement('div');

		this.renderer.setAttribute(this.container, 'role', 'tooltip');
		this.container.id = this.activeTooltipId;

		this.renderer.addClass(this.container, 'tooltip__content');
		this.renderer.addClass(this.container, this.tooltipPositionClass);
		if (this.tooltipClass) {
			this.renderer.addClass(this.container, this.tooltipClass);
		}

		this.textContainer = this.renderer.createElement('div');
		this.textContainer.className = 'tooltip__text';

		if (this.tooltipTemplate && this.tooltipTemplate instanceof TemplateRef) {
			const templateView = this.viewContainerRef.createEmbeddedView(this.tooltipTemplate);
			templateView.detectChanges();

			let templateNodes = templateView?.rootNodes;
			if (templateNodes) {
				for (const node of templateNodes) {
					this.textContainer.append(node);
				}
			}
		} else {
			this.textContainer.innerText = this.tooltipText;
		}

		this.container.append(this.textContainer);

		this.elementRef.nativeElement.append(this.container);
		this.renderer.setAttribute(this.elementRef.nativeElement, 'aria-describedby', this.activeTooltipId);

		this.container.animate(
			this.getTooltipAnimation(),
			{
				duration: 100,
				iterations: 1,
				easing: 'ease-in',
			}
		);
	}

	getTooltipAnimation(forDestroy = false) {
		const result = [
			{opacity: 0},
			{opacity: 1},
		];

		return forDestroy ? result.reverse() : result;
	}

	get tooltipPositionClass(): string {
		return `tooltip__content--${this.position}-${this.positionOfArrow}`;
	}

	getTooltipPositionY(): TooltipVerticalPosition {
		const viewportSize = this.scrollService?.getViewportSize();
		const inputRect = this.elementRef?.nativeElement?.getBoundingClientRect();
		const under = viewportSize?.viewportHeight - inputRect?.top - inputRect?.height;
		const above = inputRect?.top;

		return under > above
			? TooltipVerticalPosition.bottom
			: TooltipVerticalPosition.top;
	}

	getTooltipPositionX(): TooltipHorizontalPosition {
		const viewportSize = this.scrollService?.getViewportSize();
		const inputRect = this.elementRef?.nativeElement?.getBoundingClientRect();
		const right = viewportSize?.viewportWidth - inputRect?.left - inputRect?.width;
		const left = inputRect?.left;

		if (left === right) return TooltipHorizontalPosition.center;

		return left > right
			? TooltipHorizontalPosition.left
			: TooltipHorizontalPosition.right;
	}

	getPositionFromBreakPoints(viewportWidth: number, targetPosition: BreakpointPosition<TooltipHorizontalPosition | TooltipVerticalPosition>): TooltipHorizontalPosition | TooltipVerticalPosition | null {
		if (!Object.keys(targetPosition).length) {
			console.error('sharedTooltip: Необходимо указать брейкпоинты или постоянное значение для позиционирования');
			return null;
		}

		const parsedPoints = Object.keys(targetPosition)
			.reduce((accum, point) => {
				const key = this.deviceService.targetSizes?.[point] || point;
				accum[key] = targetPosition[point];
				return accum;
			}, {});

		const relevantPoints = Object.keys(parsedPoints)
			.map(Number)
			.filter(point => point <= viewportWidth);

		if (relevantPoints.length) {
			const targetBreakpoint = Math.max(...relevantPoints);
			return parsedPoints[targetBreakpoint];
		} else {
			console.error('sharedTooltip: не найден активный брейкпоинт');
		}

		return null;
	}

	updateArrowPosition(viewportWidth: number) {
		if (typeof this.tooltipPositionX === 'object') {
			this.positionOfArrow = this.getPositionFromBreakPoints(viewportWidth, this.tooltipPositionX) as TooltipHorizontalPosition;
			return;
		}

		if (this.tooltipPositionX === TooltipHorizontalPosition.auto) {
			this.positionOfArrow = this.getTooltipPositionX();
			return;
		}

		this.positionOfArrow = this.tooltipPositionX;
	}

	updateTooltipPosition(viewportWidth: number) {
		if (typeof this.tooltipPositionY === 'object') {
			this.position = this.getPositionFromBreakPoints(viewportWidth, this.tooltipPositionY) as TooltipVerticalPosition;
			return;
		}

		if (this.tooltipPositionY === TooltipVerticalPosition.auto) {
			this.position = this.getTooltipPositionY();
			return;
		}

		this.position = this.tooltipPositionY;
	}

	updatePosition(viewportWidth: number) {
		this.updateTooltipPosition(viewportWidth);
		this.updateArrowPosition(viewportWidth);
	}

}
