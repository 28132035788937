<div
	class="drop__inner"
	[ngClass]="dropdownClass"
	*ngIf="type === dropdownType.default"
>
	<ng-container>
		<ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
	</ng-container>

	<button
		*ngIf="!canShowInputLoader && triggerButtonVisibility"
		(click)="toggleOverlay($event)"
		class="drop__btn btn"
		[ngClass]="btnClass"
		type="button"
		sharedArrowNavigation
		[attr.disabled]="isDisabled || null"
		[groupId]="[arrowNavigationGroup, triggerNavigationGroup]"
		#triggerButton
	>
		<span class="field__title" *ngIf="animatedTitle && placeholder && !selectedOption">{{ placeholder }}</span>
		<span
			[class]="dropFieldWrapperClass"
			[ngClass]="{
				'drop__field': true,
				'field__control': isClientEnvironment
			}"
			#valueView
			*ngIf="!animatedTitle || selectedOption"
		>
			<ng-container
				*ngIf="viewTemplate; else defaultViewTemplate"
				[ngTemplateOutlet]="viewTemplate"
				[ngTemplateOutletContext]="{$implicit: selectedOption}"
			></ng-container>
			<ng-template #defaultViewTemplate>
				<span [ngClass]="dropFieldClass">{{ viewValue }}</span>
			</ng-template>
		</span>
		<ng-container
			*ngIf="afterViewTemplate"
			[ngTemplateOutlet]="afterViewTemplate"
			[ngTemplateOutletContext]="{$implicit: selectedOption}"
		></ng-container>
		<svg
			*ngIf="showArrowIcon"
			b-shared-svg
			[name]="dropIcon"
			[attr.class]="dropIconClass"
			[ngClass]="{
				'drop__icon icon': true,
				'icon--sm field__addon field__addon--icon': isClientEnvironment,
				'drop__icon--rotated': overlayIsOpened | async
			}"
		></svg>
	</button>

	<div
		class="drop__loader"
		[ngClass]="dropLoaderClass"
		*ngIf="canShowInputLoader"
	>
		{{ loaderDots }}
	</div>

	<div
		*ngIf="overlayIsOpened | async"
		[@fadeInY]="animationParams"
		(@fadeInY.start)="overlayAnimationStart($event)"
		(@fadeInY.done)="overlayAnimationDone($event)"
		[ngClass]="overlayNgClass"
		[attr.class]="overlayClass"
		#listWrapper
	>
		<ng-container *ngIf="suggestions?.length; else emptyList">
			<ul
				#listContainer
				role="listbox"
				class="drop__list"
				[ngClass]="dropListClass"
			>
				<li
					[attr.class]="dropElementClass"
					*ngFor="let option of suggestions; trackBy: trackBySuggests"
					role="option"
					(click)="selectOption(option)"
					[tabindex]="option?.isDisabledDropElement ? null : liTabindex"
					[ngClass]="getItemNgClass(option)"
					sharedArrowNavigation
					[navigationEnabled]="!option?.isDisabledDropElement"
					[groupId]="arrowNavigationGroup"
					[attr.data-selected]="isSelectedOption(option) || null"
					[attr.aria-selected]="isSelectedOption(option) || null"
					#listItem
					sharedKeyboardControl
					(activeElementKeyHandler)="selectOption(option)"
				>

					<ng-container *ngIf="isBtnCell; else itemContent">
						<div
							[attr.class]="dropElementInnerClass"
							[ngClass]="{
								'btn-cell__inner': isClientEnvironment,
								'drop__item-inner': true
							}"
						>
							<ng-container [ngTemplateOutlet]="itemContent"></ng-container>
						</div>
					</ng-container>

					<ng-template #itemContent>
						<ng-container
							*ngIf="dropdownItem; else defaultOptionTemplate"
							[ngTemplateOutlet]="dropdownItem"
							[ngTemplateOutletContext]="{$implicit: option}"
						></ng-container>
						<ng-template #defaultOptionTemplate>{{ option }}</ng-template>
						<svg
							*ngIf="showActiveIcon"
							b-shared-svg
							[name]="isClientEnvironment ? 'icons_scaling_checkmark' : 'icons_24_1-5_check'"
							[class]="dropIconActiveClass"
							[ngClass]="{
								'icon--xl': !isClientEnvironment,
								'icon--sm': isClientEnvironment,
								'drop__icon-active icon': true
							}"
						></svg>
					</ng-template>
				</li>
			</ul>
		</ng-container>

		<ng-template #emptyList>
			<ng-content select="emptyDropdownContent"></ng-content>
		</ng-template>
	</div>
</div>

<ng-container *ngIf="viewTemplate && type === dropdownType.custom">
	<button
		*ngIf="triggerButtonVisibility"
		(click)="toggleOverlay($event)"
		[ngClass]="btnClass"
		type="button"
		sharedArrowNavigation
		[attr.disabled]="isDisabled || null"
		[groupId]="arrowNavigationGroup"
	>
		<ng-container
			[ngTemplateOutlet]="viewTemplate"
			[ngTemplateOutletContext]="{$implicit: selectedOption}"
		></ng-container>
	</button>

	<ng-container>
		<ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
	</ng-container>

	<div
		*ngIf="dropdownItem && (overlayIsOpened | async) && suggestions?.length"
		[@fadeIn]
		(@fadeIn.start)="overlayAnimationStart($event)"
		(@fadeIn.done)="overlayAnimationDone($event)"
		[attr.class]="overlayClass"
		#listWrapper
	>

		<ul
			#listContainer
			role="listbox"
			[ngClass]="dropListClass"
		>
			<li
				[attr.class]="dropElementClass"
				*ngFor="let option of suggestions; trackBy: trackBySuggests"
				role="option"
				(click)="selectOption(option)"
				sharedKeyboardControl
				(activeElementKeyHandler)="selectOption(option)"
				[attr.tabindex]="liTabindex"
				sharedArrowNavigation
				[groupId]="arrowNavigationGroup"
				[attr.data-selected]="isSelectedOption(option) || null"
				[attr.aria-selected]="isSelectedOption(option) || null"
				#listItem
			>
				<ng-container
					[ngTemplateOutlet]="dropdownItem"
					[ngTemplateOutletContext]="{$implicit: option}"
				></ng-container>
			</li>
		</ul>
	</div>
</ng-container>
