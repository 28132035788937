import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputValidationDirective } from './directives/input-validation.directive';
import { FormValidationDirective } from './directives/form-validation.directive';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { ValidationComponent } from './components/validation/validation.component';
import { FocusDetectDirective } from './directives/focus-detect.directive';

@NgModule({
	declarations: [
		ValidationErrorComponent,
		FormValidationDirective,
		InputValidationDirective,
		ValidationComponent,
		FocusDetectDirective,
	],
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	exports: [
		ValidationErrorComponent,
		FormValidationDirective,
		InputValidationDirective,
		ValidationComponent,
		FocusDetectDirective,
	],
})
export class CommonFormsModule {}
