import {ChangeDetectionStrategy, Component, Inject, Injectable, Input, ViewEncapsulation, inject} from '@angular/core';
import {ConfigToken} from './tokens';
import {IIconConfig} from './interfaces';
import {DeviceService} from 'shared';

export function IconSymbolsPathNotFound(): Error {
	return Error('Unable to find symbols path');
}

@Injectable({
	providedIn: 'root',
})
export class IconResolver {
	constructor(
		@Inject(ConfigToken) private _config: IIconConfig,
	) {
		if (!this._config?.symbolsPath) {
			throw IconSymbolsPathNotFound();
		}
	}

	getPathFor(name: string): string {
		return name ? `${this._config?.symbolsPath}#${name}` : null;
	}
}

@Component({
	selector: 'b-icon, [b-icon]',
	templateUrl: './icon.html',
	styleUrls: ['./icon.scss'],
	host: {
		'class': 'b-icon',
		'[class.b-icon--sm]': 'size === "sm"',
		'[class.b-icon--md]': 'size === "md"',
		'[class.b-icon--lg]': 'size === "lg"',
		'[class.b-icon--xl]': 'size === "xl"',
	},
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
	/** SVG symbol name */
	@Input() name: string;
	@Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';

	private deviceService = inject(DeviceService);
	
	constructor(
		private _iconResolver: IconResolver,
	) {
	}

	get path(): string {
		if(this.deviceService.isServer) return;
		return this._iconResolver.getPathFor(this.name);
	}
}
