import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar.component';
import { SvgModule } from '../svg/svg.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
	declarations: [SidebarComponent],
	imports: [CommonModule, SvgModule, ButtonModule],
	exports: [SidebarComponent],
})
export class SidebarModule {}
