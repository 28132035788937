import { Viewport, ClientViewport } from '../../../services/device.service';

export enum TooltipShowTrigger {
	mouseenter = 'mouseenter', // тултип появляется при наведении указателя
	pointerdown = 'pointerdown', // тултип появляется при клике/таче
	none = 'none', // тултип не появляется
}

export enum TooltipHideTrigger {
	mouseleave = 'mouseleave', // тултип исчезает, когда указатель за пределами элемента
	pointerdown = 'pointerdown', // тултип исчезает при клике/таче вне элемента
	none = 'none', // тултип не скрывается
}

/** Вертикальное позиционирование тултипа относительно host элемента */
export enum TooltipVerticalPosition {
	top = 'top',
	bottom = 'bottom',
	auto = 'auto', // тултип будет всплывать в ту сторону по оси Y, где больше места в видимой области окна браузера (относительно элемента, на который навесили директиву)
}

/** Горизонтальное позиционирование тултипа относительно host элемента */
export enum TooltipHorizontalPosition {
	left = 'left',
	right = 'right',
	center = 'center',
	auto = 'auto', // тултип будет всплывать в ту сторону по оси X, где больше места в видимой области окна браузера (относительно элемента, на который навесили директиву)
}

export type BreakpointPosition<T> = {
	[key in (Viewport | ClientViewport | string)]?: T;
}

export type TooltipHideTriggers = TooltipHideTrigger | TooltipHideTrigger[] | (keyof typeof TooltipHideTrigger)[];
export type TooltipShowTriggers = TooltipShowTrigger | TooltipShowTrigger[] | (keyof typeof TooltipShowTrigger)[];
