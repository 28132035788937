import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'bankAccountMasking',
})
export class BankAccountMaskingPipe implements PipeTransform {
	transform(accountNumber: string, numbersToShow: number = 7): string {
		return `*${accountNumber.slice(-numbersToShow)}`;
	}
}
