import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconComponent} from './icon';
import {ConfigToken} from './tokens';
import {IIconConfig} from './interfaces';

const FILE_DECLARATIONS = [
	IconComponent,
];

@NgModule({
	declarations: [
		FILE_DECLARATIONS,
	],
	exports: [
		FILE_DECLARATIONS,
	],
	imports: [
		CommonModule,
	],
})
export class IconModule {
	static forRoot(config: IIconConfig = {symbolsPath: ''}): ModuleWithProviders<IconModule> {
		return {
			ngModule: IconModule,
			providers: [
				{
					provide: ConfigToken,
					useValue: config,
				},
			],
		};
	}
}
