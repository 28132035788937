/** Режим отображения подсказок о надёжности пароля */
export enum PasswordSecurityHint {
	/** не отображать подсказки */
	notDisplay = 'notDisplay',
	/** всегда отображать подсказки */
	alwaysDisplay = 'alwaysDisplay',
	/** отображать подсказки, когда поле помечено, как dirty */
	whenFieldDirty = 'whenFieldDirty',
	/** отображать подсказки, когда поле помечено, как touched */
	whenFieldTouched = 'whenFieldTouched',
	/** отображать подсказки, когда значение поля не пустое */
	whenFieldNotEmpty = 'whenFieldNotEmpty',
}
