<div
	*ngIf="tabs | async as tabsList"
	class="switch"
	[ngClass]="tabsClass"
>
	<button
		*ngFor="let tab of tabsList; trackBy: trackByTabs"
		[disabled]="tab.disabled"
		(click)="tabClickHandler($event, tab)"
		b-shared-button
		[class]="buttonClass"
		[ngClass]="tab.cssClass"
		textClass="text-truncate"
		role="tab"
		[attr.aria-selected]="tab.id === activeTab"
		attr.aria-controls="tabContent-{{tab.id}}"
		attr.id="tabTitle-{{tab.id}}"
	>
		<ng-container
			[ngTemplateOutlet]="tab.titleTemplate"
		></ng-container>
	</button>
</div>

<ng-container *ngIf="selectedTab | async as activeTab">
	<div
		*ngIf="activeTab?.contentTemplate"
		[ngClass]="tabPanelClass"
		attr.id="tabContent-{{activeTab.id}}"
		attr.role="tabpanel"
		attr.aria-labelledby="tabTitle-{{activeTab.id}}"
		#tabPanelElement
	>
		<ng-container
			[ngTemplateOutlet]="activeTab.contentTemplate"
		></ng-container>
	</div>
</ng-container>
