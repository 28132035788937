import { inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of, Subject, filter, take } from 'rxjs';
import {
	HttpTransportType,
	HubConnection,
	HubConnectionBuilder,
	IHttpConnectionOptions,
	LogLevel,
} from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';

interface ServiceEvent {
	type: string;
	subj: Subject<any>;
}

export interface EventChannelConfig {
	listenServerEvents: boolean;
}

/**
 * EventChannel для коммуникации сервисов
 */
@Injectable({
	providedIn: 'root',
})
export class EventChannelService {
	private eventBrocker: ServiceEvent[] = [];
	private connection?: HubConnection;
	private oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);

	constructor(
		@Inject('EVENTS') private events,
		@Inject('DATA') private data = {},
		@Inject('config') private config: EventChannelConfig = {listenServerEvents: false},
	) {
		this.parseEvents(events);
		this.parseData(data);
	}

	on<T>(eventType: string): Observable<T> {
		const _event$ = this.eventBrocker.find(event => event.type === eventType);

		if (_event$) {
			return _event$.subj.asObservable();
		}

		console.error(`Подписка на несуществующее событие ${eventType}`);
		return of(null);
	}

	dispatch<T>(eventType: string, payload?: T): void {
		const _event$ = this.eventBrocker.find(ev => ev.type === eventType);

		if (_event$) {
			_event$.subj.next(payload);
		} else {
			console.error(`Событие ${eventType} не было объявлено в списке событий`);
		}
	}

	private parseEvents(_event: Object | string): void {
		if (typeof _event === 'string') {
			const subj = new Subject();
			this.eventBrocker.push({type: _event, subj});

			if (this.config.listenServerEvents) {
				const on = () => this.connection!.on(_event, v => subj.next(JSON.parse(v)));

				if (!this.connection) {
					this.startListenServerEvents().then(on);
				} else {
					on();
				}
			}

		} else {
			Object.keys(_event).forEach((ev) => this.parseEvents(_event[ev]));
		}
	}

	private parseData(_data: Object | string): void {
		if (typeof _data === 'string') {
			const subj = new BehaviorSubject(undefined);
			this.eventBrocker.push({type: _data, subj});

		} else {
			Object.keys(_data).forEach((ev) => this.parseData(_data[ev]));
		}
	}

	private async startListenServerEvents(): Promise<void> {
		try {
			const options: IHttpConnectionOptions = {transport: HttpTransportType.WebSockets, skipNegotiation: true, accessTokenFactory: () => firstValueFrom(this.oidcSecurityService.getAccessToken()), logger: LogLevel.None};
			this.connection = new HubConnectionBuilder().withUrl('/api/signalr-service/ws', options).withAutomaticReconnect().build();

			this.oidcSecurityService.isAuthenticated$
				.pipe(
					filter(({isAuthenticated}) => isAuthenticated),
					take(1)
				)
				.subscribe(() =>this.connection.start());
		} catch {
			setTimeout(() => this.startListenServerEvents(), 5000);
		}
	}

	public stopListenServerEvents(): void {
		if (this.connection) this.connection.stop().then(() => this.connection = null);
	}
}
