import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
} from '@angular/core';
import { DateService } from '../../../services';
import { IssuingBank, issuingBankLogo, PaymentSystem, paymentSystemLogo, CardNumberView } from './models';

/**
 * Компонент для отображения пластиковой карты с реквизитами и логотипами банка и платёжной системы
 *
 * @example
 * <b-shared-payment-card
		bank="Blanc"
		paymentSystem="VisaBusiness"
		validityPeriod="12/22"
		cardNumber="xxxx xxxx xxxx 3749"
	></b-shared-payment-card>
 */
@Component({
	selector: 'b-shared-payment-card',
	templateUrl: './payment-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		DatePipe,
	],
})
export class PaymentCardComponent {

	/** Банк, выпускающий карту */
	@Input() bank: IssuingBank | keyof typeof IssuingBank | null = null;

	/** Платёжная система */
	@Input() paymentSystem: PaymentSystem | keyof typeof PaymentSystem | null = null;

	/**
	 * Срок действия карты
	 * @example validityPeriod="12/22"
	 * @example [validityPeriod]="new Date(2022, 12, 0);"
	 */
	@Input() get validityPeriod(): Date | string {
		return this._validityPeriod;
	}
	set validityPeriod(value: Date | string) {
		this._validityPeriod = value;

		if (value instanceof Date) {
			this.date = this.datePipe.transform(value, 'MM/yy');
			this.datetimeAttr = this.getDateTimeAttrFromDate(value);
		} else if (typeof value === 'string') {
			this.date = value;

			const dateNumbers = value.replace(/\D/g, '');
			const date = new Date(
				parseInt(`20${dateNumbers.substring(2, 4)}`),
				parseInt(dateNumbers.substring(0, 2)),
				0, // последний день месяца
			);
			this.datetimeAttr = this.getDateTimeAttrFromDate(date);
		} else {
			this.date = null;
			this.datetimeAttr = null;
		}
	}

	/** Режим отображения номера карты */
	@Input() cardNumberView: CardNumberView = CardNumberView.last4;

	/**
	 * Номер карты
	 * @example cardNumber="5546 0000 1234 0000 55"
	 * @example cardNumber="xxxx xxxx xxxx 5389"
	 * @example cardNumber="27•• •••• ••44 5389"
	 */
	@Input() get cardNumber(): string | number {
		return this._cardNumber;
	}
	set cardNumber(value: string | number) {
		this._cardNumber = value;
		if (!value) return;

		const cardNumber = value.toString();

		switch (this.cardNumberView) {
		case CardNumberView.last4:
			this.separatedCardNumber = [
				cardNumber.substring(cardNumber.length - 4)
			];
			break;
		case CardNumberView.last4of8:
			const lastCharacters = cardNumber.substring(cardNumber.length - 4);
			this.separatedCardNumber = this.parseCardNumber(`**** ${lastCharacters}`);
			break;
		case CardNumberView.unchanged:
		default:
			this.separatedCardNumber = this.parseCardNumber(value);
			break;
		}
	}

	private _validityPeriod: Date | string;
	private _cardNumber: string | number;

	public separatedCardNumber: string[];
	public date: string;
	public datetimeAttr: string;

	constructor(
		private datePipe: DatePipe,
		private dateService: DateService,
	) {}

	get issuingBankSvg(): string | null {
		return issuingBankLogo?.[this.bank] || null;
	}

	get paymentSystemLogo(): string | null {
		return paymentSystemLogo?.[this.paymentSystem] || null;
	}

	trackByCardNumberPart(index: number, part: string) {
		return part + index.toString();
	}

	getDateTimeAttrFromDate(date: Date): string {
		return this.dateService.ignoreTimezone(date).substring(0, 10);
	}

	parseCardNumber(value: string | number): string[] {
		return value
			.toString()
			.replace(/ /g, '')
			.match(/.{1,4}/g)
			.map(i => i.toLowerCase().replace(/x|х|•/g, '*'));
	}

}
