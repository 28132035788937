import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnInit, OnDestroy, ViewChildren, QueryList, Inject } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import disableScroll from 'disable-scroll';
import { DeviceService } from '../../../services';
import { Mark, MarkStyles, MarksState } from './popup-mark.types';

@Component({
	selector: '[b-shared-popup-mark]',
	templateUrl: './popup-mark.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('lifecycleTrigger', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('300ms ease-in', style({ opacity: 1 })),
			]),
			transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))]),
		]),
	],
})
export class PopupMarkComponent implements OnInit, OnDestroy {

	subscriptions = new Subscription();

	bodyStyles = ['lg:ov-auto', 'ov-hidden'];
	markStyles: { [markId: string]: MarkStyles };

	@Input() marksState: MarksState<string>;
	@Input() commonButtonClass: string = '';

	@ViewChildren('hotSpotsList') hotSpotsList: QueryList<any>;

	constructor(
		private deviceService: DeviceService,
		private changeDetector: ChangeDetectorRef,
		@Inject(DOCUMENT) private document: Document,
	) {}

	ngOnInit() {
		this.marksState.forEach(mark => {
			if (mark.alwaysOpen && this.deviceService.viewport?.min?.lg) {
				mark.isActive = true;
			}

			mark.popupPosition = {
				x: mark.popupPosition?.x || 'left',
				y: mark.popupPosition?.y || 'bottom',
			};
		});

		this.subscriptions.add(
			this.deviceService.viewportWidth$.subscribe(viewportWidth => {
				this.updateMarkStyles(viewportWidth);
			})
		);

		this.subscriptions.add(
			this.deviceService.viewport$.subscribe(viewport => {
				if (viewport?.min?.lg) {
					this.enableScroll();
				}

				this.marksState.forEach(mark => {
					mark.isActive = mark.alwaysOpen && viewport?.min.lg;
				});

				if (this.marksState.every(i => !i.isActive)) {
					this.enableScroll();
				}
			})
		);

		if (!this.deviceService.isServer) {
			this.subscriptions.add(
				fromEvent(document, 'touchstart').subscribe(event => {
					const marks = this.hotSpotsList.map(i => i.nativeElement);
					const clickedFromOutside = !marks.some(i => i.contains(event.target));
					const isOpened = this.marksState.some(i => i.isActive);

					if (isOpened && clickedFromOutside) {
						this.marksState.forEach(mark => {
							mark.isActive = false;
						});
						this.enableScroll();
						this.changeDetector.detectChanges();
					}
				})
			);
		}

	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	updateMarkStyles(viewportWidth: number) {
		this.markStyles = this.marksState
			.reduce((accum, mark) => {
				accum[mark.id] = this.getMarkCoords(mark, viewportWidth);
				return accum;
			}, {});
		this.changeDetector.detectChanges();
	}

	getMarkCoords(mark: Mark<string>, viewportWidth: number): MarkStyles {
		let coords: MarkStyles = {
			top: mark?.top || '0px',
			left: mark?.left || '0px',
		};

		if (mark.breakpoints && Object.keys(mark.breakpoints).length) {
			const parsedPoints = Object.keys(mark.breakpoints)
				.reduce((accum, point) => {
					const key = this.deviceService.targetSizes?.[point] || point;
					accum[key] = mark.breakpoints[point];
					return accum;
				}, {});

			const relevantPoints = Object.keys(parsedPoints)
				.map(Number)
				.filter(point => point <= viewportWidth);

			if (relevantPoints.length) {
				const targetBreakpoint = Math.max(...relevantPoints);
				coords = {
					...coords,
					...parsedPoints[targetBreakpoint],
				};
			}
		}

		return coords;
	}

	trackByMarks(index: number, mark: Mark<string>) {
		return `${mark.id}${index}`;
	}

	onMouseEnter(mark: Mark<string>) {
		if (this.deviceService.viewport?.min?.lg) {
			mark.isFocused = true;
			mark.isActive = true;
		}
	}

	onMouseLeave(mark: Mark<string>) {
		if (this.deviceService.viewport?.min?.lg) {
			mark.isFocused = false;

			if (!mark.alwaysOpen) {
				setTimeout(() => {
					if (!mark.isFocused) {
						mark.isActive = false;
						this.enableScroll();
						this.changeDetector.detectChanges();
					}
				}, 200); // debounce
			}
		}
	}

	disableScroll() {
		this.bodyStyles.forEach((i) => this.document.body.classList.add(i));
		disableScroll.on();
	}

	enableScroll() {
		this.bodyStyles.forEach((i) => this.document.body.classList.remove(i));
		disableScroll.off();
	}

	selectMark(mark: Mark<string>) {
		if (this.deviceService.viewport?.max?.lg) {
			this.marksState.forEach(mark => mark.isActive = false);
			mark.isActive = true;
			this.disableScroll();
		}
	}

	closeLayer(mark: Mark<string>) {
		mark.isActive = false;
		this.enableScroll();
	}

}
