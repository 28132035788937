import * as i0 from '@angular/core';
import { Injectable, Component, Directive, Input, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { ObjectUtils } from 'primeng/utils';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
class FilterMatchMode {}
FilterMatchMode.STARTS_WITH = 'startsWith';
FilterMatchMode.CONTAINS = 'contains';
FilterMatchMode.NOT_CONTAINS = 'notContains';
FilterMatchMode.ENDS_WITH = 'endsWith';
FilterMatchMode.EQUALS = 'equals';
FilterMatchMode.NOT_EQUALS = 'notEquals';
FilterMatchMode.IN = 'in';
FilterMatchMode.LESS_THAN = 'lt';
FilterMatchMode.LESS_THAN_OR_EQUAL_TO = 'lte';
FilterMatchMode.GREATER_THAN = 'gt';
FilterMatchMode.GREATER_THAN_OR_EQUAL_TO = 'gte';
FilterMatchMode.BETWEEN = 'between';
FilterMatchMode.IS = 'is';
FilterMatchMode.IS_NOT = 'isNot';
FilterMatchMode.BEFORE = 'before';
FilterMatchMode.AFTER = 'after';
FilterMatchMode.DATE_IS = 'dateIs';
FilterMatchMode.DATE_IS_NOT = 'dateIsNot';
FilterMatchMode.DATE_BEFORE = 'dateBefore';
FilterMatchMode.DATE_AFTER = 'dateAfter';
class PrimeNGConfig {
  constructor() {
    this.ripple = false;
    this.filterMatchModeOptions = {
      text: [FilterMatchMode.STARTS_WITH, FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.ENDS_WITH, FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
      numeric: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN, FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
      date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER]
    };
    this.translation = {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      is: 'Is',
      isNot: 'Is not',
      before: 'Before',
      after: 'After',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      dateFormat: 'mm/dd/yy',
      today: 'Today',
      weekHeader: 'Wk',
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      passwordPrompt: 'Enter a password',
      emptyMessage: 'No results found',
      emptyFilterMessage: 'No results found'
    };
    this.zIndex = {
      modal: 1100,
      overlay: 1000,
      menu: 1000,
      tooltip: 1100
    };
    this.translationSource = new Subject();
    this.translationObserver = this.translationSource.asObservable();
  }
  getTranslation(key) {
    return this.translation[key];
  }
  setTranslation(value) {
    this.translation = {
      ...this.translation,
      ...value
    };
    this.translationSource.next(this.translation);
  }
}
PrimeNGConfig.ɵfac = function PrimeNGConfig_Factory(t) {
  return new (t || PrimeNGConfig)();
};
PrimeNGConfig.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PrimeNGConfig,
  factory: PrimeNGConfig.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrimeNGConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class TranslationKeys {}
TranslationKeys.STARTS_WITH = 'startsWith';
TranslationKeys.CONTAINS = 'contains';
TranslationKeys.NOT_CONTAINS = 'notContains';
TranslationKeys.ENDS_WITH = 'endsWith';
TranslationKeys.EQUALS = 'equals';
TranslationKeys.NOT_EQUALS = 'notEquals';
TranslationKeys.NO_FILTER = 'noFilter';
TranslationKeys.LT = 'lt';
TranslationKeys.LTE = 'lte';
TranslationKeys.GT = 'gt';
TranslationKeys.GTE = 'gte';
TranslationKeys.IS = 'is';
TranslationKeys.IS_NOT = 'isNot';
TranslationKeys.BEFORE = 'before';
TranslationKeys.AFTER = 'after';
TranslationKeys.CLEAR = 'clear';
TranslationKeys.APPLY = 'apply';
TranslationKeys.MATCH_ALL = 'matchAll';
TranslationKeys.MATCH_ANY = 'matchAny';
TranslationKeys.ADD_RULE = 'addRule';
TranslationKeys.REMOVE_RULE = 'removeRule';
TranslationKeys.ACCEPT = 'accept';
TranslationKeys.REJECT = 'reject';
TranslationKeys.CHOOSE = 'choose';
TranslationKeys.UPLOAD = 'upload';
TranslationKeys.CANCEL = 'cancel';
TranslationKeys.DAY_NAMES = 'dayNames';
TranslationKeys.DAY_NAMES_SHORT = 'dayNamesShort';
TranslationKeys.DAY_NAMES_MIN = 'dayNamesMin';
TranslationKeys.MONTH_NAMES = 'monthNames';
TranslationKeys.MONTH_NAMES_SHORT = 'monthNamesShort';
TranslationKeys.TODAY = 'today';
TranslationKeys.WEEK_HEADER = 'weekHeader';
TranslationKeys.WEAK = 'weak';
TranslationKeys.MEDIUM = 'medium';
TranslationKeys.STRONG = 'strong';
TranslationKeys.PASSWORD_PROMPT = 'passwordPrompt';
TranslationKeys.EMPTY_MESSAGE = 'emptyMessage';
TranslationKeys.EMPTY_FILTER_MESSAGE = 'emptyFilterMessage';
var ConfirmEventType;
(function (ConfirmEventType) {
  ConfirmEventType[ConfirmEventType["ACCEPT"] = 0] = "ACCEPT";
  ConfirmEventType[ConfirmEventType["REJECT"] = 1] = "REJECT";
  ConfirmEventType[ConfirmEventType["CANCEL"] = 2] = "CANCEL";
})(ConfirmEventType || (ConfirmEventType = {}));
class ConfirmationService {
  constructor() {
    this.requireConfirmationSource = new Subject();
    this.acceptConfirmationSource = new Subject();
    this.requireConfirmation$ = this.requireConfirmationSource.asObservable();
    this.accept = this.acceptConfirmationSource.asObservable();
  }
  confirm(confirmation) {
    this.requireConfirmationSource.next(confirmation);
    return this;
  }
  close() {
    this.requireConfirmationSource.next(null);
    return this;
  }
  onAccept() {
    this.acceptConfirmationSource.next(null);
  }
}
ConfirmationService.ɵfac = function ConfirmationService_Factory(t) {
  return new (t || ConfirmationService)();
};
ConfirmationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ConfirmationService,
  factory: ConfirmationService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfirmationService, [{
    type: Injectable
  }], null, null);
})();
class FilterService {
  constructor() {
    this.filters = {
      startsWith: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null || filter.trim() === '') {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
        let stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale);
        return stringValue.slice(0, filterValue.length) === filterValue;
      },
      contains: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null || typeof filter === 'string' && filter.trim() === '') {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
        let stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale);
        return stringValue.indexOf(filterValue) !== -1;
      },
      notContains: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null || typeof filter === 'string' && filter.trim() === '') {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
        let stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale);
        return stringValue.indexOf(filterValue) === -1;
      },
      endsWith: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null || filter.trim() === '') {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
        let stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale);
        return stringValue.indexOf(filterValue, stringValue.length - filterValue.length) !== -1;
      },
      equals: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null || typeof filter === 'string' && filter.trim() === '') {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        if (value.getTime && filter.getTime) return value.getTime() === filter.getTime();else return ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale) == ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
      },
      notEquals: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null || typeof filter === 'string' && filter.trim() === '') {
          return false;
        }
        if (value === undefined || value === null) {
          return true;
        }
        if (value.getTime && filter.getTime) return value.getTime() !== filter.getTime();else return ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale) != ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
      },
      in: (value, filter) => {
        if (filter === undefined || filter === null || filter.length === 0) {
          return true;
        }
        for (let i = 0; i < filter.length; i++) {
          if (ObjectUtils.equals(value, filter[i])) {
            return true;
          }
        }
        return false;
      },
      between: (value, filter) => {
        if (filter == null || filter[0] == null || filter[1] == null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        if (value.getTime) return filter[0].getTime() <= value.getTime() && value.getTime() <= filter[1].getTime();else return filter[0] <= value && value <= filter[1];
      },
      lt: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        if (value.getTime && filter.getTime) return value.getTime() < filter.getTime();else return value < filter;
      },
      lte: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        if (value.getTime && filter.getTime) return value.getTime() <= filter.getTime();else return value <= filter;
      },
      gt: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        if (value.getTime && filter.getTime) return value.getTime() > filter.getTime();else return value > filter;
      },
      gte: (value, filter, filterLocale) => {
        if (filter === undefined || filter === null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        if (value.getTime && filter.getTime) return value.getTime() >= filter.getTime();else return value >= filter;
      },
      is: (value, filter, filterLocale) => {
        return this.filters.equals(value, filter, filterLocale);
      },
      isNot: (value, filter, filterLocale) => {
        return this.filters.notEquals(value, filter, filterLocale);
      },
      before: (value, filter, filterLocale) => {
        return this.filters.lt(value, filter, filterLocale);
      },
      after: (value, filter, filterLocale) => {
        return this.filters.gt(value, filter, filterLocale);
      },
      dateIs: (value, filter) => {
        if (filter === undefined || filter === null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        return value.toDateString() === filter.toDateString();
      },
      dateIsNot: (value, filter) => {
        if (filter === undefined || filter === null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        return value.toDateString() !== filter.toDateString();
      },
      dateBefore: (value, filter) => {
        if (filter === undefined || filter === null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        return value.getTime() < filter.getTime();
      },
      dateAfter: (value, filter) => {
        if (filter === undefined || filter === null) {
          return true;
        }
        if (value === undefined || value === null) {
          return false;
        }
        return value.getTime() > filter.getTime();
      }
    };
  }
  filter(value, fields, filterValue, filterMatchMode, filterLocale) {
    let filteredItems = [];
    if (value) {
      for (let item of value) {
        for (let field of fields) {
          let fieldValue = ObjectUtils.resolveFieldData(item, field);
          if (this.filters[filterMatchMode](fieldValue, filterValue, filterLocale)) {
            filteredItems.push(item);
            break;
          }
        }
      }
    }
    return filteredItems;
  }
  register(rule, fn) {
    this.filters[rule] = fn;
  }
}
FilterService.ɵfac = function FilterService_Factory(t) {
  return new (t || FilterService)();
};
FilterService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FilterService,
  factory: FilterService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ContextMenuService {
  constructor() {
    this.activeItemKeyChange = new Subject();
    this.activeItemKeyChange$ = this.activeItemKeyChange.asObservable();
  }
  changeKey(key) {
    this.activeItemKey = key;
    this.activeItemKeyChange.next(this.activeItemKey);
  }
  reset() {
    this.activeItemKey = null;
    this.activeItemKeyChange.next(this.activeItemKey);
  }
}
ContextMenuService.ɵfac = function ContextMenuService_Factory(t) {
  return new (t || ContextMenuService)();
};
ContextMenuService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ContextMenuService,
  factory: ContextMenuService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuService, [{
    type: Injectable
  }], null, null);
})();
class MessageService {
  constructor() {
    this.messageSource = new Subject();
    this.clearSource = new Subject();
    this.messageObserver = this.messageSource.asObservable();
    this.clearObserver = this.clearSource.asObservable();
  }
  add(message) {
    if (message) {
      this.messageSource.next(message);
    }
  }
  addAll(messages) {
    if (messages && messages.length) {
      this.messageSource.next(messages);
    }
  }
  clear(key) {
    this.clearSource.next(key || null);
  }
}
MessageService.ɵfac = function MessageService_Factory(t) {
  return new (t || MessageService)();
};
MessageService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MessageService,
  factory: MessageService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessageService, [{
    type: Injectable
  }], null, null);
})();
class OverlayService {
  constructor() {
    this.clickSource = new Subject();
    this.clickObservable = this.clickSource.asObservable();
  }
  add(event) {
    if (event) {
      this.clickSource.next(event);
    }
  }
}
OverlayService.ɵfac = function OverlayService_Factory(t) {
  return new (t || OverlayService)();
};
OverlayService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: OverlayService,
  factory: OverlayService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverlayService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class PrimeIcons {}
PrimeIcons.ALIGN_CENTER = 'pi pi-align-center';
PrimeIcons.ALIGN_JUSTIFY = 'pi pi-align-justify';
PrimeIcons.ALIGN_LEFT = 'pi pi-align-left';
PrimeIcons.ALIGN_RIGHT = 'pi pi-align-right';
PrimeIcons.AMAZON = 'pi pi-amazon';
PrimeIcons.ANDROID = 'pi pi-android';
PrimeIcons.ANGLE_DOUBLE_DOWN = 'pi pi-angle-double-down';
PrimeIcons.ANGLE_DOUBLE_LEFT = 'pi pi-angle-double-left';
PrimeIcons.ANGLE_DOUBLE_RIGHT = 'pi pi-angle-double-right';
PrimeIcons.ANGLE_DOUBLE_UP = 'pi pi-angle-double-up';
PrimeIcons.ANGLE_DOWN = 'pi pi-angle-down';
PrimeIcons.ANGLE_LEFT = 'pi pi-angle-left';
PrimeIcons.ANGLE_RIGHT = 'pi pi-angle-right';
PrimeIcons.ANGLE_UP = 'pi pi-angle-up';
PrimeIcons.APPLE = 'pi pi-apple';
PrimeIcons.ARROW_CIRCLE_DOWN = 'pi pi-arrow-circle-down';
PrimeIcons.ARROW_CIRCLE_LEFT = 'pi pi-arrow-circle-left';
PrimeIcons.ARROW_CIRCLE_RIGHT = 'pi pi-arrow-circle-right';
PrimeIcons.ARROW_CIRCLE_UP = 'pi pi-arrow-circle-up';
PrimeIcons.ARROW_DOWN = 'pi pi-arrow-down';
PrimeIcons.ARROW_DOWN_LEFT = 'pi pi-arrow-down-left';
PrimeIcons.ARROW_DOWN_RIGHT = 'pi pi-arrow-down-right';
PrimeIcons.ARROW_LEFT = 'pi pi-arrow-left';
PrimeIcons.ARROW_RIGHT = 'pi pi-arrow-right';
PrimeIcons.ARROW_UP = 'pi pi-arrow-up';
PrimeIcons.ARROW_UP_LEFT = 'pi pi-arrow-up-left';
PrimeIcons.ARROW_UP_RIGHT = 'pi pi-arrow-up-right';
PrimeIcons.ARROW_H = 'pi pi-arrow-h';
PrimeIcons.ARROW_V = 'pi pi-arrow-v';
PrimeIcons.AT = 'pi pi-at';
PrimeIcons.BACKWARD = 'pi pi-backward';
PrimeIcons.BAN = 'pi pi-ban';
PrimeIcons.BARS = 'pi pi-bars';
PrimeIcons.BELL = 'pi pi-bell';
PrimeIcons.BOLT = 'pi pi-bolt';
PrimeIcons.BOOK = 'pi pi-book';
PrimeIcons.BOOKMARK = 'pi pi-bookmark';
PrimeIcons.BOOKMARK_FILL = 'pi pi-bookmark-fill';
PrimeIcons.BOX = 'pi pi-box';
PrimeIcons.BRIEFCASE = 'pi pi-briefcase';
PrimeIcons.BUILDING = 'pi pi-building';
PrimeIcons.CALENDAR = 'pi pi-calendar';
PrimeIcons.CALENDAR_MINUS = 'pi pi-calendar-minus';
PrimeIcons.CALENDAR_PLUS = 'pi pi-calendar-plus';
PrimeIcons.CALENDAR_TIMES = 'pi pi-calendar-times';
PrimeIcons.CAMERA = 'pi pi-camera';
PrimeIcons.CAR = 'pi pi-car';
PrimeIcons.CARET_DOWN = 'pi pi-caret-down';
PrimeIcons.CARET_LEFT = 'pi pi-caret-left';
PrimeIcons.CARET_RIGHT = 'pi pi-caret-right';
PrimeIcons.CARET_UP = 'pi pi-caret-up';
PrimeIcons.CHART_BAR = 'pi pi-chart-bar';
PrimeIcons.CHART_LINE = 'pi pi-chart-line';
PrimeIcons.CHART_PIE = 'pi pi-chart-pie';
PrimeIcons.CHECK = 'pi pi-check';
PrimeIcons.CHECK_CIRCLE = 'pi pi-check-circle';
PrimeIcons.CHECK_SQUARE = 'pi pi-check-square';
PrimeIcons.CHEVRON_CIRCLE_DOWN = 'pi pi-chevron-circle-down';
PrimeIcons.CHEVRON_CIRCLE_LEFT = 'pi pi-chevron-circle-left';
PrimeIcons.CHEVRON_CIRCLE_RIGHT = 'pi pi-chevron-circle-right';
PrimeIcons.CHEVRON_CIRCLE_UP = 'pi pi-chevron-circle-up';
PrimeIcons.CHEVRON_DOWN = 'pi pi-chevron-down';
PrimeIcons.CHEVRON_LEFT = 'pi pi-chevron-left';
PrimeIcons.CHEVRON_RIGHT = 'pi pi-chevron-right';
PrimeIcons.CHEVRON_UP = 'pi pi-chevron-up';
PrimeIcons.CIRCLE = 'pi pi-circle';
PrimeIcons.CIRCLE_FILL = 'pi pi-circle-fill';
PrimeIcons.CLOCK = 'pi pi-clock';
PrimeIcons.CLONE = 'pi pi-clone';
PrimeIcons.CLOUD = 'pi pi-cloud';
PrimeIcons.CLOUD_DOWNLOAD = 'pi pi-cloud-download';
PrimeIcons.CLOUD_UPLOAD = 'pi pi-cloud-upload';
PrimeIcons.CODE = 'pi pi-code';
PrimeIcons.COG = 'pi pi-cog';
PrimeIcons.COMMENT = 'pi pi-comment';
PrimeIcons.COMMENTS = 'pi pi-comments';
PrimeIcons.COMPASS = 'pi pi-compass';
PrimeIcons.COPY = 'pi pi-copy';
PrimeIcons.CREDIT_CARD = 'pi pi-credit-card';
PrimeIcons.DATABASE = 'pi pi-database';
PrimeIcons.DESKTOP = 'pi pi-desktop';
PrimeIcons.DIRECTIONS = 'pi pi-directions';
PrimeIcons.DIRECTIONS_ALT = 'pi pi-directions-alt';
PrimeIcons.DISCORD = 'pi pi-discord';
PrimeIcons.DOLLAR = 'pi pi-dollar';
PrimeIcons.DOWNLOAD = 'pi pi-download';
PrimeIcons.EJECT = 'pi pi-eject';
PrimeIcons.ELLIPSIS_H = 'pi pi-ellipsis-h';
PrimeIcons.ELLIPSIS_V = 'pi pi-ellipsis-v';
PrimeIcons.ENVELOPE = 'pi pi-envelope';
PrimeIcons.EURO = 'pi pi-euro';
PrimeIcons.EXCLAMATION_CIRCLE = 'pi pi-exclamation-circle';
PrimeIcons.EXCLAMATION_TRIANGLE = 'pi pi-exclamation-triangle';
PrimeIcons.EXTERNAL_LINK = 'pi pi-external-link';
PrimeIcons.EYE = 'pi pi-eye';
PrimeIcons.EYE_SLASH = 'pi pi-eye-slash';
PrimeIcons.FACEBOOK = 'pi pi-facebook';
PrimeIcons.FAST_BACKWARD = 'pi pi-fast-backward';
PrimeIcons.FAST_FORWARD = 'pi pi-fast-forward';
PrimeIcons.FILE = 'pi pi-file';
PrimeIcons.FILE_EXCEL = 'pi pi-file-excel';
PrimeIcons.FILE_PDF = 'pi pi-file-pdf';
PrimeIcons.FILTER = 'pi pi-filter';
PrimeIcons.FILTER_FILL = 'pi pi-filter-fill';
PrimeIcons.FILTER_SLASH = 'pi pi-filter-slash';
PrimeIcons.FLAG = 'pi pi-flag';
PrimeIcons.FLAG_FILL = 'pi pi-flag-fill';
PrimeIcons.FOLDER = 'pi pi-folder';
PrimeIcons.FOLDER_OPEN = 'pi pi-folder-open';
PrimeIcons.FORWARD = 'pi pi-forward';
PrimeIcons.GITHUB = 'pi pi-github';
PrimeIcons.GLOBE = 'pi pi-globe';
PrimeIcons.GOOGLE = 'pi pi-google';
PrimeIcons.HASHTAG = 'pi pi-hashtag';
PrimeIcons.HEART = 'pi pi-heart';
PrimeIcons.HEART_FILL = 'pi pi-heart-fill';
PrimeIcons.HISTORY = 'pi pi-history';
PrimeIcons.HOME = 'pi pi-home';
PrimeIcons.ID_CARD = 'pi pi-id-card';
PrimeIcons.IMAGE = 'pi pi-image';
PrimeIcons.IMAGES = 'pi pi-images';
PrimeIcons.INBOX = 'pi pi-inbox';
PrimeIcons.INFO = 'pi pi-info';
PrimeIcons.INFO_CIRCLE = 'pi pi-info-circle';
PrimeIcons.INSTAGRAM = 'pi pi-instagram';
PrimeIcons.KEY = 'pi pi-key';
PrimeIcons.LINK = 'pi pi-link';
PrimeIcons.LINKEDIN = 'pi pi-linkedin';
PrimeIcons.LIST = 'pi pi-list';
PrimeIcons.LOCK = 'pi pi-lock';
PrimeIcons.LOCK_OPEN = 'pi pi-lock-open';
PrimeIcons.MAP = 'pi pi-map';
PrimeIcons.MAP_MARKER = 'pi pi-map-marker';
PrimeIcons.MICROSOFT = 'pi pi-microsoft';
PrimeIcons.MINUS = 'pi pi-minus';
PrimeIcons.MINUS_CIRCLE = 'pi pi-minus-circle';
PrimeIcons.MOBILE = 'pi pi-mobile';
PrimeIcons.MONEY_BILL = 'pi pi-money-bill';
PrimeIcons.MOON = 'pi pi-moon';
PrimeIcons.PALETTE = 'pi pi-palette';
PrimeIcons.PAPERCLIP = 'pi pi-paperclip';
PrimeIcons.PAUSE = 'pi pi-pause';
PrimeIcons.PAYPAL = 'pi pi-paypal';
PrimeIcons.PENCIL = 'pi pi-pencil';
PrimeIcons.PERCENTAGE = 'pi pi-percentage';
PrimeIcons.PHONE = 'pi pi-phone';
PrimeIcons.PLAY = 'pi pi-play';
PrimeIcons.PLUS = 'pi pi-plus';
PrimeIcons.PLUS_CIRCLE = 'pi pi-plus-circle';
PrimeIcons.POUND = 'pi pi-pound';
PrimeIcons.POWER_OFF = 'pi pi-power-off';
PrimeIcons.PRIME = 'pi pi-prime';
PrimeIcons.PRINT = 'pi pi-print';
PrimeIcons.QRCODE = 'pi pi-qrcode';
PrimeIcons.QUESTION = 'pi pi-question';
PrimeIcons.QUESTION_CIRCLE = 'pi pi-question-circle';
PrimeIcons.REDDIT = 'pi pi-reddit';
PrimeIcons.REFRESH = 'pi pi-refresh';
PrimeIcons.REPLAY = 'pi pi-replay';
PrimeIcons.REPLY = 'pi pi-reply';
PrimeIcons.SAVE = 'pi pi-save';
PrimeIcons.SEARCH = 'pi pi-search';
PrimeIcons.SEARCH_MINUS = 'pi pi-search-minus';
PrimeIcons.SEARCH_PLUS = 'pi pi-search-plus';
PrimeIcons.SEND = 'pi pi-send';
PrimeIcons.SERVER = 'pi pi-server';
PrimeIcons.SHARE_ALT = 'pi pi-share-alt';
PrimeIcons.SHIELD = 'pi pi-shield';
PrimeIcons.SHOPPING_BAG = 'pi pi-shopping-bag';
PrimeIcons.SHOPPING_CART = 'pi pi-shopping-cart';
PrimeIcons.SIGN_IN = 'pi pi-sign-in';
PrimeIcons.SIGN_OUT = 'pi pi-sign-out';
PrimeIcons.SITEMAP = 'pi pi-sitemap';
PrimeIcons.SLACK = 'pi pi-slack';
PrimeIcons.SLIDERS_H = 'pi pi-sliders-h';
PrimeIcons.SLIDERS_V = 'pi pi-sliders-v';
PrimeIcons.SORT = 'pi pi-sort';
PrimeIcons.SORT_ALPHA_DOWN = 'pi pi-sort-alpha-down';
PrimeIcons.SORT_ALPHA_ALT_DOWN = 'pi pi-sort-alpha-alt-down';
PrimeIcons.SORT_ALPHA_UP = 'pi pi-sort-alpha-up';
PrimeIcons.SORT_ALPHA_ALT_UP = 'pi pi-sort-alpha-alt-up';
PrimeIcons.SORT_ALT = 'pi pi-sort-alt';
PrimeIcons.SORT_ALT_SLASH = 'pi pi-sort-slash';
PrimeIcons.SORT_AMOUNT_DOWN = 'pi pi-sort-amount-down';
PrimeIcons.SORT_AMOUNT_DOWN_ALT = 'pi pi-sort-amount-down-alt';
PrimeIcons.SORT_AMOUNT_UP = 'pi pi-sort-amount-up';
PrimeIcons.SORT_AMOUNT_UP_ALT = 'pi pi-sort-amount-up-alt';
PrimeIcons.SORT_DOWN = 'pi pi-sort-down';
PrimeIcons.SORT_NUMERIC_DOWN = 'pi pi-sort-numeric-down';
PrimeIcons.SORT_NUMERIC_ALT_DOWN = 'pi pi-sort-numeric-alt-down';
PrimeIcons.SORT_NUMERIC_UP = 'pi pi-sort-numeric-up';
PrimeIcons.SORT_NUMERIC_ALT_UP = 'pi pi-sort-numeric-alt-up';
PrimeIcons.SORT_UP = 'pi pi-sort-up';
PrimeIcons.SPINNER = 'pi pi-spinner';
PrimeIcons.STAR = 'pi pi-star';
PrimeIcons.STAR_FILL = 'pi pi-star-fill';
PrimeIcons.STEP_BACKWARD = 'pi pi-step-backward';
PrimeIcons.STEP_BACKWARD_ALT = 'pi pi-step-backward-alt';
PrimeIcons.STEP_FORWARD = 'pi pi-step-forward';
PrimeIcons.STEP_FORWARD_ALT = 'pi pi-step-forward-alt';
PrimeIcons.STOP = 'pi pi-stop';
PrimeIcons.STOP_CIRCLE = 'pi pi-stop-circle';
PrimeIcons.SUN = 'pi pi-sun';
PrimeIcons.SYNC = 'pi pi-sync';
PrimeIcons.TABLE = 'pi pi-table';
PrimeIcons.TABLET = 'pi pi-tablet';
PrimeIcons.TAG = 'pi pi-tag';
PrimeIcons.TAGS = 'pi pi-tags';
PrimeIcons.TELEGRAM = 'pi pi-telegram';
PrimeIcons.TH_LARGE = 'pi pi-th-large';
PrimeIcons.THUMBS_DOWN = 'pi pi-thumbs-down';
PrimeIcons.THUMBS_UP = 'pi pi-thumbs-up';
PrimeIcons.TICKET = 'pi pi-ticket';
PrimeIcons.TIMES = 'pi pi-times';
PrimeIcons.TIMES_CIRCLE = 'pi pi-times-circle';
PrimeIcons.TRASH = 'pi pi-trash';
PrimeIcons.TWITTER = 'pi pi-twitter';
PrimeIcons.UNDO = 'pi pi-undo';
PrimeIcons.UNLOCK = 'pi pi-unlock';
PrimeIcons.UPLOAD = 'pi pi-upload';
PrimeIcons.USER = 'pi pi-user';
PrimeIcons.USER_EDIT = 'pi pi-user-edit';
PrimeIcons.USER_MINUS = 'pi pi-user-minus';
PrimeIcons.USER_PLUS = 'pi pi-user-plus';
PrimeIcons.USERS = 'pi pi-users';
PrimeIcons.VIDEO = 'pi pi-video';
PrimeIcons.VIMEO = 'pi pi-vimeo';
PrimeIcons.VOLUME_DOWN = 'pi pi-volume-down';
PrimeIcons.VOLUME_OFF = 'pi pi-volume-off';
PrimeIcons.VOLUME_UP = 'pi pi-volume-up';
PrimeIcons.WALLET = 'pi pi-wallet';
PrimeIcons.WHATSAPP = 'pi pi-whatsapp';
PrimeIcons.WIFI = 'pi pi-wifi';
PrimeIcons.WINDOW_MAXIMIZE = 'pi pi-window-maximize';
PrimeIcons.WINDOW_MINIMIZE = 'pi pi-window-minimize';
PrimeIcons.YOUTUBE = 'pi pi-youtube';
class FilterOperator {}
FilterOperator.AND = 'and';
FilterOperator.OR = 'or';
class Header {}
Header.ɵfac = function Header_Factory(t) {
  return new (t || Header)();
};
Header.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Header,
  selectors: [["p-header"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function Header_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Header, [{
    type: Component,
    args: [{
      selector: 'p-header',
      template: '<ng-content></ng-content>'
    }]
  }], null, null);
})();
class Footer {}
Footer.ɵfac = function Footer_Factory(t) {
  return new (t || Footer)();
};
Footer.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Footer,
  selectors: [["p-footer"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function Footer_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Footer, [{
    type: Component,
    args: [{
      selector: 'p-footer',
      template: '<ng-content></ng-content>'
    }]
  }], null, null);
})();
class PrimeTemplate {
  constructor(template) {
    this.template = template;
  }
  getType() {
    return this.name;
  }
}
PrimeTemplate.ɵfac = function PrimeTemplate_Factory(t) {
  return new (t || PrimeTemplate)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
PrimeTemplate.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PrimeTemplate,
  selectors: [["", "pTemplate", ""]],
  inputs: {
    type: "type",
    name: [i0.ɵɵInputFlags.None, "pTemplate", "name"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrimeTemplate, [{
    type: Directive,
    args: [{
      selector: '[pTemplate]',
      host: {}
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, {
    type: [{
      type: Input
    }],
    name: [{
      type: Input,
      args: ['pTemplate']
    }]
  });
})();
class SharedModule {}
SharedModule.ɵfac = function SharedModule_Factory(t) {
  return new (t || SharedModule)();
};
SharedModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SharedModule
});
SharedModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [Header, Footer, PrimeTemplate],
      declarations: [Header, Footer, PrimeTemplate]
    }]
  }], null, null);
})();
class TreeDragDropService {
  constructor() {
    this.dragStartSource = new Subject();
    this.dragStopSource = new Subject();
    this.dragStart$ = this.dragStartSource.asObservable();
    this.dragStop$ = this.dragStopSource.asObservable();
  }
  startDrag(event) {
    this.dragStartSource.next(event);
  }
  stopDrag(event) {
    this.dragStopSource.next(event);
  }
}
TreeDragDropService.ɵfac = function TreeDragDropService_Factory(t) {
  return new (t || TreeDragDropService)();
};
TreeDragDropService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TreeDragDropService,
  factory: TreeDragDropService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeDragDropService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ConfirmEventType, ConfirmationService, ContextMenuService, FilterMatchMode, FilterOperator, FilterService, Footer, Header, MessageService, OverlayService, PrimeIcons, PrimeNGConfig, PrimeTemplate, SharedModule, TranslationKeys, TreeDragDropService };
