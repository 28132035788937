<b-shared-overlay
	overlayId="QAToolbar"
	[routable]="true"
	overlayClass="overlay-content--sidebar"
	containerClass="sidebar--xs sidebar--sticky-footer"
	sidebarControlsClass="container pt-0"
	type="sidebarSecondary"
	[showBackground]="true"
	[blockScroll]="true"
	[closeOnEsc]="true"
	[closeOnOutsideClick]="true"
>
	<ng-template #header>
		<h3 class="sidebar__title">QA Toolbar</h3>
	</ng-template>

	<ng-template #content>
		<b-shared-toolbar-content #toolbarContent>
			<ng-template *ngIf="contentRef" #content>
				<ng-container [ngTemplateOutlet]="contentRef"></ng-container>
			</ng-template>

			<ng-template *ngIf="beforeContentRef" #beforeContent>
				<ng-container [ngTemplateOutlet]="beforeContentRef"></ng-container>
			</ng-template>
		</b-shared-toolbar-content>
	</ng-template>

	<ng-template #footer>
		<ng-container>
			<div class="row lg:row--gx-20 md:row--gx-16 row--gx-12 flex-grow-1">
				<div class="col-12">
					<button
						b-shared-button
						class="btn btn--secondary"
						(click)="copy()"
					>
						<ng-container *ngIf="(dataCopied | async); else copyTemplate">Готово</ng-container>
						<ng-template #copyTemplate>Скопировать</ng-template>
					</button>
				</div>
			</div>
		</ng-container>
	</ng-template>
</b-shared-overlay>
