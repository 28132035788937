import {Injectable} from '@angular/core';
import {innOnlyZeroes, innTwoFirstCharactersIsZeroes, validInn, validLengthInn} from '../validators/inn';
import {
	fifteenDightOgrn,
	thirteenDightOgrn,
	validLengthOgrn,
	validOgrn,
	validOgrnip,
	validOgrnValue,
} from '../validators/ogrn';
import {AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isCyrillicOnly} from '../validators/common';
import {MaskService} from './mask.service';

type ValidatorResult = ValidationErrors | null;

@Injectable({
	providedIn: 'root',
})
export class ValidatorsService {
	constructor(
		private maskService: MaskService,
	) {
	}

	fieldLength(...validLength: number[]) {
		return (control: UntypedFormControl) => {
			const valueLength = control.value?.toString()?.length || 0;

			const isValid = validLength.some((length) => valueLength === length);

			if (!isValid) {
				return {
					fieldLength: true,
				};
			}

			return null;
		};
	}

	equalOfAnotherControl(getAnotherControl: () => UntypedFormControl | AbstractControl) {
		return (control: UntypedFormControl | AbstractControl) => {
			const anotherControl = getAnotherControl?.();

			if (!anotherControl) return null;

			const isValid = anotherControl.value === control.value;

			if (!isValid) {
				return {
					notEqualOfAnotherControl: true,
				};
			}

			return null;
		};
	}

	notEqualOfAnotherControl(getAnotherControl: () => UntypedFormControl | AbstractControl) {
		return (control: UntypedFormControl | AbstractControl) => {
			const anotherControl = getAnotherControl?.();

			if (!anotherControl) return null;

			const isValid = anotherControl.value !== control.value;

			if (!isValid) {
				return {
					equalOfAnotherControl: true,
				};
			}

			return null;
		};
	}

	isCyrillicOnly(control: UntypedFormControl): ValidatorResult {
		const isValid = isCyrillicOnly(control.value);

		if (!isValid) {
			return {
				isNotOnlyCyrillic: true,
			};
		}

		return null;
	}

	/* Проверка на допустимые символы:
  0123456789
  АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЫЪЭЮЯабвгдеёжзийклмнопрстуфхцчшщьыъэюя
  ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz
  !"#$%&'( )*+,-./:;<=>?@[]^_`{|}~№
  [пробел]
  */
	validChars(control: UntypedFormControl): ValidationErrors | null {
		if (!control.value?.length) {
			return null;
		}
		return /^[a-zA-ZёЁа-яА-Я0-9\s!"#$%&'( )*+,-./:;<=>?@[\]\\^_`{|}~№«»]+$/.test(control.value)
			? null
			: {invalidChars: true};
	}

	// Не может состоять только из цифр и/или спецсимволов и пробелов
	validCharsName(control: UntypedFormControl): ValidationErrors | null {
		if (!control.value?.length) {
			return null;
		}

		const valAfterReplace = control.value.replace(
			/[0-9\s!"#$%&'( )*+,-./:;<=>?@[\]\\^_`{|}~№«»]+/gi,
			'',
		);

		return valAfterReplace.length ? null : {containsOnlyDigitsOrSpec: true};
	}

	validLengthInn({lengthOfFiveIsAvailable = false}: { lengthOfFiveIsAvailable?: boolean }) {
		return (control: UntypedFormControl): ValidatorResult => {
			const inn = control.value?.toString();
			return validLengthInn({inn, lengthOfFiveIsAvailable}) ? null : {innLength: true};
		};
	}

	innOnlyZeroes(control: UntypedFormControl): ValidatorResult {
		const inn = control.value?.toString();
		return innOnlyZeroes(inn) ? null : {onlyZeroes: true};
	}

	innTwoFirstCharactersIsZeroes(control: UntypedFormControl): ValidatorResult {
		const inn = control.value?.toString();
		return innTwoFirstCharactersIsZeroes(inn) ? null : {twoFirstCharactersIsZeroes: true};
	}

	validInn(control: UntypedFormControl): ValidatorResult {
		const inn = control.value?.toString();
		return validInn(inn) ? null : {incorrectInn: true};
	}

	validLengthOgrn(control: UntypedFormControl): ValidatorResult {
		const ogrn = control.value?.toString();
		return validLengthOgrn(ogrn) ? null : {ogrnLength: true};
	}

	fifteenDightOgrn(control: UntypedFormControl): ValidatorResult {
		const ogrn = control.value?.toString();
		return fifteenDightOgrn(ogrn) ? null : {invalidFifteenDightOgrn: true};
	}

	thirteenDightOgrn(control: UntypedFormControl): ValidatorResult {
		const ogrn = control.value?.toString();
		return thirteenDightOgrn(ogrn) ? null : {invalidThirteenDightOgrn: true};
	}

	validOgrn(control: UntypedFormControl): ValidatorResult {
		const ogrn = control.value?.toString();
		return validOgrn(ogrn) ? null : {invalidOgrn: true};
	}

	validOgrnip(control: UntypedFormControl): ValidatorResult {
		const ogrn = control.value?.toString();
		return validOgrnip(ogrn) ? null : {invalidOgrnip: true};
	}

	validOgrnValue(control: UntypedFormControl): ValidatorResult {
		const ogrn = control.value?.toString();
		if (!ogrn || (ogrn.length !== 13 && ogrn.length !== 15) || !/^\d+$/.test(ogrn)) {
			return {ogrn: true};
		}

		if (ogrn.length === 15) {
			return fifteenDightOgrn(ogrn) ? null : {invalidFifteenDightOgrn: true};
		} else if (ogrn.length === 13) {
			return thirteenDightOgrn(ogrn) ? null : {invalidThirteenDightOgrn: true};
		}
	}

	// Валидация контрола, в котором можно ввести и ОГРН, и ИНН, и название компании
	companyRequisities(control: UntypedFormControl): ValidationErrors | null {
		const value: string = control.value?.toString();

		// валидно, если в инпуте не только цифры (идет поиск по названию компании)
		if (!/^\d+$/.test(value)) {
			return null;
		}

		if (!value) {
			return {invalidInnAndOgrn: true};
		}

		let isValid;

		if (validLengthInn({inn: value})) {
			isValid = innTwoFirstCharactersIsZeroes(value) && validInn(value);
		} else if (validLengthOgrn(value)) {
			isValid = validOgrnValue(value);
		} else {
			isValid = false;
		}

		return isValid ? null : {invalidInnAndOgrn: true};
	}

	mobilePhone(withPrefix = true) {
		return (control: UntypedFormControl): ValidationErrors | null => {
			const value: string = control.value?.toString().replace(/\D/g, '');
			if (!value) {
				return {invalidMobilePhone: true};
			}
			const isValid = withPrefix ? /^((8|(\+)?7)[\- ]?)?9$/.test(value) : value.startsWith('9');
			return isValid ? null : {invalidMobilePhone: true};
		};
	}

	/**
	 * Валидатор контрольной суммы номера счёта
	 */
	controlKeyValidator(bank: UntypedFormControl): ValidatorFn {
		const checkControlKeyBankAccount = (bankAccount: string, bik: string): boolean => {
			if (!bankAccount || !bik) {
				return false;
			}

			const coefficient = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
			let controlNumber = bik.toString().substr(-3);

			if (parseInt(controlNumber, 10) <= 2) {
				controlNumber = '0'.concat(bik.toString().substr(-5, 2));
			}

			const tempAcccount = controlNumber.concat(bankAccount);

			let sum = 0;

			for (let i = 0; i < tempAcccount.length; i++) {
				sum = sum + ((Number(tempAcccount.charAt(i)) * coefficient[i]) % 10);
			}

			return sum % 10 === 0;
		};

		return (control: UntypedFormControl): ValidationErrors | null => {
			const val = control.value?.toString() || '';

			if (bank.invalid || val.length < 20) {
				return null;
			}

			if (!checkControlKeyBankAccount(control.value, bank.value?.bic)) {
				return {invalidControlKey: true};
			}

			return null;
		};
	}

	/**
	 * Валидатор маски счёта
	 */
	bankAccountMaskValidator(inn: UntypedFormControl | string): ValidatorFn {
		const _inn = typeof inn === 'string' ? inn : inn?.value;
		return (control: UntypedFormControl): ValidationErrors | null => {
			const val = control.value?.toString() || '';

			if (val.length === 20) {
				if (/0{20}/.test(val)) {
					return {onlyZero: true};
				}

				if (this.maskService.nonResidentAccounts.some((m: RegExp) => m.test(val))) {
					return {nonResidentAccount: true};
				}

				if (!_inn) {
					return null;
				}

				if (this.maskService.flAccounts.some((m: RegExp) => m.test(val))) {
					if (_inn.length === 10) {
						return {receiverUlWithFlAccount: true};
					}

					if (_inn.length === 12) {
						return {receiverIpWithFlAccount: true};
					}
				}

				if (_inn.length === 10 &&
					this.maskService.ipAccounts.some((m: RegExp) => m.test(val))) {
					return {receiverUlWithIpAccount: true};
				}

				if (_inn.length === 12 &&
					this.maskService.ulAccounts.some((m: RegExp) => m.test(val))) {
					return {receiverIpWithUlAccount: true};
				}
			}

			return null;
		};
	}

	arrayRequiredTrue(params: { atLeastOne?: boolean } = {}): ValidatorFn {
		return (control: UntypedFormControl): ValidationErrors | null => {
			const {atLeastOne} = params;
			const validation = control.value?.reduce((accumulator, value) => {
				if (atLeastOne) {
					return accumulator || value;
				} else {
					return accumulator && value;
				}
			}, !atLeastOne);

			if (!validation) {
				return {arrayRequiredTrue: true};
			}

			return null;
		};
	}
}
