import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalendarComponent } from './calendar.component';
import { SvgModule } from '../svg/svg.module';
import { ButtonModule } from '../button/button.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [CalendarComponent],
	imports: [
		CommonModule,
		FormsModule,
		SvgModule,
		ButtonModule,
		DropdownModule,
	],
	exports: [CalendarComponent],
})
export class CalendarModule {}
