import { Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { ScrollService } from './scroll.service';

export enum SwipeSide {
	top = 'top',
	bottom = 'bottom',
	left = 'left',
	right = 'right',
}

@Injectable({
	providedIn: 'root',
})
export class TouchService {

	constructor(
		private scrollService: ScrollService,
	) {}

	getArea(swipeSide: SwipeSide, ratio: number, rect: DOMRect) {
		let start: number;
		let end: number;

		switch (swipeSide) {
		case SwipeSide.left:
			start = rect.left;
			end = this.getBorderToHide(swipeSide, ratio, rect);
			break;
		case SwipeSide.right:
			start = this.getBorderToHide(swipeSide, ratio, rect);
			end = rect.right;
			break;
		case SwipeSide.bottom:
			start = this.getBorderToHide(swipeSide, ratio, rect);
			end = rect.bottom;
			break;
		case SwipeSide.top:
		default:
			start = rect.top;
			end = this.getBorderToHide(swipeSide, ratio, rect);
			break;
		}

		return { start, end };
	}

	getBorderToHide(swipeSide: SwipeSide, ratio: number, rect: DOMRect) {
		switch (swipeSide) {
		case SwipeSide.left:
			return rect.left + rect.width * ratio;
		case SwipeSide.right:
			return rect.right - rect.width * ratio;
		case SwipeSide.bottom:
			return rect.bottom - rect.height * ratio;
		case SwipeSide.top:
		default:
			return rect.top + rect.height * ratio;
		}
	}

	getTouchCoords(swipeSide: SwipeSide, touch: Touch) {
		switch (swipeSide) {
		case SwipeSide.left:
		case SwipeSide.right:
			return touch.clientX;
		case SwipeSide.bottom:
		case SwipeSide.top:
		default:
			return touch.clientY;
		}
	}

	getTranslate(swipeSide: SwipeSide, value: number) {
		switch (swipeSide) {
		case SwipeSide.left:
		case SwipeSide.right:
			return `translateX(${value}px)`;
		case SwipeSide.bottom:
		case SwipeSide.top:
		default:
			return `translateY(${value}px)`;
		}
	}

	getEndPosition(swipeSide: SwipeSide, rect: DOMRect) {
		switch (swipeSide) {
		case SwipeSide.left:
			return rect.width + (this.scrollService.getViewportSize().viewportWidth - rect.left);
		case SwipeSide.right:
			return -(rect.width + rect.left);
		case SwipeSide.bottom:
			return -(rect.height + rect.top);
		case SwipeSide.top:
		default:
			return rect.height + (this.scrollService.getViewportSize().viewportHeight - rect.bottom);
		}
	}

	checkNeedReverse(swipeSide: SwipeSide): boolean {
		return [SwipeSide.bottom, SwipeSide.right].includes(swipeSide);
	}

	addCloseEventWhenSwiping({
		targetElement,
		onSwipe,
		startDragging,
		swipeLength,
		canReverseDrag = true,
		hideElement = false,
		swipeSide = SwipeSide.top,
	}: {
		targetElement: HTMLElement; // DOM элемент
		onSwipe: () => void; // Функция, вызывающаяся после свайпа
		startDragging: number; // Длина свайпа (относительно targetElement) для начала дропа элемента
		swipeLength: number; // Длина свайпа (относительно targetElement) для скрытия элемента и вызова функции onSwipe
		canReverseDrag?: boolean; // Drg&Drop работает в обратную сторону
		hideElement?: boolean; // Скрывать элемент после свайпа
		swipeSide?: SwipeSide; // Сторона начала Drag&drop'a
	}): Subscription {
		const subscriptions = new Subscription();
		let rect;

		let canStartDragging;
		let transform = 0;
		let startTouchCoords: number;
		let dragCoefficient = 1;

		const touchStart = fromEvent(targetElement, 'touchstart').subscribe({
			next: (event: TouchEvent) => {
				const touchEvent = event.changedTouches[0];
				startTouchCoords = this.getTouchCoords(swipeSide, touchEvent);
				rect = targetElement.getBoundingClientRect();

				const areaToStartDragging = this.getArea(swipeSide, startDragging, rect);

				canStartDragging = startTouchCoords >= areaToStartDragging.start && startTouchCoords <= areaToStartDragging.end;

				if (canStartDragging) {
					event.preventDefault();
				}
			},
		});

		const touchMove = fromEvent(targetElement, 'touchmove').subscribe({
			next: (event: TouchEvent) => {
				const touchEvent = event.changedTouches[0];
				const touchMoveCoords = this.getTouchCoords(swipeSide, touchEvent);

				if (canStartDragging) {
					event.preventDefault();
					const isReverseDrag = this.checkNeedReverse(swipeSide) ?
						touchMoveCoords > startTouchCoords
						: touchMoveCoords <= startTouchCoords;
					if (!canReverseDrag && isReverseDrag) {
						return;
					}
					dragCoefficient = isReverseDrag ? 0.05 : 1;
					transform = -(startTouchCoords - touchMoveCoords) * dragCoefficient;
					targetElement.style.transform = this.getTranslate(swipeSide, transform);
				}
			},
		});

		subscriptions.add(
			fromEvent(targetElement, 'touchend').subscribe({
				next: (event: TouchEvent) => {
					if (canStartDragging) {
						const borderToHideElement = this.getBorderToHide(swipeSide, swipeLength, rect);
						const touchEvent = event.changedTouches[0];
						const touchEndCoords = this.getTouchCoords(swipeSide, touchEvent);

						const isOppositeSide = this.checkNeedReverse(swipeSide) ?
							touchEndCoords > startTouchCoords :
							touchEndCoords <= startTouchCoords;

						const isBeforeBorder = this.checkNeedReverse(swipeSide) ?
							touchEndCoords > borderToHideElement :
							touchEndCoords <= borderToHideElement;

						if (isOppositeSide || isBeforeBorder) {
							const resetAnimation = targetElement.animate(
								[
									{ transform: this.getTranslate(swipeSide, transform), offset: 0 },
									{ transform: this.getTranslate(swipeSide, 0), offset: 1 },
								],
								{
									duration: isOppositeSide ? 100 : 150,
									iterations: 1,
									easing: 'ease-in',
								},
							);

							transform = 0;
							targetElement.style.transform = this.getTranslate(swipeSide, transform);
							resetAnimation.play();
						} else {
							const duration = 200;
							const start = transform;
							transform = this.getEndPosition(swipeSide, rect);

							const resetAnimation = targetElement.animate(
								[
									{ transform: this.getTranslate(swipeSide, start), opacity: getComputedStyle(targetElement).opacity || 1, offset: 0 },
									{ transform: this.getTranslate(swipeSide, transform), opacity: 0, offset: 1 },
								],
								{
									duration,
									iterations: 1,
									easing: 'ease-in',
								},
							);

							resetAnimation.play();
							targetElement.style.transform = this.getTranslate(swipeSide, transform);
							touchMove.unsubscribe();
							touchStart.unsubscribe();
							setTimeout(() => {
								if (hideElement && targetElement?.style) {
									targetElement.style.display = 'none';
									targetElement.style.visibility = 'hidden';
								}
								onSwipe?.();
							}, duration);
						}
					}
				},
			})
		);

		subscriptions.add(touchStart);
		subscriptions.add(touchMove);

		return subscriptions;
	}
}
