import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
	selector: 'b-shared-validation-error',
	template: '<p><small class="p-invalid" *ngIf="showError">{{ errorText }}</small></p>',
})
export class ValidationErrorComponent {
	@Input() errors: { [key: string]: string };
	@Input() control: NgControl;

	get showError() {
		return this.control?.invalid && this.control?.dirty;
	}

	get errorText() {
		const key = Object.keys(this.errors).find((errorKey) =>
			Boolean(this.control?.errors[errorKey]),
		);
		return key ? this.errors[key] : null;
	}
}
