export enum SuggestPosition {
	auto = 'auto', // слой будет всплывать туда, где больше места в видимой области окна браузера
	above = 'above', // вверх
	under = 'under', // вниз
}

export enum AutocompleteLoaderType {
	none = 'none', // нет лоадера
	input = 'input', // лоадер в инпуте
	overlay = 'overlay', // лоадер в оверлее
	inline = 'inline', // кастомный встроенный  контент после инпута
}

export enum OverlayAnimationLifecycleStep {
	destroyed = 'destroyed',
	initAnimation = 'initAnimation',
	static = 'static',
	destroyAnimation = 'destroyAnimation',
}

/** отображение результатов поиска */
export enum SuggestView {
	overlay = 'overlay', // результаты отображаются в всплывающем слое
	inline = 'inline', // результаты отображаются после инпута
}
