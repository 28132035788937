import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { OverlayModule } from '../overlay/overlay.module';
import { SvgModule } from '../svg/svg.module';
import { ToolbarContentComponent } from './toolbar-content/toolbar-content.component';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
	declarations: [
		ToolbarComponent,
		ToolbarContentComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		OverlayModule,
		ButtonModule,
		CheckboxModule,
		SvgModule,
	],
	exports: [ToolbarComponent],
})
export class ToolbarModule {}
