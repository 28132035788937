import {animate, style, transition, trigger} from '@angular/animations';

const TRANSITION = '{{duration}}ms ease-in-out';
const DURATION = {params: {duration: 300}};
const STAGGER = 300;


export const animationFadeIn = trigger('fadeIn', [
	transition(':leave', [
		style({opacity: 1}), animate(TRANSITION, style({opacity: 0}))], DURATION),
	transition(':enter', [
		style({opacity: 0}), animate(TRANSITION, style({opacity: 1}))], DURATION)
]);
