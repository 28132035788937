<ng-container *ngIf="isEmojiSupported !== null">
	<img
		*ngIf="!isEmojiSupported; else originalEmoji"
		[@fade]
		src="assets/img/emoji/{{ code }}.svg"
		[alt]="emoji"
		[class]="classInner"
		ngClass="emoji__img"
	>
	<ng-template #originalEmoji>
		<span [class]="classInner">{{ emoji }}</span>
	</ng-template>
</ng-container>
