import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'b-shared-emoji-view',
	templateUrl: './emoji-view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fade', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('300ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('300ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
})
export class EmojiViewComponent implements OnChanges {
	code = '';

	@HostBinding('class.emoji') classes = 'emoji';

	/**
	 * Поддержка эмодзи — при отсутствии выводится svg
	 *
	 */
	@Input() isEmojiSupported: boolean;

	/**
	 * Эмодзи
	 *
	 */
	@Input() emoji: string;

	/**
	 * Классы для внутреннего блока
	 *
	 */
	@Input() classInner: string;

	ngOnChanges() {
		let comp: number;
		if (this.emoji.length === 1) {
			comp = this.emoji.charCodeAt(0);
		}
		comp =
			(this.emoji.charCodeAt(0) - 0xd800) * 0x400 + (this.emoji.charCodeAt(1) - 0xdc00) + 0x10000;
		if (!comp || comp < 0) {
			comp = this.emoji.charCodeAt(0);
		}
		this.code = comp.toString(16);
	}
}
