import { Directive, Input, ElementRef, OnDestroy, OnChanges, Renderer2, Inject, inject, afterNextRender } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImageService } from '../image/image.service';
import { ImageExtension } from '../image/models/image.enums';
import { WINDOW } from '../../../tokens';
import {DeviceService} from '../../../services';


@Directive({
	selector: '[sharedVideo]'
})
export class VideoDirective implements OnChanges, OnDestroy {

	subsriptions = new Subscription();
	lazyVideoObserver: IntersectionObserver;
	extensions = [
		{ ext: '.mp4', mime: 'video/mp4;' },
		{ ext: '.webm', mime: 'video/webm;' },
	];
	webpSupported: boolean | null = null;
	avifSupported: boolean | null = null;

	/**
	 * URL к видео файлу.
	 * Необходимо указать только один url, файлы с другими форматами автомтически появятся в тегах <source>
	 *
	 * @example
	 * source="/assets/muted-video/app-ui/app-ui.mp4"
	 */
	@Input() source: string;

	/**
	 * Отклонение от вьюпорта при ленивой загрузке
	 */
	@Input() rootMargin: string = '120%';

	/**
	 * Число или массив чисел, указывающий, при каком проценте видимости целевого элемента должна начаться загрузка постера и видео
	 */
	@Input() threshold: number | number[] = 0.01;

	/**
	 * url к превью постеру
	 */
	@Input() preview: string;
	
	private deviceService = inject(DeviceService);

	constructor(
		private elementRef: ElementRef<HTMLVideoElement>,
		private imageService: ImageService,
		private renderer: Renderer2,
		@Inject(WINDOW) private _window: Window,
	) {
		afterNextRender(() => {
			this.subsriptions.add(
				this.imageService.checkWebpSupport().subscribe(webpSupported => this.webpSupported = webpSupported)
			);
	
			this.subsriptions.add(
				this.imageService.checkAvifSupport().subscribe(avifSupported => this.avifSupported = avifSupported)
			);
		});
	}

	ngOnChanges() {
		if(!this.deviceService.isServer) {
			const targetVideoElement = this.elementRef.nativeElement;

			if (targetVideoElement.hasAttribute('poster') && !this.preview) {
				console.error('sharedVideo: вместо атрибута \'poster\' нужно передать параметр \'preview\'');
				return;
			}

			if (!this.source) {
				console.error('sharedVideo: необходимо указать параметр source');
				return;
			}

			this.deleteVideoChildrens();


			if (!this.intersectionObserverIsSupported) {
				this.loadPosterAndVideo(targetVideoElement);
				return;
			}

			this.lazyVideoObserver?.unobserve?.(this.elementRef.nativeElement);
			this.lazyVideoObserver = new IntersectionObserver((entries, observer) => {
				const targetEntry = entries.find(entry => entry.target === targetVideoElement);

				if (!targetEntry || !targetEntry?.isIntersecting) return;

				observer?.unobserve(targetEntry.target);

				this.loadPosterAndVideo(targetVideoElement);
			}, {
				root: null, // null > viewport
				rootMargin: this.rootMargin,
				threshold: this.threshold,
			});

			this.lazyVideoObserver.observe(this.elementRef.nativeElement);
		}
	}

	ngOnDestroy() {
		this.lazyVideoObserver?.unobserve?.(this.elementRef.nativeElement);
		this.subsriptions?.unsubscribe();
	}

	loadPosterAndVideo(targetVideoElement: HTMLVideoElement) {
		if (this.preview) {
			targetVideoElement.poster = this.getPosterUrl(this.preview);
		}

		this.addVideoSources();

		targetVideoElement?.load?.();
	}

	addVideoSources() {
		this.sources.forEach(source => {
			const sourceElement = this.renderer.createElement('source');
			sourceElement.src = source.path;
			sourceElement.type = source.mime;
			this.elementRef.nativeElement.append(sourceElement);
		});
	}

	deleteVideoChildrens() {
		this.elementRef?.nativeElement?.pause();
		Object.values(this.elementRef?.nativeElement?.children)?.forEach(element => element?.remove());
	}

	get intersectionObserverIsSupported(): boolean {
		if(this.deviceService.isServer) return;
		return 'IntersectionObserver' in this._window;
	}

	get sources() {
		if(this.deviceService.isServer) return;
		return this.extensions.map(item => ({
			path: this.source.replace(/\.[^/.]+$/, item.ext),
			mime: item.mime,
		}));
	}

	getPosterUrl(url: string): string {
		if (this.avifSupported) return url.replace(/\.[^.]+$/, `.${ImageExtension.avif}`);
		if (this.webpSupported) return url.replace(/\.[^.]+$/, `.${ImageExtension.webp}`);
		return url;
	}

}
