import {Routes} from '@angular/router';
import {SeoMetaResolver} from '../resolvers';

interface MetaTags {
	title: string;
	description: string;
	keywords: string;
	viewport?: string;
	ogTitle?: string;
	ogDescription?: string;
	ogImage?: string;
	ogAlt?: string;
	ogUrl?: string;
}

interface ExpandRoutesConfig {
	routes: Routes;
	defaultMeta: MetaTags;
}

export const expandRoutes = ({routes, defaultMeta}: ExpandRoutesConfig): Routes => {
	return routes.map(route => {
		const resolvers = route?.resolve || {};
		const routeData = route?.data || {};

		route.resolve = {
			...resolvers,
			seoMetaResolver: SeoMetaResolver
		};

		route.data = {
			...defaultMeta,
			...routeData,
		};

		return route;
	});
};
