import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import { SharedClassDirective } from './shared-class.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [SharedClassDirective],
	exports: [SharedClassDirective],
})
export class SharedClassModule {}
