import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreButtonsComponent } from './store-buttons.component';
import { SvgModule } from '../svg/svg.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
	declarations: [
		StoreButtonsComponent,
	],
	imports: [
		CommonModule,
		SvgModule,
		ButtonModule,
	],
	exports: [
		StoreButtonsComponent,
	]
})
export class StoreButtonsModule {}
