import {
	Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation,
	Input, ViewChild, ElementRef, Inject, ChangeDetectorRef, Renderer2, RendererStyleFlags2,
} from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, distinctUntilChanged, fromEvent, map, Subscription } from 'rxjs';
import { DatePickerMode } from './models/DatePickerMode';
import { DateRange } from './models/DateRange';
import { DatePickerValue } from './models/DatePickerValue';
import { DatePipe, DOCUMENT } from '@angular/common';
import { DisplayedData } from './models/DisplayedData';
import { ExtendedDate } from './models/ExtendedDate';
import { DatePickerPosition } from './models/DatePickerPosition';
import {DateService, ScrollService} from '../../../services';
import { OverlayService2 } from '../overlay/overlay.service';
import { UnavailableDates } from './models/UnavailableDates';
import { Guid } from 'guid-typescript';

@Component({
	selector: 'b-shared-date-picker',
	templateUrl: './date-picker.component.html',
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('200ms ease-out', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: DatePickerComponent,
		}
	],
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

	/** формат отображения выбранной даты: https://angular.io/api/common/DatePipe#custom-format-options */
	@Input() dateFormat: string = 'd MMMM yyyy';

	/** подсказка в инпуте, пока не выбрана дата */
	@Input() placeholder: string = 'Дата';

	/** флаг для отображения кнопки сброса значения */
	@Input() clearable: boolean = true;

	/** значение при сбросе */
	@Input() resetValue: DatePickerValue = null;

	/** режим: выбор одной даты или диапазона */
	@Input() mode: DatePickerMode | keyof typeof DatePickerMode = DatePickerMode.oneDate;

	/** максимальное количество дней для выбора в режиме multipleDates */
	@Input() maxDateCount: number | null = null;

	/** режим позиционирования календаря */
	@Input() position: DatePickerPosition | keyof typeof DatePickerPosition = DatePickerPosition.fitsWithBottomPriority;

	/* aлаг для вкл/выкл режима закрытия слоя при нажатии на Escape **/
	@Input() closeOnEsc: boolean = true;

	/** года, доступные для выбора в дропдауне */
	@Input() yearsList: number[] | null = null;

	/** даты, недоступные для выбора */
	@Input() unavailableDates: UnavailableDates = [];

	@Input() inputTriggerNgClass = {};
	@Input() inputTriggerClass: string | null = null;
	@Input() containerDisabledClass: string | null = 'field--disabled';
	@Input() containerClassWithNonEmptyValue: string | null = 'field--title';

	readonly fieldValue$ = new BehaviorSubject<DatePickerValue>(null);
	private fieldValue = this.fieldValue$
		.asObservable()
		.pipe(distinctUntilChanged());
	private get _fieldValue(): DatePickerValue {
		return this.fieldValue$.getValue();
	}

	public inputValue = this.fieldValue.pipe(
		map(fieldValue => {
			if (typeof fieldValue === 'string') return fieldValue;

			if (fieldValue instanceof Date) {
				return this.datePipe.transform(fieldValue, this.dateFormat);
			}

			if (Array.isArray(fieldValue)) {
				const datesList = fieldValue
					.map(date => this.datePipe.transform(date, this.dateFormat))
					.join(', ');

				return datesList || null;
			}

			return this.parseDateRange(fieldValue);
		}),
		distinctUntilChanged(),
	);

	public fieldIsClearable = this.fieldValue.pipe(
		map(value => Boolean(this.clearable && this.resetValue !== value)),
		distinctUntilChanged(),
	);

	public valueIsSelected = this.fieldValue.pipe(
		map(value => value !== null),
		distinctUntilChanged(),
	);

	private displayedData$ = new BehaviorSubject<DisplayedData | null>(null);
	public displayedData = this.displayedData$
		.asObservable()
		.pipe(distinctUntilChanged());
	private get _displayedData(): DisplayedData | null {
		return this.displayedData$.getValue();
	}

	private overlayIsOpened$ = new BehaviorSubject<boolean>(false);
	public overlayIsOpened = this.overlayIsOpened$
		.asObservable()
		.pipe(distinctUntilChanged());
	private get _overlayIsOpened(): boolean {
		return this.overlayIsOpened$.getValue();
	}

	private onChange = (value: DatePickerValue) => {};
	private onTouched = () => {};
	private touched = false;
	private disabled: boolean | null = null;
	subscriptions = new Subscription();
	calendarInvisible: boolean = true;
	animationInProcess: boolean = false;
	yearsDropdownModel: Date | null = null;
	arrowNavigationGroup = `${Guid.create().toString()}-DatePicker`;

	calendarNgClass = {};

	@ViewChild('calendarPanel') calendarPanel: ElementRef<HTMLElement>;
	@ViewChild('inputTrigger') inputTrigger: ElementRef<HTMLElement>;

	constructor(
		private datePipe: DatePipe,
		private elementRef: ElementRef<HTMLElement>,
		public changeDetector: ChangeDetectorRef,
		private scrollService: ScrollService,
		private overlayService: OverlayService2,
		private dateService: DateService,
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.fieldValue.subscribe({
				next: value => {
					this.markSelectedDates();
				}
			})
		);

		this.subscriptions.add(
			this.overlayIsOpened.subscribe({
				next: isOpened => {
					if (isOpened) {
						this.overlayService.registerDropdownOverlay(this);

						let targetDate: ExtendedDate;

						if (!this._fieldValue || typeof this._fieldValue === 'string') {
							targetDate = new Date();
						} else if (this._fieldValue instanceof Date) {
							targetDate = this._fieldValue;
						} else if (Array.isArray(this._fieldValue)) {
							targetDate = this._fieldValue?.[0];
						} else {
							targetDate = this._fieldValue?.from || this._fieldValue?.to || new Date();
						}

						const days = this.getDaysFromDate(targetDate);
						const years = this.getYearsListFromDate(targetDate);

						this.displayedData$.next({
							...days,
							monthDate: targetDate,
							yearsList: years,
						});

						this.yearsDropdownModel = targetDate;
					} else {
						this.overlayService.removeDropdownOverlay(this);

						const range = this._fieldValue as DateRange;
						if (this.mode === DatePickerMode.dateRange && range?.from && !range?.to) {
							const newValue = {
								from: range.from,
								to: new Date(range.from),
							};
							this.onChange(newValue);
							this.fieldValue$.next(newValue);
						}

						this.yearsDropdownModel = null;

						this.displayedData$.next({
							weeks: null,
							monthDate: null,
							daysOfThePreviousMonth: null,
							dates: null,
							daysOfTheNextMonth: null,
							yearsList: [],
						});
					}
				}
			})
		);

		this.subscriptions.add(
			this.displayedData.subscribe({
				next: displayedData => {
					this.markSelectedDates();
				}
			})
		);

		this.subscriptions.add(
			this.valueIsSelected.subscribe({
				next: valueIsSelected => {
					if (this.containerClassWithNonEmptyValue && valueIsSelected) {
						this.renderer.addClass(this.elementRef.nativeElement, this.containerClassWithNonEmptyValue);
					} else {
						this.renderer.removeClass(this.elementRef.nativeElement, this.containerClassWithNonEmptyValue);
					}
				},
			})
		);

		this.subscriptions.add(
			fromEvent(this.document, 'pointerdown').subscribe(this.documentListener)
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	writeValue(value: DatePickerValue) {
		this.fieldValue$.next(value);
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;

		if (disabled) {
			this.renderer.addClass(this.elementRef.nativeElement, this.containerDisabledClass);
		} else {
			this.renderer.removeClass(this.elementRef.nativeElement, this.containerDisabledClass);
		}
	}

	get inputTriggerClasses() {
		const result = {
			...this.inputTriggerNgClass,
		};

		if (this.inputTriggerClass) {
			result[this.inputTriggerClass] = true;
		}

		return result;
	}

	get calendarClasses() {
		return {
			'invisible': this.calendarInvisible,
			...this.calendarNgClass,
		};
	}

	markSelectedDates() {
		const fieldValue = this._fieldValue as any;

		this._displayedData?.weeks?.forEach(week => {
			week?.forEach(day => {
				day.isSelected = false;
				day.isFromDate = false;
				day.isToDate = false;
				day.isRange = false;
				day.isUnavailable = false;

				if (this.dateIsUnavailable(day)) {
					day.isUnavailable = true;
				}

				if (fieldValue?.from && fieldValue?.to) {
					day.isRange = this.dateService.itsSameDay(day, fieldValue?.from) ||
						this.dateService.itsSameDay(day, fieldValue?.to) || (
						fieldValue.from.getTime() <= day.getTime() &&
						fieldValue.to.getTime() >= day.getTime()
					);
				}

				if (!fieldValue || typeof fieldValue === 'string') return;

				if (this.mode === DatePickerMode.oneDate) {
					if (this.dateService.itsSameDay(day, fieldValue)) day.isSelected = true;
				} else if (this.mode === DatePickerMode.dateRange) {
					if (this.dateService.itsSameDay(day, fieldValue?.from)) day.isFromDate = true;
					if (this.dateService.itsSameDay(day, fieldValue?.to)) day.isToDate = true;
				} else if (this.mode === DatePickerMode.multipleDates) {
					if (fieldValue?.some(date => this.dateService.itsSameDay(day, date))) day.isSelected = true;
				}
			});
		});
	}

	getFirstDayOfMonth = (targetDate: ExtendedDate) => new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);

	getLastDayOfMonth = (targetDate: ExtendedDate) => new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0);

	spliceIntoChunks<T>(array: T[], size: number) {
		const targetArray = [...array];
		const res: T[][] = [];
		while (targetArray.length > 0) {
			const chunk = targetArray.splice(0, size);
			res.push(chunk);
		}
		return res;
	}

	getYearsListFromDate(date: ExtendedDate): ExtendedDate[] {
		const years = this.yearsList?.length ?
			[...this.yearsList] :
			Array.from({ length: 11 }, (value, index) => index + 1 - 6 + date.getFullYear());

		return years
			.map(year => {
				const result = new Date(date);
				result.setFullYear(year);
				return result;
			});
	}

	getDaysFromDate(date: ExtendedDate) {
		const datesOfNeighboringMonths = this.getDatesOfNeighboringMonths(date);
		const datesByMonth = this.getDatesByMonth(date);

		const today: ExtendedDate = datesByMonth.find(date => this.dateService.itsSameDay(date, new Date()));
		if (today) today.isToday = true;

		const dates = [
			...datesOfNeighboringMonths.daysOfThePreviousMonth,
			...datesByMonth,
			...datesOfNeighboringMonths.daysOfTheNextMonth,
		];

		return {
			weeks: this.spliceIntoChunks(dates, 7),
			dates: datesByMonth,
			daysOfThePreviousMonth: datesOfNeighboringMonths.daysOfThePreviousMonth,
			daysOfTheNextMonth: datesOfNeighboringMonths.daysOfTheNextMonth,
		};
	}

	/** метод для получения списка дат всех дней месяца, соответствующего переданной дате */
	getDatesByMonth(targetDate: ExtendedDate): ExtendedDate[] {
		const days: Date[] = [];
		const targetMonth = targetDate.getMonth();

		let date = this.getFirstDayOfMonth(targetDate);

		while (date.getMonth() === targetMonth) {
		  days.push(new Date(date));
		  date.setDate(date.getDate() + 1);
		}

		return days;
	}

	/** метод для получения списка дат соседних месяцев */
	getDatesOfNeighboringMonths(targetDate: ExtendedDate) {
		const firstDayOfMonth = this.getFirstDayOfMonth(targetDate);
		const lastDayOfMonth = this.getLastDayOfMonth(targetDate);

		let daysOfThePreviousMonth: ExtendedDate[] = [];
		let daysOfTheNextMonth: ExtendedDate[] = [];

		if (firstDayOfMonth.getDay() !== 1) {
			let previousWeekIsFull = false;
			this.weekDaysList
				.forEach(i => {
					if (previousWeekIsFull) return;

					const date: ExtendedDate = new Date(
						firstDayOfMonth.getFullYear(),
						firstDayOfMonth.getMonth(),
						firstDayOfMonth.getDate() - (i + 1),
					);

					previousWeekIsFull = date.getDay() === 1;

					date.isNeighboringMonth = true;
					daysOfThePreviousMonth.push(date);
				});
		}

		if (lastDayOfMonth.getDay() !== 0) {
			let nextWeekIsFull = false;
			this.weekDaysList
				.forEach(i => {
					if (nextWeekIsFull) return;

					const date: ExtendedDate = new Date(
						lastDayOfMonth.getFullYear(),
						lastDayOfMonth.getMonth(),
						lastDayOfMonth.getDate() + (i + 1),
					);

					nextWeekIsFull = date.getDay() === 0;

					date.isNeighboringMonth = true;
					daysOfTheNextMonth.push(date);
				});
		}

		daysOfThePreviousMonth = daysOfThePreviousMonth.sort((a, b) => a.getTime() - b.getTime());
		daysOfTheNextMonth = daysOfTheNextMonth.sort((a, b) => a.getTime() - b.getTime());

		return { daysOfThePreviousMonth, daysOfTheNextMonth };
	}

	get weekDaysList(): number[] {
		return Array.from({ length: 7 }, (value, index) => index);
	}

	private parseDateRange(range: DateRange): string | null {
		// if (this.itsSameDay(range.from, range.to)) {
		// 	return this.datePipe.transform(range.from, this.dateFormat);
		// }

		let from: string | null = null;
		let to: string | null = null;

		if (range?.from instanceof Date) from = this.datePipe.transform(range.from, this.dateFormat);
		if (range?.to instanceof Date) to = this.datePipe.transform(range.to, this.dateFormat);

		const value = [from, to].filter(Boolean).join(' – ');

		return value || null;
	}

	toggleOverlay() {
		if (!this.disabled && !this.animationInProcess) {
			this.overlayIsOpened$.next(!this._overlayIsOpened);
		}
	}

	selectDate(date: ExtendedDate) {
		if (date?.isNeighboringMonth || date?.isUnavailable) return;

		if (this.mode === DatePickerMode.oneDate) {
			this.onChange(date);
			this.fieldValue$.next(date);
			this.overlayIsOpened$.next(false);
		} else if (this.mode === DatePickerMode.dateRange) {
			const fieldValue = this._fieldValue as DateRange;
			let newValue: DateRange;

			if (!fieldValue?.from) {
				// if (this.itsSameDay(date, fieldValue?.to)) return;

				newValue = {
					from: date,
					to: fieldValue?.to || null,
				};
			} else if (!fieldValue?.to) {
				// if (this.itsSameDay(date, fieldValue?.from)) return;

				newValue = {
					from: fieldValue?.from || null,
					to: date,
				};
			} else {
				newValue = {
					from: date,
					to: null,
				};
			}

			if (newValue?.from && newValue?.to) {
				const earlyDate = newValue.from.getTime() < newValue.to.getTime() ?
					new Date(newValue.from) :
					new Date(newValue.to);

				const lateDate = newValue.from.getTime() > newValue.to.getTime() ?
					new Date(newValue.from) :
					new Date(newValue.to);

				newValue = {
					from: earlyDate,
					to: lateDate,
				};

				this.overlayIsOpened$.next(false);
			}

			this.onChange(newValue);
			this.fieldValue$.next(newValue);
		} else if (this.mode === DatePickerMode.multipleDates) {
			const fieldValue = this._fieldValue as any;
			const isList = Boolean(fieldValue) && Array.isArray(fieldValue);

			const newValue = isList ?
				[...fieldValue] :
				[];

			if (this.maxDateCount && newValue.length + 1 > this.maxDateCount) return;

			const indexOfAlreadySelectedDate = newValue.findIndex(i => this.dateService.itsSameDay(i, date));
			if (indexOfAlreadySelectedDate !== -1) {
				newValue.splice(indexOfAlreadySelectedDate, 1);
			} else {
				newValue.push(date);
			}

			this.onChange(newValue);
			this.fieldValue$.next(newValue);

			if (this.maxDateCount && newValue.length === this.maxDateCount) {
				this.overlayIsOpened$.next(false);
			}
		}
	}

	documentListener = (event: PointerEvent) => {
		const targetElement = event.target as HTMLElement;

		const isBeyondBorders = !this.elementRef?.nativeElement?.contains(targetElement);

		if (isBeyondBorders) {
			this.overlayIsOpened$.next(false);
		}
	};

	yearsDropdownModelChanged() {
		const date = new Date(this.yearsDropdownModel);

		const days = this.getDaysFromDate(date);
		const years = this.getYearsListFromDate(date);

		this.displayedData$.next({
			...days,
			monthDate: date,
			yearsList: years,
		});
	}

	switchMonth(next: boolean = false) {
		const currentMonthDate = this._displayedData?.monthDate || new Date();
		const newMonthDate = new Date(currentMonthDate);

		if (next) {
			newMonthDate.setMonth(currentMonthDate.getMonth() + 1);
		} else {
			newMonthDate.setMonth(currentMonthDate.getMonth() - 1);
		}

		const days = this.getDaysFromDate(newMonthDate);
		const years = this.getYearsListFromDate(newMonthDate);

		this.displayedData$.next({
			...days,
			monthDate: newMonthDate,
			yearsList: years,
		});

		this.yearsDropdownModel = newMonthDate;
	}

	trackByWeekDay(index: number, date: ExtendedDate) {
		return date.toLocaleString();
	}

	trackByWeeks(index: number, week: ExtendedDate[]) {
		return week[0].toLocaleString();
	}

	get calendarPadding(): number {
		return parseFloat(
			this.elementRef?.nativeElement?.style?.getPropertyValue('--calendar-product-panel-offset-sidebar') || '16'
		);
	}

	flexibleSetupCalendarPositioning() {
		const calendarElement = this.calendarPanel?.nativeElement;
		const inputTriggerRect = this.inputTrigger.nativeElement.getBoundingClientRect();
		const calendarRect = calendarElement.getBoundingClientRect();
		const viewportSizes = this.scrollService.getViewportSize();
		const padding = this.calendarPadding;

		const topCoordsCalendarTopSide = inputTriggerRect.top - 8 - calendarRect.height;
		const topCoordsCalendarBottomSide = inputTriggerRect.top + inputTriggerRect.height + 8 + calendarRect.height;

		const topCoordsViewportTopSide = padding * 2;
		const topCoordsViewportBottomSide = viewportSizes.viewportHeight - (padding * 2);

		const fitsAtTheTop = topCoordsCalendarTopSide >= topCoordsViewportTopSide;
		const fitsAtTheBottom = topCoordsCalendarBottomSide <= topCoordsViewportBottomSide;

		const heightOnTopOfTrigger = inputTriggerRect.top;
		const heightOnBottomOfTrigger = viewportSizes.viewportHeight - inputTriggerRect.top - inputTriggerRect.height;

		const moreSpaceOnTop = heightOnTopOfTrigger > heightOnBottomOfTrigger;
		const moreSpaceOnBottom = heightOnTopOfTrigger <= heightOnBottomOfTrigger;

		const translateValueToViewportTopSide = `-${calendarRect.top - topCoordsViewportTopSide}px`;
		const translateValueToViewportBottomSide = `${topCoordsViewportBottomSide - calendarRect.bottom}px`;

		const panel = this.calendarPanel?.nativeElement;

		if (!fitsAtTheTop && !fitsAtTheBottom) {
			if (!panel) return;

			if (this.position === DatePickerPosition.fits) {
				if (moreSpaceOnTop) this.renderer.setStyle(panel, '--calendar-product-panel-offset', translateValueToViewportTopSide, RendererStyleFlags2.DashCase);
				else if (moreSpaceOnBottom) this.renderer.setStyle(panel, '--calendar-product-panel-offset', translateValueToViewportBottomSide, RendererStyleFlags2.DashCase);
			} else if (this.position === DatePickerPosition.fitsWithTopPriority) {
				this.renderer.setStyle(panel, '--calendar-product-panel-offset', translateValueToViewportTopSide, RendererStyleFlags2.DashCase);
			} else if (this.position === DatePickerPosition.fitsWithBottomPriority) {
				this.renderer.setStyle(panel, '--calendar-product-panel-offset', translateValueToViewportBottomSide, RendererStyleFlags2.DashCase);
			}
		} else if (fitsAtTheTop && fitsAtTheBottom) {
			this.calendarNgClass['calendar__panel--to-top'] = this.position === DatePickerPosition.fits ?
				moreSpaceOnTop :
				this.position === DatePickerPosition.fitsWithTopPriority;
			this.calendarNgClass['calendar__panel--to-bottom'] = this.position === DatePickerPosition.fits ?
				moreSpaceOnBottom :
				this.position === DatePickerPosition.fitsWithBottomPriority;
		} else if (fitsAtTheTop) {
			if (this.position === DatePickerPosition.fitsWithBottomPriority) {
				this.renderer.setStyle(panel, '--calendar-product-panel-offset', translateValueToViewportBottomSide, RendererStyleFlags2.DashCase);
			} else {
				this.calendarNgClass['calendar__panel--to-top'] = true;
				this.calendarNgClass['calendar__panel--to-bottom'] = false;
			}
		} else if (fitsAtTheBottom) {
			if (this.position === DatePickerPosition.fitsWithTopPriority) {
				this.renderer.setStyle(panel, '--calendar-product-panel-offset', translateValueToViewportTopSide, RendererStyleFlags2.DashCase);
			} else {
				this.calendarNgClass['calendar__panel--to-top'] = false;
				this.calendarNgClass['calendar__panel--to-bottom'] = true;
			}
		}
	}

	setupCalendarPositioning() {
		this.calendarNgClass['calendar__panel--to-top'] = false;
		this.calendarNgClass['calendar__panel--to-bottom'] = false;

		const calendarElement = this.calendarPanel?.nativeElement;
		this.renderer.setStyle(calendarElement, '--calendar-product-panel-offset', '0', RendererStyleFlags2.DashCase);

		this.changeDetector.detectChanges();

		if (this.position === DatePickerPosition.top) {
			this.calendarNgClass['calendar__panel--to-top'] = true;
		} else if (this.position === DatePickerPosition.bottom) {
			this.calendarNgClass['calendar__panel--to-bottom'] = true;
		} else {
			this.flexibleSetupCalendarPositioning();
		}

		this.calendarInvisible = false;
		this.changeDetector.detectChanges();
	}

	calendarAnimationStart(event: AnimationEvent) {
		const isDestroyAnimationStart = event.toState === 'void' && event.phaseName === 'start';
		const isInitAnimationStart = event.fromState === 'void' && event.phaseName === 'start';

		if (isInitAnimationStart) {
			this.animationInProcess = true;
			this.calendarInvisible = true;
			this.changeDetector.detectChanges();
			this.setupCalendarPositioning();
		}

		if (isDestroyAnimationStart) {
			this.animationInProcess = true;
		}
	}

	calendarAnimationDone(event: AnimationEvent) {
		const isDestroyAnimationEnd = event.toState === 'void' && event.phaseName === 'done';
		const isInitAnimationEnd = event.fromState === 'void' && event.phaseName === 'done';

		if (isDestroyAnimationEnd) {
			this.calendarInvisible = true;
			this.animationInProcess = false;
			this.calendarNgClass['calendar__panel--to-top'] = false;
			this.calendarNgClass['calendar__panel--to-bottom'] = false;
		}

		if (isInitAnimationEnd) {
			this.animationInProcess = false;
		}

		this.changeDetector.detectChanges();
	}

	resetField() {
		this.onChange(this.resetValue);
		this.fieldValue$.next(this.resetValue);
	}

	public hideOverlay() {
		this.overlayIsOpened$.next(false);
	}

	yearsUniquePropFn = (date: ExtendedDate) => `${date.getFullYear()}.${date.getMonth()}.${date.getDate()}`;

	dateIsUnavailable(date: ExtendedDate) {
		if (!this.unavailableDates?.length) return false;

		return this.unavailableDates.some(unavailableData => {
			if (unavailableData instanceof Date) return this.dateService.itsSameDay(unavailableData, date);
			if (Array.isArray(unavailableData)) return unavailableData.some(i => i instanceof Date && this.dateService.itsSameDay(date, i));

			const rangeStartIndicated = unavailableData.from instanceof Date;
			const rangeEndIndicated = unavailableData.to instanceof Date;

			if (rangeStartIndicated && rangeEndIndicated) {
				return this.dateService.itsSameDay(unavailableData.from, date) ||
					this.dateService.itsSameDay(unavailableData.to, date) ||
					(unavailableData.from.getTime() <= date.getTime() && unavailableData.to.getTime() >= date.getTime());
			} else if (rangeStartIndicated) {
				return this.dateService.itsSameDay(unavailableData.from, date) ||
					unavailableData.from.getTime() <= date.getTime();
			} else if (rangeEndIndicated) {
				return this.dateService.itsSameDay(unavailableData.to, date) ||
					unavailableData.to.getTime() >= date.getTime();
			}

			return true;
		});
	}

	getTriggerElement() {
		return this.inputTrigger?.nativeElement;
	}

	getDropdownOverlay() {
		return this.calendarPanel?.nativeElement;
	}

}
