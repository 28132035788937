import { UntypedFormGroup } from '@angular/forms';

export function validateControls(form: UntypedFormGroup, markAsTouched: boolean = false) {
	Object.values(form.controls).forEach((control) => {
		if (control.status !== 'DISABLED') {
			control.markAsDirty();
			if (markAsTouched) {
				control.markAsTouched();
			}
			control.updateValueAndValidity();
		}
		if (control instanceof UntypedFormGroup) {
			this.validateControls(control);
		}
	});
}
