import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FeatureTogglingService } from './feature-toggling.service';

@NgModule()
export class FeatureTogglingModule {

	constructor(@Optional() @SkipSelf() parentModule?: FeatureTogglingModule) {
		if (parentModule) {
		  throw new Error('FeatureTogglingModule уже загружен. Импортируйте его только в AppModule');
		}
	}

	public static forRoot(): ModuleWithProviders<FeatureTogglingModule> {
		return {
			ngModule: FeatureTogglingModule,
			providers: [
				FeatureTogglingService,
			],
		};
	}
}
