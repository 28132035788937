import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SeparateInputComponent } from './separate-input.component';

@NgModule({
	declarations: [
		SeparateInputComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	exports: [
		SeparateInputComponent,
	]
})
export class SeparateInputModule {}
