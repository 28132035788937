import {Component,Input,Output,OnInit,AfterViewInit,OnDestroy,ElementRef,ViewChild,EventEmitter,ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation} from '@angular/core';
import {UiConfig} from '../../services';
import {MessageService, Message} from './message.service';
import {UniqueComponentId} from '../../utils/uniquecomponentid';
import {Subscription} from 'rxjs';
import {trigger,transition,query,animateChild,AnimationEvent} from '@angular/animations';
import ZIndexUtils from '../../utils/zindexutils';

@Component({
	selector: 'b-shared-toast',
	templateUrl: './toast.component.html',
	animations: [
		trigger('toastAnimation', [
			transition(':enter, :leave', [
				query('@*', animateChild())
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() key: string;
	@Input() autoZIndex: boolean = true;
	@Input() baseZIndex: number = 0;
	@Input() preventOpenDuplicates: boolean = false;
	@Input() preventDuplicates: boolean = false;
	@Input() breakpoints: any;

	@Output() closeEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild('container') containerViewChild: ElementRef;

	messageSubscription: Subscription;
	clearSubscription: Subscription;
	messages: Message[];
	messagesArchieve: Message[];

	constructor(public messageService: MessageService, private cd: ChangeDetectorRef, public config: UiConfig) {}

	styleElement: any;
	id: string = UniqueComponentId();

	ngOnInit(): void {
		this.messageSubscription = this.messageService.messageObserver.subscribe(messages => {
			if (messages) {
				if (messages instanceof Array) {
					const filteredMessages = messages.filter(m => this.canAdd(m));
					this.add(filteredMessages);
				} else if (this.canAdd(messages)) {
					this.add([messages]);
				}
			}
		});

		this.clearSubscription = this.messageService.clearObserver.subscribe(key => {
			if (key) {
				if (this.key === key) {
					this.messages = null;
				}
			} else {
				this.messages = null;
			}

			this.cd.detectChanges();
		});
	}

	ngAfterViewInit(): void {
		if (this.autoZIndex) {
			ZIndexUtils.set('modal', this.containerViewChild.nativeElement, this.baseZIndex || this.config.zIndex.modal);
		}
		if (this.breakpoints) {
			this.createStyle();
		}
	}

	add(messages: Message[]): void {
		this.messages = this.messages ? [...this.messages, ...messages] : [...messages];

		if (this.preventDuplicates) {
			this.messagesArchieve = this.messagesArchieve ? [...this.messagesArchieve, ...messages] : [...messages];
		}

		this.cd.markForCheck();
	}

	canAdd(message: Message): boolean {
		let allow = this.key === message.key;

		if (allow && this.preventOpenDuplicates) {
			allow = !this.containsMessage(this.messages, message);
		}

		if (allow && this.preventDuplicates) {
			allow = !this.containsMessage(this.messagesArchieve, message);
		}

		return allow;
	}

	containsMessage(collection: Message[], message: Message): boolean {
		if (!collection) {
			return false;
		}

		return collection.find(m => {
			return ((m.summary === message.summary) && (m.detail == message.detail) && (m.severity === message.severity));
		}) != null;
	}

	onMessageClose(event): void {
		this.messages.splice(event.index, 1);

		this.closeEvent.emit({
			message: event.message
		});

		this.cd.detectChanges();
	}

	onAnimationStart(event: AnimationEvent): void {
		if (event.fromState === 'void' && this.autoZIndex) {
			this.containerViewChild.nativeElement.style.zIndex = String(this.baseZIndex);
			this.containerViewChild.nativeElement.setAttribute(this.id, '');
		}
	}

	createStyle(): void {
		if (!this.styleElement) {
			this.styleElement = document.createElement('style');
			this.styleElement.type = 'text/css';
			document.head.appendChild(this.styleElement);
			let innerHTML = '';
			for (let breakpoint in this.breakpoints) {
				let breakpointStyle = '';
				for (let styleProp in this.breakpoints[breakpoint]) {
					breakpointStyle += styleProp + ':' + this.breakpoints[breakpoint][styleProp] + ' !important;';
				}
				innerHTML += `
										@media screen and (max-width: ${breakpoint}) {
												.p-toast[${this.id}] {
													 ${breakpointStyle}
												}
										}
								`;
			}

			this.styleElement.innerHTML = innerHTML;
		}
	}

	destroyStyle(): void {
		if (this.styleElement) {
			document.head.removeChild(this.styleElement);
			this.styleElement = null;
		}
	}

	ngOnDestroy(): void {
		if (this.messageSubscription) {
			this.messageSubscription.unsubscribe();
		}

		if (this.containerViewChild && this.autoZIndex) {
			ZIndexUtils.clear(this.containerViewChild.nativeElement);
		}

		if (this.clearSubscription) {
			this.clearSubscription.unsubscribe();
		}

		this.destroyStyle();
	}
}
