import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, from, map, Observable, of, take, tap, throwError } from 'rxjs';
import { mimeTypes } from '../types/mimeTypes';
import { WINDOW } from '../tokens';

@Injectable({
	providedIn: 'root',
})
export class FileService {

	private renderer: Renderer2;
	private iframeElement: HTMLIFrameElement | null = null;
	public filesLoader: Map<string, boolean> = new Map();

	private cachedFiles$ = new BehaviorSubject<{ [filePath in string]: Blob }>({});
	public cachedFiles = this.cachedFiles$.asObservable();
	public get _cachedFiles(): { [filePath in string]: Blob } {
		return this.cachedFiles$.getValue();
	}

	cacheFile(filePath: string, blob: Blob) {
		const newCache = {...this._cachedFiles};
		newCache[filePath] = blob;

		this.cachedFiles$.next(newCache);
	}

	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(WINDOW) private _window: Window,
		private http: HttpClient,
		private rendererFactory: RendererFactory2,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	private removeFrame = () => {
		this.iframeElement?.remove();
		this.iframeElement = null;
	};

	private setDownload = () => {
		try {
			this.iframeElement.contentWindow.onbeforeunload = this.removeFrame;
		} catch {} // for Firefox

		try {
			this.iframeElement.contentWindow.onafterprint = this.removeFrame;
		} catch {} // for Firefox

		setTimeout(() => {
			this.iframeElement.contentWindow.focus(); // Required for IE
			this.iframeElement.contentWindow.print();
		}, 100); // for Firefox
	};

	private printPage(fileObjectUrl: string) {
		this.iframeElement = this.renderer.createElement('iframe');

		this.iframeElement.onload = this.setDownload;

		this.iframeElement.style.position = 'fixed';
		this.iframeElement.style.right = '0';
		this.iframeElement.style.bottom = '0';
		this.iframeElement.style.width = '0';
		this.iframeElement.style.height = '0';
		this.iframeElement.style.border = '0';

		this.iframeElement.src = fileObjectUrl;

		this.renderer.appendChild(this.document.body, this.iframeElement);
	}

	/** Метод для скачивания txt файла с переданным текстом */
	public downloadTxtFromText(text: string, fileName: string) {
		this.downloadBlob({
			blob: new Blob([text], {
				type: 'text/plain;charset=utf-8'
			}),
			fileName,
		});
	}

	private copyTextWithExecCommand(text: string): Observable<boolean> {
		const textarea = this.renderer.createElement('textarea');
		textarea.value = text;

		// предотвращаем скролл
		textarea.style.top = '0';
		textarea.style.left = '0';
		textarea.style.position = 'fixed';

		this.renderer.appendChild(this.document.body, textarea);
		textarea.focus();
		textarea.select();

		let successful = false;

		try {
		  successful = this.document.execCommand('copy');
		} catch {
			successful = false;
		} finally {
			this.renderer.removeChild(this.document.body, textarea);
		}

		return successful ? of(successful) : throwError(() => successful);
	}

	/** Метод для копирования текста */
	public copyText(text: string): Observable<boolean> {
		if (navigator?.clipboard) {
			return from(
				navigator.clipboard.writeText(text)
			).pipe(
				catchError(() => this.copyTextWithExecCommand(text)),
				map(() => true),
				take(1),
			);
		} else {
			return this.copyTextWithExecCommand(text).pipe(take(1));
		}
	}

	/**
	 * Метод для печати файла
	 */
	public printFile(
		/**
		 * @example
		 * printFile('/podarok-za-otzyv.pdf');
		 * printFile(ArrayBuffer | Blob);
		 */
		printableFile: ArrayBuffer | Blob | string | null,
		mime?: string
	) {
		if (!printableFile) return;

		if (printableFile instanceof ArrayBuffer) {
			const fileObjectUrl = URL.createObjectURL(
				new Blob([printableFile], { type: mime })
			);
			this.printPage(fileObjectUrl);
		} else if (printableFile instanceof Blob) {
			const fileObjectUrl = URL.createObjectURL(printableFile);
			this.printPage(fileObjectUrl);
		} else if (typeof printableFile === 'string') {
			if (this._cachedFiles[printableFile]) {
				const fileObjectUrl = URL.createObjectURL(this._cachedFiles[printableFile]);
				this.printPage(fileObjectUrl);
				return;
			}

			if (this.filesLoader.has(printableFile)) {
				return this.cachedFiles.pipe(
					filter(files => Boolean(files[printableFile])),
					take(1),
					map(files => files[printableFile]),
				);
			}

			this.filesLoader.set(printableFile, true);

			return this.http.get(printableFile, { responseType: 'blob' }).pipe(
				tap({
					next: result => {
						this.filesLoader.set(printableFile, false);
						this.cachedFiles$.next({ ...this._cachedFiles, [printableFile]: result });

						const fileObjectUrl = URL.createObjectURL(result);
						this.printPage(fileObjectUrl);
					},
					error: () => {
						this.filesLoader.set(printableFile, false);
					}
				}),
			);
		}
	}

	/** Метод для скачивания файла */
	public downloadFile({
		loadableFile,
		fileName,
		mime,
		extension = null,
		open = false,
		openTarget = '_self',
		download = true,
	}: {
		loadableFile: ArrayBuffer | Blob | string | null,
		fileName?: string,
		mime?: string,
		extension?: string | null,
		open?: boolean,
		openTarget?: '_self' | '_blank' | '_parent' | '_top',
		download?: boolean,
	}) {
		if (!loadableFile) return;

		if (loadableFile instanceof ArrayBuffer) {
			const file = new Blob([loadableFile], { type: mime });

			if (open) this._window.open(URL.createObjectURL(file), openTarget);

			if (download) {
				this.downloadBlob({
					blob: new Blob([loadableFile], { type: mime }),
					fileName,
					mime,
					extension,
				});
			}
		} else if (loadableFile instanceof Blob) {
			if (open) this._window.open(URL.createObjectURL(loadableFile), openTarget);
			if (download) this.downloadBlob({
				blob: loadableFile,
				fileName,
				mime,
				extension,
			});
		} else if (typeof loadableFile === 'string') {
			if (this._cachedFiles[loadableFile]) {
				const fileBlob = this._cachedFiles[loadableFile];

				if (open) this._window.open(URL.createObjectURL(fileBlob), openTarget);
				if (download) this.downloadBlob({ blob: fileBlob, fileName, mime, extension });

				return of(fileBlob);
			}

			if (this.filesLoader.has(loadableFile)) {
				return this.cachedFiles.pipe(
					filter(files => Boolean(files[loadableFile])),
					take(1),
					map(files => files[loadableFile]),
				);
			}

			this.filesLoader.set(loadableFile, true);

			return this.http.get(loadableFile, { responseType: 'blob' }).pipe(
				tap({
					next: result => {
						this.filesLoader.set(loadableFile, false);
						this.cachedFiles$.next({ ...this._cachedFiles, [loadableFile]: result });

						if (open) this._window.open(URL.createObjectURL(result), openTarget);
						if (download) this.downloadBlob({ blob: result, fileName, mime, extension });
					},
					error: () => {
						this.filesLoader.set(loadableFile, false);
					},
				}),
			);
		}
	}

	/** Метод для скачивания blob */
	private downloadBlob({
		blob,
		fileName,
		mime,
		extension = null,
	}: {
		blob: Blob,
		fileName: string,
		mime?: string,
		extension?: string | null,
	}) {
		if (!blob) return console.error('downloadBlob() method error: Не передан blob');
		if (!fileName) return console.error('downloadBlob() method error: Не передан fileName');

		const navigator = this._window?.navigator as Navigator & { msSaveOrOpenBlob: any };

		if (navigator?.msSaveOrOpenBlob) return navigator.msSaveOrOpenBlob(blob);

		const data = URL.createObjectURL(blob);

		let ext: string | null = null;

		if (extension) {
			ext = extension;
		} else {
			const mimeType = mime || blob.type;
			ext = this.getExtFromMime(mimeType);
		}

		const link = this.renderer.createElement('a');
		link.href = data;

		if (blob instanceof File) {
			link.download = blob.name;
		} else {
			link.download = ext ? `${fileName}${ext}` : fileName;
		}

		link.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: this._window,
			})
		);

		setTimeout(() => {
			URL.revokeObjectURL(data);
			link?.remove();
		}, 100); // for Firefox
	}

	private getExtFromMime(mime: string): string | null {
		return mimeTypes?.[mime] ?? null;
	}
}
