export enum SuggestPosition {
	auto = 'auto', // слой будет всплывать туда, где больше места в видимой области окна браузера
	above = 'above', // вверх
	under = 'under', // вниз
}

export enum LoaderType {
	none = 'none', // нет лоадера
	input = 'input', // лоадер в инпуте
}

export enum DropdownType {
	default = 'default',
	custom = 'custom',
}
