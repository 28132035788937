<ng-template #fileListItem let-context>
	<ng-container
		*ngIf="fileView"
		[ngTemplateOutlet]="fileView"
		[ngTemplateOutletContext]="{$implicit: context.file}"
	></ng-container>

	<ng-container *ngIf="uploadedFilesList | async as uploadedFiles">
		<ng-container *ngIf="!uploadedFiles.includes(context.file); else fileLoader">
			<div
				[attr.class]="fileItemBtnsWrapperClass"
				*ngIf="deletableFiles"
			>
				<button
					#deleteButton
					b-shared-button
					[icon]="deleteButtonIcon"
					[iconClass]="deleteButtonIconClass"
					[sharedClass]="[
						context?.isFailedFile ? failedFileDeleteButtonClass : deleteButtonClass
					]"
					title="Удалить"
					(click)="deleteButtonClickHandler($event, context.file)"
				></button>
			</div>
		</ng-container>

		<ng-template #fileLoader>
			<div [attr.class]="fileLoaderWrapperClass">
				<svg
					b-shared-svg
					[name]="iconLoaderName"
					class="icon rotating"
					[ngClass]="iconLoaderClass"
				></svg>
			</div>
		</ng-template>
	</ng-container>
</ng-template>

<ng-template #filesList>
	<ng-container *sharedLet="(failedFileList | async) as failedFiles">
		<ng-container *sharedLet="(asyncFileList | async) as asyncFiles">
			<ng-container *sharedLet="(fieldValue | async) as value">
				<ul
					*ngIf="value?.files?.length || asyncFiles?.length || failedFiles?.length || isUploadFieldInList"
					[attr.class]="fileListClass"
				>
					<ng-container *ngIf="value?.files?.length">
						<li
							*ngFor="let file of value.files"
							[attr.class]="fileItemClass"
							(click)="fileClickHandler($event, file)"
							sharedKeyboardControl
							(activeElementKeyHandler)="fileClickHandler($event, file)"
						>
							<ng-container *ngIf="!isContentWrapper">
								<ng-container
									[ngTemplateOutlet]="fileItemContent"
								></ng-container>
							</ng-container>

							<div
								*ngIf="isContentWrapper"
								[attr.class]="fileItemContentClass"
							>
								<ng-container
									[ngTemplateOutlet]="fileItemContent"
								></ng-container>
							</div>

							<ng-template #fileItemContent>
								<ng-container
									[ngTemplateOutlet]="fileListItem"
									[ngTemplateOutletContext]="{
										$implicit: {
											file: file,
											isFailedFile: false
										}
									}"
								></ng-container>
							</ng-template>
						</li>
					</ng-container>

					<ng-container *ngIf="asyncFiles?.length">
						<li
							*ngFor="let asyncFile of asyncFiles"
							[attr.class]="fileItemClass"
							(click)="fileClickHandler($event, asyncFile)"
							sharedKeyboardControl
							(activeElementKeyHandler)="fileClickHandler($event, asyncFile)"
						>
							<ng-container *ngIf="!isContentWrapper">
								<ng-container
									[ngTemplateOutlet]="asyncFileItemContent"
								></ng-container>
							</ng-container>

							<div
								*ngIf="isContentWrapper"
								[attr.class]="fileItemContentClass"
							>
								<ng-container
									[ngTemplateOutlet]="asyncFileItemContent"
								></ng-container>
							</div>

							<ng-template #asyncFileItemContent>
								<ng-container
									[ngTemplateOutlet]="fileListItem"
									[ngTemplateOutletContext]="{
										$implicit: {
											file: asyncFile,
											isFailedFile: false
										}
									}"
								></ng-container>
							</ng-template>
						</li>
					</ng-container>

					<ng-container *ngIf="failedFiles?.length">
						<li
							*ngFor="let failedFile of failedFiles"
							[sharedClass]="[fileItemClass, failedFileItemClass]"
						>
							<ng-container *ngIf="!isContentWrapper">
								<ng-container
									[ngTemplateOutlet]="failedFileItemContent"
								></ng-container>
							</ng-container>

							<div
								*ngIf="isContentWrapper"
								[attr.class]="fileItemContentClass"
							>
								<ng-container
									[ngTemplateOutlet]="failedFileItemContent"
								></ng-container>
							</div>

							<ng-template #failedFileItemContent>
								<ng-container
									[ngTemplateOutlet]="fileListItem"
									[ngTemplateOutletContext]="{
										$implicit: {
											file: failedFile,
											isFailedFile: true
										}
									}"
								></ng-container>
							</ng-template>
						</li>
					</ng-container>

					<li
						*ngIf="isUploadFieldInList"
						[attr.class]="uploadFileItemClass"
					>
						<ng-container
							[ngTemplateOutlet]="uploadField"
						></ng-container>
					</li>
				</ul>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<ng-container *ngIf="fileListVisibility && fileView && fileListPosition === fileListPositionEnum.before">
	<ng-container [ngTemplateOutlet]="filesList"></ng-container>
</ng-container>


<ng-container
	*ngIf="!isUploadFieldInList"
	[ngTemplateOutlet]="uploadField"
></ng-container>

<ng-template #uploadField>
	<label
		[attr.class]="areaClass"
		[ngClass]="areaNgClass"
		(dragenter)="dragenterHandler($event)"
		(dragleave)="dragleaveHandler($event)"
		(dragover)="dragoverHandler($event)"
		(drop)="dropHandler($event)"
		(dragend)="dragendHandler($event)"
		(dragexit)="dragexitHandler($event)"
		[@fade]
	>
		<input
			[attr.class]="inputClass"
			[ngClass]="inputNgClass"
			type="file"
			[attr.multiple]="multiple || null"
			[attr.disabled]="disabled || null"
			[attr.accept]="formats"
			(change)="fileSelectHandler($event)"
			(click)="inputClickHandler($event)"
			(blur)="blurInputHandler()"
			#uploadInput
			[attr.hidden]="hiddenInput || null"
		>
		<svg
			*ngIf="showIcon"
			b-shared-svg
			[name]="iconName"
			[attr.class]="iconClass"
			[ngClass]="iconNgClass"
		></svg>
		<p *ngIf="showLabel && labelRef" [attr.class]="labelClass" [ngClass]="labelNgClass">
			<ng-container
				[ngTemplateOutlet]="labelRef"
				[ngTemplateOutletContext]="{$implicit: fileModel}"
			></ng-container>
		</p>
		<button
			*ngIf="uploadButton"
			b-shared-button
			(click)="uploadButtonClick()"
			textClass="fw-n fz-md"
			[attr.class]="uploadButtonClass"
		>
			<ng-container [ngTemplateOutlet]="uploadButton"></ng-container>
		</button>
	</label>
</ng-template>

<ng-container *ngIf="fileListVisibility && fileView && fileListPosition === fileListPositionEnum.after">
	<ng-container [ngTemplateOutlet]="filesList"></ng-container>
</ng-container>
