import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast.component';
import { ToastItemComponent } from './toast-item.component';
import { SvgModule } from '../svg/svg.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
	imports: [
		CommonModule,
		SvgModule,
		ButtonModule,
	],
	exports: [
		ToastComponent
	],
	declarations: [
		ToastComponent,
		ToastItemComponent,
	]
})
export class ToastModule { }
