import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, AfterViewInit, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'b-shared-separate-input',
	templateUrl: './separate-input.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SeparateInputComponent,
		}
	],
})
export class SeparateInputComponent implements ControlValueAccessor, AfterViewInit {

	subscriptions = new Subscription();
	inputsValue = '';
	onChange = (value) => {};
	onTouched = () => {};
	touched = false;
	smsFieldsIsDisabled = false;

	private _fieldsCount: number = 4;
	public fieldsList = Array.from({ length: this._fieldsCount }, (value, index) => ({ placeholder: this.placeholder }));
	@Input() set fieldsCount(count) {
		this._fieldsCount = count;
		this.fieldsList = Array.from({ length: this._fieldsCount }, (value, index) => ({ placeholder: this.placeholder }));
	}
	get fieldsCount() {
		return this._fieldsCount;
	}

	// type="number" передавать нельзя: сломает setSelectionRange + в некоторых браузерах в инпуте появятся ненужные контролы.
	// вместо type="number" юзать [onlyNumbers]="true"
	@Input() placeholder = '•';
	@Input() inputmode = 'numeric';
	@Input() inputClass = 'field__control';
	@Input() inputNgClass = {};
	@Input() fieldNgClass = {};
	@Input() containerClass = '';
	@Input() autofocus = true;
	@Input() onlyNumbers = true;
	@Input() disabled = false;

	@ViewChildren('inputRef') inputsList: QueryList<ElementRef<HTMLInputElement>>;

	constructor(
		private changeDetector: ChangeDetectorRef,
	) {}

	ngAfterViewInit() {
		if (this.inputsValue.length) {
			this.inputsValue.split('').forEach((value, index) => {
				const inputsList = this.inputsList.toArray();
				inputsList[index].nativeElement.value = value;
			});
		}

		if (this.autofocus) {
			this.focusFirstEmptyInput();
		}
	}

	writeValue(inputsValue: string) {
		if (this.inputsList?.length) {
			const inputsList = this.inputsList.toArray();

			if (inputsValue.length) {
				inputsValue.split('').forEach((value, index) => {
					inputsList[index].nativeElement.value = value;
				});
			} else {
				inputsList.forEach(field => field.nativeElement.value = '');
			}
		}

		this.inputsValue = inputsValue;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.smsFieldsIsDisabled = disabled;

		if (disabled) {
			this.inputsList.forEach(field => field?.nativeElement?.blur());
		}

		this.changeDetector.detectChanges();
	}

	calculateInputsValue() {
		return this.inputsList.reduce((accum, input) => {
			const inputValue = input.nativeElement.value;
			return inputValue ? accum + inputValue : accum;
		}, '');
	}

	trackByFields(index: number) {
		return index;
	}

	getFirstEmptyField() {
		return this.inputsList.find(field => !field.nativeElement?.value);
	}

	focusFirstEmptyInput() {
		const firstEmptyField = this.getFirstEmptyField();
		firstEmptyField?.nativeElement?.focus();
	}

	inputChange(event) {
		this.markAsTouched();

		if (this.onlyNumbers) {
			event.target.value = event.target.value?.replace(/[^0-9]+/g, '');
		}

		if (event.target.value?.length >= 2) {
			event.target.value.length = 1;
			return;
		}

		const inputsList = this.inputsList.toArray();
		const targetFieldIndex = inputsList.findIndex(field => field.nativeElement === event.target);

		if (event.target.value || targetFieldIndex !== 0) {
			this.focusFirstEmptyInput();
		}

		const newValue = this.calculateInputsValue();
		this.onChange(newValue);
		this.inputsValue = newValue;
	}

	setCursorToEnd(targetInput: HTMLInputElement) {
		if (!targetInput) {
			return;
		}

		const fieldValue = targetInput.value || '';
		targetInput.setSelectionRange(fieldValue.length, fieldValue.length);
	}

	inputFocused(feild) {
		feild.placeholder = null;

		this.markAsTouched();
		const focusedElement = this.getFirstEmptyField()?.nativeElement
			|| this.inputsList?.last?.nativeElement;
		focusedElement?.focus();
		this.setCursorToEnd(focusedElement);
	}

	inputBlured(feild) {
		feild.placeholder = this.placeholder;
	}

	inputKeyDown(event) {
		this.markAsTouched();
		const inputsList = this.inputsList.toArray();
		const targetFieldIndex = inputsList.findIndex(field => field.nativeElement === event.target);

		const isBackspace = event?.key?.toLowerCase() === 'backspace'
			|| event?.code?.toLowerCase() === 'backspace'
			|| event?.keyCode === 8;

		if (isBackspace) {
			event.target.value = null;
		}

		if (isBackspace && !event.target.value && targetFieldIndex !== 0) {
			const previousField = inputsList[targetFieldIndex - 1]?.nativeElement;
			previousField.value = null;
			previousField?.focus();
		}
	}

	get isDisabled() {
		return this.smsFieldsIsDisabled || this.disabled;
	}

	// публичные методы также предназачены для вызовов из родителя через ref:

	public focus() {
		this.markAsTouched();
		this.focusFirstEmptyInput();
	}

}
