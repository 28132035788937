import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentVisibilityDirective } from './content-visibility.directive';

@NgModule({
	declarations: [
		ContentVisibilityDirective,
	],
	imports: [
		CommonModule,
	],
	exports: [
		ContentVisibilityDirective,
	],
})
export class ContentVisibilityModule {}
