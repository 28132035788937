import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragNDropDirective } from './drag-n-drop.directive';

@NgModule({
	declarations: [DragNDropDirective],
	imports: [
		CommonModule,
	],
	exports: [DragNDropDirective]
})
export class DragNDropModule { }
