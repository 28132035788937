import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankAccountPipe } from './bank-account.pipe';

@NgModule({
	declarations: [BankAccountPipe],
	imports: [CommonModule],
	exports: [BankAccountPipe],
})
export class BankAccountPipeModule {}
