import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {
	transform(rawNum:string) {
		return rawNum?.length === 11 ?
			`+${rawNum.slice(0,1)} (${rawNum.slice(1,4)}) ${rawNum.slice(4,7)}-${rawNum.slice(7,9)}-${rawNum.slice(9)}` : '';
	}
}
