<span
	#container
	[ngClass]="{'drop__inner p-calendar calendar':true, 'p-calendar-w-btn': showIcon,
		'p-calendar-timeonly': timeOnly, 'p-calendar-disabled':disabled,
		'p-focus': focus}"
	[ngStyle]="style"
	[class]="styleClass">
	<ng-template [ngIf]="!inline">
		<input
			#inputfield
			type="text"
			[attr.id]="inputId"
			[attr.name]="name"
			[attr.required]="required"
			[attr.aria-required]="required"
			[value]="inputFieldValue"
			(focus)="onInputFocus($event)"
			(keydown)="onInputKeydown($event)"
			(click)="onInputClick()"
			(blur)="onInputBlur($event)"
			[readonly]="readonlyInput"
			(input)="onUserInput($event)"
			[ngStyle]="inputStyle"
			[class]="inputStyleClass"
			[placeholder]="placeholder||''"
			[disabled]="disabled"
			[attr.tabindex]="tabindex"
			[attr.inputmode]="touchUI ? 'off' : null"
			[ngClass]="'p-inputtext p-component'"
			autocomplete="off"
			[attr.aria-labelledby]="ariaLabelledBy"
		>
		<button
			pButton
			b-shared-button
			[icon]="icon"
			[iconClass]="iconClass"
			class="btn"
			title="Календарь"
			[disabled]="disabled"
			tabindex="0"
			*ngIf="showIcon"
			(click)="onButtonClick($event,inputfield)"
		></button>
	</ng-template>
	<div
		#contentWrapper
		[class]="panelStyleClass"
		[ngStyle]="panelStyle"
		[ngClass]="{'p-datepicker p-component': true, 'p-datepicker-inline':inline, 'p-disabled':disabled,
			'p-datepicker-timeonly':timeOnly,'p-datepicker-multiple-month': this.numberOfMonths > 1,
			'p-datepicker-monthpicker': (view === 'month'), 'p-datepicker-touch-ui': touchUI}"
		[@overlayAnimation]="touchUI ? {value: 'visibleTouchUI', params: {showTransitionParams: showTransitionOptions,
			hideTransitionParams: hideTransitionOptions}}: {value: 'visible', params: {showTransitionParams:
			showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
		[@.disabled]="inline === true"
		(@overlayAnimation.start)="onOverlayAnimationStart($event)"
		(@overlayAnimation.done)="onOverlayAnimationDone($event)"
		(click)="onOverlayClick($event)"
		*ngIf="inline || overlayVisible"
	>

		<ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
		<ng-container *ngIf="!timeOnly">
			<div class="p-datepicker-group-container">
				<div class="p-datepicker-group" *ngFor="let month of months; let i = index;">
					<div class="calendar__header">
						<button
							b-shared-button
							icon="icons_24_1-5_arrow-left"
							class="btn p-datepicker-prev p-link"
							iconClass="icon--xl"
							title="Назад"
							(keydown)="onContainerButtonKeydown($event)"
							(click)="onPrevButtonClick($event)"
							*ngIf="i === 0"
						></button>
						<div class="p-datepicker-title">
							<span
								class="p-datepicker-month lg:text-lg text-md"
								*ngIf="!monthNavigator && (view !== 'month')"
							>
								{{getTranslation('monthNames')[month.month]}}
							</span>
							<select
								tabindex="0"
								class="p-datepicker-month"
								*ngIf="monthNavigator && (view !== 'month') && numberOfMonths === 1"
								(change)="onMonthDropdownChange($event.target.value)"
							>
								<option
									[value]="i"
									*ngFor="let monthName of getTranslation('monthNames');let i = index"
									[selected]="i === month.month"
								>
									{{monthName}}
								</option>
							</select>
							<b-shared-dropdown
								*ngIf="yearNavigator && numberOfMonths === 1"
								[suggestions]="yearOptions"
								[ngModel]="currentYear"
								suggestPosition="under"
								class="drop drop--sm flex-shrink-0"
								dropIconName="icons_24_2_arrow-down-sm"
								dropIconClass="icon--xl"
								dropIconActiveClass="d-none"
								dropFieldClass="lg:text-lg text-md"
								dropElementClass="flex-jc-center px-0"
								(selectHandler)="onYearDropdownChange($event)"
							>
								<ng-template let-year #dropdownItem>
									<span class="m-auto">{{year}}</span>
								</ng-template>
							</b-shared-dropdown>
							<span class="p-datepicker-year" *ngIf="!yearNavigator">
								{{view === 'month' ? currentYear : month.year}}
							</span>
						</div>
						<button
							b-shared-button
							icon="icons_24_1-5_arrow-right"
							class="btn p-datepicker-next p-link"
							iconClass="icon--xl"
							title="Вперёд"
							(keydown)="onContainerButtonKeydown($event)"
							(click)="onNextButtonClick($event)"
							*ngIf="numberOfMonths === 1 ? true : (i === numberOfMonths -1)"
						></button>
					</div>
					<div class="p-datepicker-calendar-container" *ngIf="view ==='date'">
						<table class="p-datepicker-calendar">
							<thead>
								<tr>
									<th *ngIf="showWeek" class="p-datepicker-weekheader p-disabled">
										<span>{{getTranslation('weekHeader')}}</span>
									</th>
									<th
										class="calendar__week-day"
										scope="col"
										*ngFor="let weekDay of weekDays;let begin = first; let end = last">
										<span>{{weekDay}}</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let week of month.dates; let j = index;">
									<td *ngIf="showWeek" class="p-datepicker-weeknumber">
										<span class="p-disabled">{{month.weekNumbers[j]}}</span>
									</td>
									<td
										*ngFor="let date of week"
										[ngClass]="{'p-datepicker-other-month': date.otherMonth,
											'p-datepicker-today':date.today}"
									>
										<ng-container *ngIf="date.otherMonth ? showOtherMonths : true">
											<span
												[ngClass]="getDayClass(date)"
												class="calendar__day"
												(click)="onDateSelect($event,date)"
												draggable="false"
												(keydown)="onDateCellKeydown($event,date,i)"
											>
												<ng-container *ngIf="!dateTemplate">{{date.day}}</ng-container>
												<ng-container
													*ngTemplateOutlet="dateTemplate; context: {$implicit: date}"
												></ng-container>
											</span>
										</ng-container>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="p-monthpicker" *ngIf="view === 'month'">
				<span
					*ngFor="let m of monthPickerValues; let i = index"
					(click)="onMonthSelect($event, i)"
					(keydown)="onMonthCellKeydown($event,i)"
					class="p-monthpicker-month"
					[ngClass]="{'p-highlight': isMonthSelected(i),
					'p-disabled':!isSelectable(1, i, this.currentYear, false)}"
				>
					{{m}}
				</span>
			</div>
		</ng-container>
		<div class="p-timepicker" *ngIf="showTime||timeOnly">
			<div class="p-hour-picker">
				<button
					class="p-link"
					type="button"
					(keydown)="onContainerButtonKeydown($event)"
					(keydown.enter)="incrementHour($event)"
					(mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
					(mouseup)="onTimePickerElementMouseUp($event)"
					(mouseleave)="onTimePickerElementMouseLeave()"
				>
					<span class="pi pi-chevron-up"></span>
				</button>
				<span><ng-container *ngIf="currentHour < 10">0</ng-container>{{currentHour}}</span>
				<button
					class="p-link"
					type="button"
					(keydown)="onContainerButtonKeydown($event)"
					(keydown.enter)="decrementHour($event)"
					(mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
					(mouseup)="onTimePickerElementMouseUp($event)"
					(mouseleave)="onTimePickerElementMouseLeave()"
				>
					<span class="pi pi-chevron-down"></span>
				</button>
			</div>
			<div class="p-separator">
				<span>{{timeSeparator}}</span>
			</div>
			<div class="p-minute-picker">
				<button
					class="p-link"
					type="button"
					(keydown)="onContainerButtonKeydown($event)"
					(keydown.enter)="incrementMinute($event)"
					(mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
					(mouseup)="onTimePickerElementMouseUp($event)"
					(mouseleave)="onTimePickerElementMouseLeave()"
				>
					<span class="pi pi-chevron-up"></span>
				</button>
				<span><ng-container *ngIf="currentMinute < 10">0</ng-container>{{currentMinute}}</span>
				<button
					class="p-link"
					type="button"
					(keydown)="onContainerButtonKeydown($event)"
					(keydown.enter)="decrementMinute($event)"
					(mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
					(mouseup)="onTimePickerElementMouseUp($event)"
					(mouseleave)="onTimePickerElementMouseLeave()"
				>
					<span class="pi pi-chevron-down"></span>
				</button>
			</div>
			<div class="p-separator" *ngIf="showSeconds">
				<span>{{timeSeparator}}</span>
			</div>
			<div class="p-second-picker" *ngIf="showSeconds">
				<button
					class="p-link"
					type="button"
					(keydown)="onContainerButtonKeydown($event)"
					(keydown.enter)="incrementSecond($event)"
					(mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
					(mouseup)="onTimePickerElementMouseUp($event)"
					(mouseleave)="onTimePickerElementMouseLeave()"
				>
					<span class="pi pi-chevron-up"></span>
				</button>
				<span><ng-container *ngIf="currentSecond < 10">0</ng-container>{{currentSecond}}</span>
				<button
					class="p-link"
					type="button"
					(keydown)="onContainerButtonKeydown($event)"
					(keydown.enter)="decrementSecond($event)"
					(mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
					(mouseup)="onTimePickerElementMouseUp($event)"
					(mouseleave)="onTimePickerElementMouseLeave()"
				>
					<span class="pi pi-chevron-down"></span>
				</button>
			</div>
			<div class="p-ampm-picker" *ngIf="hourFormat==='12'">
				<button
					class="p-link"
					type="button"
					(keydown)="onContainerButtonKeydown($event)"
					(click)="toggleAMPM($event)"
					(keydown.enter)="toggleAMPM($event)"
				>
					<span class="pi pi-chevron-up"></span>
				</button>
				<span>{{pm ? 'PM' : 'AM'}}</span>
				<button
					class="p-link"
					type="button"
					(keydown)="onContainerButtonKeydown($event)"
					(click)="toggleAMPM($event)"
					(keydown.enter)="toggleAMPM($event)"
				>
					<span class="pi pi-chevron-down"></span>
				</button>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
	</div>
</span>
