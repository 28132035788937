import {
	Component,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	Input,
	ChangeDetectorRef,
	ElementRef,
	Output,
	EventEmitter,
	ViewChild,
	OnDestroy,
	HostBinding,
	ContentChild,
	TemplateRef,
	AfterContentInit,
	Renderer2,
	RendererStyleFlags2,
	afterNextRender,
	input,
	effect,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { RangeSliderOrientation, SliderType } from './models/RangeSliderOrientation';
import { DomEventsService } from '../../../services';

@Component({
	selector: 'b-shared-range-slider',
	templateUrl: './range-slider.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: RangeSliderComponent,
		}
	],
})
export class RangeSliderComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
	@HostBinding('class') class = 'range';

	/** ориентация слайдера: вертикальная или горизонтальная */
	@Input() orientation: RangeSliderOrientation = RangeSliderOrientation.horizontal;

	/** минимальное значение диапазона шкалы */
	@Input() min: number = 0;

	/** максимальное значение диапазона шкалы */
	@Input() max: number = 100;

	/** шаг ползунк(а/ов) при перемещении по шкале */
	@Input() step: number = 1;

	/** анимация слайда */
	@Input() animate: boolean = false;

	/** равные шаги (расстояния) слайдера */
	@Input() isEqualSteps: boolean = false;

	/**
	 * Заранее определённый набор точек. Если указан, то параметр step будет игнорироваться.
	 */
	private _points: number[] | null = null;
	@Input() set points(value: number[] | null) {
		this._points = (Array.isArray(value) && value?.length) ?
			value?.sort((a, b) => a - b) :
			null;

		if (this._points.length > 0) {
			this.min = this._points[0];
			this.max = this._points[this._points.length - 1];
		}
	}
	get points(): number[] | null {
		return this._points;
	}

	/** флаг для отображения/скрытия ползунков */
	@Input() showThumbs: boolean = true;

	/** CSS класс для обёртки */
	@Input() wrapperClass: string | null = null;

	/** aria атрибуты */
	@Input() ariaLabelledBy: string | null = null;
	@Input() singleAriaValueText: string | null = null;
	@Input() startAriaValueText: string | null = null;
	@Input() endAriaValueText: string | null = null;

	/** определяем цвет прогресса */
	readonly type = input<SliderType>(null);
	/** Для реги в шторке */
	readonly isOverlay = input<boolean>(false);

	@Output() changeHandler: EventEmitter<number | [number, number]>;
	@Output() blurHandler: EventEmitter<any> = new EventEmitter();
	@Output() focusHandler: EventEmitter<any> = new EventEmitter();

	@Output() pointerDownHandler: EventEmitter<PointerEvent> = new EventEmitter();
	@Output() pointerUpHandler: EventEmitter<PointerEvent> = new EventEmitter();
	@Output() thumbPointerDownHandler: EventEmitter<PointerEvent> = new EventEmitter();
	@Output() thumbPointerUpHandler: EventEmitter<PointerEvent> = new EventEmitter();

	@ContentChild('contentBeforeBar') contentBeforeBar: TemplateRef<any>;
	@ContentChild('contentAfterBar') contentAfterBar: TemplateRef<any>;

	fieldValue$ = new BehaviorSubject<number | [number, number]>(null);
	fieldValue = this.fieldValue$.asObservable();

	onChange = (value) => {};
	onTouched = () => {};
	touched = false;
	disabled = false;
	subscriptions = new Subscription();
	isFocused: boolean;
	isRange: boolean;
	isSingle: boolean;
	singleDragging: boolean = false;
	rangeDragging: boolean = false;
	isThumbDragging: boolean = false;
	targetRangeThumb = null;
	value: number | [number, number] = null;
	valuesChecked: boolean = false;

	@ViewChild('singleThumb') singleThumb: ElementRef<HTMLElement>;
	@ViewChild('startThumb') startThumb: ElementRef<HTMLElement>;
	@ViewChild('endThumb') endThumb: ElementRef<HTMLElement>;

	constructor(
		private changeDetector: ChangeDetectorRef,
		public elementRef: ElementRef,
		private domEventsService: DomEventsService,
		private renderer: Renderer2,
	) {
		afterNextRender(() => {
			this.domEventsService.registerRangeSlider(this);

			this.subscriptions.add(
				fromEvent(this.elementRef.nativeElement, 'pointerdown')
					.subscribe({
						next: this.pointerdownHandler
					})
			);

			this.subscriptions.add(
				this.fieldValue.subscribe({
					next: value => {
						this.value = value;
						this.changeHandler?.emit(value);
						this.onChange(value);

						if (!this.valuesChecked && this.value !== null) {
							this.checkValidityValues();
						}

						this.isRange = this.isRangeSlider;
						this.isSingle = this.isSingleSlider;

						if (this.isSingle) {
							this.renderer.setStyle(this.elementRef.nativeElement, '--range-start-value', '0', RendererStyleFlags2.DashCase);
							this.renderer.setStyle(
								this.elementRef.nativeElement,
								'--range-end-value',
								this.getPropertyValue(value as number),
								RendererStyleFlags2.DashCase
							);
						} else if (this.isRange) {
							this.renderer.setStyle(
								this.elementRef.nativeElement,
								'--range-start-value',
								this.getPropertyValue(value[0]),
								RendererStyleFlags2.DashCase
							);
							this.renderer.setStyle(
								this.elementRef.nativeElement,
								'--range-end-value',
								this.getPropertyValue(value[1]),
								RendererStyleFlags2.DashCase
							);
						}

						this.changeDetector.detectChanges();
					}
				})
			);
		});

		effect(() => {
			const sliderType = this.type();
			if (sliderType) {
				this.setTypeClasses();
			}
		});
	}

	ngAfterContentInit() {
		// чтобы актуализировать темплейты, (contentBeforeBar, contentAfterBar) полученные через @ContentChild:
		this.changeDetector.detectChanges();
	}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
		this.domEventsService?.removeRangeSlider(this);
	}

	writeValue(value: number | [number, number]) {
		this.fieldValue$.next(value);
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;

		if (disabled) {
			this.renderer.addClass(this.elementRef.nativeElement, 'range--disabled');
		} else {
			this.renderer.removeClass(this.elementRef.nativeElement, 'range--disabled');
		}
	}

	changeValue(value: number | [number, number]) {
		this.markAsTouched();

		if (!this.disabled) {
			this.fieldValue$.next(value);
		}
	}

	get valueIsValid(): boolean {
		return this.isSingleSlider || this.isRangeSlider;
	}

	get isSingleSlider(): boolean {
		return Boolean(typeof this.value === 'number');
	}

	get isRangeSlider(): boolean {
		return Boolean(
			Array.isArray(this.value) &&
			this.value?.length === 2 &&
			this.value.every(i => typeof i === 'number')
		);
	}

	/** диапазон значений слайдера */
	get sliderPointsRange(): number {
		return this.max - this.min;
	}

	/** длина слайдера в пикселях. зависит от ориентации слайдера */
	get sliderLength(): number {
		const style = getComputedStyle(this.elementRef.nativeElement);

		if (this.orientation === RangeSliderOrientation.horizontal) return parseFloat(style.width);
		if (this.orientation === RangeSliderOrientation.vertical) return parseFloat(style.height);
	}

	/** длина еденицы диапазона в пикселях */
	get pointLength(): number {
		return this.sliderLength / this.sliderPointsRange;
	}

	/** количество шагов на шкале */
	get stepsCount(): number {
		return this.sliderPointsRange / this.step;
	}

	/** длина шага в пикселях */
	get stepLength(): number {
		return this.sliderLength / this.stepsCount;
	}

	getTargetThumb(event: PointerEvent): {
		propertyName: string;
		targetThumb: HTMLElement;
	} {
		if (this.thumbsAtTheBeginning) {
			return {
				propertyName: '--range-end-value',
				targetThumb: this.endThumb?.nativeElement,
			};
		}

		if (this.thumbsAtTheEnd) {
			return {
				propertyName: '--range-start-value',
				targetThumb: this.startThumb?.nativeElement,
			};
		}

		let pointerCoords: number;
		let startThumbCoords: number;
		let endThumbCoords: number;

		const startThumbRect = this.startThumb?.nativeElement?.getBoundingClientRect();
		const endThumbRect = this.endThumb?.nativeElement?.getBoundingClientRect();

		if (this.orientation === RangeSliderOrientation.vertical) {
			pointerCoords = event.clientY;
			startThumbCoords = startThumbRect.top + (startThumbRect.height / 2);
			endThumbCoords = endThumbRect.top + (endThumbRect.height / 2);
		} else if (this.orientation === RangeSliderOrientation.horizontal) {
			pointerCoords = event.clientX;
			startThumbCoords = startThumbRect.left + (startThumbRect.width / 2);
			endThumbCoords = endThumbRect.left + (endThumbRect.width / 2);
		} else {
			return null;
		}

		const distanceToStartSlider = Math.abs(pointerCoords - startThumbCoords);
		const distanceToEndSlider = Math.abs(pointerCoords - endThumbCoords);

		if (distanceToStartSlider === distanceToEndSlider) {
			const coordsAbove = pointerCoords > startThumbCoords && pointerCoords > endThumbCoords;

			if (this.orientation === RangeSliderOrientation.vertical) {
				return {
					propertyName: coordsAbove ? '--range-start-value' : '--range-end-value',
					targetThumb: coordsAbove ? this.startThumb?.nativeElement : this.endThumb?.nativeElement,
				};
			} else if (this.orientation === RangeSliderOrientation.horizontal) {
				return {
					propertyName: coordsAbove ? '--range-end-value' : '--range-start-value',
					targetThumb: coordsAbove ? this.endThumb?.nativeElement : this.startThumb?.nativeElement,
				};
			}
		}

		const targetThumbIsStart = distanceToStartSlider < distanceToEndSlider;

		return {
			propertyName: targetThumbIsStart ? '--range-start-value' : '--range-end-value',
			targetThumb: targetThumbIsStart ? this.startThumb?.nativeElement : this.endThumb?.nativeElement,
		};
	}

	calculateValue(event: PointerEvent, propertyName: string): void {
		let coords: number;
		const sliderRect = this.elementRef.nativeElement.getBoundingClientRect();

		if (this.orientation === RangeSliderOrientation.vertical) {
			coords = (sliderRect.top + sliderRect.height) - event.clientY;
		} else if (this.orientation === RangeSliderOrientation.horizontal) {
			coords = event.clientX - sliderRect.left;
		} else {
			return null;
		}

		let thumbValue;

		if (this.points?.length) {
			const step = (coords / this.pointLength) + this.points[0];
			const closestPoint = this.points.reduce((prev, curr) => Math.abs(curr - step) < Math.abs(prev - step) ? curr : prev);
			thumbValue = this.normalizeValue(closestPoint);
		} else {
			thumbValue = Math.round(coords / (this.pointLength * this.step)) * this.step + this.min;
			thumbValue = this.normalizeValue(thumbValue);
		}

		let newValue;

		if (this.isSingleSlider) {
			newValue = thumbValue;
		} else if (this.isRangeSlider) {
			const startValue = this.value[0];
			const endValue = this.value[1];
			newValue = [startValue, endValue];

			if (propertyName === '--range-start-value') {
				newValue[0] = thumbValue >= endValue ? endValue : thumbValue;
			} else if (propertyName === '--range-end-value') {
				newValue[1] = thumbValue <= startValue ? startValue : thumbValue;
			}
		}

		this.changeValue(newValue);
	}

	normalizeValue(value: number) {
		let thumbValue: number = value;
		if (thumbValue >= this.max) thumbValue = this.max;
		if (thumbValue <= this.min) thumbValue = this.min;

		return thumbValue;
	}

	get thumbsAtTheBeginning(): boolean {
		return this.isRangeSlider && (this.value as [number, number]).every(value => value === this.min);
	}

	get thumbsAtTheEnd(): boolean {
		return this.isRangeSlider && (this.value as [number, number]).every(value => value === this.max);
	}

	getPropertyValue(thumbValue: number): number {
		if (this.sliderPointsRange === 0) {
			return 100;
		}

		let propertyValue: number;

		if (this.isEqualSteps) {
			let index = this.points.findIndex(value => value === thumbValue);
			propertyValue = index / (this.points.length - 1) * 100;
		} else {
			propertyValue = (thumbValue - this.min) / this.sliderPointsRange * 100;
		}

		propertyValue = propertyValue <= 0 ? 0 : propertyValue;
		propertyValue = propertyValue >= 100 ? 100 : propertyValue;

		return propertyValue;
	}

	checkValidityValues() {
		this.valuesChecked = true;

		if (!this.valueIsValid) {
			console.error('b-shared-range-slider: значение компонента должно быть типа - number | [number, number]');
		}

		if (this.min > this.max) console.error('b-shared-range-slider: Значение параметра min должно быть меньше значения параметра max');

		if (typeof this.step !== 'number') console.error('b-shared-range-slider: Значение параметра step должно быть типа number');
		if (typeof this.min !== 'number') console.error('b-shared-range-slider: Значение параметра min должно быть типа number');
		if (typeof this.max !== 'number') console.error('b-shared-range-slider: Значение параметра max должно быть типа number');

		if (this.step <= 0) console.error('b-shared-range-slider: Значение параметра step должно быть больше 0');

		if (this.step > this.sliderPointsRange && this.sliderPointsRange !== 0) console.error('b-shared-range-slider: Значение параметра step не может быть больше разницы параметров max и min');

		if (Math.round(this.sliderPointsRange % this.step) !== 0) console.error('b-shared-range-slider: Значение параметра step должно быть кратно разнице параметров max и min');

		if (this.isSingleSlider) {
			const value = this.value as number;

			if (
				value < this.min ||
				value > this.max
			) console.error('b-shared-range-slider: Значение слайдера должно быть не меньше его минимального значения и не больше максимального');

			if (Math.round(value % this.step) !== 0) console.error('b-shared-range-slider: Значение слайдера должно быть кратно параметру step');
		} else if (this.isRangeSlider) {
			const startValue = this.value[0] as number;
			const endValue = this.value[1] as number;

			if (
				startValue < this.min ||
				startValue > this.max ||
				endValue < this.min ||
				endValue > this.max
			) console.error('b-shared-range-slider: Значение слайдера должно быть не меньше его минимального значения и не больше максимального');

			if (startValue > endValue) console.error('b-shared-range-slider: Значение начала диапазона должно быть меньше или равно значению конца диапазона');

			if (
				startValue % this.step !== 0 ||
				endValue % this.step !== 0
			) console.error('b-shared-range-slider: Значение слайдера должно быть кратно параметру step');
		}
	}

	pointerdownHandler = (event: PointerEvent) => {
		event.preventDefault();

		if (this.disabled) {
			return;
		}

		this.isFocused = true;

		const isDraggingThumb = [
			this.singleThumb?.nativeElement,
			this.startThumb?.nativeElement,
			this.endThumb?.nativeElement
		].some(element => element === event.target);

		this.pointerDownHandler.emit(event);

		if (isDraggingThumb) {
			this.isThumbDragging = true;
			this.thumbPointerDownHandler.emit(event);
		}

		if (this.isSingleSlider) {
			this.singleDragging = true;
			this.singleThumb?.nativeElement?.focus();
		} else if (this.isRangeSlider) {
			this.rangeDragging = true;
			this.targetRangeThumb = this.getTargetThumb(event);
			this.targetRangeThumb.targetThumb?.focus();
		}
	};

	/**
	 * Used in DomEventsService
	 */
	pointerupHandler = (event: PointerEvent) => {
		event.preventDefault();
		this.markAsTouched();

		if (this.disabled) {
			return;
		}

		this.isFocused = false;

		if (this.isSingleSlider) {
			this.calculateValue(event, '--range-end-value');
		} else if (this.isRangeSlider) {
			this.targetRangeThumb = this.getTargetThumb(event);
			this.calculateValue(event, this.targetRangeThumb.propertyName);
		}
	};

	/**
	 * Used in DomEventsService
	 */
	pointermoveHandler = (event: PointerEvent) => {
		if (this.disabled || !this.isFocused) {
			return;
		}

		let percentage: number;
		let newValue;
		const sliderRect = this.elementRef.nativeElement.getBoundingClientRect();

		if (this.orientation === RangeSliderOrientation.vertical) {
			percentage = ((sliderRect.top + sliderRect.height) - event.clientY) / sliderRect.height;
		}

		if (this.orientation === RangeSliderOrientation.horizontal) {
			percentage = (event.clientX - sliderRect.left) / sliderRect.width;
		}

		if (!percentage) {
			return;
		}

		if (this.isSingleSlider) {
			this.calculateValue(event, '--range-end-value');
			newValue = percentage;
		} else if (this.isRangeSlider) {
			this.calculateValue(event, this.targetRangeThumb.propertyName);
			// newValue = percentage; // todo Calculate value
		}

		this.renderer.setStyle(
			this.elementRef.nativeElement,
			'--range-end-value',
			Math.max(0, Math.min(newValue * 100, 100)),
			RendererStyleFlags2.DashCase
		);
	};

	private setTypeClasses(): void {
		switch (this.type()) {
		case 'success':
			this.wrapperClass = 'range__bar--success';
			break;
		case 'error':
			this.wrapperClass = 'range__bar--error';
			break;
		default:
			this.wrapperClass = null;
			break;
		}
		this.changeDetector.detectChanges();
	}
}
