<ng-container
	*ngIf="contentBeforeBar"
	[ngTemplateOutlet]="contentBeforeBar"
></ng-container>

<div
	class="range__bar"
	[class.range__bar--easing]="!isFocused && !isOverlay"
	[ngClass]="wrapperClass"
	[class.range__animation]="animate">
	<div
		*ngIf="showThumbs && isSingle"
		#singleThumb
		role="slider"
		class="range__thumb"
		[class.pe-none]="disabled"
		[attr.tabindex]="disabled ? null : 0"
		[attr.aria-valuemin]="min"
		[attr.aria-valuemax]="max"
		[attr.aria-valuenow]="value"
		[attr.aria-orientation]="orientation"
		[attr.aria-labelledby]="ariaLabelledBy"
		[attr.aria-valuetext]="singleAriaValueText"
		aria-label="Choose a value"
		(focus)="focusHandler?.emit($event)"
		(blur)="blurHandler?.emit($event)"
	></div>

	<ng-container *ngIf="showThumbs && isRange">
		<div
			#startThumb
			role="slider"
			class="range__thumb"
			[attr.tabindex]="disabled ? null : 0"
			[attr.aria-valuemin]="min"
			[attr.aria-valuemax]="value[1]"
			[attr.aria-valuenow]="value[0]"
			[attr.aria-orientation]="orientation"
			[attr.aria-labelledby]="ariaLabelledBy"
			[attr.aria-valuetext]="startAriaValueText"
			[ngClass]="{
				'pe-none': disabled
			}"
			aria-label="Choose a value"
			(focus)="focusHandler?.emit($event, 'startThumb')"
			(blur)="blurHandler?.emit($event, 'startThumb')"
		></div>
		<div
			#endThumb
			role="slider"
			class="range__thumb"
			[attr.tabindex]="disabled ? null : 0"
			[attr.aria-valuemin]="value[0]"
			[attr.aria-valuemax]="max"
			[attr.aria-valuenow]="value[1]"
			[attr.aria-orientation]="orientation"
			[attr.aria-labelledby]="ariaLabelledBy"
			[attr.aria-valuetext]="endAriaValueText"
			[ngClass]="{
				'pe-none': disabled
			}"
			aria-label="Choose a value"
			(focus)="focusHandler?.emit($event, 'endThumb')"
			(blur)="blurHandler?.emit($event, 'endThumb')"
		></div>
	</ng-container>

</div>

<ng-container
	*ngIf="contentAfterBar"
	[ngTemplateOutlet]="contentAfterBar"
></ng-container>
