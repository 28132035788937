<div class="drop__inner">
	<button
		(click)="toggleOverlay()"
		class="drop__btn btn"
		type="button"
		#triggerButton
	>
		<span class="drop__field" #viewValueElement *ngIf="valueView">
			<span [ngClass]="dropFieldClass">{{ valueView }}</span>
		</span>
		<svg
			b-shared-svg
			[name]="dropIconName"
			class="drop__icon icon icon--xl ml-auto"
			[ngClass]="{'drop__icon--rotated': overlayIsOpened | async}"
		></svg>
	</button>

	<div
		*ngIf="overlayIsOpened | async"
		#overlay
		[@fadeIn]="animationParams"
		[ngClass]="overlayNgClass"
		class="drop__wrapper drop__content"
	>
		<ng-container [ngSwitch]="mode">
			<ng-container *ngSwitchCase="modeTypes.grouped">
				<ng-container *ngIf="groupedModel?.length">
					<ul
						#listContainer
						class="drop__list"
						role="listbox"
					>
						<ng-container *ngFor="let group of groupedModel; trackBy: trackByGroups">
							<li *ngIf="group?.label" class="p-0">{{ group.label }}</li>
							<ng-container *ngIf="group?.items?.length">
								<li
									*ngFor="let item of group.items; trackBy: trackByElements"
									role="option"
								>
									<label
										*ngIf="group.type === groupTypes.radio"
										class="drop__item checkbox pt-8"
									>
										<input
											type="radio"
											[attr.name]="group.id"
											[value]="item.id"
											[(ngModel)]="group.items.selectedRadioId"
											(ngModelChange)="changeModel()"
										>
										<svg
											b-shared-svg
											name="checkbox_md"
											class="checkbox__box"
											height="24"
										></svg>
										<span class="checkbox__label">{{ item.label }}</span>
									</label>

									<label
										*ngIf="group.type === groupTypes.checkbox"
										class="drop__item checkbox pt-8"
									>
										<input
											class="checkbox__input"
											type="checkbox"
											[attr.name]="group.id"
											[value]="item.id"
											[(ngModel)]="item.checked"
											(ngModelChange)="changeModel()"
										>
										<svg
											b-shared-svg
											name="checkbox_md"
											class="checkbox__box"
											height="24"
										></svg>
										<span class="checkbox__label">{{ item.label }}</span>
									</label>
								</li>
							</ng-container>
						</ng-container>
					</ul>
				</ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="modeTypes.checkbox">
				<ng-container *ngIf="model?.length">
					<ul
						#listContainer
						class="drop__list"
						role="listbox"
					>
						<li
							*ngFor="let checkbox of model; trackBy: trackByElements"
							role="option"
						>
							<label class="drop__item checkbox pt-8">
								<input
									class="checkbox__input"
									type="checkbox"
									[attr.name]="guid.create().toString()"
									[value]="checkbox.id"
									[(ngModel)]="checkbox.checked"
									(ngModelChange)="changeModel()"
								>
								<svg
									b-shared-svg
									name="checkbox_md"
									class="checkbox__box"
									height="24"
								></svg>
								<span class="checkbox__label">{{ checkbox.label }}</span>
							</label>
						</li>
					</ul>
				</ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="modeTypes.radio">
				<ng-container *ngIf="model?.length">
					<ul
						#listContainer
						class="drop__list"
						role="listbox"
					>
						<li
							*ngFor="let radiobutton of model; trackBy: trackByElements"
							role="option"
						>
							<label class="drop__item checkbox pt-8">
								<input
									type="radio"
									[attr.name]="guid.create().toString()"
									[value]="radiobutton.id"
									[(ngModel)]="model.selectedRadioId"
									(ngModelChange)="changeModel()"
								>
								<svg
									b-shared-svg
									name="checkbox_md"
									class="checkbox__box"
									height="24"
								></svg>
								<span class="checkbox__label">{{ radiobutton.label }}</span>
							</label>
						</li>
					</ul>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</div>
