import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AsyncViewDirective } from './async-view.directive';

@NgModule({
	declarations: [
		AsyncViewDirective,
	],
	imports: [
		CommonModule,
	],
	exports: [
		AsyncViewDirective,
	],
})
export class AsyncViewModule {}
