import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '../svg/svg.module';
import { PaymentCardComponent } from './payment-card.component';

@NgModule({
	declarations: [PaymentCardComponent],
	imports: [
		CommonModule,
		SvgModule,
	],
	exports: [PaymentCardComponent],
})
export class PaymentCardModule {}
