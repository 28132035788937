import { animate, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component, EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	AfterViewInit,
	ViewEncapsulation,
	ContentChildren,
	QueryList,
	ElementRef,
	TemplateRef,
	ViewChildren,
	ContentChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import { DateService, Project, SharedService } from '../../../services';
import { SharedTemplateDirective } from '../../directives';
import { SeparateInputComponent } from '../separate-input/separate-input.component';

@Component({
	selector: 'b-shared-sms-confirm',
	templateUrl: './sms-confirm.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('lifecycleTrigger', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('200ms ease-in', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0 })),
			]),
		]),
	],
})
export class SmsConfirmComponent implements OnDestroy, OnInit, AfterViewInit {

	/** симуляция проекта */
	@Input() projectSimulation: Project | keyof typeof Project | null = null;

	/** длина смс-кода */
	@Input() codeLength: number = 4;

	/** включение глобальной блокировки смс подтверждения */
	@Input() globalBlocking: boolean = false;

	/** уникальный идентификатор для хранения даты разблокировки смс подтверждения в localStorage */
	@Input() blockId: string | null = null;

	/** разделённый инпут */
	@Input() separateInput: boolean = true;

	/** плейсхолдер */
	@Input() placeholder: string = '•';

	/** список response type'ов, при которых считается, что смс успешно подтверждена. если null или список пуст, то смс считается подтверждённой при 200 ответе */
	@Input() successTypes: string[]| null = ['Confirmed', 'Success'];

	/** список response type'ов, при которых считается, что смс подтверждение временно заблокировано и нужно отобразить таймер */
	@Input() blockedTypes: string[] = ['Blocked'];

	/** список response type'ов, при которых считается, что смс успешно отправлено. если null или список пуст, то смс считается подтверждённой при 200 ответе */
	@Input() sentTypes: string[] | null = ['Sent'];

	/** список response type'ов, при которых считается, что повторная отправка смс временно заблокирована и нужно отобразить таймер */
	@Input() throttledTypes: string[] = ['Throttled'];

	/** дополнительные классы для подсказок */
	@Input() hintClass: string = 'hint';

	/** дополнительные классы для подсказок с ошибкой */
	@Input() hintErrorClass: string;

	/** дополнительные классы для кнопки "отправить код еще раз" */
	@Input() resendBtnClass: string;

	/**
	 * Список response type'ов, при которых инпут будет выделен, как невалидный.
	 * Если передан null, инпут будет выделен при наличии любой ошибки валидации
	 */
	@Input() fieldErrorTypes: string[] | null = null;

	/** таймер до повторного запроса смс */
	@Input() resendingTimer: number = 60000;

	/** таймер блокировки смс подтверждения */
	@Input() blockingTimer: number = 300000;

	@Input() confirmMethod?: (smsCode: string) => Observable<any>;
	@Input() sendCodeMethod?: () => Observable<any>;
	@Input() resendCodeMethod?: () => Observable<any>;

	@Output() successConfirmEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() sentSmsConfirmationEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() finalSmsConfirmationEvent: EventEmitter<any> = new EventEmitter<any>();

	@ContentChildren(SharedTemplateDirective) customErrorTemplates: QueryList<SharedTemplateDirective>;
	@ViewChildren(SharedTemplateDirective) templates: QueryList<SharedTemplateDirective>;
	@ViewChild('separateSmsInput') separateSmsInput: SeparateInputComponent;
	@ViewChild('defaultSmsInput') defaultSmsInput: ElementRef<HTMLInputElement>;
	@ContentChild('throttledTemplate') throttledTemplate: TemplateRef<any>;
	@ContentChild('resendButtonTemplate') resendButtonTemplate: TemplateRef<any>;
	@ContentChild('titleTemplate') titleTemplate: TemplateRef<any>;

	smsForm: UntypedFormGroup;
	subscriptions = new Subscription();
	sendCodeLoader = false;
	confirmLoader: boolean = false;
	codeEntryBlocked = false;
	blockingCodeTimer: Subscription;
	throttleCodeTimer: Subscription;
	timeToUnlockThrottle?: number;
	timeToUnlockBlocking?: number;
	isThrottled: boolean = false;
	globalBlockId: string = 'globalBlockingDateOfSharedSmsConfirm';
	blockIdPrefix = 'blockingIdOfSharedSmsConfirm-';
	inputIsFocused: boolean = false;
	isServerThrottle: boolean = false;
	codeReceivedAfterInit = false;

	constructor(
		private fb: UntypedFormBuilder,
		private dateService: DateService,
		private changeDetector: ChangeDetectorRef,
		private sharedService: SharedService,
	) {}

	ngOnInit(): void {
		this.removeOutdatedBlocks();

		if (!this.globalBlocking && !this.blockId) {
			console.error('b-shared-sms-confirm: для блокировки смс подтверждения необходимо указать идентификатор blockId');
			return;
		}

		if (!this.confirmMethod || !this.sendCodeMethod || !this.resendCodeMethod) {
			console.error('b-shared-sms-confirm: необходимо передать методы для получения и подтверждения смс: confirmMethod, sendCodeMethod, resendCodeMethod');
			return;
		}

		this.smsForm = this.fb.group({
			smsCode: [''],
		});

		this.subscriptions.add(
			this.smsField?.valueChanges
				.subscribe((value: string) => {
					const parsedCode = value
						?.replace(/[^0-9]+/g, '')
						?.substring(0, this.codeLength);

					if (!this.separateInput) {
						this.smsField?.patchValue(parsedCode, { emitEvent: false });
					}

					if (parsedCode?.length === this.codeLength) {
						this.confirmSms();
					}
				}),
		);
	}

	ngAfterViewInit(): void {
		this.changeDetector.detectChanges();

		if (this.unlockDate) {
			this.blockCodeEntry(new Date(this.unlockDate), true);
		} else {
			this.sendCode(false);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions?.unsubscribe();
		this.blockingCodeTimer?.unsubscribe();
		this.throttleCodeTimer?.unsubscribe();
	}

	startResendingCodeBlockingFlow({
		throttleDate = new Date(new Date().getTime() + Number(this.resendingTimer)),
		isServerThrottle = false,
		serverErrorType,
	}: {
		throttleDate?: Date,
		isServerThrottle?: boolean,
		serverErrorType?: string,
	}): void {
		this.isServerThrottle = false;
		const smsSendingUnlocked = new Date(throttleDate)?.getTime() - new Date().getTime() <= 0;

		if (smsSendingUnlocked) {
			return;
		}

		if (isServerThrottle) {
			this.isServerThrottle = true;
		}

		this.smsForm.patchValue({ smsCode: '' });
		this.changeDetector.detectChanges();

		this.throttleCodeTimer?.unsubscribe();
		this.throttleCodeTimer = this.dateService.timerUntilDate(throttleDate).pipe(
			tap({
				next: (timeToUnlock: number) => {
					if (isServerThrottle) {
						this.isServerThrottle = true;
					}
					this.timeToUnlockThrottle = timeToUnlock / 1000;
					this.isThrottled = true;
					this.changeDetector.detectChanges();
				},
				complete: () => {
					this.isServerThrottle = false;
					this.timeToUnlockThrottle = 0;
					this.isThrottled = false;

					if (this.codeReceivedAfterInit && this.smsField.disabled) {
						this.smsField?.enable({ emitEvent: false });
					}

					if (isServerThrottle) {
						this.smsField?.setErrors({
							...this.smsField?.errors,
							[serverErrorType]: null,
						});
					}
					this.changeDetector.detectChanges();
				},
			})
		)
			.subscribe();
	}

	blockCodeEntry(unlockDate: Date = new Date(new Date().getTime() + Number(this.blockingTimer)), init: boolean = false): void {
		const smsVerifyUnlocked = new Date(unlockDate)?.getTime() - new Date().getTime() <= 0;

		if (smsVerifyUnlocked) {
			this.unlockDate = null;
			if (init) {
				this.sendCode(false);
			}
			return;
		}

		this.smsField?.disable({ emitEvent: false });
		this.smsForm.patchValue({ smsCode: '' });
		this.smsField?.setErrors({
			...this.smsField?.errors,
			EntryBlocked: true,
		});

		this.unlockDate = unlockDate.toString();
		this.isServerThrottle = false;
		this.timeToUnlockThrottle = 0;
		this.isThrottled = false;

		this.changeDetector.detectChanges();

		this.throttleCodeTimer?.unsubscribe();
		this.blockingCodeTimer?.unsubscribe();
		this.blockingCodeTimer = this.dateService.timerUntilDate(unlockDate)
			.pipe(
				tap({
					next: (timeToUnlock: number) => {
						this.codeEntryBlocked = true;
						this.timeToUnlockBlocking = timeToUnlock / 1000;
						this.changeDetector.detectChanges();
					},
					complete: () => {
						this.codeEntryBlocked = false;
						this.timeToUnlockBlocking = 0;
						this.unlockDate = null;

						if (this.codeReceivedAfterInit) {
							this.smsField?.enable({ emitEvent: false });
						}

						this.smsField?.setErrors({
							...this.smsField?.errors,
							EntryBlocked: null,
						});
						this.focusInput();
						this.changeDetector.detectChanges();
					}
				})
			)
			.subscribe();
	}

	get smsField(): UntypedFormControl {
		return this.smsForm?.controls?.smsCode as UntypedFormControl;
	}

	/** Единый обработчик для API подтверждения смс и для API получения смс */
	private responseHandler({
		response,
		smsCode = '',
		event,
		status,
	}: {
		response: any;
		status: 'success' | 'error';
		smsCode?: string;
		event: 'send' | 'resend' | 'confirm',
	}) {
		// добавить в message/type параметры, если в API другая модель:
		const message = response?.error?.error?.message || response?.error?.message || response?.value?.message || response?.userMessage || response?.message;
		const type = response?.error?.error?.type || response?.error?.value?.type || response?.error?.type || response?.value?.type || response?.status || response?.type;

		let successTypes = [];
		if (event === 'confirm') {
			successTypes = this.successTypes;
		} else if (event === 'send' || event === 'resend') {
			successTypes = this.sentTypes;
		}

		const isSuccess = (!successTypes || !successTypes?.length) ?
			status === 'success' :
			successTypes.includes(type);
		const isBlocked = this.blockedTypes.includes(type);
		const isThrottled = this.throttledTypes.includes(type);
		const isSendingSuccess = isSuccess && (event === 'send' || event === 'resend');

		if (isSendingSuccess) {
			this.codeReceivedAfterInit = true;
		}

		if (!isBlocked) {
			if (this.codeReceivedAfterInit) {
				this.smsField?.enable({ emitEvent: false });
			}
			this.smsForm.patchValue({ smsCode: '' });
			this.focusInput();
		} else {
			// добавить в timeToUnlock параметры, если в API другая модель:
			const timeToUnlock = response?.payload?.refreshCodeTimeoutSeconds || response?.error?.error?.data?.timeoutMs || Number(this.blockingTimer);
			const unlockDate = new Date(new Date().getTime() + timeToUnlock);
			this.blockCodeEntry(unlockDate);
			return;
		}

		if (isSuccess) {
			this.smsField?.setErrors({});

			if (event === 'confirm') {
				this.successConfirmEvent.emit({
					smsCode,
					response,
				});
			}
			if (isSendingSuccess) {
				const blockingData: any = {};

				const throttleTime = response?.timeoutMilliseconds || Number(this.resendingTimer);

				if (throttleTime) {
					const throttleDate = new Date(new Date().getTime() + throttleTime);
					blockingData.throttleDate = throttleDate;
				}

				this.startResendingCodeBlockingFlow(blockingData);
			}

			if (event === 'confirm') {
				this.throttleCodeTimer?.unsubscribe();
				this.blockingCodeTimer?.unsubscribe();
			}
		}

		if (isThrottled) {
			const throttleTime = response?.error?.error?.data?.timeoutMs || Number(this.resendingTimer);
			const throttleDate = new Date(new Date().getTime() + throttleTime);
			this.startResendingCodeBlockingFlow({
				throttleDate,
				isServerThrottle: true,
				serverErrorType: type,
			});
		}

		if (!isSuccess && type) {
			this.smsField?.setErrors({
				...this.smsField?.errors,
				[type]: {
					response,
					message,
				},
			});
		}
	}

	confirmSms(): void {
		const smsCode = this.smsField?.value;

		if (!smsCode?.length || smsCode?.length < this.codeLength) {
			return;
		}

		this.smsField?.disable({ emitEvent: false });
		this.smsForm.setErrors({});
		this.smsForm.updateValueAndValidity({ emitEvent: false });

		this.sentSmsConfirmationEvent.emit(smsCode);

		this.confirmLoader = true;
		this.changeDetector.detectChanges();

		this.subscriptions.add(
			this.confirmMethod(smsCode)
				.pipe(
					finalize(() => {
						this.confirmLoader = false;
						this.changeDetector.detectChanges();
						this.finalSmsConfirmationEvent.emit();
					})
				)
				.subscribe({
					next: response => {
						this.responseHandler({
							response,
							status: 'success',
							smsCode,
							event: 'confirm',
						});
					},
					error: (response) => {
						this.responseHandler({
							response,
							status: 'error',
							smsCode,
							event: 'confirm',
						});
					}
				})
		);
	}

	sendCode(resend: boolean = false): void {
		this.smsField?.disable({ emitEvent: false });
		this.smsForm.patchValue({ smsCode: '' });
		this.smsField?.setErrors({});

		this.sendCodeLoader = true;
		this.changeDetector.detectChanges();

		const method = resend ? this.resendCodeMethod : this.sendCodeMethod;

		this.subscriptions.add(
			method()
				.pipe(
					finalize(() => {
						this.sendCodeLoader = false;
						this.changeDetector.detectChanges();
					})
				)
				.subscribe({
					next: response => {
						this.responseHandler({
							response,
							status: 'success',
							event: resend ? 'resend' : 'send',
						});
					},
					error: response => {
						this.responseHandler({
							response,
							status: 'error',
							event: resend ? 'resend' : 'send',
						});
					},
				})
		);
	}

	get project(): Project | keyof typeof Project {
		return this.projectSimulation || this.sharedService?.environment?.project;
	}


	public get isClientEnvironment(): boolean {
		return Boolean(this.project) && this.project === Project.client;
	}


	private get unlockDate(): string | null {
		return localStorage.getItem(this.globalBlocking ? this.globalBlockId : `${this.blockIdPrefix}${this.blockId}`) || null;
	}

	private set unlockDate(value: string | null) {
		const id = this.globalBlocking ? this.globalBlockId : `${this.blockIdPrefix}${this.blockId}`;
		if (value) {
			localStorage.setItem(id, value);
		} else {
			localStorage.removeItem(id);
		}
	}

	public get errors() {
		return Object.keys(this.smsField?.errors || {})
			.filter(errorKey => Boolean(this.smsField?.errors[errorKey]))
			.map(errorKey => ({
				errorKey,
				template: this.customErrorTemplates?.find(template => template?.getName?.()?.toLowerCase() === errorKey?.toLowerCase())?.template ||
					this.defaultErrors?.find(template => template?.getName?.()?.toLowerCase() === errorKey?.toLowerCase())?.template ||
					null,
				response: this.smsField?.errors?.[errorKey]?.response,
				message: this.smsField?.errors?.[errorKey]?.message,
			}));
	}

	trackByErrors(index: number, error) {
		return error?.errorKey;
	}

	focusInput() {
		this.defaultSmsInput?.nativeElement?.focus();
		this.separateSmsInput?.focus();
	}

	get fieldWithError() {
		return Array.isArray(this.fieldErrorTypes) ?
			Object.keys(this.smsField?.errors || {}).some(errorKey => this.fieldErrorTypes.includes(errorKey)) :
			Boolean(this.errors?.length);
	}

	get defaultErrors() {
		return this.templates.filter(template => template.getGroup() === 'smsDefaultErrors');
	}

	inputFocused() {
		this.inputIsFocused = true;
	}

	inputBlured() {
		this.inputIsFocused = false;
	}

	removeOutdatedBlocks() {
		Object.entries(localStorage).forEach(([key, value]) => {
			const isSmsBlockingDate = key === this.globalBlockId || key.startsWith(this.blockIdPrefix);

			if (!isSmsBlockingDate) return;

			const isOutdated = new Date(value)?.getTime() <= new Date().getTime();

			if (isOutdated) {
				localStorage.removeItem(key);
			}
		});
	};

}
