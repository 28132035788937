import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmountComponent } from './amount.component';

@NgModule({
	declarations: [AmountComponent],
	imports: [
		CommonModule,
	],
	exports: [AmountComponent],
})
export class AmountModule {}
