<div
	[class]="overlayClass"
	[ngClass]="{
		'popup': type === overlayTypes.popup,
		'popup--product paranja paranja--1': type === overlayTypes.popup && isClientEnvironment,
		'overlay-content': true
	}"
	*ngIf="visibility"
	[@overlayLifecycleAnimation]="animationParams"
	(@overlayLifecycleAnimation.start)="animationStart($event)"
	(@overlayLifecycleAnimation.done)="animationDone($event)"
	aria-modal="true"
	[ngStyle]="{ zIndex: _zIndex }"
	#overlay
>

	<ng-container *ngIf="type === overlayTypes.popup">
		<div
			class="popup__wrapper"
			[ngClass]="overlayContainerClass"
			#overlayContent
		>
			<header
				class="popup__header"
				#headerElement
				*ngIf="headerRef || showCloseIcon"
				[@fade]
			>
				<ng-container
					*ngIf="headerRef"
					[ngTemplateOutlet]="headerRef"
					[ngTemplateOutletContext]="{$implicit: templateContext}"
				></ng-container>
				<button
					b-shared-button
					#closeButton
					icon="icons_24_2_cross"
					iconClass="icon--xl"
					title="Закрыть"
					[class]="closeBtnClass"
					[ngClass]="{
						'btn': true,
						'ml-auto': true
					}"
					(pointerup)="closeOverlay()"
					*ngIf="showCloseIcon && (hidingButtonByDrops$ | async) === false"
					[@fade]
				></button>
			</header>

			<section class="popup__content" #contentElement *ngIf="contentRef" [@fade] [ngClass]="contentClass">
				<ng-container
					[ngTemplateOutlet]="contentRef"
					[ngTemplateOutletContext]="{$implicit: templateContext}"
				></ng-container>

				<div class="popup__overlay-loader" *ngIf="blockContent" [@fade]>
					<svg
						*ngIf="loader"
						[@fade]
						b-shared-svg
						name="icons_cl_32_loader"
						class="icon icon--lg rotating"
					></svg>
				</div>
			</section>

			<footer
				class="popup__footer"
				*ngIf="footerRef"
				[@fade]
				#footerElement
			>
				<ng-container
					[ngTemplateOutlet]="footerRef"
					[ngTemplateOutletContext]="{$implicit: templateContext}"
				></ng-container>
			</footer>
		</div>
	</ng-container>

	<ng-container *ngIf="type === overlayTypes.sidebar">
		<button
			b-shared-button
			#closeButton
			icon="icons_24_2_cross"
			iconClass="icon--xl"
			title="Закрыть"
			[class]="closeBtnClass"
			[ngClass]="{
				'overlay-content__close btn btn--floating': true
			}"
			(pointerup)="closeOverlay()"
			*ngIf="showOuterCloseIcon"
			[@fade]
		></button>

		<div
			class="sidebar"
			[ngClass]="overlayContainerClass"
			#overlayContent
			(scroll)="scrollOverlay($event)"
		>
			<header
				class="sidebar__header"
				[ngClass]="{
					'shadow-line': sidebarContentIsScrolled
				}"
				#headerElement
				*ngIf="headerRef || showCloseIcon"
				[@fade]
			>
				<ng-container
					*ngIf="headerRef"
					[ngTemplateOutlet]="headerRef"
					[ngTemplateOutletContext]="{$implicit: templateContext}"
				></ng-container>
				<button
					b-shared-button
					#closeButton
					icon="icons_24_2_cross"
					iconClass="icon--xl"
					title="Закрыть"
					class="btn sidebar__close"
					[ngClass]="closeBtnClass"
					(pointerup)="closeOverlay()"
					*ngIf="showCloseIcon && (hidingButtonByDrops$ | async) === false"
					[@fade]
				></button>
			</header>

			<section
				*ngIf="contentRef"
				[@fade]
				class="sidebar__content-wrapper"
				(scroll)="sidebarContentScrollHandler($event)"
				#contentElement
				[ngClass]="contentClass"
			>
				<div class="sidebar__content">
					<ng-container
						[ngTemplateOutlet]="contentRef"
						[ngTemplateOutletContext]="{$implicit: templateContext}"
					></ng-container>
				</div>

				<div class="sidebar__overlay-loader" *ngIf="blockContent" [@fade]>
					<svg
						*ngIf="loader"
						[@fade]
						b-shared-svg
						name="icons_cl_32_loader"
						class="icon icon--lg rotating"
					></svg>
				</div>
			</section>

			<footer class="sidebar__footer" *ngIf="footerRef" [@fade] #footerElement>
				<ng-container
					[ngTemplateOutlet]="footerRef"
					[ngTemplateOutletContext]="{$implicit: templateContext}"
				></ng-container>
			</footer>
		</div>
	</ng-container>

	<ng-container
		*ngIf="
			type === overlayTypes.sidebarSecondary ||
			type === overlayTypes.dropdownSidebar ||
			type === overlayTypes.successScreen
		"
	>
		<ng-container
			*ngIf="!isCloseBtnInside"
			[ngTemplateOutlet]="sidebarCloseBtn"
		></ng-container>

		<svg
			*ngIf="bgIcon"
			[@fade]
			b-shared-svg
			[name]="bgIcon"
			class="overlay-content__bg-icon"
		></svg>

		<div class="overlay-content__aside" #aside *ngIf="overlayAsiderRef">
			<ng-container
				[ngTemplateOutlet]="overlayAsiderRef"
			></ng-container>
		</div>

		<div
			class="sidebar"
			[ngClass]="overlayContainerClass"
			#overlayContent
			(scroll)="scrollOverlay($event)"
		>
			<ng-container
				*ngIf="isCloseBtnInside"
				[ngTemplateOutlet]="sidebarCloseBtn"
			></ng-container>

			<ng-container *ngIf="(isHiddenScrollChecked$ | async) === true">
				<ngx-simplebar
					*ngIf="customScroll === scrollDestinations.inner && noHiddenScroll; else overlayContentInnerDiv"
					class="sidebar__inner"
					#overlayContentInner
				>
					<ng-container [ngTemplateOutlet]="sidebarWrapper"></ng-container>
				</ngx-simplebar>

				<ng-template #overlayContentInnerDiv>
					<div
						class="sidebar__inner"
						#overlayContentInner
					>
						<ng-container [ngTemplateOutlet]="sidebarWrapper"></ng-container>
					</div>
				</ng-template>
			</ng-container>
		</div>
	</ng-container>

</div>

<ng-template #sidebarWrapper>
	<ng-container *ngIf="(isHiddenScrollChecked$ | async) === true">
		<ngx-simplebar
			*ngIf="customScroll === scrollDestinations.wrapper && noHiddenScroll; else overlayWrapperDiv"
			class="sidebar__wrapper"
			[ngClass]="wrapperClass"
			#overlayWrapper
		>
			<ng-container [ngTemplateOutlet]="sidebarWrapperContent"></ng-container>
		</ngx-simplebar>

		<ng-template #overlayWrapperDiv>
			<div
				class="sidebar__wrapper"
				[ngClass]="wrapperClass"
				#overlayWrapper
			>
				<ng-container [ngTemplateOutlet]="sidebarWrapperContent"></ng-container>
			</div>
		</ng-template>
	</ng-container>
</ng-template>

<ng-template #sidebarWrapperContent>
	<header
		[class]="headerClass"
		#headerElement
		[ngClass]="{
			'sidebar__header': true,
			'sidebar__header--scroll': sidebarContentIsScrolled
		}"
		*ngIf="headerRef"
	>
		<ng-container
			[ngTemplateOutlet]="headerRef"
			[ngTemplateOutletContext]="{$implicit: templateContext}"
		></ng-container>
	</header>

	<ng-container *ngIf="(isHiddenScrollChecked$ | async) === true">
		<ngx-simplebar
			*ngIf="contentRef &&
			customScroll === scrollDestinations.content &&
			noHiddenScroll; else contentElementSection"
			class="sidebar__content"
			[ngClass]="contentClass"
			(scroll)="sidebarContentScrollHandler($event)"
			#contentElement
			[@fade]
		>
			<ng-container [ngTemplateOutlet]="sidebarContent"></ng-container>
		</ngx-simplebar>
		<ng-template #contentElementSection>
			<section
				*ngIf="contentRef"
				class="sidebar__content"
				[ngClass]="contentClass"
				(scroll)="sidebarContentScrollHandler($event)"
				#contentElement
				[@fade]
			>
				<ng-container [ngTemplateOutlet]="sidebarContent"></ng-container>
			</section>
		</ng-template>
	</ng-container>

	<footer
		class="sidebar__footer"
		[ngClass]="footerClass"
		*ngIf="footerRef"
		[@fade]
		#footerElement
	>
		<div
			class="sidebar__controls"
			[ngClass]="sidebarControlsClass"
		>
			<div
				class="sidebar__paranja"
				[ngClass]="sidebarParanjaNgClass"
				*ngIf="blockContent && footerRef"
				[@fade]
			>
				<ng-container [ngTemplateOutlet]="iconLoader"></ng-container>
			</div>

			<ng-container
				[ngTemplateOutlet]="footerRef"
				[ngTemplateOutletContext]="{$implicit: templateContext}"
			></ng-container>
		</div>
	</footer>
</ng-template>

<ng-template #sidebarContent>
	<ng-container
		[ngTemplateOutlet]="contentRef"
		[ngTemplateOutletContext]="{$implicit: templateContext}"
	></ng-container>

	<div
		class="sidebar__paranja"
		[ngClass]="sidebarParanjaNgClass"
		*ngIf="blockContent && !footerRef"
		[@fade]
	>
		<ng-container [ngTemplateOutlet]="iconLoader"></ng-container>
	</div>
</ng-template>

<ng-template #iconLoader>
	<svg
		*ngIf="loader"
		[@fade]
		b-shared-svg
		name="icons_scaling_loader"
		class="icon icon--lg rotating"
	></svg>
</ng-template>

<ng-template #sidebarCloseBtn>
	<button
		b-shared-button
		#closeButton
		icon="icons_scaling_cross"
		[class]="closeBtnClass"
		[ngClass]="{
			'btn btn--sm btn--icon btn--secondary-light flex-as-start ml-auto sidebar__close':
				type === overlayTypes.sidebar || type === overlayTypes.popup,
			'btn btn--floating overlay-content__close':
				type !== overlayTypes.sidebar && type !== overlayTypes.popup
		}"
		[iconClass]="type === overlayTypes.sidebar || type === overlayTypes.popup ? 'icon--sm' : 'icon--md'"
		title="Закрыть"
		(pointerup)="closeOverlay()"
		*ngIf="showCloseIcon && isTopOverlay && (hidingButtonByDrops$ | async) === false"
		[@fade]
	></button>
</ng-template>
