import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { SuggestPosition } from '../ui/components/dropdown/dropdown.types';
import { DeviceService } from './device.service';
import { WINDOW } from '../tokens';

@Injectable({
	providedIn: 'root',
})
export class ScrollService {
	private renderer: Renderer2;

	public scrollIsBlocked = false;

	/** Добавленный к body margin-right: */
	private addedMarginRightToBody = 0;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(WINDOW) private _window: Window,
		private deviceService: DeviceService,
		private rendererFactory: RendererFactory2,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	scrollToElement(element, margin = 20) {
		const rect = element?.getBoundingClientRect();

		if (!rect) {
			return;
		}

		if (rect.top <= margin) {
			this._window.scroll(0, rect.top + this.document.documentElement.scrollTop - margin);
		}
	}

	getElementScrollPositions(element: HTMLElement) {
		if (this.deviceService.isServer) {
			return null;
		}

		const rect = element?.getBoundingClientRect();
		const documentScrollPositions = this.getDocumentScrollPositions();

		if (!rect || !documentScrollPositions) {
			return;
		}

		return {
			top: rect.top + documentScrollPositions.scrollY,
			bottom: rect.bottom + documentScrollPositions.scrollY,
			left: rect.left + documentScrollPositions.scrollX,
			right: rect.right + documentScrollPositions.scrollX,
		};
	}

	// TODO: заменить вызовы getElementTopPosition на getElementScrollPositions + удалить getElementTopPosition
	getElementTopPosition(element: HTMLElement) {
		if (this.deviceService.isServer) {
			return null;
		}

		const rect = element?.getBoundingClientRect();

		if (!rect) {
			return;
		}


		return rect.top + this.getDocumentScrollPositions().scrollY;
	}

	isInViewport(element: HTMLElement) {
		if (!element || this.deviceService.isServer) {
			return;
		}

		const rect = element.getBoundingClientRect();
		const { viewportWidth, viewportHeight } = this.getViewportSize();

		return (
			rect.top <= viewportHeight &&
			rect.left <= viewportWidth &&
			(rect.top + rect.height) >= 0 &&
			(rect.left + rect.width) >= 0
		);
	}

	isPartiallyInViewport(element: HTMLElement, percentVisible: number = 1) {
		if (!element) return;

		const rect = element.getBoundingClientRect();
		const { viewportHeight } = this.getViewportSize();

		return !(
			Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
			Math.floor(100 - ((rect.bottom - viewportHeight) / rect.height) * 100) < percentVisible
		);
	}

	getDocumentHeight(): number {
		const body = this.document?.body;
		const html = this.document?.documentElement;

		return Math.max(
			body?.scrollHeight,
			body?.offsetHeight,
			html?.clientHeight,
			html?.scrollHeight,
			html?.offsetHeight
		);
	}

	getViewportSize(): { viewportWidth: number, viewportHeight: number} {
		if (this.deviceService.isServer) {
			return null;
		}

		if (this._window?.visualViewport) {
			return {
				viewportWidth: this._window.visualViewport.width,
				viewportHeight: this._window.visualViewport.height,
			};
		}

		return {
			viewportWidth: Math.max(this._window.innerWidth, this.document.documentElement.clientWidth),
			viewportHeight: Math.max(this._window.innerHeight, this.document.documentElement.clientHeight),
		};
	}

	getDocumentScrollPositions(): { scrollY: number, scrollX: number } {
		if (this.deviceService.isServer) {
			return null;
		}

		return {
			scrollY: Math.max(this.document?.documentElement?.scrollTop, this._window.scrollY),
			scrollX: Math.max(this.document?.documentElement?.scrollLeft, this._window.scrollX)
		};
	}

	scrolledToBottom(bottomOffset = 1): boolean {
		return (this._window.innerHeight + this._window.scrollY*bottomOffset) >= this.getDocumentHeight();
	}

	subscribeOnTopPartVisible(ref: ElementRef, afterVisibleAction: () => void): Subscription {
		if (!afterVisibleAction || !ref || this.deviceService.isServer) {
			return;
		}

		return fromEvent(this._window, 'scroll').subscribe(() => {
			const { viewportHeight } = this.getViewportSize();
			const domElement = ref.nativeElement;
			const elementRect = domElement.getBoundingClientRect();
			const topPartIsVisible = viewportHeight - elementRect.y >= 0 && elementRect.y >= 0;

			if (topPartIsVisible) {
				afterVisibleAction?.();
			}
		});
	}

	/** Метод для получения ширины скроллбара (полосы прокрутки) */
	calculateScrollbarWidth(element?: HTMLElement) {
		return element ?
			(element.offsetWidth - element.clientWidth) :
			/** если element не указан, возвращаем ширину полосы прокрутки body: */
			(this._window.innerWidth - this.document.body.clientWidth);
	}

	/**
	 * Метод для блокировки скролла страницы
	 */
	 public blockScroll() {
		const scrollbarWidth = this.calculateScrollbarWidth();

		/**
		 * Если есть скроллбар и отступ ещё не добавлен,
		 * то увеличиваем margin-right у body на значение ширины скроллбара, чтобы страница не дёргалась:
		 */
		if (scrollbarWidth > 0 && !this.addedMarginRightToBody) {
			this.renderer.setStyle(this.document.body, '--scrollbar-w', `${scrollbarWidth}px`, RendererStyleFlags2.DashCase);
			this.addedMarginRightToBody = scrollbarWidth;
		}

		/** Блокируем скролл страницы: */
		this.renderer.addClass(this.document.body, 'ov-hidden');
		this.scrollIsBlocked = true;
	}

	/**
	 * Метод для разблокировки скролла страницы
	 */
	public unblockScroll() {
		/** Если ранее был добавлен отступ, то снимаем его: */
		if (this.addedMarginRightToBody > 0) {
			this.renderer.setStyle(this.document.body, '--scrollbar-w', '0px', RendererStyleFlags2.DashCase);
			this.addedMarginRightToBody = 0;
		}

		/** Разблокируем скролл страницы: */
		this.renderer.removeClass(this.document.body, 'ov-hidden');
		this.scrollIsBlocked = false;
	}

	/** метод для получения координат центра элемента */
	getPositionAtCenter(element: HTMLElement) {
		const { top, left, width, height } = element.getBoundingClientRect();
		return {
			x: left + width / 2,
			y: top + height / 2
		};
	}

	/** метод для рассчёта расстояния между центрами двух элементов */
	getDistanceBetweenElements(firstElement: HTMLElement, secondElement: HTMLElement) {
		const firstPosition = this.getPositionAtCenter(firstElement);
		const secondPosition = this.getPositionAtCenter(secondElement);

		return Math.hypot(firstPosition.x - secondPosition.x, firstPosition.y - secondPosition.y);
	}

	/** метод для проверки, что переданные координаты находятся в пределах rect */
	coordsAreInsideRect(x: number, y: number, rect: DOMRect) {
		return rect?.x <= x &&
			x <= rect?.x + rect?.width &&
			rect?.y <= y &&
			y <= rect?.y + rect?.height;
	}
	/** метод для проверки, что переданные координаты находятся в пределах треугольной области (◥) */
	coordsAreInsideTriangleFromRight(x: number, y: number, rect: DOMRect) {
		return this.coordsAreInsideRect(x, y, rect) &&
			x >= ((rect?.width/rect?.height)*(y - rect?.y) + rect?.x);
	}

	/** метод для проверки, что переданные координаты находятся в пределах треугольной области (◤) */
	coordsAreInsideTriangleFromLeft(x: number, y: number, rect: DOMRect) {
		return this.coordsAreInsideRect(x, y, rect) &&
				x <= ((rect?.width/rect?.height)*(y - rect?.y) + rect?.x);
	}


	setDropHeightToPageBorders({
		drop,
		input,
		position,
	}: {
		drop: HTMLElement;
		input: HTMLElement;
		position: SuggestPosition | keyof typeof SuggestPosition;
	}) {
		const dropStyle = getComputedStyle(drop);
		const dropTranslate = parseFloat(dropStyle?.getPropertyValue('--drop-product-content-translate-y')) || 0;
		let offsetFromTrigger: number = 0;

		if (position === SuggestPosition.under) {
			const wrapperMarginTop = parseFloat(dropStyle?.marginTop) || 0;
			offsetFromTrigger = wrapperMarginTop + dropTranslate;
		} else if (position === SuggestPosition.above) {
			const wrapperMarginBottom = parseFloat(dropStyle?.marginBottom) || 0;
			offsetFromTrigger = wrapperMarginBottom + dropTranslate;
		}

		const inputRect = input.getBoundingClientRect();
		const viewportSize = this.getViewportSize();
		let maxHeight: number = parseFloat(dropStyle?.maxHeight || dropStyle?.height) || 0;

		if (position === SuggestPosition.under) {
			maxHeight = viewportSize.viewportHeight - (inputRect.bottom + offsetFromTrigger);
		} else if (position === SuggestPosition.above) {
			maxHeight = inputRect.top - offsetFromTrigger;
		}

		drop.style.setProperty('--drop-product-content-max-h', `${maxHeight}px`);
	}

}
