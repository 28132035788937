import { Directive, Input, ElementRef, OnInit, OnDestroy, Inject, inject } from '@angular/core';
import { WINDOW } from '../../../tokens';
import {DeviceService} from '../../../services';

@Directive({
	selector: '[sharedContentVisibility]'
})
export class ContentVisibilityDirective implements OnInit, OnDestroy {

	@Input() initialContainIntrinsicSize: string = '10px 500px';

	spaced: WeakMap<HTMLElement, DOMRectReadOnly> = new WeakMap();

	intersectionObserver;
	resizeObserver;

	private deviceService = inject(DeviceService);
	
	constructor(
		private elementRef: ElementRef<HTMLElement | HTMLImageElement>,
		@Inject(WINDOW) private _window: Window,
	) { }

	ngOnInit(): void {
		if (!this.apisIsSupported || this.deviceService.isServer) return;

		const targetElement = this.elementRef.nativeElement;

		type StyleDeclaration = CSSStyleDeclaration & { contentVisibility: string; containIntrinsicSize: string; };
		(targetElement.style as StyleDeclaration).contentVisibility = 'auto';
		(targetElement.style as StyleDeclaration).containIntrinsicSize = this.initialContainIntrinsicSize;

		this.resizeObserver = new ResizeObserver(
			entries => {
				entries.forEach(entry => {
					this.reserveSpace(entry.target, entry.contentRect);
				});
			}
		);

		this.intersectionObserver = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					this.reserveSpace(entry.target, entry.boundingClientRect);
				});
			},
			{ rootMargin: '500px 0px 500px 0px', root: null },
		);

		this.intersectionObserver.observe(targetElement);
		this.resizeObserver?.observe(targetElement);
	}

	ngOnDestroy(): void {
		this.intersectionObserver?.unobserve(this.elementRef.nativeElement);
		this.resizeObserver?.unobserve(this.elementRef.nativeElement);
	}

	get apisIsSupported(): boolean {
		if(this.deviceService.isServer) return;
		return CSS?.supports('content-visibility', 'auto') &&
			'IntersectionObserver' in this._window &&
			'ResizeObserver' in this._window;
	}

	isEqual = (a: number, b: number) => Math.abs(a - b) <= 2;

	rectsNotEqual = (oldRect: DOMRectReadOnly, newRect: DOMRectReadOnly) => !this.isEqual(oldRect.width, newRect.width) || !this.isEqual(oldRect.height, newRect.height);

	reserveSpace = (el, rect: DOMRectReadOnly = el.getClientBoundingRect()) => {
		const oldRect = this.spaced.get(el);

		if (!oldRect || this.rectsNotEqual(oldRect, rect)) {
			this.spaced.set(el, rect);

			el.style.containIntrinsicSize = `${rect.width}px ${rect.height}px`;
		}
	};

}
