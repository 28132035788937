import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrintDirective } from './print.directive';

@NgModule({
	declarations: [PrintDirective],
	imports: [
		CommonModule,
	],
	exports: [PrintDirective],
})
export class PrintModule {}
