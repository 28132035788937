<div
	#container
	[attr.id]="message.id"
	[class]="message.styleClass"
	[ngClass]="['p-toast-message-' + message.severity, 'p-toast-message']"
	[@messageState]="{value: 'visible'}"
	(mouseenter)="onMouseEnter()"
	(mouseleave)="onMouseLeave()"
>
	<div class="p-toast-message-content" role="alert" aria-live="assertive" aria-atomic="true"
		[ngClass]="message.contentStyleClass"
	>
		<div class="d-flex flex-ai-start flex-jc-between w-100p">
			<svg b-shared-svg name="icons_24_2_info-circle" class="icon icon--xl mr-16"></svg>
			<div class="mr-auto">
				<h4 class="h4">{{message.summary}}</h4>
				<p class="mt-8">{{message.detail}}</p>
			</div>
			 <button
					b-shared-button
					icon="icons_24_2_cross"
					iconClass="icon--xl"
					class="btn"
					(click)="onCloseIconClick($event)"
				></button>
		</div>
	</div>
</div>
