export enum EventCategory {
	user_click = 'user_click',
	user_scroll = 'user_scroll',
	user_lead = 'user_lead',
	geolocation = 'geolocation',
	form_success = 'form_success',
	user_click_lk = 'user_click_lk',
	user_lead_lk = 'user_lead_lk',
	user_click_burger = 'user_click_burger',
	user_click_header = 'user_click_header',
}

export enum EventAction {
	more_tariff_button = 'more_tariff_button',
	fb_nur_ibragimov = 'fb_nur_ibragimov',
	fb_dmitry_salatov = 'fb_dmitry_salatov',
	fb_alexey_vasiliev = 'fb_alexey_vasiliev',
	fb_anton_zaharov = 'fb_anton_zaharov',
	fb_robert = 'fb_robert',
	scrolled_50 = 'scrolled_50',
	scrolled_75 = 'scrolled_75',
	scrolled_100 = 'scrolled_100',
	get_invitation_button = 'get_invitation_button',
	phone_success = 'phone success',
	phone_confirmed = 'phone_confirmed',
	entity_success = 'entity success',
	address_success = 'address success',
	manual_input_address_success = 'manual input address success',
	meeting_set_up = 'meeting set up',
	meeting_rescheduled = 'meeting rescheduled',
	get_phone_link_success = 'get_phone_link_success',
	button_get_secret_tariff = 'button_get_secret_tariff',
	button_open_ip_ooo = 'button_open_ip_ooo',
	button_registration = 'button_registration',
	whatsapp_footer = 'whatsapp_footer',
	whatsapp_overlay = 'whatsapp_overlay',
	free_buh = 'free_buh',
	free_qr = 'free_qr',
	wants = 'wants',
	geo = 'geo',
	button_get_buh = 'button_get_buh',
	button_get_qr = 'button_get_qr',
	lead_reg = 'lead_reg',
	get_offer = 'get_offer',
	question_okved = 'question_okved',
	question_gosposh = 'question_gosposh',
	get_acquiring = 'get_acquiring',
	get_pos_acquiring = 'get_pos_acquiring',
	pricing = 'pricing',
	click_phone = 'click_phone', // клик по номеру телефона в футере лендинга
	get_blanc = 'get_blanc',
	sharing = 'sharing',
	get_currency = 'get_currency',
	get_tariff = 'get_tariff',
	get_free_tariff = 'get_free_tariff',
	blanc_dlya_tebya = 'blanc_dlya_tebya',
	blanc_dlya_coff = 'blanc_dlya_coff',
	blanc_dlya_it = 'blanc_dlya_it',
	blanc_dlya_freelancers = 'blanc_dlya_freelancers',
	blanc_dlya_stroiteley = 'blanc_dlya_stroiteley',
	blanc_dlya_internet_mag = 'blanc_dlya_internet_mag',
	specify_city = 'specify_city',
	city_is_off = 'city_is_off',
	city_is_on = 'city_is_on',
	button_get_promo = 'button_get_promo',
	button_get_blanc = 'button_get_blanc',
	want_be_partner = 'want_be_partner',
	click_connect_api = 'click_connect_api',
	click_view_document = 'click_view_document',
	click_connect_api_burger = 'click_connect_api_burger',
	send_payment = 'send_payment',
	multiple_payments = 'multiple_payments',
	statements = 'statements',
	balance = 'balance',
	phone_success_open_api = 'phone_success_open_api',
	click_on_open_an_account = 'click_on_open_an_account',
	click_on_get_support = 'click_on_get_support',
	click_on_free_currency_control = 'click_on_free_currency_control',
	click_on_merchant_acquiring = 'click_on_merchant_acquiring',
	click_on_internet_acquiting = 'click_on_internet_acquiting',
	bank_rate = 'bank_rate',
	connect = 'connect',
	posting_to_the_accounts = 'posting to the accounts',
	taxes = 'taxes',
	working_with_banking = 'working with banking',
	personal_account = 'personal account',
	lead_sol = 'lead sol',
	make_an_appointment = 'make_an_appointment',
	go_to_application = 'go_to_application',
	get_bonuses = 'get_bonuses',
	na_otdykhostatok = 'na_otdykhostatok',
	support_elfemployed = 'support_elfemployed',
	merchant_acquiring = 'merchant_acquiring',
	internet_acquiring = 'internet_acquiring',
	click_on_elama = 'click_on_elama',
	open_an_account = 'open_an_account',
	get = 'get',
	button_click_add_company = 'button_click_add_company',
	open_an_account_in_t = 'open an account in t',
	beru = 'beru',
	Zavernite = 'Zavernite',
	to_chto_nado = 'to chto nado',
	leave_a_request = 'leave a request',
	overnight = 'overnight',
	elama = 'elama', 
	acquiring_0 = 'acquiring_0',
	hot_offer_overnight	= 'hot_offer_overnight',
	hot_offer_china = 'hot_offer_china',
	hot_offer_acquiring_0 = 'hot_offer_acquiring_0',
	open_burger = 'open_burger',
	calculate_loan = 'Calculate loan',
	take_money = 'take_money',
	seller = 'seller',
	huawei = 'huawei',
	samsung_store = 'samsung_store',
	nashstore = 'nashstore',
	rustore = 'rustore',
	xiaomi_getApp = 'xiaomi_getApp',
	offer_pos_acquiring = 'offer_pos_acquiring',
	offer_qr = 'offer_qr',
	offer_overnight = 'offer_overnight',
	offer_loans = '	offer_loans',
	offer_integrations = 'offer_integrations',
	offer_internet_ads = 'offer_internet_ads',
	offer_1c_payments = 'offer_1c_payments',
	offer_nominal = 'offer_nominal',
	offer_dms = 'offer_dms',
	hot_offer_pos_acquiring = 'hot_offer_pos_acquiring',
	hot_offer_1c_payments = 'hot_offer_1c_payments',
	take_it = '	take_it',
	compare_conditions = 'compare_conditions',
	download_pdf_conditions = 'download_pdf_conditions',
	starting = 'starting',
	help = 'help',
	drop_down_payments = 'drop_down_payments',
	drop_down_self_employed = 'drop_down_self_employed',
	drop_down_salary = 'drop_down_salary',
	drop_down_fea = 'drop_down_fea',
	drop_down_loans = 'drop_down_loans',
	drop_down_nominal_account = 'drop_down_nominal_account',
	drop_down_free_accounting = 'drop_down_free_accounting',
	drop_down_passive_income = 'drop_down_passive_income',
	drop_down_transfers_to_individuals = 'drop_down_transfers_to_individuals',
	drop_down_useful = 'drop_down_useful',
	detailed_overnight = 'detailed_overnight',
	detailed_deposits_overnight = 'detailed_deposits_overnight',
	detailed_free_acquiring = 'detailed_free_acquiring',
	detailed_internet_acquiring = 'detailed_internet_acquiring',
	detailed_qr = 'detailed_qr',
	detailed_sol = 'detailed_sol',
	detailed_1c_payments = 'detailed_1c_payments',
	detailed_currency = 'detailed_currency',
	detailed_china = 'detailed_china',
	detailed_tenge = '	detailed_tenge',
	detailed_belarus = 'detailed_belarus',
	detailed_sellers = 'detailed_sellers',
	detailed_loans = 'detailed_loans',
	detailed_nominal = 'detailed_nominal',
	detailed_accounting = 'detailed_accounting',
	detailed_accounting_ausn = 'detailed_accounting-ausn',
	detailed_dms = 'detailed_dms',
	detailed_internet_ads = 'detailed_internet-ads',
	open_an_account_it = 'open_an_account_it',
	how_1c_payments_it = '	how_1c_payments_it',
	show_pricing_it = 'show_pricing_it',
	show_sol_it = 'show_sol_it',
	open_an_account_receive_it = 'open_an_account_receive_it',
	more_detailed_integrations_it = 'more_detailed_integrations_it',
	advanced_for_free = 'advanced_for_free',
	phone_form_blanc_reg = 'phone_form_blanc_reg',
	detailed_open_ip_reg = 'detailed_open_ip_reg',
	detailed_open_ooo_reg = 'detailed_open_ooo_reg',
}

export enum ExponeaTypes {
	registration = 'registration',
	hotelka_email = 'hotelka_email',
	web_action = 'web_action',
	web_screen_view = 'web_screen_view',
	inapp_action = 'inapp action', //Событие используется для webView банкоматов, можно использовать не только для банкоматов, если будет использовано webVew
	click = 'click',
}

export enum ExponeaActionTypes {
	phone = 'phone'
}

export enum ExponeaActionStatus {
	success = 'success',
	confirmed = 'confirmed',
	failure = 'failure'
}
