import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '../svg/svg.module';
import { CheckboxComponent } from './checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [CheckboxComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, SvgModule],
	exports: [CheckboxComponent],
})
export class CheckboxModule {}
