import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { SharedTemplateDirective } from './shared-template.directive';

@NgModule({
	declarations: [SharedTemplateDirective],
	imports: [CommonModule],
	exports: [SharedTemplateDirective],
})
export class SharedTemplateModule {}
