import { Injectable } from '@angular/core';
import { EMPTY, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class DateService {

	getQuarter(date: Date = new Date()): number {
		return Math.floor(date.getMonth() / 3);
	}

	getDaysDifference({
		end,
		start = new Date(),
		fullDay = false,
	}: {
		end: Date;
		start?: Date;
		fullDay?: boolean;
	}) {
		const diffTime = Math.abs(end.getTime() - start.getTime());
		const handler = fullDay ? Math.ceil : Math.floor;
		return handler(diffTime / (1000 * 60 * 60 * 24));
	}

	getAdverbOfDate(date: Date): string | null {
		const now = new Date();

		const isCurrentYear = now.getFullYear() === date.getFullYear();
		const isCurrentMonth = now.getMonth() === date.getMonth();

		if (isCurrentYear && isCurrentMonth) {
			const differenceInDays = now.getDate() - date.getDate();
			switch (differenceInDays) {
			case -2:
				return 'Послезавтра';
			case -1:
				return 'Завтра';
			case 0:
				return 'Сегодня';
			case 1:
				return 'Вчера';
			case 2:
				return 'Позавчера';
			default:
				return null;
			}
		}
		return null;
	}

	parseMilliseconds(ms: number) {
		const hours: number = Math.floor(ms / 1000 / 60 / 60);
		const minutes: number = Math.floor(ms / 1000 / 60 - hours * 60);
		const seconds: number = Math.floor(ms / 1000 - hours * 60 * 60 - minutes * 60);

		return { hours, minutes, seconds };
	}

	stringToDate(dateString: string) {
		const isoExp = /^\s*(\d{4})-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)*\.*\d*(Z)?\s*$/;

		let date = new Date(NaN);
		const parts: any = isoExp.exec(dateString);
		if (parts) {
			date =
				parts[parts.length - 1] === 'Z'
					? new Date(Date.UTC(parts[1], parts[2] - 1, parts[3], parts[4], parts[5], parts[6]))
					: new Date(parts[1], parts[2] - 1, parts[3], parts[4], parts[5], parts[6]);
		}
		return date;
	}

	ignoreTimezone(value: Date | string): string {
		const date = new Date(value);
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -1);
	}

	itsSameDay (firstDate: Date, secondDate: Date): boolean {
		return firstDate instanceof Date &&
		secondDate instanceof Date &&
		firstDate.getFullYear() === secondDate.getFullYear() &&
		firstDate.getMonth() === secondDate.getMonth() &&
		firstDate.getDate() === secondDate.getDate();
	}

	// Возвращает Observable, который испускает в next оставшиеся миллисекунды до конца targetDate каждую секунду, пока не наступит targetDate.
	// Observable завершается, (complete) когда дата targetDate наступила
	timerUntilDate(targetDate: Date, initialDelay = 0, period = 1000) {
		const isFuture = targetDate.getTime() > new Date().getTime();
		if (!isFuture) {
			return EMPTY;
		}

		return timer(initialDelay, period)
			.pipe(
				takeWhile(() => targetDate.getTime() - new Date().getTime() > 0),
				map(() => Math.ceil(
					targetDate.getTime() - new Date().getTime()
				)),
			);
	}

	isDateBetween(start: Date | string, end: Date | string, dateMeta: Date | string): boolean {
		if (!start || !end || !dateMeta) return false;

		const _startDate = typeof start === 'string' ? new Date(start) : start;
		const _endDate = typeof end === 'string' ? new Date(end) : end;
		const _dateMeta = typeof dateMeta === 'string' ? new Date(dateMeta) : dateMeta;
		let date: Date = new Date(_dateMeta.getFullYear(), _dateMeta.getMonth(), _dateMeta.getDate());
		return _startDate.getTime() <= date.getTime() && _endDate.getTime() >= date.getTime();
	}

	isDateEquals(value: Date | string, dateMeta: Date | string): boolean {
		if (!value || !dateMeta) return false;

		const _value = typeof value === 'string' ? new Date(value) : value;
		const _dateMeta = typeof dateMeta === 'string' ? new Date(dateMeta) : dateMeta;
		return _value?.getDate() === _dateMeta.getDate() && _value?.getMonth() === _dateMeta.getMonth() && _value?.getFullYear() === _dateMeta.getFullYear();
	}
}
