<ng-content></ng-content>

<ng-container *ngIf="errorVisibility" [ngSwitch]="validationType">
	<div *ngSwitchCase="validationMessages.tooltip" [ngClass]="classes" [@lifecycleTrigger]>
		<div [ngClass]="wrapperClass">
			<div [ngClass]="containerClass">
				<ng-container *ngIf="errorTemplate">
					<ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
				</ng-container>
			</div>
		</div>
		<ng-content select="optionalContent"></ng-content>
	</div>

	<div
		*ngSwitchCase="validationMessages.inlineText"
		[@lifecycleTrigger]
		class="hint hint--error"
		[ngClass]="wrapperClass"
	>
		<ng-container *ngIf="errorTemplate">
			<ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
		</ng-container>
	</div>
</ng-container>
