import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeSliderComponent } from './range-slider.component';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
	],
	exports: [
		RangeSliderComponent,
	],
	declarations: [
		RangeSliderComponent,
	],
})
export class RangeSliderModule {}
