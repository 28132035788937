<!-- <svg
	*ngIf="issuingBankSvg"
	b-shared-svg
	[name]="issuingBankSvg"
	class="logo logo--secondary"
	height="38"
></svg> -->

<svg
	*ngIf="paymentSystemLogo"
	b-shared-svg
	[name]="paymentSystemLogo"
	class="payment-card__logo"
></svg>

<p
	class="payment-card__number"
	*ngFor="let part of separatedCardNumber; ; let last = last; trackBy: trackByCardNumberPart"
>{{ part }}<ng-container *ngIf="!last">&nbsp;</ng-container>
</p>

<!-- <time
	*ngIf="date"
	[attr.datetime]="datetimeAttr"
>{{ date }}</time> -->
