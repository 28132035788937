import {
	Directive,
	OnDestroy,
	Input,
	ElementRef,
	OnChanges,
	AfterViewInit,
	Renderer2,
	RendererStyleFlags2,
	OnInit,
	Output, EventEmitter, Inject
} from '@angular/core';
import { DeviceService } from '../../../services/device.service';
import {
	BehaviorSubject, debounceTime, distinctUntilChanged, filter, forkJoin, from,
	fromEvent, map, mergeMap, Observable, of, Subscription, take, tap, timer,
} from 'rxjs';
import { ImageExtension, ImageType } from './models/image.enums';
import { HttpClient } from '@angular/common/http';
import { ImageService } from './image.service';
import { ScrollService } from '../../../services/scroll.service';
import { WINDOW } from '../../../tokens';


@Directive({
	selector: '[sharedImage]'
})
export class ImageDirective implements OnInit, AfterViewInit, OnDestroy, OnChanges {

	extsChecked = new BehaviorSubject<boolean>(false);
	extsChecked$ = this.extsChecked.asObservable().pipe(distinctUntilChanged());

	lazyImageObserver: IntersectionObserver;
	subscriptions = new Subscription();
	avifSupported: boolean;
	webpSupported: boolean;
	previewDirPath = 'assets/img-preview';
	fullsizeDirPath = 'assets/img';
	afterPropName = '--lazy-bg-after';
	beforePromName = '--lazy-bg-before';
	loadedFullsizeImages: string[] = [];
	loadedPreviewImages: string[] = [];
	scrollSubscription: Subscription;
	timerSubscription: Subscription;
	scrolled = new BehaviorSubject<boolean>(false);

	/**
	 * Коллбек при успешном расчете размера картинки
	 */
	@Output() completedCalculateSize: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Коллбек при успешной загрузке картинки
	 */
	@Output() loaded: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Флаг для вкл/выкл превью-изображений
	 */
	@Input() preview: boolean = true;

	/**
	 * URL (или список URL) к fullsize-изображению
	 *
	 * @example
	 * source="landing/image.png"
	 * [source]="['landing/image.png', 'landing/picture.jpg']"
	 */
	@Input() source: string | string[];

	/**
	 * URL (или список URL) к fullsize-изображению для псевдоэлемента after
	 *
	 * @example
	 * afterSource="landing/image.png"
	 * [afterSource]="['landing/image.png', 'landing/picture.jpg']"
	 */
	@Input() afterSource: string | string[];

	/**
	 * URL (или список URL) к fullsize-изображению для псевдоэлемента before
	 *
	 * @example
	 * beforeSource="landing/image.png"
	 * [beforeSource]="['landing/image.png', 'landing/picture.jpg']"
	 */
	@Input() beforeSource: string | string[];

	/**
	 * Отклонение от вьюпорта при ленивой загрузке
	 */
	@Input() rootMargin: string = '100%';

	/**
	 * Число или массив чисел, указывающий, при каком проценте видимости целевого элемента должна начаться загрузка изображения
	 */
	@Input() threshold: number | number[] = 0;

	/**
	 * Флаг для вкл/выкл блюра preview изображений
	 */
	@Input() previewBlur: boolean = false;

	constructor(
		private elementRef: ElementRef<HTMLElement | HTMLImageElement>,
		private deviceService: DeviceService,
		private http: HttpClient,
		private imageService: ImageService,
		private renderer: Renderer2,
		private scrollService: ScrollService,
		@Inject(WINDOW) private _window: Window,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.scrolled
				.asObservable()
				.pipe(
					distinctUntilChanged(),
					filter(Boolean),
				)
				.subscribe({
					next: () => this.setImages(),
				})
		);
	}

	ngAfterViewInit() {
		if (this.deviceService.isServer) return;

		forkJoin({
			avif: this.imageService.checkAvifSupport(),
			webp: this.imageService.checkWebpSupport(),
		})
			.pipe(take(1))
			.subscribe(({ avif, webp }) => {
				this.avifSupported = avif;
				this.webpSupported = webp;

				this.extsChecked.next(true);
				this.extsChecked.complete();
			});
	}

	ngOnChanges(changes) {
		if (this.deviceService.isServer) return;

		if (!changes.source && !changes.afterSource && !changes.beforeSource) return;

		if (this.imageType === ImageType.img && this.elementRef?.nativeElement?.hasAttribute('src') && !this.source) {
			console.error('sharedImage: для тега <img> вместо атрибута \'src\' нужно указать параметр \'source\'');
			return;
		}

		if (!this.isSourcePathCorrect()) {
			console.error(`${this.constructor.name}: Неправильный путь в @Input source. Путь не должен начинаться с assets`);
			return;
		}

		if (this.extsChecked.getValue()) {
			this.startLazy();
			return;
		}

		this.subscriptions.add(
			this.extsChecked$.pipe(filter(Boolean), take(1))
				.subscribe({
					next: () => this.startLazy(),
				})
		);
	}

	ngOnDestroy() {
		this.lazyImageObserver?.unobserve(this.elementRef?.nativeElement);
		this.scrollSubscription?.unsubscribe();
		this.timerSubscription?.unsubscribe();
		this.subscriptions?.unsubscribe();
	}

	startLazy() {
		if (this.deviceService.isServer) return;

		if (!this.intersectionObserverIsSupported) {
			this.setImages();
			return;
		}

		if (this.preview) {
			if (this.previewBlur) {
				this.setImageFilter('blur(1rem)');
			}

			this.setImages(true);
		}

		this.lazyImageObserver?.unobserve?.(this.elementRef?.nativeElement);
		this.scrollSubscription?.unsubscribe();
		this.timerSubscription?.unsubscribe();
		this.scrolled.next(false);

		this.lazyImageObserver = new IntersectionObserver((entries, observer) => {
			if (this.deviceService.isServer) return;

			const targetEntry = entries.find(entry => entry.target === this.elementRef?.nativeElement);

			if (!targetEntry || !targetEntry?.isIntersecting) return;

			this.markAsScrolled();
		}, {
			root: null, // null > viewport
			rootMargin: this.rootMargin,
			threshold: this.threshold,
		});

		this.lazyImageObserver.observe(this.elementRef?.nativeElement);

		this.scrollSubscription = fromEvent(this._window, 'scroll')
			.pipe(debounceTime(150))
			.subscribe({
				next: () => {
					const isPartiallyInViewport = this.scrollService.isPartiallyInViewport(this.elementRef?.nativeElement);

					if (isPartiallyInViewport) {
						this.markAsScrolled();
					}
				}
			});

		this.timerSubscription = timer(1000).subscribe({
			next: () => {
				const isPartiallyInViewport = this.scrollService.isPartiallyInViewport(this.elementRef?.nativeElement);

				if (isPartiallyInViewport) {
					this.markAsScrolled();
				}
			}
		});
	}

	loadAndSetImage({ path, rawPath, before = false, after = false, preview = false }: {
		path: string,
		rawPath: string,
		before?: boolean,
		after?: boolean,
		preview?: boolean,
	}): Observable<string> {
		if (this.deviceService.isServer) return of(null);

		let observable: Observable<string>;

		const imageIsDownloadable = this.imageService.imageIsDownloadable(path);
		const cachedImage = this.imageService.getCachedImage(path);

		if (imageIsDownloadable) {
			observable = this.imageService.cachedImages
				.pipe(
					filter(cachedImages => Boolean(cachedImages[path])),
					map(cachedImages => cachedImages[path]),
					take(1),
				);
		} else if (cachedImage) {
			observable = of(cachedImage).pipe(take(1));
		} else {
			observable = this.http.get(path, {
				responseType: 'blob'
			}).pipe(
				mergeMap(blob => {
					return from(
						new Promise<string>(resolve => {
							let reader = new FileReader();

							reader.addEventListener('loadend', () => {
								resolve(reader.result as string);
							});

							reader.readAsDataURL(blob);
						})
					);
				}),
				take(1),
			);

			this.imageService.addDownloadableImage(path);
		}

		return observable
			.pipe(
				tap({
					next: dataUrl => {
						this.imageService.saveImage(path, dataUrl);

						if (preview) {
							this.loadedPreviewImages.push(rawPath);
						} else {
							this.loadedFullsizeImages.push(rawPath);
						}

						if (!preview && this.previewBlur) {
							this.setImageFilter(null);
						}

						if (!(preview && this.loadedFullsizeImages.includes(rawPath))) {
							this.setImage({ path: dataUrl, before, after });
						}
					}
				}),
				take(1),
			);
	}

	getSourceList(source: string | string[]): string[] {
		let sources = [];
		if (typeof source === 'string') {
			sources = [source];
		} else if (Array.isArray(source)) {
			sources = [...source];
		}

		return sources;
	}

	setImages(preview: boolean = false) {
		this.getSourceList(this.source)?.forEach(path => {
			this.subscriptions.add(
				this.loadAndSetImage({
					path: this.getImagePath({ path, preview }),
					rawPath: path,
					preview,
				}).subscribe()
			);
		});

		this.getSourceList(this.afterSource)?.forEach(path => {
			const elRef = this.elementRef?.nativeElement;
			if (elRef) this.renderer.addClass(elRef, 'lazy-bg-after');
			this.subscriptions.add(
				this.loadAndSetImage({
					path: this.getImagePath({ path, preview }),
					rawPath: path,
					after: true,
					preview,
				}).subscribe()
			);
		});

		this.getSourceList(this.beforeSource)?.forEach(path => {
			const elRef = this.elementRef?.nativeElement;
			if (elRef) this.renderer.addClass(elRef, 'lazy-bg-before');
			this.subscriptions.add(
				this.loadAndSetImage({
					path: this.getImagePath({ path, preview }),
					rawPath: path,
					before: true,
					preview,
				}).subscribe()
			);
		});
	}

	addBackgroundNotation(path: string): string {
		return `url(${path})`;
	}

	getBackgroundValue(sources: string[]) {
		const backgroundImageResult = [];

		sources.forEach(source => {
			const fullsizePath = this.getImagePath({ path: source });
			const fullsizeImage = this.imageService.getCachedImage(fullsizePath);

			if (fullsizeImage) {
				backgroundImageResult.push(fullsizeImage);
				return;
			}

			const previewPath = this.getImagePath({ path: source, preview: true });
			const previewImage = this.imageService.getCachedImage(previewPath);

			if (previewImage) {
				backgroundImageResult.push(previewImage);
				return;
			}
		});

		if (backgroundImageResult?.length) {
			return backgroundImageResult
				.map(this.addBackgroundNotation)
				.join(', ');
		}

		return null;
	}

	setImage({ path, before = false, after = false }: {
		path: string,
		before?: boolean,
		after?: boolean,
	}) {
		if (this.deviceService.isServer) return;

		const element = this.elementRef.nativeElement;

		if (this.imageType === ImageType.img) {
			(element as HTMLImageElement).src = path;

			(element as HTMLImageElement).onload = () => {
				this.loaded.emit();
			};

			this.completedCalculateSize.emit();

			return;
		}

		if (this.imageType === ImageType.background && element.style) {
			if (after && this.afterSource) {
				const sources = this.getSourceList(this.afterSource);
				const bgImageValue = this.getBackgroundValue(sources);

				this.renderer.setStyle(element, this.afterPropName, bgImageValue || '', RendererStyleFlags2.DashCase);
				this.loaded.emit();
				this.completedCalculateSize.emit();
				return;
			}

			if (before && this.beforeSource) {
				const sources = this.getSourceList(this.beforeSource);
				const bgImageValue = this.getBackgroundValue(sources);

				this.renderer.setStyle(element, this.beforePromName, bgImageValue || '', RendererStyleFlags2.DashCase);
				this.loaded.emit();
				this.completedCalculateSize.emit();
				return;
			}

			if (this.source) {
				const sources = this.getSourceList(this.source);
				const bgImageValue = this.getBackgroundValue(sources);

				element.style.backgroundImage = bgImageValue;
				this.loaded.emit();
				this.completedCalculateSize.emit();
				return;
			}
		}
	}

	get intersectionObserverIsSupported(): boolean {
		return this.deviceService.isServer ?
			false :
			'IntersectionObserver' in this._window;
	}

	setImageFilter(filter: string | null) {
		if (this.deviceService.isServer) return;

		this.elementRef.nativeElement.style.filter = filter;
	}

	get imageType(): ImageType {
		return this.elementRef?.nativeElement?.tagName === 'IMG' ?
			ImageType.img :
			ImageType.background;
	}

	getImagePath({ path, preview = false }: {
		path: string,
		preview?: boolean,
	}): string {
		let url = `${preview ? this.previewDirPath : this.fullsizeDirPath}/${path}`;
		let newExt = null;

		if (!preview && this.avifSupported) newExt = ImageExtension.avif;
		else if (this.webpSupported) newExt = ImageExtension.webp;

		if (newExt) {
			url = url.replace(/\.[^.]+$/, `.${newExt}`);
		}

		return url;
	}

	private isSourcePathCorrect() {
		const isCorrect = (path: string): boolean => !(path.match(/^[\/]?assets/));
		const targetSources = [this.source, this.afterSource, this.beforeSource].filter(Boolean);

		return targetSources.every(src => !src || (typeof src === 'string' ? isCorrect(src) : src.every(isCorrect)));
	}

	markAsScrolled() {
		this.lazyImageObserver?.unobserve(this.elementRef?.nativeElement);
		this.scrollSubscription?.unsubscribe();
		this.timerSubscription?.unsubscribe();
		this.scrolled.next(true);
	}

}
