import { Pipe, PipeTransform } from '@angular/core';
import { SharedMapper } from './models/SharedMapper';

@Pipe({
	name: 'sharedMapper',
	pure: true,
})
export class SharedMapperPipe implements PipeTransform {

	transform<T, G>(value: T, mapper: SharedMapper<T, G>, ...args: any[]): G {
		return mapper(value, ...args);
	}

}
