import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
@Component({
	selector: '[b-shared-checkbox]',
	templateUrl: './checkbox.component.html',
})
export class CheckboxComponent {
	@HostBinding('class') class = 'checkbox';

	@Input() control:UntypedFormControl = new UntypedFormControl(false);
	@Input() size = 'md';
	@Input() checked = false;

	get height(): number {
		switch (this.size) {
		case 'sm':
			return 20;
		default:
			return 24;
		}
	}


	constructor(public elRef: ElementRef) {}
}
