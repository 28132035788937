import {NgModule} from '@angular/core';
import {BankAccountMaskingPipe} from './bank-account-masking.pipe';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [BankAccountMaskingPipe],
	imports: [CommonModule],
	exports: [BankAccountMaskingPipe]
})
export class BankAccountMaskingModule {}
