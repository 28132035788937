import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { ButtonModule } from '../button/button.module';
import { SvgModule } from '../svg/svg.module';
import { KeyboardControlModule } from '../../directives/keyboard-control/keyboard-control.module';
import { ArrowNavigationModule } from '../../directives/arrow-navigation/arrow-navigation.module';

@NgModule({
	declarations: [
		DropdownComponent,
	],
	imports: [
		CommonModule,
		ButtonModule,
		SvgModule,
		KeyboardControlModule,
		ArrowNavigationModule,
	],
	exports: [
		DropdownComponent
	],
})
export class DropdownModule { }
