import { Observable } from 'rxjs';

export const mutation = (
	target: any,
	config: any
): Observable<MutationRecord[]> => {
	return new Observable((observer) => {
		const mutation = new MutationObserver((mutations, instance) => {
			observer.next(mutations);
		});
		mutation.observe(target, config);
		const unsubscribe = () => {
			mutation.disconnect();
		};
		return unsubscribe;
	});
};
