import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedClassModule } from '../../directives/shared-class/shared-class.module';
import { SvgModule } from '../svg/svg.module';
import { ButtonComponent } from './button.component';

@NgModule({
	declarations: [ButtonComponent],
	imports: [CommonModule, SvgModule, SharedClassModule],
	exports: [ButtonComponent],
})
export class ButtonModule {}
