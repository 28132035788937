import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationElement, NavigationElementsGroups } from './models/NavigationElement';


@Injectable({
	providedIn: 'root',
})
export class ArrowNavigationService {

	public sides = {
		ArrowUp: {
			active: 'top',
			elements: 'bottom',
		},
		ArrowDown: {
			active: 'bottom',
			elements: 'top',
		},
		ArrowLeft: {
			active: 'left',
			elements: 'right',
		},
		ArrowRight: {
			active: 'right',
			elements: 'left',
		},
	};

	private navigationElements$ = new BehaviorSubject<NavigationElementsGroups>({});
	public navigationElements = this.navigationElements$.asObservable();
	public get _navigationElements(): NavigationElementsGroups {
		return this.navigationElements$.getValue();
	}

	addNavigationElement(groupId: string, element: NavigationElement) {
		const group = this._navigationElements?.[groupId] || [];
		const alreadyExists = group?.includes(element) ||
			group?.some(navElement => navElement.element === element.element);

		if (alreadyExists) return;

		this.navigationElements$.next({
			...this._navigationElements,
			[groupId]: [
				...group,
				element,
			].filter(Boolean),
		});
	}

	removeNavigationElement(groupId: string, navigationElement: NavigationElement) {
		if (!this._navigationElements?.[groupId]) return;

		const newList = {...this._navigationElements};

		const targetIndex = newList[groupId].findIndex(element => element.element === navigationElement.element);

		if (targetIndex !== -1) {
			newList[groupId].splice(targetIndex, 1);
		}

		if (!newList[groupId].length) {
			delete newList[groupId];
		}

		this.navigationElements$.next(newList);
	}

}
