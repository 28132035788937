/** Позиционирование сайдбара */
export enum SidebarPosition {
	left = 'left',
	right = 'right',
}

/** Режим отображения лоадера */
export enum LoaderType {
	/** Заблокированный контент, белый фон */
	block = 'block',
	/** Полупрозрачный фон */
	translucent = 'translucent',
}

/** Тип оверлея */
export enum OverlayType {
	popup = 'popup',
	sidebar = 'sidebar',
	sidebarSecondary = 'sidebarSecondary',
	dropdownSidebar = 'dropdownSidebar',
	successScreen = 'successScreen',
}

/** Блок для кастомного скролла */
export enum ScrollDestination {
	inner = 'inner',
	wrapper = 'wrapper',
	content = 'content',
}

export type QueryParams = {
	[key in string]?: string;
}
