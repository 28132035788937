import { Directive, Input, ElementRef, Output, EventEmitter, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { DomEventsService } from '../../../services/dom-events/dom-events.service';

/** Директива для управления DOM элементами с клавиатуры */
@Directive({
	selector: '[sharedKeyboardControl]'
})
export class KeyboardControlDirective implements OnInit, OnDestroy {

	@HostBinding('attr.tabindex') get tabindexAttr(): string {
		return this.tabindex || null;
	}

	@HostBinding('attr.role') get roleAttr(): string {
		return this.role || '';
	}

	/**
	 * Идентификаторы клавиш, при нажатии на которые нужно вызывать метод activeElementKeyHandler.
	 * Метод activeElementKeyHandler вызывается только когда target-элемент (ElementRef) находится в фокусе (document.activeElement)
	 */
	@Input() activeElementKeys: string[] = ['Enter', 'Space', ' '];
	@Input() tabindex: string = '0';
	@Input() role: string = 'button';

	/**
	 * Метод, вызывающийся при нажатии на те клавиши, идентификаторы которых указаны в параметре activeElementKeys.
	 * Метод activeElementKeyHandler вызывается только когда target-элемент (ElementRef) находится в фокусе (document.activeElement)
	 */
	@Output() activeElementKeyHandler = new EventEmitter<KeyboardEvent>();

	constructor(
		public elementRef: ElementRef,
		private domEventsService: DomEventsService,
	) {}

	ngOnInit() {
		this.domEventsService.registerKeyboardControl(this);
	}

	ngOnDestroy(): void {
		this.domEventsService.removeKeyboardControl(this);
	}

}
