import { Injectable } from '@angular/core';

type Opf = 'physicalPerson' | 'organization' | null;

@Injectable({
	providedIn: 'root',
})
export class RequisitesService {

	getOpfByInn(inn: string | number): Opf {
		const length = inn?.toString().replace(/[^0-9]+/g, '').length;

		switch (length) {
		case 10:
			return 'organization';
		case 12:
			return 'physicalPerson';
		default:
			return null;
		}
	}

	getOpfByOgrn(ogrn: string | number): Opf {
		const length = ogrn?.toString().replace(/[^0-9]+/g, '').length;

		switch (length) {
		case 13:
			return 'organization';
		case 15:
			return 'physicalPerson';
		default:
			return null;
		}
	}
}
