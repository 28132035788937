<div
	#container
	class="p-toast p-component p-toast-bottom-left"
>
	<b-shared-toast-item *ngFor="let msg of messages; let i=index"
		[message]="msg" [index]="i"
		(closeEvent)="onMessageClose($event)"
		@toastAnimation
		(@toastAnimation.start)="onAnimationStart($event)"
	></b-shared-toast-item>
</div>
