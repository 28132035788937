import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, Output, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { SharedTemplateDirective } from '../../directives';
import { Tab } from './models/Tab';

/**
 * @example Пример без *ngFor
	<b-shared-tabs
		[(activeTab)]="activeTabId"
		(tabChangeHandler)="tabChangeHandler($event)"
		buttonClass="btnClass someClass"
		activeButtonClass="tab--active"
	>
		<ng-template sharedTemplate="tabTitle-someId1">Tab 1</ng-template>
		<ng-template sharedTemplate="tabTitle-someId2" [disabled]="true">Tab 2</ng-template>
		<ng-template sharedTemplate="tabTitle-someId3">Tab 3</ng-template>

		<ng-template sharedTemplate="tabContent-someId1">Content 1</ng-template>
		<ng-template sharedTemplate="tabContent-someId2">Content 2</ng-template>
		<ng-template sharedTemplate="tabContent-someId3">Content 3</ng-template>
	</b-shared-tabs>
 *
 * @example Пример с *ngFor
	<b-shared-tabs
		[(activeTab)]="activeTabId"
		(tabChangeHandler)="tabChangeHandler($event)"
		buttonClass="btnClass someClass"
		activeButtonClass="tab--active"
	>
		<ng-container *ngFor="let tabId of tabList">
			<ng-template
				sharedTemplate="tabTitle-{{tabId}}"
				[disabled]="tabId === disabledTabId"
			>
				Tab {{tabId}}
			</ng-template>
			<ng-template sharedTemplate="tabContent-{{tabId}}">Content {{tabId}}</ng-template>
		</ng-container>
	</b-shared-tabs>
 */
@Component({
	selector: 'b-shared-tabs',
	templateUrl: './tabs.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class TabsComponent implements AfterContentChecked {

	private _activeTab: string | null = null;
	@Input() get activeTab(): string | null {
		return this._activeTab;
	}
	set activeTab(value: string | null) {
		this._activeTab = value;
		this.actualizeSelectedTab(value);
	}

	@Input() buttonClass: string | null = 'switch__item';
	@Input() activeButtonClass: string | null = 'switch__item--selected';
	@Input() tabsClass: string | null = null;
	@Input() tabPanelClass: string | null = null;

	/** Флаг для смены фокуса на первый фокусируемый элемент контента активной панели (при переключении табов) */
	@Input() changeFocusOnToggle: boolean = true;

	@Output() tabChangeHandler = new EventEmitter<Tab>();

	@HostBinding('attr.role') role = 'tablist';

	tabs$ = new BehaviorSubject<Tab[]>([]);
	tabs = this.tabs$.asObservable().pipe(distinctUntilChanged());

	selectedTab$ = new BehaviorSubject<Tab | null>(null);
	selectedTab = this.selectedTab$
		.asObservable()
		.pipe(
			distinctUntilChanged(),
		);

	@ContentChildren(SharedTemplateDirective) tabsTemplates: QueryList<SharedTemplateDirective>;

	@ViewChild('tabPanelElement') tabPanelElement: ElementRef<HTMLDivElement>;

	constructor(
		private changeDetector: ChangeDetectorRef,
	) {}

	ngAfterContentChecked(): void {
		const templates = this.tabsTemplates.toArray();
		const titles = templates.filter(template => template.getName().includes('tabTitle'));
		const contents = templates.filter(template => template.getName().includes('tabContent'));

		this.tabs$.next(
			titles
				.map(title => {
					const id = title.getName().replace('tabTitle-', '');
					const content = contents.find(i => i.getName().replace('tabContent-', '') === id);

					if (!content) return null;

					return {
						id,
						disabled: title.disabled,
						titleTemplate: title.template,
						contentTemplate: content.template,
					};
				})
				.filter(Boolean)
		);

		this.actualizeSelectedTab(this.activeTab);
	}

	trackByTabs(index: number, tab: Tab) {
		return tab.id;
	}

	tabClickHandler(event: Event, tab: Tab) {
		event?.preventDefault();
		(event?.currentTarget as HTMLButtonElement)?.blur(); // просьба дизайнеров

		if (tab.disabled) return;

		const isOldTab = this.activeTab === tab.id;

		if (isOldTab) return;

		this.activeTab = tab.id;
		this.tabChangeHandler.emit(tab);

		if (this.changeFocusOnToggle && this.tabPanelElement?.nativeElement) {
			this.changeDetector.detectChanges();

			const firstFocusableElement = this.tabPanelElement?.nativeElement?.querySelector<HTMLElement>(
				'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
			);

			firstFocusableElement?.focus();
		}
	}

	actualizeSelectedTab(activeTabId: string | null) {
		const tabs = this.tabs$.getValue();
		const selectedTab = tabs.find(i => i.id === activeTabId && !i.disabled);

		tabs.forEach(tab => tab.cssClass = null);

		if (selectedTab && this.activeButtonClass) {
			selectedTab.cssClass = this.activeButtonClass;
		}

		this.selectedTab$.next(selectedTab || null);
	}

}
