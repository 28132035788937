import {
	Directive,
	HostListener,
	Input,
} from '@angular/core';
import { FileService } from '../../../services/file.service';

@Directive({
	selector: '[sharedPrint]'
})
export class PrintDirective {

	/**
	 * Файл для печати: ArrayBuffer, Blob или URL к файлу
	 *
	 * @example
	 * [printableFile]="ArrayBuffer | Blob"
	 * printableFile="/podarok-za-otzyv.pdf"
	 */
	@Input() printableFile: ArrayBuffer | Blob | string | null = null;

	/**
	 * mime type файла для печати. Используется, если в printableFile передан ArrayBuffer
	 */
	@Input() mime: string | null = 'application/pdf';

	@HostListener('pointerup', []) pointerupHandler() {
		if (!this.printableFile) return;

		this.fileService.printFile(this.printableFile, this.mime);
	}

	constructor(
		private fileService: FileService,
	) {}

}
