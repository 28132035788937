import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MaskService {

	/**
		* Маски счетов нерезидентов
		*/
	nonResidentAccounts: RegExp[] = [
		/4080[4-9]\d{15}/,
		/45816\d{15}/,
		/45916\d{15}/,
		/30111\d{15}/,
		/30114\d{15}/,
		/30117\d{15}/,
		/30119\d{15}/,
		/3012[2-3]\d{15}/,
		/3023[0-1]\d{15}/,
		/30412\d{15}/,
		/30421\d{15}/,
		/30423\d{15}/,
		/30606\d{15}/,
		/31703\d{15}/,
		/31803\d{15}/,
		/32402\d{15}/,
		/32502\d{15}/,
		/40812\d{15}/,
		/40814\d{15}/,
		/40815\d{15}/,
		/40818\d{15}/,
		/425\d{17}/,
		/440\d{17}/,
		/456\d{17}/,
		/473\d{17}/,
		/314\d{17}/,
		/316\d{17}/,
		/321\d{17}/,
		/323\d{17}/,
	];

	/**
		* Маски счетов физ.лиц
		*/
	flAccounts: RegExp[] = [
		/40817\d{15}/,
		/40820\d{15}/,
		/423\d{17}/,
		/426\d{17}/,
		/30222\d{15}/,
		/30232\d{15}/,
		/30233\d{15}/,
		/47422\d{15}/,
	];

	/**
		* Маски счетов ИП
		*/
	ipAccounts: RegExp[] = [/408\d{17}/];

	/**
		* Маски счетов ЮЛ
		*/
	ulAccounts: RegExp[] = [/40702\d{15}/, /40703\d{15}/];

	// только цифры
	onlyNumbers(value) {
		return value?.replace(/[^0-9]+/g, '');
	}

	// кириллица, дефис и пробельные символы
	cyrillic(value) {
		return value?.replace(/[^а-яА-ЯёЁ-\s]+/g, '');
	}

	parsePhone(phone: string) {
		const clean = phone?.toString().replace(/\D\+/g, '');
		const needAddPlus = clean.startsWith('7');
		const result = phone.replace(/^(\+?[78])?(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1 ($2) $3-$4-$5');

		return needAddPlus ? `+${result}` : result;
	}
}
