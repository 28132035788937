/**
 * Декоратор для мемоизации чистых функций и геттеров:
 * - Для геттеров: Заменяет геттер на его вычесленное значение при его первом вызове
 * - Для функций: Отслеживает аргументы последнего вызова и последнее возвращаемое значение, пропуская вычисление, когда аргументы равны при строгом равенстве
 *
 * ```
 * @sharedMemo
 * get getter() {
 * 	return this.pureFunction(100);
 * }
 * ```
 *
 * ```
 * @sharedMemo
 * summ(a: number, b: number) {
 * 	return a + b;
 * }
 * ```
 */
export function sharedMemo<T>(
	_target: Object,
	propertyKey: string,
	{ get, enumerable, value }: TypedPropertyDescriptor<T>,
): TypedPropertyDescriptor<T> {
	if (get) {
		return {
			enumerable,
			get(): T {
				const value = get.call(this);

				Object.defineProperty(this, propertyKey, { enumerable, value });

				return value;
			},
		};
	}

	if (typeof value !== 'function') {
		throw new Error('sharedMemo можно использовать только с функциями и геттерами');
	}

	const original = value;

	return {
		enumerable,
		get(): T {
			let previousArgs: ReadonlyArray<unknown> = [];
			let previousResult: any;

			const patched = (...args: Array<unknown>) => {
				if (
					previousArgs.length === args.length &&
					args.every((arg, index) => arg === previousArgs[index])
				) {
					return previousResult;
				}

				previousArgs = args;
				previousResult = original.apply(this, args);

				return previousResult;
			};

			Object.defineProperty(this, propertyKey, {
				value: patched,
			});

			return patched as any;
		},
	};
}

